import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Col, Form, Button, InputGroup, Row } from "react-bootstrap";
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import useDivisions from '../../../hooks/useDivisions';

function StudentProfileEdit() {
    
    const { register, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const navigate = useNavigate()

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false)
    const [ student, setStudent ] = useState({})
    const [ file, setFile ] = useState('')
    const [ phone, setPhone ] = useState('');
    const [ guardianPhone, setGuardianPhone ] = useState('');
    const [ previewImage, setPreviewImage] = useState('');
    const [ isRequested, setIsRequested ] = useState(false);

    useEffect(() => {

        if(Object.keys(student).length === 0) {
            getStudent()
        }

    },[ student ]);

    const getStudent = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/student/profile`
        }

        GET(payload).then(res => {
            const { data } = res.data

            if(data['profile_requested']) {
                setIsRequested(true)
            }

            setPreviewImage(data['picture_path']);
            setPhone(data.phone)
            setGuardianPhone(data.guardian_phone)
            setIsLoading(false)
            setStudent(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 404) {
                navigate('/student/profile')
            }
        })

    }

    const onSubmit = (data) => {

        if(!isRequested) {

            clearErrors('phone')
            clearErrors('guardian_phone')

            data['phone'] = phone
            data['guardian_phone'] = guardianPhone

            /* if (!data.phone) {
                setError("phone", {
                  type: "manual",
                  message: "Phone number is required"
                });
                return;
            } */

            if (!data.guardian_phone) {
                setError("guardian_phone", {
                  type: "manual",
                  message: "Guardian Phone number is required"
                });
                return;
            }

            const formData = new FormData();
            formData.append("avatar", file);
            formData.append("first_name", data.first_name);
            formData.append("last_name", data.last_name);
            formData.append("guardian_name", data.parent_name);
            formData.append("email", data.email);
            formData.append("guardian_email", data.contact_email);
            formData.append("password", data.password);
            formData.append("guardian_phone", data.guardian_phone);

            let payload = {
                url : `${BACKEND_DOMAIN}/student/profile/edit/send-request`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }

            POST(payload).then(res => {

                toast.success(res.data.message)
                navigate(0)

            }).catch(err => {

                // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

                if(err.response.status === 403) {
                    toast.error(err.response.data.message)
                }
            })

        }
        
    }

    const handleUploadChange = (event) => {

        if(event.target.files[0] &&  event.target.files[0] !== undefined && event.target.files[0] !== null){
    
          setFile(event.target.files[0])
          setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    const allowOnlyNumber=(e, type)=>{

        if(type == 'student') {
            setPhone(e.target.value)
        }else {
            setGuardianPhone(e.target.value)
        }
        
        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {
           
            if(pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(valLength > maxLength){
            
            if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) ) {
                return true;
            }else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */
        
    }

    const formatUSNumber = (e, type) => {

        if(type == 'student') {
            clearErrors('phone')
        }else {
            clearErrors('guardian_phone')
        }

        let entry = e.target.value

        if(entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if(pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            let fieldName = ''
            if(type == 'student') {
                fieldName = 'phone'
            }else {
                fieldName = 'guardian_phone'
            }

            if(count != 10) {
                setError(fieldName, {
                    type: "manual",
                    message: "Number must be ten digits"
                  });
                  return;
            }

            const match = entry
            .replace(/\D+/g, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''  
            e.target.value = `${part1}${part2}${part3}`

            if(type == 'student') {
                setPhone(`${part1}${part2}${part3}`)
            }else {
                setGuardianPhone(`${part1}${part2}${part3}`)
            }

            return `${part1}${part2}${part3}`
        }
        
    }
    
    return(
        <>
        { (Object.keys(student).length > 0) &&
            <>
            <h2 className="page_hdng">Edit Student</h2>            
            <div className='my_card mt-4'>
                <Row>
                    <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} id="student-profile-edit" >
                        <input id="upload" name="upload" type="file" onChange={handleUploadChange} hidden />
                        <div className='d-flex align-items-center justify-content-end'>
                            <Button variant='trans_success' onClick={() => navigate('/student/dashboard')} >Back To Home</Button>
                        </div>
                        <Col>
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Basic Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col className='col-auto'>
                                        <Form.Group controlId="">
                                            <div className='profile_dtls_user_img'>
                                                <img height={100} src={previewImage} alt="profile" onClick={() => !isRequested && document.getElementById("upload").click()} style={{cursor: !isRequested && 'pointer', opacity: isRequested && 0.7 }} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type='text' placeholder='First Name' name="first_name" defaultValue={student.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} disabled={isRequested}  autoComplete="off"/>

                                                    {errors.first_name ?.message && <span className="error">{errors.first_name ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type='text' placeholder='Last Name' name="last_name" defaultValue={student.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')} disabled={isRequested}  autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Parent/Guardian Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="11">
                                        <Row>
                                            <Col lg="4" sm="7">
                                                <Form.Group controlId="">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type='text' placeholder='Parents' name="parent_name" defaultValue={student.guardian_name} onChange={(event) => setValue('parent_name', event.target.value)} {...register('parent_name', { required: "Parent name is required" })} disabled={isRequested}  autoComplete="off" />

                                                    {errors.parents_name ?.message && <span className="error">{errors.parents_name ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="7">
                                                <Form.Group controlId="">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type='text' placeholder='Email' name="contact_email" defaultValue={student.guardian_email} onChange={(event) => setValue('contact_email', event.target.value)} {...register('contact_email', { 
                                                        required: "Email is required",
                                                        pattern: { 
                                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Invalid email address"
                                                        } 
                                                    })} disabled={isRequested}   autoComplete="off" />

                                                    {errors.contact_email ?.message && <span className="error">{errors.contact_email ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="5">
                                                <Form.Group controlId="">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control placeholder='Phone' name="guardian_phone" type="text" defaultValue={guardianPhone} onKeyDown={(event) => allowOnlyNumber(event, 'guardian')} onBlur={(event) => formatUSNumber(event, 'guardian')} disabled={isRequested}  autoComplete="off" />

                                                    {errors.guardian_phone ?.message && <span className="error">{errors.guardian_phone ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            {/* <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>School Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>School Name</Form.Label>
                                            <Form.Control type='text' placeholder='School' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6" sm="7">
                                        <Form.Group controlId="">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" placeholder='Address' />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div> */}

                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Credentials</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>Username ( Email )</Form.Label>
                                            <Form.Control type='email' placeholder='Enter email' name="email" defaultValue={student.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', { 
                                                        required: "Username is required",
                                                        pattern: { 
                                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Invalid email address"
                                                        } 
                                                    })} disabled={isRequested}  autoComplete="off" />

                                            {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6" sm="7">
                                        <Form.Group controlId="">
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup className="password_frm_grp">
                                                <Form.Control placeholder='Enter Password' name="password" defaultValue={student.password} type={isRevealPwd ? "text" : "password"}  onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" } )} disabled={isRequested}  autoComplete="new-password" />

                                                <InputGroup.Text id="basic-addon2">
                                                    <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                </InputGroup.Text>
                                            </InputGroup>

                                            {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            
                            <Row className='justify-content-lg-end mt-3'>
                                <Col className='col-auto'>
                                    <Button variant='trans_success' size='lg' onClick={() => navigate(-1)} >Cancel</Button>
                                </Col>
                                <Col className='col-auto'>
                                    { isRequested &&
                                        <Button variant='success' size='lg' disabled  style={{opacity: 0.5}} >Request Sent</Button>
                                    }
                                    { !isRequested &&
                                        <Button variant='success' size='lg' type="submit" >Send Request</Button>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Row>
            </div>
            </>
        }
        { isLoading && 
            <div className='text-center subscriptions_packages_name no_records_loading'>
                <h5>Loading...</h5>
            </div>
        }
        </>
    )
}

export default StudentProfileEdit;