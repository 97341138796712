import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Modal, Button, Col, Row, Tab, Nav, ProgressBar, ListGroup, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import left_arrow from '../../../assets/images/icons/left_arrow.png';
import right_arrow from '../../../assets/images/icons/right_arrow.png';
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import message_circle from '../../../assets/images/icons/message_circle.png';
import ChangePassword from '../../../components/common/changePassword';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST, PATCH } from '../../../utils/axios.util';
import { useSelector } from 'react-redux';

function CoordinatorDashboardCtrl(props) {

    const [fromDate, setFromDate] = useState(new Date(moment().startOf('week').tz(TIME_ZONE)))
    const [toDate, setToDate] = useState(new Date(moment().endOf('week').tz(TIME_ZONE)))
    const [displayFromDate, setDisplayFromDate] = useState('')
    const [displayToDate, setDisplayToDate] = useState('')
    const [dashboard, setDashboard] = useState({})
    const [categories, setCategories] = useState([])
    const [assignments, setAssignments] = useState({})
    const [students, setStudents] = useState({})
    const [isCurrentWeek, setIsCurrentWeek] = useState(true)
    const [passwordModalShow, setPasswordModalShow] = useState(false)
    const handlePasswordModalClose = () => setPasswordModalShow(false)
    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)
    const closeChangePasswordModalShow = () => setChangePasswordModalShow(false)
    const paramRef = useRef({
        category: '',
        fromDate: '',
        toDate: ''
    })
    const navigate = useNavigate()

    var params = paramRef.current
    var isDashboardInitialized = useRef(false)
    var isLoginInitialized = useRef(false)
    let user = useSelector((data) => data?.authenticate)

    useEffect(() => {

        if (user.is_loggedin_first === 0) {
            setPasswordModalShow(true)
        }

        if (!isDashboardInitialized.current) {
            initializeDashboard()
        }

        if (!isLoginInitialized.current) {
            initializeLogin()
        }

    }, [dashboard]);

    const initializeLogin = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-login`
        }

        POST(payload).then(res => {

            const { data } = res.data

            isLoginInitialized.current = true
            localStorage.user = JSON.stringify(data)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const getDashboard = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/coordinator/dashboard?fromDate=${params.fromDate}&toDate=${params.toDate}&category=${params.category}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            setCategories(data.categories)
            setAssignments(data.assignments)
            setStudents(data.students)
            setDashboard(data)
            isDashboardInitialized.current = true
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

        })

    }

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={left_arrow} onClick={onClick} />
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={right_arrow} onClick={onClick} />
        </button>
    ));

    const initializeDates = (type) => {

        let initialFrom = ''
        let initialTo = ''
        let fromDateMonth = ''
        let toDateMonth = ''
        let fromDateYear = ''
        let toDateYear = ''

        initialFrom = moment().startOf('week').tz(TIME_ZONE)
        initialTo = moment().endOf('week').tz(TIME_ZONE)
        fromDateMonth = initialFrom.format('MMM')
        toDateMonth = initialTo.format('MMM')
        fromDateYear = initialFrom.format('YYYY')
        toDateYear = initialTo.format('YYYY')

        let fromDateString = initialFrom.format('MMM DD')
        let toDateString = initialTo.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = initialFrom.format('YYYY MMM DD')
            toDateString = initialTo.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = initialTo.format('DD MMM')
            }
        }

        setFromDate(new Date(initialFrom))
        setToDate(new Date(initialTo))
        return {
            initialFrom,
            initialTo,
            fromDateString,
            toDateString
        }

    }

    const initializeDashboard = () => {

        const { initialFrom, initialTo, fromDateString, toDateString } = initializeDates('dashboard')
        params.fromDate = initialFrom.format('YYYY-MM-DD')
        params.toDate = initialTo.format('YYYY-MM-DD')
        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)
        setIsCurrentWeek(true)
        getDashboard()

    }

    const changeDates = (tempFrom, tempTo) => {

        let From = tempFrom
        let To = tempTo

        let fromDateMonth = From.format('MMM')
        let toDateMonth = To.format('MMM')
        let fromDateYear = From.format('YYYY')
        let toDateYear = To.format('YYYY')
        let fromDateString = From.format('MMM DD')
        let toDateString = To.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = From.format('YYYY MMM DD')
            toDateString = To.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = To.format('DD MMM')
            }
        }

        params.fromDate = From.format('YYYY-MM-DD')
        params.toDate = To.format('YYYY-MM-DD')
        let isSameWeek = moment(params.fromDate).isSame(params.toDate, 'week')
        let isSameYear = moment(params.fromDate).isSame(params.toDate, 'year')
        if (!isSameWeek || !isSameYear) {
            setIsCurrentWeek(false)
        } else {
            setIsCurrentWeek(true)
        }
        setFromDate(new Date(tempFrom))
        setToDate(new Date(tempTo))
        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)
        getDashboard()

    }

    const validateFromDate = (date) => {

        changeDates(moment(date), moment(params.toDate))
    }

    const validateToDate = (date) => {

        changeDates(moment(params.fromDate), moment(date))
    }

    const changeCategory = (cat) => {

        params.category = cat
        getDashboard()
    }

    const openPasswordModal = () => {
        setPasswordModalShow(false)
        setChangePasswordModalShow(true)
    }

    const submitPassword = (data) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-password`,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setChangePasswordModalShow(false)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })

    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                <h2 className="page_hdng">Dashboard</h2>
                {user?.package_details?.package_name?.toLowerCase() === "enterprise" && <Button className='login-button'>
                    <a target="_blank" href={`${process.env.REACT_APP_CAPTION_TOOL_URL}/app-login?token=${user?.['session_token']}`}>Access Caption Detection Tool</a>
                </Button>}
            </div>
            <Row>
                <Col xl="12" className="mt-3">
                    {categories.length > 0 &&
                        <>
                            <Row className="card_outer_hdng">
                                <Col>
                                    <h3>Assignments</h3>
                                </Col>
                            </Row>
                            <div className="my_card mt-3">
                                <Row className="justify-content-between align-items-center">
                                    <Col className="col-auto">
                                        <div className="assignments_date">
                                            <DatePicker
                                                selected={fromDate}
                                                maxDate={toDate}
                                                onChange={(date) => validateFromDate(date, 'dashboard')}
                                                customInput={<FromDate />}
                                            />
                                            <span className="assign_date">{displayFromDate} to {displayToDate}</span>
                                            <DatePicker
                                                selected={toDate}
                                                minDate={fromDate}
                                                onChange={(date) => validateToDate(date, 'dashboard')}
                                                customInput={<ToDate />}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button variant="light" onClick={initializeDashboard} disabled={isCurrentWeek ? true : false} >This Week</Button>
                                    </Col>
                                </Row>
                                <hr />
                                <Tab.Container id="assignments" defaultActiveKey={categories[0]['id']}>
                                    <Nav variant="brand_pills">
                                        {categories.map((elem, index) => {
                                            return (
                                                <Nav.Item key={elem.id}>
                                                    <Nav.Link eventKey={elem.id} className="text-capitalize" onClick={() => changeCategory(elem.id)} >{elem.title}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                        }
                                    </Nav>

                                    <Tab.Content className="p-2">
                                        {categories.map((elem, index) => {

                                            let documentPercentage = Math.floor((elem['formats']['document']['progress'] / elem['formats']['document']['total']) * 100)
                                            let audioPercentage = Math.floor((elem['formats']['audio']['progress'] / elem['formats']['audio']['total']) * 100)
                                            let videoPercentage = Math.floor((elem['formats']['video']['progress'] / elem['formats']['video']['total']) * 100)

                                            documentPercentage = documentPercentage ? documentPercentage : 0
                                            audioPercentage = audioPercentage ? audioPercentage : 0
                                            videoPercentage = videoPercentage ? videoPercentage : 0

                                            return (
                                                <Tab.Pane eventKey={elem.id} key={elem.id}>
                                                    <div className="mt-3">
                                                        <div className="progress_row my-2">
                                                            <div className="progress_icon">
                                                                <img height={20} src={ntpd_arrow} />
                                                            </div>
                                                            <div className="progressBar_bg">
                                                                <ProgressBar now={documentPercentage} variant="brand_color2" />
                                                                <label>{elem['formats']['document']['progress']}/{elem['formats']['document']['total']}</label>
                                                            </div>
                                                        </div>

                                                        <div className="progress_row my-2">
                                                            <div className="progress_icon">
                                                                <img height={20} src={mic_arrow} />
                                                            </div>
                                                            <div className="progressBar_bg">
                                                                <ProgressBar now={audioPercentage} variant="brand_color2" />
                                                                <label>{elem['formats']['audio']['progress']}/{elem['formats']['audio']['total']}</label>
                                                            </div>
                                                        </div>

                                                        <div className="progress_row my-2">
                                                            <div className="progress_icon">
                                                                <img height={20} src={video_arrow} />
                                                            </div>
                                                            <div className="progressBar_bg">
                                                                <ProgressBar now={videoPercentage} variant="brand_color2" />
                                                                <label>{elem['formats']['video']['progress']}/{elem['formats']['video']['total']}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            )
                                        })
                                        }
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </>
                    }
                    <div className="my_card mt-3">
                        <Row className="card_outer_hdng justify-content-between align-items-center">
                            <Col className="col-auto">
                                <h3 className="m-0">Students</h3>
                            </Col>
                            <Col className="col-auto">
                                <Button variant="trans_success" onClick={() => navigate('/coordinator/students/create')}>+ Add</Button>
                            </Col>
                        </Row>
                        <Row className="">
                            {Object.keys(students).length === 0 &&
                                <div className="d-flex align-items-center">
                                    <h3 className="m-0 fs-18 fw-400 text-center"><strong>No records Found!</strong></h3>
                                </div>
                            }

                            {Object.keys(students).length > 0 && Object.keys(students).map((division, index) => {

                                return (
                                    <div className="d-flex justify-content-between align-items-center" key={division}>
                                        <h3 className="m-0 fs-18 fw-400"><strong>{division}</strong></h3>
                                        <div className="mt-2">
                                            <div className="total_students">
                                                {students[division].map((elem, index) => {

                                                    return (
                                                        <span className="total_students_img" key={elem.id}>
                                                            <img src={elem.picture_path} />
                                                            {students[division].length > 10 && (index == students[division].length - 1) &&
                                                                <span className="more_students">{students[division].length - 10}+</span>
                                                            }
                                                        </span>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                            }

                        </Row>
                    </div>

                    <Row className="mt-3">
                        <Col sm="12">
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <h3 className="fs-20 fw-600 m-0">Assignments</h3>
                                </Col>
                                <Col className="col-auto">
                                    <Button onClick={() => navigate(`/coordinator/assignments/request-assignment`)}>Request Assignment</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.requested : 0}</h4>
                                <p className='text-capatilize'>requested</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.review : 0}</h4>
                                <p className='text-capatilize'>under Review </p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.completed : 0}</h4>
                                <p className='text-capatilize'>completed</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col sm="12">
                            <Row className="mt-3">
                                <Col>
                                    <h3 className="fs-20 fw-600 m-0">Assignment Hours</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card active">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.week : 0}</h4>
                                <p className='text-capatilize'>this week</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.month : 0}</h4>
                                <p className='text-capatilize'>this month</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.total : 0}</h4>
                                <p className='text-capatilize'>Total So Far</p>
                            </div>
                        </Col>
                    </Row>
                </Col>

                {/* <Col xl="5" className="mt-3"> 
                    <Row className="card_outer_hdng justify-content-between align-items-center">
                        <Col className="col-auto">
                            <h5 className="fs-18 fw-600 m-0">Chat</h5>
                        </Col>
                    </Row>
                    <div className="my_card mt-3">

                        { Object.keys(students).length > 0 &&

                            <Tab.Container id="chats" defaultActiveKey={Object.keys(students)[0]}>
                                <Nav variant="brand_pills">
                                    { Object.keys(students).map((elem, index) => {
                                            return(
                                                <Nav.Item key={elem}>
                                                    <Nav.Link eventKey={elem} className="text-capitalize">{elem}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>

                                <Tab.Content className="p-2">
                                    { Object.keys(students).map((division, index) => {


                                        return(
                                            <Tab.Pane eventKey={division} key={division}>
                                                <div>
                                                    <ListGroup className="mt-3" variant="flush">
                                                        { students[division].map((elem, index) => {

                                                            return (
                                                                <ListGroup.Item className="mt-1">
                                                                    <div className="d-flex align-items-center justify-content-between total_students">
                                                                        <div className="d-flex align-items-center total_students_img">
                                                                            <img src={elem.picture_path} />
                                                                            <div className="ms-2">
                                                                                <p className="fs-18 fw-600 m-0">{elem.full_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <Button variant="semi_primary" className="px-2">
                                                                            <img width={20} src={message_circle} />
                                                                            <span className="fs-14 fw-500 ms-2">Chat</span>
                                                                        </Button>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </div>
                                            </Tab.Pane>
                                        )
                                        })
                                    }
                                </Tab.Content>

                            </Tab.Container>
                        }
                        
                    </div>                   
                </Col> */}
            </Row>

            <Modal className="approve-modal" show={passwordModalShow} onHide={handlePasswordModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>You have logged in for the  first time. Click Yes to change your password.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handlePasswordModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openPasswordModal()} >YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ChangePassword changePasswordModalShow={changePasswordModalShow} closeChangePasswordModalShow={closeChangePasswordModalShow} submitPassword={submitPassword} />

        </>
    )
}

export default CoordinatorDashboardCtrl;