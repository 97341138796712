import React, { useEffect, useState, forwardRef, useRef } from "react";
import { Button,ButtonGroup, Col,Dropdown, ToggleButton, ToggleButtonGroup, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import { useParams, useLocation } from 'react-router-dom'
import search from '../../../assets/images/icons/search.png';
// import filter from '../../../assets/images/icons/filter.svg';
import StudentNewChapterListCtrl from './New';
import StudentProgressChapterListCtrl from './Progress';
import StudentCompletedChapterListCtrl from './Completed';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import close from '../../../assets/images/icons/close.svg';

// import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
// import video_arrow from '../../../assets/images/icons/video_arrow.png';
// import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
// import calendar from '../../../assets/images/icons/calendar.png';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH } from '../../../utils/axios.util';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

function StudentChapterListCtrl(){

    /* const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [isFilter, isFilterActive] = useState(true);
    const filterToggle = (event) => {
        isFilterActive(current => !current);
    }; */

    const location = useLocation();
    const sub_cat_id = location.state?.sub_cat_id;
    const params = useParams();
    const [ isLoading, setIsLoading ] = useState(false)
    const [ assignments, setAssignments ] = useState({})

    var isAssignmentsInitialized = useRef(false)
    
    const paramRef = useRef({
        subCategory: params.subCategory, 
        search: '',
        sortBy: 'newest'
    })

    var queryStrings = paramRef.current

    useEffect(() => {

        if(isAssignmentsInitialized.current == false) {
            getAssignments()
        }
        
    },[ assignments ]);

    const getAssignments = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/student/library/assignments?sub_category=${sub_cat_id}&search=${queryStrings.search}&sort_by=${queryStrings.sortBy}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setAssignments(data)
            setIsLoading(false)
            isAssignmentsInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            isAssignmentsInitialized.current = true
            console.log(err)
            setIsLoading(false)
            if(err.response.status === 404) {
                setAssignments({})
            }
        })

    }

    const addRemoveFavourite = (assignment_id) => {

        setIsLoading(true)

        let data = {
            assignment_id
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/student/favourites/update`,
            data
        }

        PATCH(payload).then(res => {
            setIsLoading(false)
            getAssignments()
        }).catch(err => {
            console.log(err)
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })

    }

  /*   const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className='select_date_box w-100' onClick={onClick} ref={ref}>
            <span>{!fromDate ? 'Start Date' : value}</span>
            <span>
                <img src={calendar} />
            </span>
            
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className='select_date_box w-100' onClick={onClick} ref={ref}>
            <span>{!toDate ? 'End Date' : value}</span>
            <span>
                <img src={calendar} />
            </span>
            
        </button>
    )); */

    return(
        <>

            <h2 className="page_hdng">Library</h2>
            <Row>
                <Col>
                    <div className="my_card mt-3 position-relative">
                    <Tab.Container defaultActiveKey="new">
                        <Row className="justify-content-between align-items-center">
                            <Col sm="12" className="col-lg-auto">
                                <Nav variant="brand_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="new">New</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="progress">Progress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="completed">Completed</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm="12" className="col-lg-auto">
                                <div className="d-flex justify-content-between align-items-center">
                                    <InputGroup className="list_seach">
                                        <Button variant="outline-secondary" id="">
                                            <img width="15" src={search} />
                                        </Button>
                                        <Form.Control size="sm" placeholder="Search" onChange={(e) => { queryStrings.search = e.target.value; getAssignments() }}  autoComplete="off" />
                                    </InputGroup>
                                    <Dropdown className="sortByBtn">
                                        <Dropdown.Toggle variant="link" className="ms-3">
                                            <span className="fs-14 fw-500">Sort By</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Form>
                                                <Dropdown.ItemText>
                                                    <Form.Check type="radio" label="Newest" name="sort_by" id="sort_by_newest" value="newest" onChange={() => { queryStrings.sortBy = 'newest'; getAssignments() } } checked={queryStrings.sortBy == 'newest' && true } />
                                                </Dropdown.ItemText>
                                                <Dropdown.ItemText>
                                                    <Form.Check type="radio" label="Alphabetical" name="sort_by" id="sort_by_alphabetical" value="alphabetical" onChange={() => { queryStrings.sortBy = 'alphabetical'; getAssignments() }} checked={queryStrings.sortBy == 'alphabetical' && true } />
                                                </Dropdown.ItemText>
                                            </Form>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                   {/*  <Button variant="link" className="p-0 ms-3" onClick={filterToggle}>
                                        <img width="15" src={filter} />
                                    </Button> */}
                                </div>
                            </Col>
                            {/* <div className={isFilter ? 'filter_container filter_deactive' : 'filter_container filter_active'}>
                                <button className="btn close_filter" onClick={filterToggle}>
                                    <img src={close} />
                                </button>
                                <h2 className="fs-20 fw-500 text-center">Filter</h2>
                                <Form>
                                    <Row className="g-2">
                                        <Col sm="12">
                                            <p className="my-2">Category</p>
                                        </Col>
                                        <Col className="col-auto">
                                            <Form.Check className="check_btn_toggle" label="English"  type="checkbox" id="english" />
                                        </Col>
                                        <Col className="col-auto">
                                            <Form.Check className="check_btn_toggle"  label="Maths"  type="checkbox" id="maths" />
                                        </Col>
                                        <Col className="col-auto">
                                            <Form.Check className="check_btn_toggle"  label="Science"  type="checkbox" id="science" />
                                        </Col>
                                        <Col className="col-auto">
                                            <Form.Check className="check_btn_toggle"  label="Social Sciences"  type="checkbox" id="social_sciences" />
                                        </Col>
                                        <Col className="col-auto">
                                            <Form.Check className="check_btn_toggle"  label="Computer Basics"  type="checkbox" id="computer_basics" />
                                        </Col>
                                    </Row>
                                    <Row className="g-2">
                                        <Col className="mt-4">
                                            <hr />
                                        </Col>
                                        <Col sm="12">
                                            <p className="mb-2">Sub Category</p>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Select as="select">
                                                <option>Select Chapter</option>
                                                <option>Chapter1</option>
                                                <option>Chapter2</option>
                                                <option>Chapter3</option>
                                                <option>Chapter4</option>
                                                <option>Chapter5</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="g-2">
                                        <Col className="mt-4">
                                            <hr />
                                        </Col>
                                        <Col sm="12">
                                            <p className="mb-2">Assignments Type</p>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Check inline className="me-4 mb-2" label={<img className="ms-2" src={mic_arrow} />}  name="assignmentsType" type="radio" id="assignmentsTypeMic" />
                                            <Form.Check inline className="me-4 mb-2" label={<img className="ms-2" src={video_arrow} />}  name="assignmentsType" type="radio" id="assignmentsTypeVid" />
                                            <Form.Check inline className="me-4 mb-2" label={<img className="ms-2" src={ntpd_arrow} />}  name="assignmentsType" type="radio" id="assignmentsTypeNtpd" />
                                        </Col>
                                    </Row>
                                    <Row className="g-2">
                                        <Col className="mt-4">
                                            <hr />
                                        </Col>
                                        <Col sm="12">
                                            <p className="mb-2">Date Filter</p>
                                        </Col>
                                        <Col sm="12">
                                            <Row className="g-2 align-items-center">
                                                <Col className="col">
                                                    <DatePicker
                                                        selected={fromDate}
                                                        onChange={(date) => setFromDate(date)}
                                                        customInput={<FromDate />}
                                                    />
                                                </Col>
                                                <Col className="col-auto">To</Col>
                                                <Col className="col">
                                                    <DatePicker
                                                        selected={toDate}
                                                        minDate={fromDate}
                                                        onChange={(date) => setToDate(date)}
                                                        customInput={<ToDate />}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="g-2">
                                        <Col>
                                            <Button variant="success" size="lg" className="w-100 mt-3">Apply Filter</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div> */}
                        </Row>
                        
                        <Tab.Content className="p-0">
                            <Tab.Pane eventKey="new">
                                <Row className="my-3 justify-content-between align-items-center">
                                    <Col sm="12" className="col-lg-auto">New  { Object.keys(assignments).length > 0 ? assignments.pending.length : 0 }</Col>
                                    {/* <Col sm="12" className="col-lg-auto">
                                        <Dropdown className="sortByBtn">
                                            <Dropdown.Toggle variant="link">
                                                <span className="fs-14 fw-500">Sort By</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Form>
                                                    <Dropdown.ItemText>
                                                        <Form.Check type="radio" label="Relevance" name="sort_by" id="sort_by_relevance" />
                                                    </Dropdown.ItemText>
                                                    <Dropdown.ItemText>
                                                        <Form.Check type="radio" label="Alphabetically" name="sort_by" id="sort_by_alphabetically" />
                                                    </Dropdown.ItemText>
                                                    <Dropdown.ItemText>
                                                        <Form.Check type="radio" label="Date" name="sort_by" id="sort_by_date" />
                                                    </Dropdown.ItemText>
                                                </Form>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col> */}
                                </Row>
                                { Object.keys(assignments).length > 0 && assignments.hasOwnProperty('pending') && assignments.pending.length > 0 &&
                                    <StudentNewChapterListCtrl assignments={assignments.pending} addRemoveFavourite={addRemoveFavourite} />
                                }
                                { (!assignments.hasOwnProperty('pending') || assignments.pending.length === 0) &&
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div to="chapter" className="chapter_list_card mt-3 text-center">
                                                No Records Found!
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="progress">
                                <Row className="my-3 justify-content-between align-items-center">
                                    <Col sm="12" className="col-lg-auto">Progress  { Object.keys(assignments).length > 0 ? assignments.progress.length : 0 }</Col>
                                    {/* <Col sm="12" className="col-lg-auto">
                                        <span className="fs-14 fw-500">Sort By</span>
                                        <span className="ms-2">
                                            <img height={15} src={short_by} />
                                        </span>
                                    </Col> */}
                                </Row>
                                { Object.keys(assignments).length > 0 && assignments.progress.length > 0 &&
                                    <StudentProgressChapterListCtrl assignments={assignments.progress} addRemoveFavourite={addRemoveFavourite} />
                                }
                                { (!assignments.hasOwnProperty('progress') || assignments.progress.length === 0) &&
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div to="chapter" className="chapter_list_card mt-3 text-center">
                                                No Records Found!
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="completed">
                                <Row className="my-3 justify-content-between align-items-center">
                                    <Col sm="12" className="col-lg-auto">Completed  { Object.keys(assignments).length > 0 ? assignments.completed.length : 0 }</Col>
                                    {/* <Col sm="12" className="col-lg-auto">
                                        <span className="fs-14 fw-500">Sort By</span>
                                        <span className="ms-2">
                                            <img height={15} src={short_by} />
                                        </span>
                                    </Col> */}
                                </Row>
                                { Object.keys(assignments).length > 0 && assignments.completed.length > 0 &&
                                    <StudentCompletedChapterListCtrl assignments={assignments.completed} addRemoveFavourite={addRemoveFavourite} />
                                }
                                { (!assignments.hasOwnProperty('completed') || assignments.completed.length === 0) &&
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div to="chapter" className="chapter_list_card mt-3 text-center">
                                                No Records Found!
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                    { isLoading && 
                        <div className='text-center subscriptions_packages_name no_records_loading'>
                            <h5>Loading...</h5>
                        </div>
                    }
                </Col>
            </Row>
        
        </>
    )
}

export default StudentChapterListCtrl;