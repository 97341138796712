import React, { useState, useEffect, useRef } from 'react';
import {Col, Button,Row, Form, FloatingLabel, Tab, Nav, Badge } from "react-bootstrap";
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../assets/images/dummy.png';
import paper_clip from '../../assets/images/icons/paper_clip.png';
import search from '../../assets/images/icons/search.png';
import member2 from '../../assets/images/member2.png';

import firebaseDb from '../../utils/firebase.util';
import { set, ref, push, update, onChildAdded, onChildChanged } from "firebase/database";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../config/variables';
import { GET, POST } from '../../utils/axios.util';

function StudentChatCtrl(props) {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const user = props.user

    const [ chatsList, setChatsList ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ techSupport, setTechSupport ] = useState(null)
    const [ chatMessage, setChatMessage ] = useState(null)
    const bottomRef = useRef(null);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});

        if(chatsList.length === 0 ) {
            setIsLoading(true)
            getChats()
        }

        if(!techSupport) {
            getSuperAdmin()
        }

    },[ chatsList, techSupport ]);

    const textAreaChange = (event) => {
        setChatMessage(event.target.value)
        setValue('chat', event.target.value)
    }

    const getSuperAdmin = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/super-admin/info`
        }

        GET(payload).then(res => {

            const { data } = res.data

            data['status'] = 'offline'
            data['user_id'] = data['id']

            setTechSupport(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const getChats = () => {

        const chatListRef = ref(firebaseDb, `support/user_${user.id}`);

        let chats = [...chatsList]
        let techSupportIds = []
        let techSupportUsers = []
        let usersList = {}

        setIsLoading(false)

        onChildAdded(chatListRef, (data) => {

            let value= data.val()
            value['key'] = data.key
            chats = [...chats, value]

            usersList[`${user.id}_${user.role}`] = chats

            if(value.role != user.role) {

                if(!techSupportIds.includes(value.user_id)) {
                    techSupportIds.push(value.user_id)
                    techSupportUsers.push(value)
                }

                const updates = {};
                updates[`support/user_${user.id}/${data.key}`] = {
                    ...value,
                    is_seen: 1,
                    timestamp: moment().tz(TIME_ZONE).format('XX'),
                    timestampFormatted: moment().tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
                };

                update(ref(firebaseDb), updates);

            }

            if(techSupportUsers.length > 0) {
                setTechSupport(techSupportUsers[techSupportUsers.length-1])
            }
            
            setChatsList(chats)
        });

        onChildChanged(chatListRef, (data) => {

            let value = data.val()

            if(techSupport && (techSupport.role != user.role) && (value.role != user.role) ) {
                if(( (techSupport.user_id != value.user_id) && (value.status == 'online' && techSupport.status == 'offline') || (value.status == 'offline' && techSupport.status == 'offline') ) || (techSupport.user_id == value.user_id)) {
                    setTechSupport(value)
                }
            }

        });

    }

    const onSubmit = (data) => {

        let payload= {
            user_id: user.id,
            full_name: `${user.first_name} ${user.last_name}`,
            picture_path: `${user.picture_path}`,
            is_seen: 0,
            role: user.role,
            message: data.chat,
            status: 'online',
            timestamp: moment().tz(TIME_ZONE).format('XX'),
            timestampFormatted: moment().tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
        }

        const chatListRef = ref(firebaseDb, `support/user_${user.id}`);
        const newChatRef = push(chatListRef);
        const key = newChatRef.key;

        payload['key'] = key

        set(newChatRef, payload)
        .then(res => {
            setValue('chat', '')
            setChatMessage('')
        })
        .catch(error => {

            // Session Logout Code
            if(error.response && error.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(error)
        });

    }

    return(
        <>
            <h2 className="page_hdng">Chats</h2>
            <div className='my_card chat_contact mt-4'>
                <Row>
                    <Col sm="12" lg="12">
                        <div className="my_card_brand_dark_color">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='chat_active_uesr'>
                                    <img src={techSupport && techSupport.picture_path} />
                                    <div className=''>
                                        <h5 className='fs-20 fw-600 m-0'>{techSupport && techSupport.full_name}</h5>
                                        <span className={(techSupport && techSupport.status == 'online') ? 'online_mark' : 'offline_mark'}>{techSupport && techSupport.status}</span>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='chat_msg_container' id="chat_div" >
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                <ul className='chat_msg_list'>
                                    { chatsList.length > 0 &&

                                        chatsList.map((elem, index) => {

                                            let classValue = 'chat_msg_list_item msg_me'

                                            if(elem.role != user.role) {
                                                classValue = 'chat_msg_list_item msg_other'
                                            } 

                                            return (
                                                <li className={classValue} key={elem.key}>
                                                    <div className='chat_msg'>{elem.message}</div>
                                                </li>
                                            )
                                        })
                                    }
                                    <div ref={bottomRef} />
                                </ul>
                                <FloatingLabel controlId="floatingTextarea" className="chat_textarea" >
                                    <Form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                                    <Form.Control as="textarea" placeholder="Type something" name="chat" {...register('chat')} onChange={(event) => textAreaChange(event)} autoComplete="off" />
                                        <div className='chat_textarea_buttons'>
                                            {/* <div className="upload_btn_wrapper">
                                                <button className="attach_btn">
                                                    <img height="15" src={paper_clip} />
                                                    <span className='ms-2'>Attach</span>
                                                </button>
                                                <Form.Control type="file" name="myfile" />
                                            </div> */}
                                                <Button disabled={!chatMessage} variant='success' type="submit">Send</Button>
                                        </div>
                                    </Form>
                                </FloatingLabel>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate
    }
}
  
export default connect(mapStateToProps, null)(StudentChatCtrl);