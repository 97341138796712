import React from "react";

import { Outlet, useNavigate } from "react-router-dom";
import useAuthenticate from '../../hooks/useAuthenticate';

function SchoolInterfaceCtrl(props){

    const user = useAuthenticate()
    let navigate = useNavigate()

    if(Object.keys(user).length > 0 && user.role === 'coordinator') {

        return(
            <>
                <section className="School_interface">
                    <Outlet/>
                </section>
            </>
        )
    }else {
        return navigate('/')
    }
}

export default SchoolInterfaceCtrl;