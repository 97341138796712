import React, { useEffect, useState } from 'react';
import {Breadcrumb, Button,  Col,Form,FormControl,InputGroup,Nav, Row,Tab, Table} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../../assets/images/dummy.png';
import member5 from '../../../assets/images/member5.png';
import close from '../../../assets/images/icons/close.svg';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';

function SchoolAddDivision(){

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [ coordinators, setCoordinators ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ coordinatorIds, setCoordinatorIds ] = useState([])
    const [ checkedState, setCheckedState ] = useState({})

    useEffect(() => {

        if(coordinators.length === 0) {
            getCoordinators()
        }

    },[ coordinators, checkedState ]);

    const getCoordinators = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/coordinator/all`
        }

        GET(payload).then(res => {
            const { data } = res.data

            let checkMap = {}
            for(let x of data) {
              checkMap[x.id] = false
            }
            setCheckedState(prevState => {
              return {
                ...prevState,
                ...checkMap
              }
            })
            setIsLoading(false)
            setCoordinators(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            setIsLoading(false)
            console.log(err)
            
        })

    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        for(let y in checkedState) {
          if(y == checkId) {
            tempCheckstate[y] = !checkedState[y]
          }
        }
        
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });

    }

    const onSubmit = (data) => {

        if(Object.keys(checkedState).length > 0) {

            let checkedData = Object.keys(checkedState).filter(key => checkedState[key] === true)
      
            if(checkedData.length > 0) {
                data = { ...data, coordinator_ids: JSON.stringify(checkedData) }
            }

        }

        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/create`,
            data
        }

        setIsLoading(true)
        POST(payload).then(res => {

            setIsLoading(false)
            toast.success(res.data.message)
            navigate('/school/divisions')

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    return(
        <>        

            <h2 className="page_hdng">Add Class</h2>

            { isLoading && 
                <div className='text-center subscriptions_packages_name no_records_loading'>
                    <h5>Loading...</h5>
                </div>
            }
            { !isLoading &&
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='my_card mt-4'>
                        <Row>
                            <Col>
                                <div className="my_card_brand_dark_color">
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <NavLink to="/school/divisions">
                                            <Button variant='trans_success'>Back</Button>
                                        </NavLink>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='fs-20 fw-500 text-center'>Enter Details</h5>
                                    </div>
                                    <Row>
                                        <Col lg="10">
                                            <Row>
                                                {/* <Col sm="6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Class Name<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type='text' name="name" {
                                                            ...register('name', 
                                                            {
                                                                required: "Name is required"
                                                            })
                                                        } />

                                                        {errors.name && <span className="error">{errors.name.message}</span> }

                                                    </Form.Group>
                                                </Col> */}
                                                <Col sm="6">
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Class</Form.Label>
                                                        <Form.Control type="text" name="name" onChange={(event) => setValue('name', event.target.value)} {...register('name', {
                                                            required: "Name is required"
                                                        })}  autoComplete="off" />

                                                        {errors.name && <span className="error">{errors.name.message}</span> }

                                                    </Form.Group>
                                                </Col>
                                                {/* <Col sm="6" >
                                                    <Form.Label>Class<span className='text-danger'>*</span></Form.Label>
                                                    
                                                    <Form.Group className="mb-3" controlId="name">
                                                        <Form.Select name="name" {...register("name", {
                                                                required: "Name is required"
                                                            })} >
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                            <option value={9}>9</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                            <option value={12}>12</option>
                                                        </Form.Select>

                                                        {errors.name && <span className="error">{errors.name.message}</span> }

                                                    </Form.Group>
                                                </Col> */}
                                            </Row>
                                            
                                            <Row className='g-3'>
                                                { coordinators.length > 0 && 
                                                    <Col lg="12">
                                                        <Form.Group controlId="">
                                                            <Form.Label>Select Coordinator</Form.Label>
                                                        </Form.Group>
                                                        <Row className='g-3'>
                                                            {
                                                                coordinators.map((elem, index) => {
                                                                    return (
                                                                        <Col className="col-auto" key={elem.id} >
                                                                            <Form.Check className="check_success_btn_toggle" label={
                                                                                <span><img className="rounded-circle" width="40px" src={elem.picture_path} /> <span>{elem.full_name}</span> </span>
                                                                            } name="coordinatorCheck" type="checkbox" id={`coord-${elem.id}`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col>
                                                }
                                                {/* <Col lg="12">
                                                    <Form.Group controlId="">
                                                        <Button variant='trans_bdr_success' size='lg'>Add Category</Button>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="12">
                                                    <Row>
                                                        <Col md="6">
                                                            <div className='addedCategoryCard'>
                                                                <button className='close_category_btn'>
                                                                    <img src={close} />
                                                                </button>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Category<span className='text-danger'>*</span></Form.Label>
                                                                    <Form.Control type='text' value="Physics"  />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3">
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <Form.Label>Sub Category (if any)</Form.Label>
                                                                        <Button variant="link" className='p-0 fs-16 fw-400 text_brand_color3'>Add more</Button>
                                                                    </div>
                                                                    <Form.Control type='text' value="Physics"  />
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div className='addedCategoryCard'>
                                                                <button className='close_category_btn'>
                                                                    <img src={close} />
                                                                </button>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Category<span className='text-danger'>*</span></Form.Label>
                                                                    <Form.Control type='text' value="Physics"  />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3">
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <Form.Label>Sub Category (if any)</Form.Label>
                                                                        <Button variant="link" className='p-0 fs-16 fw-400 text_brand_color3'>Add more</Button>
                                                                    </div>
                                                                    <Form.Control type='text' value="Physics"  />
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                                <Col sm="12" className='text-center'>
                                                    <Button className='px-5' variant='success' size='lg' type="submit" >Add</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                        
                                </div>
                                
                                
                                
                            </Col>
                            
                        </Row>
                    </div>
                </Form>
            }
            
                   
        </>
    )
}

export default SchoolAddDivision;