import React, { useState, useEffect, useRef } from 'react';
import {Breadcrumb, Button,  Col,Form,FormControl,InputGroup,Nav, Row,Tab, Table} from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import search from '../../../assets/images/icons/search.png';
import member5 from '../../../assets/images/member5.png';

import { GET, POST } from '../../../utils/axios.util';
import { BACKEND_DOMAIN } from '../../../config/variables';
import PAGINATION from '../../../components/common/pagination';

function SchoolDivisionsCtrl(){

    const [ keyword, setKeyword ] = useState('')
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ divisions, setDivisions ] = useState([]);

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    var isDivisionInitialized = useRef(false)

    useEffect(() => {

        if(divisions.length === 0 && !isDivisionInitialized.current) {
            getDivisions(keyword)
        }
        
    },[ divisions, currentItems, itemOffset, itemsPerPage ]);

    const getDivisions = (keyword) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions?search=${keyword}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            setIsNoRecords(false)
            setIsLoading(false)
            setDivisions(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
            isDivisionInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const deleteDivision = (id) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/delete`,
            data: { division_id: id }
        }

        POST(payload).then(res => {

            setIsLoading(false)
            toast.success(res.data.message)
            getDivisions()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handlePageClick = (event) => {

        let items = divisions

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };

    return(
        <>        

            <h2 className="page_hdng">Classes</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="3">
                    <div className="assignments_card">
                        <h4>{divisions.length}</h4>
                        <p>Class</p>
                    </div>
                </Col>
            </Row>
            
                        
            <Row className="">
                <Col xl="12" className="mt-3"> 
                    <Row className=" align-items-center">
                        <Col>
                            <Breadcrumb className='mb-0'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        
                        <Col className="col-auto">
                            <div className='d-flex align-items-center'>
                                <InputGroup className="">
                                    <FormControl size='md' placeholder="search" onChange={(event) => getDivisions(event.target.value)}/>
                                        <Button variant='secondary' size='md'>
                                            <img width={20} src={search} />
                                        </Button>
                                </InputGroup>
                                
                                <NavLink to="create" className="btn btn-success btn-md px-4 ms-2">Add Class</NavLink>
                                
                            </div>
                        </Col>
                    </Row>

                    <div className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th className='text-start'>Classes</th>
                                    <th className='text-center'>Coordinators</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                { !isLoading && isNoRecords &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                { !isNoRecords && !isLoading && divisions.length > 0 &&
                                    currentItems.map((elem, index) => {
                                        return (
                                            <tr key={elem.id}>
                                                <td className="fs-14">
                                                    <NavLink to={`${elem.id}/categories`}>{elem.name}</NavLink>
                                                </td>
                                                <td className='fs-14 text-center'>
                                                    <div className="total_students">
                                                        { elem.coordinators.length === 0 &&
                                                            <span className="total_students_img">
                                                                --
                                                            </span>
                                                        }
                                                        { elem.coordinators.length > 0 &&
                                                            elem.coordinators.map((coord, index) => {
                                                                if(index < 2) {
                                                                    return (
                                                                        <span key={coord.id}>
                                                                            <span className="total_students_img">
                                                                                <img src={coord.picture_path} />
                                                                            </span>
                                                                        </span>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        { elem.coordinators.length > 3 &&
                                                            <span className="total_students_img">
                                                                <img src={elem.coordinators[2].picture_path} />
                                                                <span className="more_students">{elem.coordinators.length - 3}+</span>
                                                            </span>
                                                        }
                                                    </div>
                                                </td>
                                                <td className='fs-14 text-center'>
                                                    
                                                    <Button variant='dark'  size='sm' onClick={() => navigate(`${elem.id}/categories`)} >View Subjects</Button>&nbsp;|&nbsp;
                                                    <span className="badge badge_md" onClick={() => navigate(`${elem.id}/edit`)} style={{ cursor: 'pointer' }} >
                                                        <img src={mode_edit} />
                                                    </span>
                                                    
                                                    <span className="badge badge_md" onClick={() => deleteDivision(elem.id)} style={{cursor: 'pointer'}}>
                                                        <img src={rd_trash} />
                                                    </span>
                                                </td>
                                            </tr>
                                            )
                                        })
                                    }
                            </tbody>
                        </Table>
                        { divisions.length > 0 &&
                            <PAGINATION allItems={divisions} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                        }
                    </div>                   
                </Col>

            </Row>
        </>
    )
}

export default SchoolDivisionsCtrl;