import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import StudentInterfaceCtrl from '../Interfaces/Student';
import StudentDashboardLayoutCtrl from '../Interfaces/Student/DashLayout';
import StudentDashboardCtrl from '../Interfaces/Student/Dashboard';
import StudentRecentlyPlayedCtrl from '../Interfaces/Student/Recently_played';
import StudentLatestPlayedCtrl from '../Interfaces/Student/Latest_played';
import StudentLibraryCtrl from '../Interfaces/Student/Library';
// import StudentSupportCtrl from '../Interfaces/Student/Support';
import StudentChatCtrl from '../Interfaces/Student/Chat';
// import StudentSupportChatCtrl from '../Interfaces/Student/Support/Chat';
import StudentChapterListCtrl from '../Interfaces/Student/Chapter';
import StudentProfileCtrl from '../Interfaces/Student/Profile';
import StudentProfileEdit from '../Interfaces/Student/Profile/edit';


import SchoolInterfaceCtrl from '../Interfaces/School';
import DashboardLayoutCtrl from '../Interfaces/School/dashLayout';
import SchoolDashboardCtrl from '../Interfaces/School/Dashboard';
import SchoolUsersCtrl from '../Interfaces/School/Users';
import SchoolCoordinatorCreate from '../Interfaces/School/Users/Coordinator/create';
import SchoolSubAdminCreate from '../Interfaces/School/Users/SubAdmin/create';
import SchoolStudentCreate from '../Interfaces/School/Users/Student/create';
import SchoolInterpreterCreate from '../Interfaces/School/Users/Interpreter/create';
import SchoolCoordinatorEdit from '../Interfaces/School/Users/Coordinator/edit';
import SchoolSubAdminEdit from '../Interfaces/School/Users/SubAdmin/edit';
import SchoolStudentEdit from '../Interfaces/School/Users/Student/edit';
import SchoolInterpreterEdit from '../Interfaces/School/Users/Interpreter/edit';
import SchoolDivisionsCtrl from '../Interfaces/School/Divisions';
// import SchoolAddDivision from "../Interfaces/School/Divisions/addDivision";
import SchoolAddDivision from "../Interfaces/School/Divisions/addDivision";
import SchoolEditDivision from "../Interfaces/School/Divisions/editDivision";
import SchoolDivisionsCategoriesCtrl from "../Interfaces/School/Divisions/Categories";
import SchoolDivisionsSubCategoriesCtrl from "../Interfaces/School/Divisions/SubCategories";

import SchoolInterpretationCtrl from '../Interfaces/School/Interpretation';
import SchoolSubscriptionsCtrl from '../Interfaces/School/Subscriptions';
// import SchoolSupportCtrl from '../Interfaces/School/Support';
import SchoolChatCtrl from '../Interfaces/School/Chat';
import SchoolProfileCtrl from '../Interfaces/School/Profile';
import SchoolEditProfileCtrl from '../Interfaces/School/Profile/edit';
import SchoolNotificationsCtrl from '../Interfaces/School/Notifications';

import InterpreterInterfaceCtrl from '../Interfaces/Interpreter';
import InterpreterDashboardLayoutCtrl from '../Interfaces/Interpreter/DashLayout';
import InterpreterDashboardCtrl from '../Interfaces/Interpreter/Dashboard';
import InterpreterSalaryCtrl from '../Interfaces/Interpreter/Salary';
// import InterpreterSupportCtrl from '../Interfaces/Interpreter/Support';
import InterpreterSupportChatCtrl from '../Interfaces/Interpreter/Support/Chat';
import InterpreterProfileCtrl from '../Interfaces/Interpreter/Profile';
import InterpreterEditProfileCtrl from '../Interfaces/Interpreter/Profile/edit';
import InterpreterNotificationsCtrl from '../Interfaces/Interpreter/Notifications';
import InterpreterAssignmentsCtrl from '../Interfaces/Interpreter/Assignments';

import CoordinatorInterfaceCtrl from '../Interfaces/Coordinator';
import CoordinatorProfile from '../Interfaces/Coordinator/Profile';
import CoordinatorEditProfile from '../Interfaces/Coordinator/Profile/edit';
import CoordinatorDashboardLayoutCtrl from '../Interfaces/Coordinator/DashLayout';
import CoordinatorDashboardCtrl from '../Interfaces/Coordinator/Dashboard';
import CoordinatorStudentCtrl from '../Interfaces/Coordinator/Student';
import CoordinatorAddStudentCtrl from '../Interfaces/Coordinator/Student/create';
import CoordinatorStudentEdit from '../Interfaces/Coordinator/Student/edit';
import CoordinatorAssignments from '../Interfaces/Coordinator/Assignments';
import CoordinatorRequestAssignments from '../Interfaces/Coordinator/Assignments/requestAssignment';

import CoordinatorChatCtrl from '../Interfaces/Coordinator/Chat';
import CoordinatorMessageCtrl from '../Interfaces/Coordinator/Support/Chat';
import CoordinatorNotificationsCtrl from '../Interfaces/Coordinator/Notifications';
import AssignToStudentComponent from "../Interfaces/Coordinator/Assignments/assign_to_student";

import SubAdminInterface from '../Interfaces/SubAdmin';
import SubAdminLayout from '../Interfaces/SubAdmin/Common';
import SubAdminDashboard from '../Interfaces/SubAdmin/Dashboard';
import SubAdminProfile from '../Interfaces/SubAdmin/Profile';
import SubAdminProfileEdit from '../Interfaces/SubAdmin/Profile/edit';
import SubAdminTickets from '../Interfaces/SubAdmin/Support';
import SubAdminInterpretation from '../Interfaces/SubAdmin/Interpretation';
import SubAdminUsers from '../Interfaces/SubAdmin/Users';
import SubAdminCoordinatorCreate from '../Interfaces/SubAdmin/Users/Coordinator/create';
import SubAdminCoordinatorEdit from '../Interfaces/SubAdmin/Users/Coordinator/edit';
import SubAdminInterpreterCreate from '../Interfaces/SubAdmin/Users/Interpreter/create';
import SubAdminInterpreterEdit from '../Interfaces/SubAdmin/Users/Interpreter/edit';
import SubAdminStudentCreate from '../Interfaces/SubAdmin/Users/Student/create';
import SubAdminStudentEdit from '../Interfaces/SubAdmin/Users/Student/edit';
import Organizations from '../Interfaces/SubAdmin/Organizations';
import OrganizationUsers from '../Interfaces/SubAdmin/Organizations/Users';
import OrganizationSubAdminCreate from '../Interfaces/SubAdmin/Organizations/Users/SubAdmin/create';
import OrganizationSubAdminEdit from '../Interfaces/SubAdmin/Organizations/Users/SubAdmin/edit';
import OrganizationCoordinatorCreate from '../Interfaces/SubAdmin/Organizations/Users/Coordinator/create';
import OrganizationCoordinatorEdit from '../Interfaces/SubAdmin/Organizations/Users/Coordinator/edit';
import OrganizationStudentCreate from '../Interfaces/SubAdmin/Organizations/Users/Student/create';
import OrganizationStudentEdit from '../Interfaces/SubAdmin/Organizations/Users/Student/edit';
import OrganizationInterpreterCreate from '../Interfaces/SubAdmin/Organizations/Users/Interpreter/create';
import OrganizationInterpreterEdit from '../Interfaces/SubAdmin/Organizations/Users/Interpreter/edit';
import Packages from '../Interfaces/SubAdmin/Packages';
import PackageCreate from '../Interfaces/SubAdmin/Packages/create';
import PackageEdit from '../Interfaces/SubAdmin/Packages/edit';
import ChatLayout from "../Interfaces/SubAdmin/Chat";

import Login from '../components/common/login';
import Logout from '../components/common/logout';
import ForgotPassword from '../components/common/forgotPassword';
import ViewAssignment from '../components/common/viewAssignment';
import Notification from '../components/common/notification';
import SupportForm from '../components/common/support';
import Tickets from '../components/common/tickets';
import Payments from '../components/common/payments';
import AssignInterpreter from '../components/common/assignInterpreter';
import Chat from '../components/common/chat';
import Invoice from "../components/common/invoice";

const Root = () => {
    
    return(
        <>
            <Routes>
                <Route path="/">
                    <Route index element={<Login/>} />
                    <Route path="login" element={<Login/>} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="forgot-password" element={<ForgotPassword/>} />
                </Route>

                {/* /////////////////////
                    School Route Here
                //////////////////////// */}

                <Route path="/school" element={<SchoolInterfaceCtrl/>} >
                    <Route index element={<SchoolDashboardCtrl/>} />
                    
                    <Route element={<DashboardLayoutCtrl/>} >
                        <Route path="dashboard" element={<SchoolDashboardCtrl/>} />
                        <Route path="users" element={<SchoolUsersCtrl/>}/>
                        <Route path="users/coordinator/create" element={<SchoolCoordinatorCreate/>} />
                        <Route path="users/coordinator/:id/edit" element={<SchoolCoordinatorEdit/>} />
                        <Route path="users/sub-admin/create" element={<SchoolSubAdminCreate/>} />
                        <Route path="users/sub-admin/:id/edit" element={<SchoolSubAdminEdit/>} />
                        <Route path="users/student/create" element={<SchoolStudentCreate/>} />
                        <Route path="users/student/:id/edit" element={<SchoolStudentEdit/>} />
                        <Route path="users/interpreter/create" element={<SchoolInterpreterCreate/>} />
                        <Route path="users/interpreter/:id/edit" element={<SchoolInterpreterEdit/>} />
                        <Route path="payments" element={<Payments/>} />
                        <Route path="divisions" element={<SchoolDivisionsCtrl/>} />
                        <Route path="divisions/create" element={<SchoolAddDivision/>} />
                        <Route path="divisions/:id/edit" element={<SchoolEditDivision/>} />

                        <Route path="divisions/:id/categories" element={<SchoolDivisionsCategoriesCtrl/>} />
                        <Route path="divisions/:division_id/categories/:category_id/sub-categories" element={<SchoolDivisionsSubCategoriesCtrl/>} />
                        <Route path="interpretations" element={<SchoolInterpretationCtrl/>} />
                        <Route path="support" element={<Tickets/>} />
                        <Route path="support/new-ticket" element={<SupportForm/>} />
                        {/* <Route path="message" element={<SchoolChatCtrl/>} /> */}
                        <Route path="chat" element={<ChatLayout/>} />
                        <Route path="support/chat/:id" element={<ChatLayout/>} />
                        <Route path="profile" element={<SchoolProfileCtrl/>} />
                        <Route path="profile/edit" element={<SchoolEditProfileCtrl/>} />
                        <Route path="notifications" element={<Notification/>} />
                        <Route path="assignments/:id" element={<ViewAssignment/>} />
                        <Route path="assignments/:id/assign/interpreter" element={<AssignInterpreter/>} />
                        {/* <Route path="notifications" element={<Notification/>} /> */}

                        <Route path="subscriptions" element={<SchoolSubscriptionsCtrl/>} >
                            <Route index element={<SchoolSubscriptionsCtrl/>} />
                            <Route path=":plan" element={<SchoolSubscriptionsCtrl/>} />
                        </Route>
                        <Route path="invoice" element={<Invoice/> } />
                    </Route>
                </Route>

                {/* /////////////////////
                    Non Protected School Routes
                //////////////////////// */}

                <Route path="/school/login" element={<Login/>} />
                <Route path="/school/forgot-password" element={<ForgotPassword/>} />
                <Route path="/school/logout" element={<Logout/>} />

                {/* /////////////////////
                    SubAdmin Route Here
                //////////////////////// */}

                <Route path="/sub-admin" element={<SubAdminInterface/>} >
                    <Route index element={<SubAdminDashboard/>} />
                    
                    <Route element={<SubAdminLayout/>} >
                        <Route path="dashboard" element={<SubAdminDashboard/>} />
                        <Route path="notifications" element={<Notification/>} />
                        <Route path="payments" element={<Payments/>} />
                        <Route path="chat" element={<ChatLayout/>} />

                        <Route path="users">
                            <Route index element={<SubAdminUsers/>} />
                            <Route path="coordinator/create" element={<SubAdminCoordinatorCreate/>} />
                            <Route path="coordinator/:id/edit" element={<SubAdminCoordinatorEdit/>} />
                            <Route path="student/create" element={<SubAdminStudentCreate/>} />
                            <Route path="student/:id/edit" element={<SubAdminStudentEdit/>} />
                            <Route path="interpreter/create" element={<SubAdminInterpreterCreate/>} />
                            <Route path="interpreter/:id/edit" element={<SubAdminInterpreterEdit/>} />
                        </Route>

                        <Route path="interpretations">
                            <Route index element={<SubAdminInterpretation/>} />
                            <Route path=":id" element={<ViewAssignment/>} />
                            <Route path=":id/assign/interpreter" element={<AssignInterpreter/>} />
                        </Route>

                        <Route path="organizations">
                            <Route index element={<Organizations/>} />
                            <Route path=":id/users" element={<OrganizationUsers/>} />
                            <Route path=":org_id/users/sub-admin/create" element={<OrganizationSubAdminCreate/>} />
                            <Route path=":org_id/users/sub-admin/:id/edit" element={<OrganizationSubAdminEdit/>} />
                            <Route path=":org_id/users/student/create" element={<OrganizationStudentCreate/>} />
                            <Route path=":org_id/users/student/:id/edit" element={<OrganizationStudentEdit/>} />
                            <Route path=":org_id/users/coordinator/create" element={<OrganizationCoordinatorCreate/>} />
                            <Route path=":org_id/users/coordinator/:id/edit" element={<OrganizationCoordinatorEdit/>} />
                            <Route path=":org_id/users/interpreter/create" element={<OrganizationInterpreterCreate/>} />
                            <Route path=":org_id/users/interpreter/:id/edit" element={<OrganizationInterpreterEdit/>} />
                        </Route>

                        <Route path="packages">
                            <Route index element={<Packages/>} />
                            <Route path="create" element={<PackageCreate/>} />
                            <Route path=":id/edit" element={<PackageEdit/>} />
                        </Route>

                        <Route path="support">
                            <Route index element={<SubAdminTickets/>} />
                            <Route path="new-ticket" element={<SupportForm/>} />
                        </Route>

                        <Route path="profile">
                            <Route index element={<SubAdminProfile/>} />
                            <Route path="edit" element={<SubAdminProfileEdit/>} />
                        </Route>
                    </Route>
                </Route>

                {/* /////////////////////
                    Non Protected SubAdmin Routes
                //////////////////////// */}

                <Route path="/sub-admin/login" element={<Login/>} />
                <Route path="/sub-admin/forgot-password" element={<ForgotPassword/>} />
                <Route path="/sub-admin/logout" element={<Logout/>} />

                {/* /////////////////////
                    Student Route Here
                //////////////////////// */}

                <Route path="/student" element={<StudentInterfaceCtrl/>} >
                    <Route index element={<StudentDashboardCtrl/>} />
                    
                    <Route element={<StudentDashboardLayoutCtrl/>}>
                        <Route path="dashboard" element={<StudentDashboardCtrl/>} />
                        <Route path="dashboard/recently-played" element={<StudentRecentlyPlayedCtrl/>} />
                        <Route path="dashboard/latest-played" element={<StudentLatestPlayedCtrl/>} />
                        <Route path="library" element={<StudentLibraryCtrl/>} />
                        <Route path="library/:subCategory" element={<StudentChapterListCtrl/>} />
                        <Route path="chat" element={<ChatLayout/>} />
                        {/* <Route path="support/chat" element={<StudentSupportChatCtrl/>} /> */}
                        <Route path="support" element={<Tickets/>} />
                        <Route path="support/new-ticket" element={<SupportForm/>} />
                        <Route path="profile" element={<StudentProfileCtrl/>} />
                        <Route path="profile/edit" element={<StudentProfileEdit/>} />
                        <Route path="dashboard/video" element={<ViewAssignment/>} />
                        <Route path="assignments/:id" element={<ViewAssignment/>} />
                        <Route path="notifications" element={<Notification/>} />
                        
                    </Route>
                </Route>

                {/* /////////////////////
                    Non Protected Student Routes
                //////////////////////// */}

                <Route path="/student/login" element={<Login/>} />
                <Route path="/student/forgot-password" element={<ForgotPassword/>} />
                <Route path="/student/logout" element={<Logout/>} />

                {/* /////////////////////
                    Coordinator Route Here
                //////////////////////// */}

                <Route path="/coordinator" element={<CoordinatorInterfaceCtrl/>} >
                    <Route index element={<CoordinatorDashboardCtrl/>} />

                    <Route element={<CoordinatorDashboardLayoutCtrl/>}>
                        <Route path="dashboard" element={<CoordinatorDashboardCtrl/>} />
                        <Route path="profile" element={<CoordinatorProfile/>} />
                        <Route path="profile/edit" element={<CoordinatorEditProfile/>} />
                        <Route path="students" element={<CoordinatorStudentCtrl/>} />
                        <Route path="students/create" element={<CoordinatorAddStudentCtrl />} />
                        <Route path="students/:id/edit" element={<CoordinatorStudentEdit/>} />
                        <Route path="assignments" element={<CoordinatorAssignments/>} />
                        <Route path="assignments/request-assignment" element={<CoordinatorRequestAssignments/>} />
                        <Route path="assignments/:id/assign-students" element={<AssignToStudentComponent/>} />
                        <Route path="assignments/:id" element={<ViewAssignment/>} />
                        <Route path="assignments/:id/assign/interpreter" element={<AssignInterpreter/>} />
                        <Route path="chat" element={<ChatLayout/>} />
                        <Route path="support" element={<Tickets/>} />
                        <Route path="support/new-ticket" element={<SupportForm/>} />
                        <Route path="support/chat" element={<CoordinatorMessageCtrl/>} />
                        <Route path="notifications" element={<CoordinatorNotificationsCtrl/>} />
                    </Route>
                </Route>

                {/* /////////////////////
                    Non Protected Coordinator Routes
                //////////////////////// */}

                <Route path="/coordinator/login" element={<Login/>} />
                <Route path="/coordinator/forgot-password" element={<ForgotPassword/>} />
                <Route path="/coordinator/logout" element={<Logout/>} />

                {/* /////////////////////
                    Interpreter Route Here
                //////////////////////// */}

                <Route path="/interpreter" element={<InterpreterInterfaceCtrl/>} >
                    <Route index element={<InterpreterDashboardCtrl/>} />
                    
                    <Route element={<InterpreterDashboardLayoutCtrl/>}>
                        <Route path="dashboard" element={<InterpreterDashboardCtrl/>} />
                        <Route path="salary" element={<InterpreterSalaryCtrl/>} />
                        <Route path="support/chat" element={<InterpreterSupportChatCtrl/>} />
                        <Route path="profile" element={<InterpreterProfileCtrl/>} />
                        <Route path="profile/edit" element={<InterpreterEditProfileCtrl/>} />
                        <Route path="notifications" element={<InterpreterNotificationsCtrl/>} />

                        <Route path="chat" element={<ChatLayout/>} />
                        <Route path="assignments" element={<InterpreterAssignmentsCtrl/>} />
                        <Route path="assignments/:id" element={<ViewAssignment/>} />
                        <Route path="support" element={<Tickets/>} />
                        <Route path="support/new-ticket" element={<SupportForm/>} />
                    </Route>
                </Route>

                {/* /////////////////////
                    Non Protected Interpreter Routes
                //////////////////////// */}

                <Route path="/interpreter/login" element={<Login/>} />
                <Route path="/interpreter/forgot-password" element={<ForgotPassword/>} />
                <Route path="/interpreter/logout" element={<Logout/>} />
                
            </Routes>
            <ToastContainer />
        </>
    )
}

export default Root;