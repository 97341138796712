import React from 'react';
import { Dropdown,InputGroup,Nav,NavDropdown,Form,Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import search from '../../../../assets/images/icons/search.png';
// import user from '../../../../assets/images/icons/user.png';
import notify from '../../../../assets/images/icons/menu/notify.svg';
import chat from '../../../../assets/images/icons/menu/chat.svg';
// import avatar from '../../../../assets/images/dummy.png';
import Brand from '../../../../assets/images/logo.png';
import TopSearchBar from '../../../SubAdmin/Common/SearchBar';

function SchoolTopBarCtrl(props) {

    const user = props.user;

    return(
        <>
        <div className='top_header'>
            <Row className='justify-content-between align-items-center'>
                <Col lg="4">
                    <div className='justify-content-between align-items-center d-flex '>
                        <TopSearchBar />
                        <div className='top_header_brand'>
                            <NavLink>
                                <img width={40} src={Brand} />
                            </NavLink>
                        </div>
                        
                        <Button className="toggle" onClick={props.toggleSideBar}>
                            <span className="line-toggle"></span>
                            <span className="line-toggle"></span>
                            <span className="line-toggle"></span>
                        </Button>
                    </div>
                </Col>
                <Col className='col-auto'>
                    <div className='top_right_nav'>
                        <Nav as="ul" className='align-items-center'>
                            <Nav.Item as="li" className=''>
                                <NavLink className="nav-link" to="chat">
                                    <span>
                                        <img width={25} src={chat} />
                                    </span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li" className='notification'>
                                <NavLink className="nav-link" to="notifications">
                                    <span>
                                        <img width={25} src={notify} />
                                        <span className="notify"></span>
                                    </span>
                                </NavLink>
                            </Nav.Item>
                            {/* <Nav.Item as="li" className=''>
                                <NavLink className="nav-link" to="profile">
                                    <span>
                                        <img className="rounded-3" height={50} width={48} src={user.picture_path} />
                                    </span> 
                                    
                                </NavLink>
                            </Nav.Item> */}
                            <Nav.Item as="li" className='ms-3'>
                                <NavLink className="" to="profile">
                                    <Dropdown className='user'>
                                        <Dropdown.Toggle className='ps-0' variant="link" id="dropdown-basic">
                                        <span className='p-0'>
                                            <img src={user.picture_path} />
                                        </span> 
                                        <div className='text-start'>
                                            <p className='m-0 text-capitalize'>{user.full_name}</p>
                                            <small className='logged_user_typ'>{user.role}</small>
                                        </div>
                                        </Dropdown.Toggle>
                                        {/* <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu> */}
                                    </Dropdown>
                                </NavLink>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    )
}


export default SchoolTopBarCtrl;