import React, { useEffect, useState } from 'react';
import { Col, Button, Row, } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET } from '../../../utils/axios.util';

import SchoolSubscriptionsPlanCtrl from './Plan';

function SchoolSubscriptionsCtrl(props){

    const [ subscriptionPlans, setSubscriptionPlans ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ planType, setPlanType ] = useState('monthly')

    useEffect(() => {

        if(!subscriptionPlans.length > 0) {
            getSubscriptionPlans()
        }
        
    },[]);


    const getSubscriptionPlans = () => {

        setIsLoading(true)

        let payload = {
            url : `${BACKEND_DOMAIN}/subscriptions`
        }
        GET(payload).then(res => {
            if(res.data) { 

                setSubscriptionPlans(res.data.data)
                setIsNoRecords(false)
                setIsLoading(false)
            }
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const changePlanType = (type) => {
        setPlanType(type)
    }

    return(
        <>        

            <h2 className="page_hdng">Subscriptions</h2>
            { (isNoRecords === false) &&  subscriptionPlans.length > 0 &&
                <div className="my_card_brand_color3 mt-3 p-3">
                    <Row className="basic_subscription justify-content-center align-items-center">
                        <Col>
                            <div className="d-flex align-items-center">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.8" cx="4" cy="12" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.6" cx="4" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.4" cx="12" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle cx="20" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle cx="12" cy="12" r="4" fill="#F2F1FF"/>
                                    </svg>
                                </span>
                                <h5 className="mb-0 ms-3">Current Subscription</h5>&nbsp;
                                <button className="change_plan_btn" >{subscriptionPlans[0]['current_subscription']['name']}</button>
                            </div>
                        </Col>
                        {/* <Col className="col-auto">
                            <button className="change_plan_btn" >Change plan</button>
                        </Col> */}
                    </Row>
                </div>
            }
            <Row>
                <Col>
                    { (isNoRecords === false) &&  subscriptionPlans.length > 0 &&
                        <div className='my_card mt-3'>
                            <Row>
                                <Col sm="12" md="6" lg="6">
                                    <div className='my_card_brand_dark_color h-100'>
                                        <SchoolSubscriptionsPlanCtrl onChangePlanType={changePlanType} />
                                    </div>
                                </Col>
                                <Col sm="12" md="6" lg="6" className='subscriptions_packages plans'>

                                {   subscriptionPlans.map((elem, index) => {
                                    
                                    let price = 0
                                    let planText = ''
                                    /* if(planType === 'monthly') {
                                        price = elem.monthly_amount
                                    }else {
                                        price = elem.yearly_amount
                                    } */

                                    if(elem.monthly_amount > 0) {
                                        price = elem.monthly_amount
                                        planText = 'monthly'
                                    }else {
                                        price = elem.yearly_amount
                                        planText = 'yearly'
                                    }

                                    return (
                                        <div key={elem.id}>
                                            <NavLink to={elem.name.toLowerCase()} >
                                                <div className='packages_container'>
                                                    <div className='subscriptions_packages_name'>
                                                        <h5>{elem.name}</h5>
                                                        { elem.discount && 
                                                            <Button className="change_plan_btn discount" >Save {elem.discount}%</Button>
                                                        }
                                                    </div>
                                                    <h4>${price}<sub>/<span className="text-capitalize">{planText}</span></sub></h4>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )

                                    })
                                }

                                </Col>
                            </Row>
                            <hr/>
                            <div className='text-end'>
                                <Button variant='success' size='lg'>Choose plan</Button>
                            </div>
                        </div>
                    }
                    { isNoRecords && 
                        <div className='text-center subscriptions_packages_name no_records_loading' >
                            <h5>No Records Found!</h5>
                        </div>
                    }
                    { isLoading && 
                        <div className='text-center subscriptions_packages_name no_records_loading'>
                            <h5>Loading...</h5>
                        </div>
                    }
                </Col>
            </Row>
        </>
    )
}

export default SchoolSubscriptionsCtrl;