import React, { useEffect, useState } from 'react';
import {Tab , Nav } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../../config/variables';
import { GET } from '../../../../utils/axios.util';

function SchoolSubscriptionsPlanCtrl(props){

    var location = useLocation();
    const navigate = useNavigate();
    
    const params = useParams();
    const [ subscriptionPlans, setSubscriptionPlans ] = useState([])
    const [ currentPlan, setCurrentPlan ] = useState({})
    const [ plan, setPlan ] = useState(params.plan)

    useEffect(() => {

        console.log('currentPlan')
        console.log(currentPlan)

        if(location.pathname == "/school/subscriptions") {
            if(Object.keys(currentPlan).length === 0) {
                getSubscriptionPlans()
            }else {

                navigate(`/school/subscriptions/${currentPlan.name.toLowerCase()}`)
            }
        }else {
            if(!subscriptionPlans.length > 0 || plan !== params.plan ) {
                getSubscriptionPlans()
            }
            
            setPlan(() => params.plan)
        }

    },[ currentPlan, params.plan ]);


    const getSubscriptionPlans = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/subscriptions`
        }
        GET(payload).then(res => {

            if(res.data) { 
                setSubscriptionPlans(res.data.data)
                var planFilter = res.data.data.filter(pln => {
                    return params.plan === pln.name.toLowerCase()
                }) 
                if(location.pathname == "/school/subscriptions") {
                    planFilter[0] = res.data.data[0]
                }

                setCurrentPlan(planFilter[0])
            }
        }).catch(err => {
            console.log(err)
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })

    }

    return(
        <>
            <div className="my_type_subscriptions">
                <Tab.Container id="" defaultActiveKey={ (currentPlan && (currentPlan.monthly_amount > 0)) ? 'monthly' : 'yearly' } activeKey={ ( (Object.keys(currentPlan).length > 0) && (currentPlan.monthly_amount > 0) ) ? 'monthly' : 'yearly' } >
                    <Nav variant="" className="my_pill_tab_nav">
                        { currentPlan.monthly_amount > 0 &&
                            <Nav.Item>
                                <Nav.Link eventKey="monthly" onClick={() => props.onChangePlanType('monthly')} >Monthly</Nav.Link>
                            </Nav.Item>
                        }
                        { currentPlan.yearly_amount > 0 &&
                            <Nav.Item>
                                <Nav.Link eventKey="yearly" onClick={() => props.onChangePlanType('yearly')} >Yearly</Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                    <Tab.Content>
                        { currentPlan.monthly_amount > 0 &&
                            <Tab.Pane eventKey="monthly">
                                <Nav className="my_type_subscriptions_content">
                                { Object.keys(currentPlan).length > 0 &&

                                    currentPlan.features.map((elem, index) => { 

                                        return (
                                            <Nav.Item key={elem}>
                                                <p>{elem}</p>
                                            </Nav.Item>
                                        )
                                    })
                                    
                                }
                                </Nav>
                                
                                <div className="d-flex justify-content-center">
                                    <p>Up to {currentPlan.no_of_accounts} users Accounts</p>
                                </div>

                            </Tab.Pane>
                        }
                        { currentPlan.yearly_amount > 0 &&
                            <Tab.Pane eventKey="yearly">
                                <Nav className="my_type_subscriptions_content">
                                { Object.keys(currentPlan).length > 0 &&

                                    <>
                                        {   currentPlan.features.map((elem, index) => { 

                                                return (
                                                    <Nav.Item key={elem}>
                                                        <p>{elem}</p>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                        
                                    </>
                                }
                                </Nav>

                                <div className="d-flex justify-content-center">
                                    <p>Up to {currentPlan.no_of_accounts} users Accounts</p>
                                </div>
                            </Tab.Pane>
                        }
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default SchoolSubscriptionsPlanCtrl;