import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";

import useAuthenticate from '../../hooks/useAuthenticate';

function SubAdminInterface(props) {

    const user = useAuthenticate()
    let navigate = useNavigate()

    useEffect(() => {

    }, [ user ])

    if(Object.keys(user).length > 0 && (user.role === 'sub_super_admin' || user.role === 'sub_admin') ) {

        return(
            <>
                <section className="School_interface">
                    <Outlet/>
                </section>
            </>
        )
    }else {
       
        return navigate('/login')
    }

}

export default SubAdminInterface;