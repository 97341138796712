import React,{useState} from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import StudentSideBarCtrl from '../Common/Sidebar';
import StudentTopBarCtrl from '../Common/Topbar';

function StudentDashboardLayoutCtrl(props){

    const [isSidebar, isSidebarActive] = useState(false)

    const sideBarClick = (event) => {
        isSidebarActive(current => !current);
    };

    return(
        <>
            <div className="dashboardLayout">
            <div className={isSidebar ? 'sidebar_container sidebar_deactive' : 'sidebar_container sidebar_active'}>
                    <StudentSideBarCtrl {...props}  toggleSideBar={sideBarClick} />
                </div>
                <div className="main_body_container">
                    <StudentTopBarCtrl {...props}  toggleSideBar={sideBarClick} />
                    <Container fluid>
                        <section className="main_body_content mt-4">
                            <Outlet/>
                        </section>
                    </Container>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate
    }
}
  
export default connect(mapStateToProps, null)(StudentDashboardLayoutCtrl);