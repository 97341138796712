import React, { useState } from 'react';
import {Col, Button,Row,Tab,Nav, Badge } from "react-bootstrap";
import left_arrow from '../../../assets/images/icons/left_arrow.png';
import right_arrow from '../../../assets/images/icons/right_arrow.png';
import member1 from '../../../assets/images/member1.png';
import member2 from '../../../assets/images/member2.png';
import member3 from '../../../assets/images/member3.png';


function CoordinatorNotificationsCtrl(){

    return(
        <>        

            <h2 className="page_hdng">Notifications</h2>
            <div className='my_card mt-4'>
                <Row>
                    <Col>
                    <div className="my_card">
                        <Tab.Container id="fdgdfg" defaultActiveKey="general">
                            <Row className='align-items-center justify-content-between'>
                                <Col className='col-auto'>
                                    <Nav className="my_pill_tab_nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="general">General</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="assignments">Assignments</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="payment">Payment</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col className='col-auto'>
                                    
                                <div className="assignments_date">
                                    <Button variant='light'>
                                    <button>
                                        <img src={left_arrow} />
                                    </button>
                                    <span className="assign_date">July 22 to 29</span>
                                    <button>
                                        <img src={right_arrow} />
                                    </button>
                                    </Button>
                                </div>
                                </Col>
                            </Row>

                            <Tab.Content className='p-0 mt-3'>
                                <Tab.Pane eventKey="general">
                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Adam Khalil</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_brand_color2 m-0'>15 Feb, 2021, 10:00 AM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member3} />
                                                        {/* <span>20+</span> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Abubakr Dampha</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        {/* <span>20+</span> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>
                                    
                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Adam Khalil</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        {/* <span>20+</span> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Abubakr Dampha</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        {/* <span>20+</span> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Adam Khalil</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        {/* <span>20+</span> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="assignments">
                                Assignments
                                </Tab.Pane>
                                <Tab.Pane eventKey="payment">
                                Payment
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CoordinatorNotificationsCtrl;