import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import invFtrLogo from '../../assets/images/logo_dark.png';
import { NavLink } from "react-router-dom";
import download from 'downloadjs';
import { BACKEND_DOMAIN } from '../../config/variables';
import { GET } from '../../utils/axios.util';

function SchoolInvoiceComponent(){

    const generatePdf = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/generate-invoice`
        }

        function downloadPdf() {
            fetch('http://localhost:3000/public/pdfs/output.pdf')
            .then(async response => {
                let blob = await response.blob()
                download(blob, 'test.pdf');
            })
        }

        GET(payload).then(res => {
            downloadPdf()
        }).catch(err => {
            console.log(err)
        })

    }

    return(
        <>

            <h2 className="page_hdng">Invoice</h2>
            <Row className="justify-content-center my-3">
                <Col md="8">
                    <div className="invoice_container" id="invoice-container-id">
                        <div className="invoice_header">

                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <div>
                                            <h4>Invoice</h4>
                                            <p>Date June 1- 30 2020 <br/> Invoice No.: 9465642761</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="float-end" >
                                            <h5>School Name</h5>
                                            <p>(School Address)Gabby Chen <br/> 102 Bayview Ave <br/> Toronto, Ontario <br/> M6P 2K6</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr>
                                    <th>Enterprises</th>
                                    <th>16/05/2023</th>
                                    <th>16/05/2024</th>
                                    <td>79.99 X 12 =</td>
                                    <th className="text-end">959.88</th>
                                </tr>
                            </tbody> */}
                        </Table>

                        {/* <Row>
                            <Col>
                                <div>
                                    <h4>Invoice</h4>
                                    <p>Date June 1- 30 2020 <br/> Invoice No.: 9465642761</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="float-end" >
                                    <h5>School Name</h5>
                                    <p>(School Address)Gabby Chen <br/> 102 Bayview Ave <br/> Toronto, Ontario <br/> M6P 2K6</p>
                                </div>
                            </Col>
                        </Row> */}
                        </div>
                        <div className="invoice_body">
                            <Table className="rt_table">
                                <thead>
                                    <tr>
                                        <th>Subscription Type</th>
                                        <th>Valid From</th>
                                        <th>Valid Till</th>
                                        <th>Rate</th>
                                        <th className="text-end">Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Enterprises</th>
                                        <th>16/05/2023</th>
                                        <th>16/05/2024</th>
                                        <td>79.99 X 12 =</td>
                                        <th className="text-end">959.88</th>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr />
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <span className="fs-12 fw-500">Discount Coupon</span>
                                </div>
                                <div className="">
                                    <span className="fs-10 fw-500">$0.88</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <span className="fs-10 fw-500">TAX</span>
                                </div>
                                <div className="">
                                    <span className="fs-10 fw-500">$41</span>
                                </div>
                            </div>

                            <hr /> */}
                        </div>
                        {/* <div className="invoice_amount d-flex justify-content-between align-items-center"> */}
                        <div className="invoice_amount" >
                            <span className="fs-12 fw-500">Total amount</span>
                            <span className="fs-20 fw-700 float-end">$1000</span>
                        </div>


                        <Table>
                            <thead className="invoice_footer" >
                                <tr>
                                    <th>
                                        <div class="float-end">
                                            <img src={invFtrLogo} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="">
                                            <h3>Thank you</h3>
                                            <p>Terp2go pvt Ltd</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr>
                                    <th>Enterprises</th>
                                    <th>16/05/2023</th>
                                    <th>16/05/2024</th>
                                    <td>79.99 X 12 =</td>
                                    <th className="text-end">959.88</th>
                                </tr>
                            </tbody> */}
                        </Table>

                        {/* <div className="invoice_footer" >
                            <Row>
                                <Col sm="12" md="4" lg="4" lg-4 className="d-flex justify-content-center" >
                                    <img src={invFtrLogo} />
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <div className="">
                                        <h3>Thank you</h3>
                                        <p>Terp2go pvt Ltd</p>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                        

                        {/* <div className="invoice_footer">
                            <NavLink>
                                <img src={invFtrLogo} />
                            </NavLink>
                            <div className="">
                                <h3>Thank you</h3>
                                <p>Terp2go pvt Ltd</p>
                            </div>
                        </div> */}
                    </div>

                    <button className="select_date_box mt-2 float-end" onClick={generatePdf} >
                        <span>Download</span>
                    </button>
                </Col>
            </Row>
        </>
    )
}

export default SchoolInvoiceComponent;