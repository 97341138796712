import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { CSVLink } from "react-csv";
import {Modal, Col, Form, Row,Tab,Nav , Table, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Moment from 'react-moment';
import moment from 'moment-timezone';
import docs_play from '../../../assets/images/icons/docs_play.png';
import grn_excla from '../../../assets/images/icons/grn_excla.png';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import calendar from '../../../assets/images/icons/calendar.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import useDivisions from '../../../hooks/useDivisions';
import PAGINATION from '../../../components/common/pagination';
import Privileged from '../../../HOC/privileged';
import useMenuAccess from '../../../hooks/useMenuAccess';

function SubAdminUsers(){

    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();

    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ users, setUsers ] = useState({})
    const [ fromDate, setFromDate ] = useState('');
    const [ toDate, setToDate ] = useState('');
    const [ divisonOptions, setDivisonOptions ] = useState('');
    const [ checkedState, setCheckedState ] = useState({})
    const [ deleteUserInfo, setDeleteUserInfo ] = useState({});
    const [ allCheckedState, setAllCheckedState ] = useState(false)
    const [ deleteOrgModalShow, setOrgDeleteModalShow ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(true)
    const divisions = useDivisions()
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const menuAccess = useMenuAccess();

    const paramRef = useRef({
        category: '',
        division: '',
        fromDate: '',
        toDate: ''
    })

    var params = paramRef.current

    const studentsHeaders = ["Firstname", "Lastname", "Email", "Password", "Division"]
    const generalHeaders = ["Firstname", "Lastname", "Email", "Password"]
    const [ csvData, setCsvData ] = useState([])

    const itemsPerPage = 10
    const [ studentsOffset, setStudentsOffset ] = useState(0);
    const [ subAdminsOffset, setSubAdminsOffset ] = useState(0);
    const [ coordinatorsOffset, setCoordinatorsOffset ] = useState(0);
    const [ interpretersOffset, setInterpretersOffset ] = useState(0);
    const [ studentsItems, setStudentsItems ] = useState([])
    const [ subAdminsItems, setSubAdminsItems ] = useState([])
    const [ coordinatorsItems, setCoordinatorsItems ] = useState([])
    const [ interpretersItems, setInterpretersItems ] = useState([])
    const [ studentsPageCount, setStudentsPageCount ] = useState(0);
    const [ subAdminsPageCount, setSubAdminsPageCount ] = useState(0);
    const [ coordinatorsPageCount, setCoordinatorsPageCount ] = useState(0);
    const [ interpretersPageCount, setInterpretersPageCount ] = useState(0);
    const [ activePageNumber, setActivePageNumber ] = useState('');
    const handleOrgDeleteModalClose = () => setOrgDeleteModalShow(false);

    var isUsersInitialized = useRef(false)

    /* Maintain tab state code starts here */
    
    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')
    let defaultTabValue = 'coordinators'
    let initialCreatePath = 'coordinator/create'
    let initialAddText = 'Add Coordinator'
    let currentPageNumValue = 0
    let initialPageValue = 0

    if(urlTab == 'interpreters') {
        defaultTabValue = 'interpreters'
    }
   
    if(urlTab == 'students') {
        defaultTabValue = 'students'
    }

    if(defaultTabValue == 'interpreters') {
        initialCreatePath = 'interpreter/create'
        initialAddText = 'Add Interpreter'
    }
    
    if(defaultTabValue == 'students') {
        initialCreatePath = 'student/create'
        initialAddText = 'Add Student'
    }

    if(urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [ currentPageNum, setCurrentPageNum ] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    const [ defaultTab, setDefaultTab ] = useState(defaultTabValue);
    const [ currentTabState, setCurrentTabState ] = useState(defaultTabValue);
    const [ createPath, setCreatePath ] = useState(initialCreatePath)
    const [ addText, setAddText ] = useState(initialAddText);

    /* Maintain tab state code ends here */

    useEffect(() => {

        if(Object.keys(users).length === 0 && !isUsersInitialized.current) {
            getUsers()
        }

        if(divisions.length > 0 && !divisonOptions) {
            let optionValues =  divisions.map((e) => {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setDivisonOptions(optionValues)
        }
        
    },[ users, divisions, studentsItems, coordinatorsItems, interpretersItems, studentsOffset, coordinatorsOffset, interpretersOffset, itemsPerPage ]);

    const getUsers = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/users/list?&division=${params.division}&fromDate=${params.fromDate}&toDate=${params.toDate}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            const studentsEndOffset = studentsOffset + itemsPerPage;
            const coordinatorsEndOffset = coordinatorsOffset + itemsPerPage;
            const interpretersEndOffset = interpretersOffset + itemsPerPage;
            let studentsChunk = []
            let coordinatorsChunk = []
            let interpretersChunk = []
            let studentsPageCount = Math.ceil(data.students.length / itemsPerPage);
            let coordinatorsPageCount = Math.ceil(data.coordinators.length / itemsPerPage);
            let interpretersPageCount = Math.ceil(data.interpreters.length / itemsPerPage);

            if(data.students.length > 0) {
                studentsChunk = data.students.slice(studentsOffset, studentsEndOffset)
                initializeCheckBox(studentsChunk)
            }
            if(data.coordinators.length > 0) {
                coordinatorsChunk = data.coordinators.slice(coordinatorsOffset, coordinatorsEndOffset)
            }
            if(data.interpreters.length > 0) {
                interpretersChunk = data.interpreters.slice(interpretersOffset, interpretersEndOffset)
            }

            setIsLoading(false)
            setIsNoRecords(false)
            setUsers(data)

            setStudentsPageCount(studentsPageCount)
            setCoordinatorsPageCount(coordinatorsPageCount)
            setInterpretersPageCount(interpretersPageCount)
            setStudentsItems(studentsChunk)
            setCoordinatorsItems(coordinatorsChunk)
            setInterpretersItems(interpretersChunk)
            isUsersInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const initializeCheckBox = (tempItems) => {

        if(tempItems.length > 0) {
            let checkMap = {}
            for(let x of tempItems) {
                checkMap[x.id] = false
            }
            setCheckedState(prevState => {
                return {
                    ...checkMap
                }
            })
        }
        setIsDisabled(true)
        
    }
    
    const deleteUser = () => {

        let role = deleteUserInfo.role
        let user_id = deleteUserInfo.id

        let data = {
            user_id
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/school/${role}/delete`,
            data
        }

        POST(payload).then(res => {
            setOrgDeleteModalShow(false)
            toast.success(res.data.message)
            getUsers()
        }).catch(err => {

            setOrgDeleteModalShow(false)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            toast.error(err.response.data.message)
        })
    }

    const handleOrgDeleteModalShow = (elem) => {

        setDeleteUserInfo(elem)
        setOrgDeleteModalShow(true)

    }

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            <span>{!fromDate ? 'Start Date' : value}</span>
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            <span>{!toDate ? 'End Date' : value}</span>
        </button>
    ));

    const changeDivision = (event) => {

        setValue('division', event.target.value)
        params.division = event.target.value
        getUsers()
    }

    const validateFromDate = (date) => {

        if(moment(toDate).isBefore(date)) {
            setToDate('')
        }
        setFromDate(date);
        params.fromDate = moment(date).tz(TIME_ZONE).format('YYYY-MM-DD');
        getUsers()
    }

    const validateToDate = (date) => {

        setToDate(date);
        params.toDate = moment(date).tz(TIME_ZONE).format('YYYY-MM-DD');
        getUsers()
    }

    const togglePassword = (eLId) => {
        let element = document.getElementById(`password_${eLId}`)
        let eyecon = document.getElementById(`eyecon_${eLId}`)
        if(element.type == 'text') {
            element.setAttribute('type', 'password')
        }else {
            element.setAttribute('type', 'text')
        }

        if(eyecon.getAttribute('src') == eye) {
            eyecon.setAttribute('src', eye_slash) 
        }else {
            eyecon.setAttribute('src', eye)
        }
    }

    const handlePageClick = (event, currTab) => {

        let items = []

        if(currTab == 'students') {
            items = users.students
        }
        if(currTab == 'coordinators') {
            items = users.coordinators
        }
        if(currTab == 'interpreters') {
            items = users.interpreters
        }
        
        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        let checkMap = {}
        for(let x of chunk) {
            checkMap[x.id] = false
        }
        setCheckedState(prevState => {
        return {
            ...checkMap
        }
        })
        setAllCheckedState(false)
        
        if(currTab == 'students') {
            setStudentsPageCount(pageCount)
            setStudentsItems(chunk)
            setStudentsOffset(newOffset);
        }
        if(currTab == 'coordinators') {
            setCoordinatorsPageCount(pageCount)
            setCoordinatorsItems(chunk)
            setCoordinatorsOffset(newOffset);
        }
        if(currTab == 'interpreters') {
            setInterpretersPageCount(pageCount)
            setInterpretersItems(chunk)
            setInterpretersOffset(newOffset);
        }
            
        if(chunk.length > 0) {
            initializeCheckBox(chunk)
        }
    };

    const handleAllCheckBox = (event) => {

        let inverseValue =  !allCheckedState

        let tempCheckstate = {}
        for(let y in checkedState) {
          if(inverseValue == true) {
            tempCheckstate[y] = true
          }else{
            tempCheckstate[y] = false
          }
          
        }

        let checkedData = Object.keys(tempCheckstate).filter(key => tempCheckstate[key] === true)

        if(checkedData.length > 0) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setAllCheckedState(inverseValue)
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });
        
    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        let tempCheckstateArr = []
        for(let y in checkedState) {
            if(y == checkId) {
                tempCheckstate[y] = !checkedState[y]
            }
        }
        let dummyCheck = {
            ...checkedState,
            ...tempCheckstate
        }

        for(let z in dummyCheck) {
            tempCheckstateArr.push(dummyCheck[z])
        }
        if(tempCheckstateArr.every(element => element === true)) {
            setAllCheckedState(true)
        }else {
            setAllCheckedState(false)
        }

        if(tempCheckstateArr.some(element => element === true)) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setCheckedState(prevState => {
            return {
            ...prevState,
            ...tempCheckstate
            }
        });
    }

    const exportSelected = () => {

        let csvHeaders = []
        let tempUsers = []
        if(currentTabState == 'students') {
            csvHeaders = studentsHeaders
            tempUsers = studentsItems
        }else {
            csvHeaders = generalHeaders
        }
        if(currentTabState == 'coordinators') { 
            tempUsers = coordinatorsItems
        }
        if(currentTabState == 'interpreters') { 
            tempUsers = interpretersItems
        }
        let tempCsvData = [[...csvHeaders]]
        for(let x in checkedState) {
            if(checkedState[x] == true) {
                let filterUsers = tempUsers.filter(elem => elem.id == x )
                let filteredUser = []

                if(filterUsers.length > 0) {

                    if(currentTabState == 'students') {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password'], filterUsers[0]['division_name']]
                    }else {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password']]
                    }
                    tempCsvData = [...tempCsvData, filteredUser]
                    
                }
            }
        }

        setCsvData(tempCsvData)
            
    }

    const exportAll = () => {

        let csvHeaders = []
        let tempUsers = []
        if(currentTabState == 'students') {
            csvHeaders = studentsHeaders
            tempUsers = users.students
        }else {
            csvHeaders = generalHeaders
        }
        if(currentTabState == 'coordinators') { 
            tempUsers = users.coordinators
        }
        if(currentTabState == 'interpreters') { 
            tempUsers = users.interpreters
        }

        let tempCsvData = [[...csvHeaders]]
        for(let x of tempUsers) {
            let filteredUser = []
            if(currentTabState == 'students') {
                filteredUser = [x['first_name'], x['last_name'], x['email'], x['password'], x['division_name']]
            }else {
                filteredUser = [x['first_name'], x['last_name'], x['email'], x['password']]
            }
            tempCsvData = [...tempCsvData, filteredUser]
        }

        setCsvData(tempCsvData)

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
        let tempItems = []
        if(tab == 'students') {
            setCreatePath('student/create')
            tempItems = studentsItems
            setAddText('Add Student')
        }
        if(tab == 'coordinators') {
            setCreatePath('coordinator/create')
            tempItems = coordinatorsItems
            setAddText('Add Coordinator')
        }
        if(tab == 'interpreters') {
            setCreatePath('interpreter/create')
            tempItems = interpretersItems
            setAddText('Add Interpreter')
        }
        setCurrentTabState(tab)
        setAllCheckedState(false)
        initializeCheckBox(tempItems)
    }

    const editPage = (elem, type) => {

        let activeItemSelector = ''

        if(currentTabState == 'interpreters') {
            activeItemSelector = `#fdgdfg-tabpane-interpreters ul li.page-item.active a`
        }

        if(currentTabState == 'students') {
            activeItemSelector = `#fdgdfg-tabpane-students ul li.page-item.active a`
        }

        if(currentTabState == 'coordinators') {
            activeItemSelector = `#fdgdfg-tabpane-coordinators ul li.page-item.active a`
        }

        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        if(type == 'students') {
            navigate(`/sub-admin/users/student/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }

        if(type == 'coordinators') {
            navigate(`/sub-admin/users/coordinator/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }

        if(type == 'interpreters') {
            navigate(`/sub-admin/users/interpreter/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }
    }

    return(
        <>        

            <h2 className="page_hdng">Users</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.coordinators.length > 0) ? users.coordinators.length : 0}</h4>
                        <p>Coordinators</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.interpreters.length > 0) ? users.interpreters.length : 0}</h4>
                        <p>Interpreters</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.students.length > 0) ? users.students.length : 0}</h4>
                        <p>Students</p>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <div className="fixed_tab">
                    <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                        <Row className='justify-content-between'>
                            <Col className='col-auto'>
                                <Nav variant="fixed_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="coordinators" onClick={() => changeTab('coordinators') } >Coordinators</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="interpreters" onClick={() => changeTab('interpreters') } >Interpreters</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="students" onClick={() => changeTab('students')} >Students</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            
                            <Col className='col-auto'>
                                <Row>
                                    <Col className='col-auto'>
                                        <div className='d-flex justify-content-end'>
                                            <Form.Select className='me-2' name="division" {...register('division')} onChange={(event) => changeDivision(event)} style={{height: '45px'}}>
                                                <option value="">-- Class --</option>
                                                {divisonOptions}
                                            </Form.Select>
                                            <div className='d-flex align-items-center'>
                                                <DatePicker
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={fromDate}
                                                    onChange={(date) => validateFromDate(date)}
                                                    customInput={<FromDate />}
                                                />
                                                <span className='mx-2'>To</span>
                                                <DatePicker
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={toDate}
                                                    minDate={fromDate}
                                                    onChange={(date) => validateToDate(date)}
                                                    customInput={<ToDate />}
                                                />
                                            </div>
                                                <CSVLink 
                                                    data={csvData} 
                                                    filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                    onClick={() => !isDisabled}
                                                >
                                                    <Button size='md' className='px-4 ms-2' disabled={isDisabled} onClick={exportSelected} >Export Selected</Button>
                                                </CSVLink>
                                                <CSVLink 
                                                    data={csvData} 
                                                    filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                    onClick={() => (currentTabState == 'students' ? (Object.keys(users).length > 0 && users.students.length > 0) : (currentTabState == 'coordinators' ? (Object.keys(users).length > 0 && users.coordinators.length > 0) : (currentTabState == 'interpreters' ? (Object.keys(users).length > 0 && users.interpreters.length > 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length > 0 && users.sub_admins.length > 0) : false )))) }
                                                >
                                                    <Button size='md' className='px-4 ms-2' onClick={exportAll} disabled={(currentTabState == 'students' ? (Object.keys(users).length === 0 || users.students.length === 0) : (currentTabState == 'coordinators' ? (Object.keys(users).length === 0 || users.coordinators.length === 0) : (currentTabState == 'interpreters' ? (Object.keys(users).length === 0 || users.interpreters.length === 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length === 0 || users.sub_admins.length === 0) : false ))))} >Export All</Button>
                                                </CSVLink>
                                                { menuAccess && menuAccess.create_mode === 1 &&
                                                    <Button variant='success' size='md' className='ms-2' onClick={() => navigate(`${createPath}?tab=${currentTabState}&page=${activePageNumber}`)} style={{ width: '-webkit-fill-available' }} >{addText}</Button>
                                                }
                                        </div>
                                    </Col>
                                </Row>
                                
                                    
                            </Col>
                        </Row>

                        <Tab.Content>
                            <Tab.Pane eventKey="coordinators">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Coordinators Name</th>
                                            <th>Added Since</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { isLoading && 
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                            Loading...
                                            </td>
                                        </tr>
                                    }
                                    { !isLoading && (Object.keys(users).length > 0 && users.coordinators.length === 0) &&
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.coordinators.length > 0) &&
                                        coordinatorsItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td width="50">
                                                        {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`}>
                                                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                        </div>
                                                        ))}
                                                    </td>
                                                    <td width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td>
                                                        <h5>{elem.full_name}</h5>
                                                        {/* <p className='text_light_color'>{elem.division_name}</p> */}
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YYYY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.email}</h5>
                                                    </td>
                                                    <td className="paddsowd">
                                                        <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                        <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge badge_md">
                                                            <img src={docs_play} />
                                                        </span>
                                                        <span className="badge badge_md">
                                                            <img src={grn_excla} />
                                                        </span> */}
                                                        { menuAccess && menuAccess.update_mode === 1 &&
                                                            <span className="badge badge_md" onClick={() => editPage(elem, 'coordinators')} style={{ cursor: 'pointer' }} >
                                                                <img src={mode_edit} />
                                                            </span>
                                                        }
                                                        { menuAccess && menuAccess.delete_mode === 1 &&
                                                            <NavLink data-role="coordinator" data-id={elem.id} onClick={() => handleOrgDeleteModalShow(elem)} >
                                                                <span className="badge badge_md">
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            </NavLink>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        )
                                    }
                                    </tbody>
                                </Table>
                                { Object.keys(users).length > 0 && users.coordinators.length > 0 &&
                                    <PAGINATION allItems={users.coordinators} chunkedItems={coordinatorsItems} handlePageClick={event => handlePageClick(event, 'coordinators')} pageCount={coordinatorsPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="interpreters">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Interpreter Name</th>
                                            <th>Added Since</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { isLoading && 
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                            Loading...
                                            </td>
                                        </tr>
                                    }
                                    { !isLoading && (Object.keys(users).length > 0 && users.interpreters.length === 0) &&
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.interpreters.length > 0) &&
                                        interpretersItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td width="50">
                                                        {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`}>
                                                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                        </div>
                                                        ))}
                                                    </td>
                                                    <td width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td>
                                                        <h5>{elem.full_name}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YYYY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.email}</h5>
                                                    </td>
                                                    <td className="paddsowd">
                                                        <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                        <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge badge_md">
                                                            <img src={docs_play} />
                                                        </span>
                                                        <span className="badge badge_md">
                                                            <img src={grn_excla} />
                                                        </span> */}
                                                        { menuAccess && menuAccess.update_mode === 1 &&
                                                            <span className="badge badge_md" onClick={() => editPage(elem, 'interpreters')} style={{ cursor: 'pointer' }} >
                                                                <img src={mode_edit} />
                                                            </span>
                                                        }
                                                        { menuAccess && menuAccess.delete_mode === 1 &&
                                                            <NavLink data-role="interpreter" data-id={elem.id} onClick={() => handleOrgDeleteModalShow(elem)} >
                                                                <span className="badge badge_md">
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            </NavLink>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        )
                                    }
                                    </tbody>
                                </Table>
                                { Object.keys(users).length > 0 && users.interpreters.length > 0 &&
                                    <PAGINATION allItems={users.interpreters} chunkedItems={interpretersItems} handlePageClick={event => handlePageClick(event, 'interpreters')} pageCount={interpretersPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="students">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Students Name</th>
                                            <th>Added Since</th>
                                            <th>Parent Name</th>
                                            <th>Phone</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { isLoading && 
                                        <tr className="text-center">
                                            <td colSpan="9" className="fs-4">
                                            Loading...
                                            </td>
                                        </tr>
                                    }
                                    { !isLoading && (Object.keys(users).length > 0 && users.students.length === 0) &&
                                        <tr className="text-center">
                                            <td colSpan="9" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.students.length > 0) &&
                                        studentsItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td width="50">
                                                        {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`}>
                                                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                        </div>
                                                        ))}
                                                    </td>
                                                    <td width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td>
                                                        <h5>{elem.full_name}</h5>
                                                        <p className='text_light_color'>{elem.division_name}</p>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YYYY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.guardian_name}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.phone}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.email}</h5>
                                                    </td>
                                                    <td className="paddsowd">
                                                        <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                        <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge badge_md">
                                                            <img src={grn_excla} />
                                                        </span> */}
                                                        { menuAccess && menuAccess.update_mode === 1 &&
                                                            <span className="badge badge_md" onClick={() => editPage(elem, 'students')} style={{ cursor: 'pointer' }} >
                                                                <img src={mode_edit} />
                                                            </span>
                                                        }
                                                        { menuAccess && menuAccess.delete_mode === 1 &&
                                                            <NavLink data-role="student" data-id={elem.id} onClick={() => handleOrgDeleteModalShow(elem)} >
                                                                <span className="badge badge_md">
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            </NavLink>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                        
                                    </tbody>
                                </Table>
                                { Object.keys(users).length > 0 && users.students.length > 0 &&
                                    <PAGINATION allItems={users.students} chunkedItems={studentsItems} handlePageClick={event => handlePageClick(event, 'students')} pageCount={studentsPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={deleteOrgModalShow} onHide={handleOrgDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to delete ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleOrgDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deleteUser()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Privileged(SubAdminUsers);