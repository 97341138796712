import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import eye from '../../../../assets/images/icons/eye.svg';
import eye_slash from '../../../../assets/images/icons/eye_slash.svg';

import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../../config/variables';
import { GET, PATCH } from '../../../../utils/axios.util';
import useDivisions from '../../../../hooks/useDivisions';
import NoAccess from '../../../../components/common/noaccess'
import useMenuAccess from '../../../../hooks/useMenuAccess';

function SubAdminStudentEdit() {

    const { register, handleSubmit, setValue, setError, clearErrors, getValues, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const params = useParams();
    const divisions = useDivisions()

    const [pwd, setPwd] = useState('');
    const [displayDate, setDisplayDate] = useState('')
    const [date, setDate] = useState(new Date(moment().tz(TIME_ZONE)))
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [options, setOptions] = useState(null)
    const [student, setStudent] = useState({})
    const [showNewClass, setShowNewClass] = useState('block')
    const [phone, setPhone] = useState('');
    const [guardianPhone, setGuardianPhone] = useState('');
    const menuAccess = useMenuAccess();

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    useEffect(() => {

        if (Object.keys(student).length === 0) {
            getStudent()
        }

        if (divisions.length > 0 && !options) {
            let optionValues = divisions.map((e) => {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            })
            setOptions(optionValues)
        }

    }, [student, divisions]);

    const getStudent = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/school/student/${params.id}`
        }

        GET(payload).then(res => {
            const { data } = res.data
            if (data.division_id) {
                setShowNewClass('none')
            }

            setPhone(data.phone)
            console.log(data.dob)
            setGuardianPhone(data.guardian_phone)
            if (data.dob) {
                setDate(new Date(moment(data.dob).format("MM/DD/YYYY")))
                setDisplayDate(moment(data.dob).format('MM/DD/YYYY'))
                setValue('dob', moment(data.dob).format('MM/DD/YYYY'))
            }
            setStudent(data)
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 404) {
                navigate('/sub-admin/users')
            }
        })

    }

    const handleClass = (event) => {

        let classVal = event.target.value
        setValue('new_class', '')
        if (classVal) {
            setShowNewClass('none')
        } else {
            setShowNewClass('block')
        }
    }

    const onSubmit = (data) => {

        clearErrors('phone')
        clearErrors('guardian_phone')

        data['dob'] = moment(data.dob).format('YYYY-MM-DD')
        data['phone'] = phone
        data['guardian_phone'] = guardianPhone

        if (!data.phone) {
            setError("phone", {
                type: "manual",
                message: "Phone number is required"
            });
            return;
        }

        if (!data.guardian_phone) {
            setError("guardian_phone", {
                type: "manual",
                message: "Guardian Phone number is required"
            });
            return;
        }

        if (!data.division_id && !data.new_class) {
            setError("new_class", {
                type: "manual",
                message: "Class is required"
            });
            return;
        }

        let payload = {
            url: `${BACKEND_DOMAIN}/school/student/${params.id}/edit`,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)

            let navigateUrl = `/sub-admin/users`

            if (urlTab) {
                navigateUrl = `/sub-admin/users?tab=${urlTab}`
            }

            if (urlTab && urlPage) {
                navigateUrl = `/sub-admin/users?tab=${urlTab}&page=${urlPage}`
            }

            navigate(navigateUrl)

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber = (e, type) => {

        if (type == 'student') {
            setPhone(e.target.value)
        } else {
            setGuardianPhone(e.target.value)
        }

        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {

            if (pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue = String.fromCharCode(e.keyCode);
        let key = e.which

        if (valLength > maxLength) {

            if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46)) {
                return true;
            } else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue)) {
            return true;
        } else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */

    }

    const formatUSNumber = (e, type) => {

        if (type == 'student') {
            clearErrors('phone')
        } else {
            clearErrors('guardian_phone')
        }

        let entry = e.target.value

        if (entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if (pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            let fieldName = ''
            if (type == 'student') {
                fieldName = 'phone'
            } else {
                fieldName = 'guardian_phone'
            }

            if (count != 10) {
                setError(fieldName, {
                    type: "manual",
                    message: "Number must be ten digits"
                });
                return;
            }

            const match = entry
                .replace(/\D+/g, '')
                .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
            e.target.value = `${part1}${part2}${part3}`

            if (type == 'student') {
                setPhone(`${part1}${part2}${part3}`)
            } else {
                setGuardianPhone(`${part1}${part2}${part3}`)
            }

            return `${part1}${part2}${part3}`
        }

    }

    const previousPage = () => {

        let navigateUrl = `/sub-admin/users`

        if (urlTab) {
            navigateUrl = `/sub-admin/users?tab=${urlTab}`
        }

        if (urlTab && urlPage) {
            navigateUrl = `/sub-admin/users?tab=${urlTab}&page=${urlPage}`
        }

        navigate(navigateUrl)
    }

    const DATE = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" name="dob" {...register("dob", { required: "Date Of Birth is required" })} defaultValue={displayDate} onClick={onClick} ref={ref} autoComplete="off" />
    ));

    if (menuAccess && menuAccess.update_mode === 1) {
        return (
            <>
                {(Object.keys(student).length > 0) && options &&
                    <>
                        <h2 className="page_hdng">Users</h2>
                        <Row className="mt-5 justify-content-center">
                            <Col sm="5">
                                <div>
                                    <h4 className='text-center'>Edit Student</h4>
                                    <Form onSubmit={handleSubmit(onSubmit)} >
                                        <p className='dfn_label'>Basic Info</p>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control type="text" name="first_name" defaultValue={student.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} autoComplete="off" />

                                                    {errors.first_name?.message && <span className="error">{errors.first_name?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" name="last_name" defaultValue={student.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')} autoComplete="off" />
                                                </Form.Group>
                                            </Col>

                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event, 'student')} onBlur={(event) => formatUSNumber(event, 'student')} autoComplete="off" />

                                                    {errors.phone?.message && <span className="error">{errors.phone?.message}</span>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group mb-3">
                                                    <Form.Label>DOB</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name="dob"
                                                        defaultValue={date}
                                                        placeholder="MM/DD/YYYY"
                                                        {...register("dob", {
                                                            onChange: (e) => setDate(e?.target?.value),
                                                            required: "Date Of Birth is required",
                                                            pattern: {
                                                                value: /^\d{2}\/\d{2}\/\d{4}$/,
                                                                message: "Invalid date format. Enter in MM/DD/YYYY format"
                                                            }
                                                        })}
                                                        autoComplete="off"
                                                    />

                                                    {errors.dob?.message && <span className="error">{errors.dob?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Assign Class</Form.Label>
                                                    <Form.Select name="division_id" {...register("division_id")} defaultValue={student.division_id} onChange={(event) => handleClass(event)} >
                                                        <option value="">-- Other --</option>
                                                        {options}
                                                    </Form.Select>
                                                    {errors.division_id?.message && <span className="error">{errors.division_id?.message}</span>}
                                                </Form.Group>
                                            </Col>
                                            {/* <Col sm="6" style={{display: showNewClass}}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>New Class</Form.Label>
                                                <Form.Select name="new_class" {...register("new_class")}>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                </Form.Select>

                                                {errors.new_class ?.message && <span className="error">{errors.new_class ?.message}</span> }
                                            </Form.Group>
                                        </Col> */}
                                            <Col sm="6" style={{ display: showNewClass }} >
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Control type="text" name="new_class" onChange={(event) => setValue('new_class', event.target.value)} {...register('new_class')} autoComplete="off" />

                                                    {errors.new_class && <span className="error">{errors.new_class.message}</span>}

                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <p className='dfn_label'>Parent/Guardian Info</p>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" name="guardian_name" defaultValue={student.guardian_name} onChange={(event) => setValue('guardian_name', event.target.value)} {...register('guardian_name')} autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Email Id</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="guardian_email" defaultValue={student.guardian_email} onChange={(event) => setValue('guardian_email', event.target.value)} {...register('guardian_email'
                                                    )} autoComplete="off" />

                                                    {errors.guardian_email && <span className="error">{errors.guardian_email.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="guardian_phone" type="text" defaultValue={guardianPhone} onKeyDown={(event) => allowOnlyNumber(event, 'guardian')} onBlur={(event) => formatUSNumber(event, 'guardian')} autoComplete="off" />

                                                    {errors.guardian_phone && <span className="error">{errors.guardian_phone.message}</span>}

                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <p className='dfn_label'>Credentials</p>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>User Name (Email)</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="email" defaultValue={student.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', {
                                                        required: "User Name is required",
                                                        pattern: {
                                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Invalid email address"
                                                        },
                                                        validate: (value) => getValues("guardian_email") !== value || "User Name must be unique"
                                                    })} autoComplete="off" />

                                                    {errors.email?.message && <span className="error">{errors.email?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Password</Form.Label>
                                                    <InputGroup className="password_frm_grp">
                                                        <Form.Control name="password" placeholder="Enter Password" type={isRevealPwd ? "text" : "password"} defaultValue={student.password} onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" })} autoComplete="new-password" />
                                                        <InputGroup.Text id="basic-addon2">
                                                            <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    {errors.password?.message && <span className="error">{errors.password?.message}</span>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3 justify-content-center">
                                            <Col className='col-auto'>
                                                <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                            </Col>
                                            <Col className='col-auto'>
                                                <button className='save_btn' type="submit" >Save</button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </>
        )
    } else {

        return <NoAccess />
    }
}

export default SubAdminStudentEdit;