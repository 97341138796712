import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import SchoolSideBarCtrl from '../Common/Sidebar';
import SchoolTopBarCtrl from '../Common/Topbar';
import AudioPlayerComponent from "../../../components/common/audioPlayer";

function DashboardLayoutCtrl(props){

    const [isSidebar, isSidebarActive] = useState(false)
    const [ showPlayer, setShowPlayer ] = useState(false)

    const sideBarClick = (event) => {
        isSidebarActive(current => !current);
    };

    useEffect(() => { 

        if(props.audio.file_path) {
            setShowPlayer(true)
        }else {
            setShowPlayer(false)
        }

    }, [ props.audio.file_path ])

    const closePlayer = () => {
        setShowPlayer(false)
        props.audio.file_path = ''
    }

    return(
        <>
        <div className="dashboardLayout">
        <div className={isSidebar ? 'sidebar_container sidebar_deactive' : 'sidebar_container sidebar_active'}>
                <SchoolSideBarCtrl {...props} toggleSideBar={sideBarClick} />
            </div>
            <div className="main_body_container">
                <SchoolTopBarCtrl {...props} toggleSideBar={sideBarClick} />
                <Container fluid>
                    <section className="main_body_content mt-4">
                        <Outlet/>
                    </section>
                </Container>
                { showPlayer &&
                    <div className="fixed_audio_player">
                        <AudioPlayerComponent {...props} onClose={closePlayer} />
                    </div>
                }
            </div>
        </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate,
       audio: state.audio
    }
}
  
export default connect(mapStateToProps, null)(DashboardLayoutCtrl);