import React, { useEffect, useState } from 'react';
import {Col, Button, Row, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';

import { authenticate } from "../../../redux/actions";

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH } from '../../../utils/axios.util';

function CoordinatorEditProfile(){

    const { register, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ profile, setProfile ] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)
    const [ file, setFile ] = useState('')
    const [ phone, setPhone ] = useState('');
    const [ previewImage, setPreviewImage] = useState('');

    useEffect(() => {

        if(Object.keys(profile).length === 0) {
            getProfile()
        }
        
    },[profile]);

    const getProfile = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/profile`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                
                dispatch(authenticate(data))
                setPhone(data.phone)
                setProfile(data)
                setPreviewImage(data['picture_path']);
                setIsLoading(false)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
        })

    }

    const onSubmit = (data) => {

        clearErrors('phone')

        data['phone'] = phone

        if (!data.phone) {
            setError("phone", {
              type: "manual",
              message: "Phone number is required"
            });
            return;
        }

        const formData = new FormData();
        formData.append("avatar", file);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("password", data.password);

        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/profile/edit`,
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            data: formData
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            getProfile()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handleUploadChange = (event) => {

        if(event.target.files[0] &&  event.target.files[0] !== undefined && event.target.files[0] !== null){
    
          setFile(event.target.files[0])
          setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    const allowOnlyNumber=(e)=>{

        setPhone(e.target.value)
        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {
           
            if(pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(valLength > maxLength){
            
            if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) ) {
                return true;
            }else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */
        
    }

    const formatUSNumber = (e) => {

        clearErrors('phone')

        let entry = e.target.value

        if(entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if(pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            if(count != 10) {
                setError("phone", {
                    type: "manual",
                    message: "Number must be ten digits"
                  });
                  return;
            }

            const match = entry
            .replace(/\D+/g, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''  
            e.target.value = `${part1}${part2}${part3}`
            setPhone(`${part1}${part2}${part3}`)

            return `${part1}${part2}${part3}`
        }
        
    }

    return(
        <>        

            <h2 className="page_hdng">Profile</h2>
            <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}  autoComplete="off"> 
                <input id="upload" name="upload" type="file" onChange={handleUploadChange}  hidden />
                <div className='d-flex align-items-center justify-content-end'>
                    <Button variant='trans_success' onClick={() => navigate('/coordinator/dashboard')} >Back To Home</Button>
                </div>
                <div className='my_card mt-4'>
                { Object.keys(profile).length > 0 &&
                    <>
                    <Row>
                        <Col>
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Basic Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col className='col-auto'>
                                        <Form.Group controlId="">
                                            <div className='profile_dtls_user_img'>
                                                <img height={100} src={previewImage} alt="profile" onClick={() => document.getElementById("upload").click()} style={{cursor: 'pointer'}} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="9">
                                        <Row>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" name="first_name" defaultValue={profile.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} autoComplete="off" />

                                                    {errors.first_name ?.message && <span className="error">{errors.first_name ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" name="last_name" defaultValue={profile.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')} autoComplete="off"/>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Personal Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="9">
                                        <Row>
                                            <Col lg="4" sm="5">
                                                <Form.Group controlId="">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event)} onBlur={formatUSNumber} autoComplete="off" />

                                                    {errors.phone ?.message && <span className="error">{errors.phone ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Credentials</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>User Name (Email)</Form.Label>
                                            <Form.Control type="email" name="email" placeholder='Enter email' defaultValue={profile.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', { 
                                                required: "User Name is required",
                                                pattern: { 
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address"
                                                }
                                            })}  autoComplete="off" />

                                            {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }

                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup className="password_frm_grp">
                                                <Form.Control placeholder='Enter Password' name="password" defaultValue={profile.password} type={isRevealPwd ? "text" : "password"}  onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" } )} autoComplete="new-password" />

                                                <InputGroup.Text id="basic-addon2">
                                                    <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                </InputGroup.Text>
                                            </InputGroup>
                                            {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-lg-end mt-3'>
                        <Col className='col-auto'>
                            <Button variant='trans_success' size='lg' onClick={() => navigate(-1)} >Cancel</Button>
                        </Col>
                        <Col className='col-auto'>
                            <Button variant='success' size='lg' type="submit" >Save Changes</Button>
                        </Col>
                        
                    </Row>
                    </>
                }
                { isLoading && 
                    <div className='text-center subscriptions_packages_name no_records_loading'>
                        <h5>Loading...</h5>
                    </div>
                }
                </div>
           </Form>
        </>
    )
}

export default CoordinatorEditProfile;