import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const useMenuAccess = () => {

    const location = useLocation()
    const user = useSelector(state => state.authenticate);

    var menuAccess = null

    if(user.role_access && user.role_access.length > 0) {
        let menuFilter = user.role_access.filter(item => {
            let menuRegEx = new RegExp(item['menu_name'], 'i');
            if(/organizations/i.test(location.pathname)) {

                return menuRegEx.test(location.pathname) || /users/i.test(item['menu_name'])
            }else {

                return menuRegEx.test(location.pathname)
            }
        })

        if(menuFilter.length > 0){
            if(/organizations/i.test(location.pathname)) {

                menuAccess = {}

                for(let x of menuFilter) {
                    if(/users/i.test(x['menu_name'])) {
                        menuAccess['users_menu'] = x
                    }else {
                        menuAccess = {
                            ...menuAccess,
                            ...x
                        }
                    }
                }
                
            }else {

                menuAccess = menuFilter[0]
            }
           
        }
    }

    return menuAccess;

}

export default useMenuAccess