import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { NavLink, useNavigate } from 'react-router-dom';
import {Modal, Col, Button,Form, Row,Tab,Nav , Table } from "react-bootstrap";
import moment from 'moment-timezone';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import grn_excla from '../../../assets/images/icons/grn_excla.png';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import calendar from '../../../assets/images/icons/calendar.png';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';
import useDivisions from '../../../hooks/useDivisions';

function CoordinatorStudentCtrl(props){

    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();

    const navigate = useNavigate()
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ users, setUsers ] = useState({})
    const [ checkedState, setCheckedState ] = useState({})
    const [ allCheckedState, setAllCheckedState ] = useState(false)
    const [ isDisabled, setIsDisabled ] = useState(true)
    const [ fromDate, setFromDate ] = useState('');
    const [ toDate, setToDate ] = useState('');
    const [ divisonOptions, setDivisonOptions ] = useState('');
    const divisions = useDivisions()
    const [ deleteUserInfo, setDeleteUserInfo ] = useState({});
    const [ activePageNumber, setActivePageNumber ] = useState('');
    const [ deleteOrgModalShow, setOrgDeleteModalShow ] = useState(false);
    const handleOrgDeleteModalClose = () => setOrgDeleteModalShow(false);

    const csvHeaders = ["Firstname", "Lastname", "Email", "Password", "Division"]
    const [ csvData, setCsvData ] = useState([
        csvHeaders
    ])

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    const paramRef = useRef({
        category: '',
        division: '',
        fromDate: '',
        toDate: ''
    })

    var params = paramRef.current
    var isStudentsInitialized = useRef(false)

    useEffect(() => {

        if(Object.keys(users).length === 0 && !isStudentsInitialized.current) {
            getUsers()
        }

        if(divisions.length > 0 && !divisonOptions) {
            let optionValues =  divisions.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setDivisonOptions(optionValues)
        }

    },[ divisions, currentItems, itemOffset, itemsPerPage ]);


    const getUsers = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/students?&division=${params.division}&fromDate=${params.fromDate}&toDate=${params.toDate}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            let checkMap = {}
            for(let x of chunk) {
                checkMap[x.id] = false
            }
            setCheckedState(prevState => {
                return {
                    ...prevState,
                    ...checkMap
                }
            })

            setIsNoRecords(false)
            if(data.length === 0){
                setIsNoRecords(true)
            }
            setIsLoading(false)
            setUsers(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
            isStudentsInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const deleteUser = (event) => {

        let user_id = deleteUserInfo.id

        let data = {
            user_id
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/students/delete`,
            data
        }

        console.log(data)

        POST(payload).then(res => {
            setOrgDeleteModalShow(false)
            toast.success(res.data.message)

            getUsers()
        }).catch(err => {

            setOrgDeleteModalShow(false)
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            toast.error(err.response.data.message)
        })
    }

    const handleAllCheckBox = (event) => {

        let inverseValue =  !allCheckedState
    
        let tempCheckstate = {}
        for(let y in checkedState) {
          if(inverseValue == true) {
            tempCheckstate[y] = true
          }else{
            tempCheckstate[y] = false
          }
          
        }

        let checkedData = Object.keys(tempCheckstate).filter(key => tempCheckstate[key] === true)

        if(checkedData.length > 0) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setAllCheckedState(inverseValue)
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });
        
    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        let tempCheckstateArr = []
        for(let y in checkedState) {
            if(y == checkId) {
            tempCheckstate[y] = !checkedState[y]
            }
        }
        let dummyCheck = {
            ...checkedState,
            ...tempCheckstate
        }

        for(let z in dummyCheck) {
            tempCheckstateArr.push(dummyCheck[z])
        }
        if(tempCheckstateArr.every(element => element === true)) {
            setAllCheckedState(true)
        }else {
            setAllCheckedState(false)
        }

        if(tempCheckstateArr.some(element => element === true)) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setCheckedState(prevState => {
            return {
            ...prevState,
            ...tempCheckstate
            }
        });
    }

    const handlePageClick = (event) => {

        let items = users

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        let checkMap = {}
        for(let x of chunk) {
            checkMap[x.id] = false
        }
        setCheckedState(prevState => {
        return {
            ...checkMap
        }
        })
        setAllCheckedState(false)
        
        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };

    const exportSelected = () => {

        let tempCsvData = [[...csvHeaders]]
        for(let x in checkedState) {
            if(checkedState[x] == true) {
                let filterUsers = users.filter(elem => elem.id == x )
                let filteredUser = []

                if(filterUsers.length > 0) {

                    filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password'], filterUsers[0]['division_name']]
                    tempCsvData = [...tempCsvData, filteredUser]
                }
            }
        }

        setCsvData(tempCsvData)
            
    }

    const exportAll = () => {

        let tempCsvData = [[...csvHeaders]]
        for(let x of users) {
            let filteredUser = [x['first_name'], x['last_name'], x['email'], x['division_name']]
            tempCsvData = [...tempCsvData, filteredUser]
        }

        setCsvData(tempCsvData)

    }

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            <span>{!fromDate ? 'Start Date' : value}</span>
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            <span>{!toDate ? 'End Date' : value}</span>
        </button>
    ));

    const changeDivision = (event) => {

        setValue('division', event.target.value)
        params.division = event.target.value
        getUsers()
    }

    const validateFromDate = (date) => {

        if(moment(toDate).isBefore(date)) {
            setToDate('')
        }
        setFromDate(date);
        params.fromDate = moment(date).tz(TIME_ZONE).format('YYYY-MM-DD');
        getUsers()
    }

    const validateToDate = (date) => {
        setToDate(date);
        params.toDate = moment(date).tz(TIME_ZONE).format('YYYY-MM-DD');
        getUsers()
    }

    const togglePassword = (eLId) => {
        let element = document.getElementById(`password_${eLId}`)
        let eyecon = document.getElementById(`eyecon_${eLId}`)
        if(element.type == 'text') {
            element.setAttribute('type', 'password')
        }else {
            element.setAttribute('type', 'text')
        }

        if(eyecon.getAttribute('src') == eye) {
            eyecon.setAttribute('src', eye_slash) 
        }else {
            eyecon.setAttribute('src', eye)
        }
    }

    const handleOrgDeleteModalShow = (elem) => {

        setDeleteUserInfo(elem)
        setOrgDeleteModalShow(true)

    }

    const editPage = (elem, type) => {

        let activeItemSelector = `#fdgdfg-tabpane-students ul li.page-item.active a`

        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        navigate(`/coordinator/students/${elem.id}/edit`)
        
    }

    return(
        <>        

            <h2 className="page_hdng">Users</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.length > 0) ? users.length : 0}</h4>
                        <p>Students</p>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <div className="fixed_tab">
                    <Tab.Container id="fdgdfg" defaultActiveKey="students">
                        <Row className='justify-content-between'>
                            <Col>
                                <Nav variant="fixed_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="students">Students</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            
                            <Col className='col-auto'>
                                {/* <Form> */}
                                    <Row>
                                        <Col className='col-auto'>
                                            <div className='d-flex justify-content-end'>
                                                <Form.Select className='me-2' name="division" {...register('division')} onChange={(event) => changeDivision(event)} style={{height: '45px'}}>
                                                    <option value="">-- Class --</option>
                                                    {divisonOptions}
                                                </Form.Select>
                                                <div className='d-flex align-items-center'>
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={fromDate}
                                                        onChange={(date) => validateFromDate(date)}
                                                        customInput={<FromDate />}
                                                    />
                                                    <span className='mx-2'>To</span>
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={toDate}
                                                        minDate={fromDate}
                                                        onChange={(date) => validateToDate(date)}
                                                        customInput={<ToDate />}
                                                    />
                                                </div>
                                                <NavLink to="create" className="btn btn-success btn-md px-4 ms-2">Add Student</NavLink>
                                                
                                                <CSVLink 
                                                    data={csvData} 
                                                    filename={`Students-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                    onClick={() => !isDisabled}
                                                >
                                                    <Button size='md' className='px-4 ms-2' disabled={isDisabled} onClick={exportSelected} >Export Selected</Button>
                                                </CSVLink>
                                                <CSVLink 
                                                    data={csvData} 
                                                    filename={`Students-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                    onClick={() => Object.keys(users).length > 0}
                                                >
                                                    <Button size='md' className='px-4 ms-2' onClick={exportAll} disabled={Object.keys(users).length === 0} >Export All</Button>
                                                </CSVLink>
                                            </div>
                                        </Col>
                                    </Row>  
                                {/* </Form> */}
                            </Col>
                        </Row>

                        <Tab.Content>
                            <Tab.Pane eventKey="students">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Students Name</th>
                                            <th>Added Since</th>
                                            <th>Class</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { isLoading && 
                                            <div className='text-center subscriptions_packages_name no_records_loading'>
                                                <h5>Loading...</h5>
                                            </div>
                                        }
                                        { !isLoading && isNoRecords &&
                                            <tr className="text-center">
                                                <td colSpan="8" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { (Object.keys(users).length > 0 && users.length === 0) &&
                                            <tr className="text-center">
                                                <td colSpan="8" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.length > 0) &&
                                            currentItems.map((elem, index) => {
                                                return (
                                                    <tr key={elem.id}>
                                                        <td width="50">
                                                            {['checkbox'].map((type) => (
                                                            <div key={`inline-${type}`}>
                                                                <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                            </div>
                                                            ))}
                                                        </td>
                                                        <td width="50">
                                                            <img className='tbl_usr_img' src={elem.picture_path} />
                                                        </td>
                                                        <td>
                                                            <h5 className="user-name">
                                                                {elem.full_name}
                                                                { elem.profile_requested && 
                                                                    <span className="badge">
                                                                        Action Required!
                                                                    </span>
                                                                }
                                                            </h5>
                                                            <p className='text_light_color'>{elem.division_name}</p>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.created_at}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.division_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.email}</h5>
                                                        </td>
                                                        <td className="paddsowd">
                                                            <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                            <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                        </td>
                                                        <td className="text-center">
                                                            {/* <span className="badge badge_md">
                                                                <img src={grn_excla} />
                                                            </span> */}
                                                            <span className="badge badge_md" onClick={() => editPage(elem)} style={{ cursor: 'pointer' }} >
                                                                <img src={mode_edit} />
                                                            </span>
                                                            <NavLink data-role="student" data-id={elem.id} onClick={() => handleOrgDeleteModalShow(elem)} >
                                                                <span className="badge badge_md">
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                { users.length > 0 &&
                                    <PAGINATION allItems={users} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={deleteOrgModalShow} onHide={handleOrgDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to delete ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleOrgDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deleteUser()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CoordinatorStudentCtrl;