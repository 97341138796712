import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from "react-router-dom";
import { BACKEND_DOMAIN } from '../config/variables';
import { GET } from '../utils/axios.util';

const useDivisions = () => {

    const [ divisions, setDivisions ] = useState([]);
    const location = useLocation();

    let coordinatorPattern = /coordinator/i;
    let schoolPattern = /school/i;
    let subAdminPattern = /sub\-admin/i;

    let URL = `${BACKEND_DOMAIN}/divisions`

    if(coordinatorPattern.test(location.pathname) && !schoolPattern.test(location.pathname) && !subAdminPattern.test(location.pathname)) {
        URL = `${BACKEND_DOMAIN}/coordinator/divisions`
    }

    useEffect(() => {

        let payload = {
            url : URL
        }

        GET(payload).then(res => {
            
            const { data } = res.data
            setDivisions(data)
        }).catch(err => {
            
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            setDivisions([])
        })

    }, [])


    return divisions;

}

export default useDivisions