import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import useAuthenticate from '../../hooks/useAuthenticate';

function StudentInterfaceCtrl(){

    const user = useAuthenticate()
    let navigate = useNavigate()

    useEffect(() => {

    }, [ user ])

    if(Object.keys(user).length > 0 && user.role === 'student') {

        return(
            <>
                <section className="School_interface">
                    <Outlet/>
                </section>
            </>
        )
    }else {
        return navigate('/')
    }
}

export default StudentInterfaceCtrl;