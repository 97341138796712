import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, Row, Form, InputGroup, FloatingLabel, Tab, Nav, Modal } from "react-bootstrap";
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../../assets/images/dummy.png';
import paper_clip from '../../../assets/images/icons/paper_clip.png';
import search from '../../../assets/images/icons/search.png';
import member2 from '../../../assets/images/member2.png';

import firebaseDb from '../../../utils/firebase.util';
import { set, get, child, ref, push, update, onChildAdded, onChildChanged } from "firebase/database";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import { validateAndSortNumbers } from '../../../utils/functions';
import { useParams } from 'react-router-dom';
// import useIsMounted from '../../../hooks/useIsMounted';

function SubAdminSupportChat(props) {
    const {id} = useParams()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const user = props.user

    const [allContacts, setAllContacts] = useState([])
    const [chatsList, setChatsList] = useState([])
    const [messagesList, setMessagesList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [otherUser, setOtherUser] = useState(null)
    const [isChatOpened, setIsChatOpened] = useState(false)
    const [chatMessage, setChatMessage] = useState(null)
    const [blockType, setBlockType] = useState('block')
    const [blockModalShow, setBlockModalShow] = useState(false)
    const [blockModalButtons, setBlockModalButtons] = useState(true)
    const [blockModalText, setBlockModalText] = useState('Are you sure you want to block this user ?')
    const bottomRef = useRef(null);

    const usersListRef = useRef({})
    const chatsListRef = useRef([])
    const contactsInitialized = useRef(false)
    var anotherUserRef = useRef(null)

    var usersList = usersListRef.current
    var allUsersList = []
    var keyword = ''

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

        if (!contactsInitialized.current) {
            getContacts(keyword)
        }

        return () => {
            let chatDiv = document.querySelector('#chat-contact')
            if (!chatDiv) {
                anotherUserRef.current = null
            }
        }

    }, [chatsList, messagesList, allContacts]);
    const textAreaChange = (event) => {
        setChatMessage(event.target.value)
        setValue('chat', event.target.value)
    }

    const renderChatList = (value) => {

        console.log('*****renderChatList*****')
        console.log(value)

        let usersArr = []
        let usersListArr = []
        let otherUsersArr = []
        let lastChat = null
        let newUsersListArr = []

        for (let key in value) {

            // if(anotherUserRef.current) {
            // if((anotherUserRef?.current?.unique_id == value?.[key]?.['unique_id']) && (value?.[key]?.['is_seen'] == 0)) {
            //     console.info("test123")
            //     value[key]['is_seen'] = 1


            //     const updates = {};
            //     updates[`chats/user_${validateAndSortNumbers(anotherUserRef.current.unique_id,user.unique_id)}/${key}`] = {
            //         ...value[key],
            //         is_seen: 1,
            //     };

            //     update(ref(firebaseDb), updates);
            // }

            // if(user?.unique_id == value?.[key]?.['unique_id'] || user?.unique_id == value?.[key]?.['to_user_unique_id']){
            otherUsersArr.push(value[key])
            // }

            // }

            usersArr.push(value[key])

        }

        if (usersArr.length > 0) {
            lastChat = usersArr[usersArr.length - 1]
        }

        if (otherUsersArr.length > 0) {

            otherUsersArr.sort(function (a, b) {
                return Number(b.timestamp) - Number(a.timestamp)
            });

            otherUsersArr.sort(function (a, b) {
                return Number(a.is_seen) - Number(b.is_seen)
            });

            let usr = otherUsersArr?.find((i) => (i?.to_user_unique_id == user?.unique_id))
            if (!usr) {
                const anotherusr = otherUsersArr?.find((i) => (i?.unique_id == user?.unique_id))
                if (anotherusr) {
                    usr = anotherusr
                }
            }
            if (usr) {

                // usr['message'] = lastChat['message']
                // usr['timestampFormatted'] = lastChat['timestampFormatted']

                usersList[usr['unique_id']] = usr
            }

            let chatUserIds = []


            if (Object.keys(usersList).length > 0) {
                for (let x in usersList) {
                    chatUserIds.push(usersList[x]['unique_id'])
                    usersListArr.push(usersList[x])

                    usersListArr.sort(function (a, b) {
                        return Number(b.timestamp) - Number(a.timestamp)
                    });

                }
            }

            if (usersListArr.length > 0 && allUsersList.length > 0) {

                let tempNonChatUsersList = []
                let tempChatUsersList = []
                for (let i = 0; i < allUsersList.length; i++) {
                    if (!chatUserIds.includes(allUsersList[i]['unique_id'])) {
                        tempNonChatUsersList.push(allUsersList[i])
                    } else {

                        let filterChatUsr = usersListArr.filter(item => {
                            return ((item.unique_id == allUsersList[i]['unique_id']))
                        })

                        tempChatUsersList.push(filterChatUsr[0])
                    }
                }

                tempChatUsersList.sort(function (a, b) {
                    return Number(b.timestamp) - Number(a.timestamp)
                });

                newUsersListArr = [...tempChatUsersList, ...tempNonChatUsersList]

            } else {
                newUsersListArr = allUsersList
            }

        } else {
            newUsersListArr = allUsersList
        }

        chatsListRef.current = newUsersListArr
        // console.log("++++-=-=-===",user,newUsersListArr)
        const filterChatData = newUsersListArr?.filter((item) => item?.to_user_id == user?.id)
        setChatsList(filterChatData)

    }

    const renderMessagesList = (item) => {

        if (!anotherUserRef.current || (anotherUserRef.current && (anotherUserRef.current.user_id == item.user_id))) {

            const dbRef = ref(firebaseDb);

            let messageList = []
            let updateChatList = false
            var chatListTemp = chatsListRef.current

            get(child(dbRef, `chats/user_${validateAndSortNumbers(item.unique_id, user.unique_id)}`)).then((snapshot) => {
                if (snapshot.exists()) {

                    let messages = snapshot.val()

                    let finalKey = null

                    for (let msgKey in messages) {

                        const updates = {};

                        if (messages[msgKey]?.unique_id !== user?.unique_id) {
                            updates[`chats/user_${validateAndSortNumbers(item.unique_id, user.unique_id)}/${msgKey}`] = {
                                ...messages[msgKey],
                                is_seen: 1
                            };
                        }

                        if (messages[msgKey]['role'] == item.role) {
                            finalKey = messages[msgKey]
                        }

                        update(ref(firebaseDb), updates);
                        messageList.push(messages[msgKey])
                    }

                    if (finalKey) {

                        for (let i = 0; i < chatListTemp.length; i++) {

                            if (chatListTemp[i].user_id == item.user_id) {
                                chatListTemp[i] = {
                                    ...finalKey,
                                    message: chatListTemp[i]['message'],
                                    timestamp: chatListTemp[i]['timestamp'],
                                    timestampFormatted: chatListTemp[i]['timestampFormatted']
                                }

                                updateChatList = true
                                const filterChatData = chatListTemp?.filter((item) => item?.to_user_id == user?.id)
                                setChatsList(filterChatData)
                            } else {
                                updateChatList = false
                            }
                        }
                    }

                }

                setIsChatOpened(true)
                setOtherUser(item)
                const filterData = messageList?.filter((item) => item?.type === "support" && item?.support_ticketId == id)
                setMessagesList(filterData)
            }).catch((error) => {
                console.error(error);
            });
        }

    }

    const getContacts = (keyword) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/chat/contacts/user?search=${keyword}`
        }

        setIsLoading(true)
        GET(payload).then(res => {
            const { data } = res.data

            allUsersList = data

            contactsInitialized.current = true
            setIsLoading(false)
            setAllContacts(data)
            getChats()

        }).catch(err => {

            contactsInitialized.current = true
            setIsLoading(false)
            console.log(err)

        })

    }

    const getChats = async () => {

        const chatListRef = ref(firebaseDb, `chats`);

        onChildAdded(chatListRef, (data) => {

            console.log('*******onChildAdded()*******')

            let value = data.val()
            if (anotherUserRef.current) {
                renderMessagesList(anotherUserRef.current)
            } else {

                renderChatList(value)
            }

        });

        onChildChanged(chatListRef, (data) => {

            console.log('*******onChildChanged()*******')

            if (anotherUserRef.current) {

                let item = anotherUserRef.current
                renderMessagesList(item)
            } else {

                let value = data.val()
                let messageList = []

                for (let msgKey in value) {
                    messageList.push(value[msgKey])
                }
                const filterData = messageList?.filter((item) => item?.type === "support" && item?.support_ticketId == id)
                setMessagesList(filterData)
                renderChatList(value)

            }

        });

    }

    const openChat = (item) => {
        console.info("itemss++ ", item)
        setMessagesList([])
        anotherUserRef.current = item
        renderMessagesList(item)
    }

    const clearMessagesList = (user_id) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/chat/messages/delete`,
            data: {
                user_id
            }
        }

        POST(payload).then(res => {

            setMessagesList([]);

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const openBlockModal = (type) => {

        let text = 'Are you sure you want to block this user ?'
        setBlockType('block')

        if (type == 'unblock') {
            text = 'Are you sure you want to unblock this user ?'
            setBlockType('unblock')
        }

        setBlockModalButtons(true)
        setBlockModalText(text)
        setBlockModalShow(true)
    }

    const blockUser = () => {

        let URL = `${BACKEND_DOMAIN}/admin/chat/block`
        if (blockType == 'unblock') {
            URL = `${BACKEND_DOMAIN}/admin/chat/unblock`
        }

        let payload = {
            url: URL,
            data: {
                user_id: otherUser.user_id,
                role: otherUser.role
            }
        }

        POST(payload).then(res => {

            let newotheruser = {}

            if (blockType == 'unblock') {
                newotheruser = {
                    ...otherUser,
                    is_chat_blocked: 0
                }
                anotherUserRef.current = newotheruser
            } else {
                newotheruser = {
                    ...otherUser,
                    is_chat_blocked: 1
                }
                anotherUserRef.current = newotheruser
            }

            chatsList.map(item => {
                if (item.user_id == otherUser.user_id) {
                    if (blockType == 'unblock') {
                        item['is_chat_blocked'] = 0
                    } else {
                        item['is_chat_blocked'] = 1
                    }

                }
            })

            const filterChatData = chatsList?.filter((item) => item?.to_user_id == user?.id)
            setChatsList(filterChatData)
            setOtherUser(newotheruser)

            setTimeout(() => {
                setBlockModalShow(false)
            }, 2000)

            setBlockModalButtons(false);
            setBlockModalText(res.data.message);

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const onSubmit = (data) => {

        let timestamp = moment().tz(TIME_ZONE).format('XX')
        let timestampFormatted = moment().tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
        let formData = data

        let payload = {
            to_user_id: anotherUserRef.current.user_id,
            to_user_unique_id: anotherUserRef.current.unique_id,
            user_id: user.id,
            unique_id: user.unique_id,
            full_name: `${user.first_name} ${user.last_name}`,
            picture_path: `${user.picture_path}`,
            is_seen: 0,
            role: user.role,
            message: data.chat,
            status: 'online',
            timestamp,
            timestampFormatted,
            type: "support",
            support_ticketId: id,
        }

        const chatListRef = ref(firebaseDb, `chats/user_${validateAndSortNumbers(anotherUserRef.current.unique_id, user.unique_id)}`);
        const newChatRef = push(chatListRef);
        const key = newChatRef.key;

        payload['key'] = key

        set(newChatRef, payload)
            .then(res => {
                setValue('chat', '')
                setChatMessage('')
                chatsList.map(item => {
                    if (item.user_id == anotherUserRef.current) {

                        item['message'] = formData.chat
                        item['timestamp'] = timestamp
                        item['timestampFormatted'] = timestampFormatted
                    }
                })
                chatsListRef.current = chatsList
                const filterChatData = chatsList?.filter((item) => item?.to_user_id == user?.id)
                setChatsList(filterChatData)
            })
            .catch(error => {
                console.log(error)
            });

    }
    return (
        <>
            <h2 className="page_hdng">Chats</h2>
            <div className='my_card chat_contact mt-4' id="chat-contact">
                <Row>
                    <Col sm="12" lg="5">
                        <div className="my_card_brand_dark_color">
                            <InputGroup className="chat_list_seach">
                                <Button variant="outline-secondary" id="">
                                    <img width="15" src={search} />
                                </Button>
                                <Form.Control size="sm" placeholder="Search" onChange={(e) => getContacts(e.target.value)} autoComplete="off" />
                            </InputGroup>
                            <Tab.Container className="" defaultActiveKey="chats">
                                <Nav fill justify variant="brand_outline_pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="chats">Chat</Nav.Link>
                                    </Nav.Item>
                                    {/*  <Nav.Item>
                                        <Nav.Link eventKey="contacts">Contacts</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="chats">
                                        {isLoading &&
                                            <div className="contact_list" >
                                                <Row className='align-items-center'>
                                                    <h5 className='fs-16 fw-600 text-center'>Loading...</h5>
                                                </Row>
                                            </div>
                                        }
                                        {!isLoading && chatsList.length === 0 &&
                                            <div className="contact_list" >
                                                <Row className='align-items-center'>
                                                    <h5 className='fs-16 fw-600 text-center'>No Records Found!</h5>
                                                </Row>
                                            </div>
                                        }
                                        {!isLoading && chatsList.length > 0 &&
                                            chatsList.map((elem, index) => {

                                                let textClass = 'text_light_color2'

                                                let classNames = 'contact_list'

                                                if (elem.is_seen === 0) {
                                                    textClass = 'text_brand_color2'
                                                }

                                                if (anotherUserRef.current && elem.user_id == anotherUserRef.current.user_id) {
                                                    classNames = 'contact_list active'
                                                }

                                                return (
                                                    <div className={classNames} key={elem.user_id} style={{ cursor: 'pointer' }} onClick={() => openChat(elem)} >
                                                        <Row className='align-items-center justify-content-between'>
                                                            <Col className='col-auto'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img width="50" className="rounded-5" src={elem.picture_path} />
                                                                    </div>
                                                                    <div className="ms-2">
                                                                        <h4 className='fs-16 fw-600'>{elem.full_name}</h4>
                                                                        <p className='text_light_color2 fs-14 fw-400 m-0'>{elem.message}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className='col-auto'>
                                                                <div className='text-end'>
                                                                    <p className='text_light_color2 fs-12 fw-500 mb-2 text-capitalize'>{elem.role}</p>
                                                                    {elem.timestampFormatted &&
                                                                        <p className={`${textClass} fw-14 fw-500 m-0`}>{moment(elem.timestampFormatted).fromNow()}</p>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Tab.Pane>
                                    
                                </Tab.Content>
                            </Tab.Container>
                            <div className="chat_list_"></div>
                        </div>
                    </Col>
                    <Col sm="12" lg="7">
                        <div className="my_card_brand_dark_color">
                            {isChatOpened &&

                                <>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='chat_active_uesr'>
                                            <img src={otherUser && otherUser.picture_path} />
                                            <h5 className='fs-20 fw-600 m-0'>{otherUser && otherUser.full_name}</h5>
                                            {/* <span className={(otherUser && otherUser.status && otherUser.status == 'online') ? 'online_mark' : 'offline_mark'}>{otherUser && otherUser.status}</span> */}
                                        </div>
                                        <div>
                                            <Button variant="danger" className="mx-2" onClick={() => clearMessagesList(otherUser.user_id)} >Clear All</Button>
                                            {otherUser.is_chat_blocked === 0 &&
                                                <Button variant="primary" onClick={() => openBlockModal('block')} >Block</Button>
                                            }
                                            {otherUser.is_chat_blocked === 1 &&
                                                <Button variant="primary" onClick={() => openBlockModal('unblock')} >Unblock</Button>
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            }

                            <div className='chat_msg_container'>
                                {isLoading &&
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                {!isChatOpened && !isLoading &&
                                    <div className='d-flex justify-content-center'>
                                        <h5>            </h5>
                                    </div>
                                }
                                {isChatOpened &&
                                    <>
                                        <ul className='chat_msg_list'>
                                            {messagesList.length > 0 &&

                                                messagesList.map((elem, index) => {

                                                    if (!elem.message || elem.message?.length < 1) {
                                                        return
                                                    }

                                                    let classValue = 'chat_msg_list_item msg_me'

                                                    if (elem?.user_id !== user?.id) {
                                                        classValue = 'chat_msg_list_item msg_other'
                                                    }

                                                    return (
                                                        <li className={classValue} key={elem.key}>
                                                            <div className='chat_msg'>{elem.message}
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                            <div ref={bottomRef} />
                                        </ul>
                                        <FloatingLabel controlId="floatingTextarea" className="chat_textarea" >
                                            <Form onSubmit={handleSubmit(onSubmit)} >
                                                <input type="hidden" name="other_user" value={anotherUserRef.current && anotherUserRef.current.user_id} {...register('other_user')} />
                                                <Form.Control as="textarea" placeholder="Type something" name="chat" {...register('chat')} onChange={(event) => textAreaChange(event)} autoComplete="off" />
                                                <div className='chat_textarea_buttons'>
                                                    {/* <div className="upload_btn_wrapper">
                                                        <button className="attach_btn">
                                                            <img height="15" src={paper_clip} />
                                                            <span className='ms-2'>Attach</span>
                                                        </button>
                                                        <Form.Control type="file" name="myfile" />
                                                    </div> */}
                                                    <Button disabled={!chatMessage} variant='success' type="submit">Send</Button>
                                                </div>
                                            </Form>
                                        </FloatingLabel>
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="approve-modal" show={blockModalShow} onHide={() => setBlockModalShow(false)} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>{blockModalText}</h4>
                </Modal.Body>
                {blockModalButtons &&
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={() => setBlockModalShow(false)} >NO</Button>
                            <Button variant="primary" onClick={() => blockUser()}>YES</Button>
                        </div>
                    </Modal.Footer>
                }
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authenticate
    }
}

export default connect(mapStateToProps, null)(SubAdminSupportChat);