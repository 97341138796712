import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import useAuthenticate from '../../hooks/useAuthenticate';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";  

function SchoolInterfaceCtrl(props){

    const user = useAuthenticate()
    let navigate = useNavigate()

    useEffect(() => {

    }, [ user ])

    if(Object.keys(user).length > 0 && user.role === 'admin') {

        return(
            <>
                <section className="School_interface">
                    <Outlet/>
                </section>
            </>
        )
    }else {
        return navigate('/')
    }

}

export default SchoolInterfaceCtrl;