import React, { useState, useEffect } from 'react';
import {Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../../../config/variables';
import { GET, POST } from '../../../../../utils/axios.util';

import eye from '../../../../../assets/images/icons/eye.svg';
import eye_slash from '../../../../../assets/images/icons/eye_slash.svg';
import Privileged from '../../../../../HOC/privileged';

function SuperAdminInterpreterEdit(){
    
    const { control, register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const location = useLocation()

    const params = useParams();

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ interpreter, setInterpreter ] = useState({})
    const [ institutes, setInstitutes ] = useState([])
    const [ instituteOptions, setInstituteOptions ] = useState(null)
    const [ file, setFile ] = useState('')
    const [ previewText, setPreviewText] = useState('Upload Image');
    const [ slug, setSlug ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ contactPhone, setContactPhone ] = useState('')
    const [ previewImage, setPreviewImage] = useState('');
    const orgnz_id = params.org_id;
    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    useEffect(() => {

        let slugParam = ''
        let saffPattern = /staffs/i

        if(saffPattern.test(location.pathname)) {
            slugParam = 'staff'
            setSlug('staff')
        }else {
            slugParam = 'org'
            setSlug('org')
        }

        if(Object.keys(interpreter).length === 0) {
            getInterpreter()
        }

       /*  if(institutes.length === 0){
            getInstitutes()
        }

        if(institutes.length > 0 && !instituteOptions) {
            let instituteValues =  institutes.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setInstituteOptions(instituteValues)
        } */

    },[ interpreter ]);

    const getInstitutes = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/institutes`
        }

        GET(payload).then(res => {
            const { data } = res.data
            setInstitutes(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })

    }

    const getInterpreter = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/interpreter/${params.id}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            console.log(data)

            setPhone(data.phone)
            setContactPhone(data.contact_phone)
            if(data.certificate_filename && data.certificate_filename.length > 17){
                data.certificate_filename = data.certificate_filename.substring(0, 17) + '...'
            }
            setPreviewText(data.certificate_filename)
            setPreviewImage(data.certificate_image)
            setInterpreter(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)

            if(err.response.status === 404) {
                navigate('/users')
            }
        })

    }

    const previousPage = () => {

        let navigateUrl = ''

        if(slug == 'staff') {

            navigateUrl = '/staffs'

            if(urlTab) {
                navigateUrl = `/staffs?tab=${urlTab}`
            }

            if(urlTab && urlPage) {
                navigateUrl = `/staffs?tab=${urlTab}&page=${urlPage}`
            }

        }else {

            navigateUrl = `/sub-admin/organizations/${orgnz_id}/users`

            if(urlTab) {
                navigateUrl = `/sub-admin/organizations/${orgnz_id}/users?tab=${urlTab}`
            }

            if(urlTab && urlPage) {
                navigateUrl = `/sub-admin/organizations/${orgnz_id}/users?tab=${urlTab}&page=${urlPage}`
            }

        }

        navigate(navigateUrl)
    }

    const onSubmit = (data) => {

        clearErrors('phone')

        data['phone'] = phone

        if (!data.phone) {
            setError("phone", {
              type: "manual",
              message: "Phone number is required"
            });
            return;
        }

        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("phone", phone);
        formData.append("email", data.email);
        formData.append("password", data.password);
        formData.append("cert_image", file);

        if(slug == 'staff') {

            clearErrors('contact_phone')

            if (!file && !interpreter.certificate_image) {
                setError("cert_image", {
                  type: "manual",
                  message: "Certificate Image is required"
                });
                return;
            }

            formData.append("address", data.address);
            formData.append("contact_name", data.contact_name);
            formData.append("contact_phone", contactPhone);
            formData.append("contact_email", data.contact_email);
            formData.append("contact_relationship", data.contact_relationship);
            formData.append("certificate_type", data.certificate_type);
        }

        let URL = `${BACKEND_DOMAIN}/admin/interpreter/${params.id}/edit`

        if(slug == 'org') {
            URL = `${BACKEND_DOMAIN}/admin/interpreter/organization/${orgnz_id}/${params.id}/edit`
            formData.append("institute", orgnz_id);
        }

        let payload = {
            url : URL,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }

        POST(payload).then(res => {

            toast.success(res.data.message)

            let navigateUrl = ''

            if(slug == 'org') {

                navigateUrl = `/sub-admin/organizations/${orgnz_id}/users`

                if(urlTab) {
                    navigateUrl = `/sub-admin/organizations/${orgnz_id}/users?tab=${urlTab}`
                }

                if(urlTab && urlPage) {
                    navigateUrl = `/sub-admin/organizations/${orgnz_id}/users?tab=${urlTab}&page=${urlPage}`
                }

            }else {

                navigateUrl = '/staffs'

                if(urlTab) {
                    navigateUrl = `/staffs?tab=${urlTab}`
                }

                if(urlTab && urlPage) {
                    navigateUrl = `/staffs?tab=${urlTab}&page=${urlPage}`
                }

            }

            navigate(navigateUrl)

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber=(e, type)=>{

        if(type == 'interpreter') {
            setPhone(e.target.value)
        }else {
            setContactPhone(e.target.value)
        }

        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {
           
            if(pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(valLength > maxLength){
            
            if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) ) {
                return true;
            }else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */
        
    }

    const formatUSNumber = (e, type) => {

        if(type == 'interpreter') {
            clearErrors('phone')
        }else {
            clearErrors('contact_phone')
        }

        let entry = e.target.value

        if(entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if(pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            let fieldName = ''
            if(type == 'interpreter') {
                fieldName = 'phone'
            }else {
                fieldName = 'contact_phone'
            }

            if(count != 10) {
                setError(fieldName, {
                    type: "manual",
                    message: "Number must be ten digits"
                  });
                  return;
            }

            const match = entry
            .replace(/\D+/g, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''  
            e.target.value = `${part1}${part2}${part3}`

            if(type == 'interpreter') {
                setPhone(`${part1}${part2}${part3}`)
            }else {
                setContactPhone(`${part1}${part2}${part3}`)
            }

            return `${part1}${part2}${part3}`
        }
        
    }

    const handleUpload = (event) => {

        if(event.target.files[0] &&  event.target.files[0] !== undefined && event.target.files[0] !== null){
    
            setFile(event.target.files[0])

            let fileName = event.target.files[0].name

            if(fileName.length > 17){
                fileName = fileName.substring(0, 17) + '...'
            }
            setPreviewText(fileName);
        }
    }

    const resetfileUpoad = () => {
        let uploadElem = document.querySelector('input[name="cert_image"]')
        uploadElem.value = ''
        interpreter.certificate_image = null
        setFile(null)
        setPreviewText('Upload Image')
        setPreviewImage('')
    }

    return(
        <>
            { Object.keys(interpreter).length > 0 &&
            <>
                <h2 className="page_hdng">Users </h2>
                <Row className="mt-5 justify-content-center">
                    <Col sm="5">
                        <div>
                            <h4 className='text-center'>Edidvfdsfvsdfvt Interpreter</h4>
                            <Form onSubmit={handleSubmit(onSubmit)} >
                                <p className='dfn_label'>Basic Info</p>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control type="text" name="first_name" defaultValue={interpreter.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} autoComplete="off"  />

                                            {errors.first_name ?.message && <span className="error">{errors.first_name ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" name="last_name" defaultValue={interpreter.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')}  autoComplete="off" />
                                        </Form.Group>
                                    </Col>

                                    { slug == 'staff' &&
                                    <>
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control name="address" type="text" defaultValue={interpreter.address} {...register('address', { required: "Address is required" })}  autoComplete="off"/>

                                                {errors.address ?.message && <span className="error">{errors.address ?.message}</span> }
                                            </Form.Group>
                                        </Col> 
                                    </>
                                }

                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event)} onBlur={formatUSNumber}  autoComplete="off" />

                                            {errors.phone ?.message && <span className="error">{errors.phone ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm="6">
                                        <Form.Label>Organization</Form.Label>
                                        <Form.Group className="mb-3" controlId="institute">
                                            <Form.Select name="institute" {...register("institute")} defaultValue={interpreter.institute_id} >
                                                <option value="" style={{ display: 'none' }} >-- Organization --</option>
                                                {instituteOptions}
                                            </Form.Select>

                                            {errors.institute ?.message && <span className="error">{errors.institute ?.message}</span> }

                                        </Form.Group>
                                    </Col> */}
                                </Row>

                                { slug == 'staff' &&

                                    <>
                                        <p className='dfn_label'>Emergency Contact</p>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" name="contact_name" defaultValue={interpreter.contact_name} onChange={(event) => setValue('contact_name', event.target.value)} {...register('contact_name')}  autoComplete="off"/>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <Form.Control type="text" name="contact_relationship" defaultValue={interpreter.contact_relationship} onChange={(event) => setValue('contact_relationship', event.target.value)} {...register('contact_relationship')}  autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="contact_email" defaultValue={interpreter.email} onChange={(event) => setValue('contact_email', event.target.value)} {...register('contact_email')}  autoComplete="off" />

                                                    {errors.contact_email && <span className="error">{errors.contact_email.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="contact_phone" type="text" defaultValue={interpreter.contact_phone} onKeyDown={(event) => allowOnlyNumber(event, 'contact_phone')} onBlur={(event) => formatUSNumber(event, 'contact_phone')}  autoComplete="off" />

                                                    {errors.contact_phone && <span className="error">{errors.contact_phone.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Certificate Credentials</Form.Label>
                                                    <Form.Select name="certificate_type" {...register("certificate_type")} defaultValue={interpreter.certificate_type} >
                                                        <option value="state" >State Certified</option>
                                                        <option value="national" >Nationally Certified</option>
                                                        <option value="certified_deaf" >Certified Deaf Interpreter</option>
                                                    </Form.Select>

                                                    {errors.certificate_type ?.message && <span className="error">{errors.certificate_type ?.message}</span> }
                                                </Form.Group>
                                            </Col>

                                            <Col sm="6">
                                                <Form.Group className="mb-3">
                                                <Form.Label>Choose file</Form.Label>
                                                    { (file || interpreter.certificate_image ) &&
                                                        <span className="remove-icon d-flex justify-content-center" onClick={() => resetfileUpoad()}>x</span>
                                                    }
                                                    <div className='choosen_file_control'>

                                                    {/*  <label>{file ? (fileName.length > 19 ? fileName.substring(0, 19) + "..." : fileName ) : 'No Image Chosen' }</label> */}
                                                        <div className="upload_btn_wrapper">
                                                            <button className="d-flex align-items-center justify-content-center attach_btn bg-brand_color2 text_brand_color1 fw-500 m-0" style={{cursor: 'pointer', border: '0px', borderRadius: '10px', padding: '5px', width: '191px'}}>
                                                                <span className='ms-2'>{previewText}</span>
                                                            </button>
                                                            <Controller
                                                                name="cert_image"
                                                                control={control}
                                                                render={({ field: { ref, ...field } }) => (
                                                                    <Form.Control 
                                                                    {...field} 
                                                                    innerRef={ref} 
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(e) => {
                                                                        handleUpload(e)
                                                                        clearErrors("cert_image");
                                                                    }}  autoComplete="off" />
                                                                )}
                                                            />
                                                            
                                                        </div>

                                                    </div>

                                                    { previewImage && 
                                                        <div className='profile_dtls_user_img'>
                                                            <img height={100} src={previewImage} alt="cert_image" onClick={() => window.open(previewImage, '_blank')} style={{cursor: 'pointer'}} />
                                                        </div>
                                                    }

                                                    {errors.cert_image && <span className="error">{errors.cert_image.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                <p className='dfn_label'>Credentials</p>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>User Name (Email)</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" name="email" defaultValue={interpreter.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', { 
                                                required: "User Name is required",
                                                pattern: { 
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address"
                                                }
                                            })}  autoComplete="off" />

                                            {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Password</Form.Label>
                                                <InputGroup className="password_frm_grp">
                                                    <Form.Control name="password" placeholder="Enter Password" type={isRevealPwd ? "text" : "password"} defaultValue={interpreter.password} onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" } )}  autoComplete="new-password" />
                                                    <InputGroup.Text id="basic-addon2">
                                                        <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3 justify-content-center">
                                    <Col className='col-auto'>
                                        <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                    </Col>
                                    <Col className='col-auto'>
                                        <button className='save_btn' type="submit" >Save</button>
                                    </Col>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                </Row>
            </>
            }
        </>
    )
}

export default Privileged(SuperAdminInterpreterEdit);