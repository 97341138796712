import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { authenticate } from "../redux/actions";

import { BACKEND_DOMAIN } from '../config/variables';
import { GET } from '../utils/axios.util';

const useAuthenticate = () => {

    const [ user, setUser ] = useState({});
    const dispatch = useDispatch()

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    useEffect(() => {

        let payload = {
            url : `${BACKEND_DOMAIN}/login/authenticate`
        }

        if(token) {
            payload['headers'] = {
                'x-access-token': token
            }
        }

        GET(payload).then(res => {

            dispatch(authenticate(res.data.data))
            setUser(res.data.data)

        }).catch(err => {

            dispatch(authenticate({}))
            setUser({})
            return false;
        })

    }, [])


    return user;

}

export default useAuthenticate