import React, { useEffect, useState } from 'react';
import {Col, Button, Row, Form } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET } from '../../../utils/axios.util';

function CoordinatorProfile(){

    const [ profile, setProfile ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    useEffect(() => {

        if(!profile) {
            getProfile()
        }
        
    },[profile]);

    const getProfile = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/profile`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                setProfile(data)
                setIsLoading(false)
            }
        }).catch(err => {
            console.log(err)
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })

    }

    return(
        <>        

            <h2 className="page_hdng">Profile</h2>
            <div className='my_card mt-4'>
                <Row>
                    <Form >
                        <Col>
                        { profile &&
                            <>
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Basic Info</h5>
                                    <NavLink to="edit">
                                        <Button variant='trans_success'>Edit profile</Button>
                                    </NavLink>
                                </div>
                                <hr/>
                                <Row>
                                    <Col className='col-auto'>
                                        <Form.Group controlId="">
                                            <div className='profile_dtls_user_img'>
                                                <img height={100} src={profile.picture_path} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="9">
                                        <Row>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" name="first_name" defaultValue={profile.first_name} disabled autoComplete="off"  />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" name="last_name" defaultValue={profile.last_name} disabled autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Personal Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="9">
                                        <Row>
                                            <Col lg="5" sm="7">
                                                <Form.Group controlId="">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" defaultValue={profile.email} disabled autoComplete="off"  />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="5">
                                                <Form.Group controlId="">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control name="phone" defaultValue={profile.phone} disabled autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>School Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control name="school_name" defaultValue={profile.school_name} disabled autoComplete="off"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6" sm="7">
                                        <Form.Group controlId="">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control name="school_phone" defaultValue={profile.school_phone} disabled autoComplete="off" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            </>
                        }
                        { isLoading && 
                            <div className='text-center subscriptions_packages_name no_records_loading'>
                                <h5>Loading...</h5>
                            </div>
                        }
                        </Col>
                    </Form>
                </Row>
            </div>
        </>
    )
}

export default CoordinatorProfile;