import React,{ useState, useEffect, useRef } from "react";

import {Col, Button,Row,InputGroup,Form, FloatingLabel, ListGroup, ToggleButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../../assets/images/dummy.png';
import paper_clip from '../../../assets/images/icons/paper_clip.png';
import search from '../../../assets/images/icons/search.png';
import member2 from '../../../assets/images/member2.png';

import { BACKEND_DOMAIN } from '../../../config/variables';
import useDivisions from '../../../hooks/useDivisions';
import { GET, POST } from '../../../utils/axios.util';

function AssignToStudentComponent() {

    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();
    const divisions = useDivisions()
    const params = useParams();
    const navigate = useNavigate();
    const assignmentId = params.id

    const [ users, setUsers ] = useState([])
    const [ checked, setChecked ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ checkedState, setCheckedState ] = useState({})
    const [ allCheckedState, setAllCheckedState ] = useState(false)
    const [ divisonOptions, setDivisonOptions ] = useState('');
    var initializeUsers = useRef(false)
    const [ isDisabled, setIsDisabled ] = useState(true)

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    useEffect(() => {

        if(!initializeUsers.current == true) {
            getUsers()
        }

        if(divisions.length > 0 && !divisonOptions) {
            let optionValues =  divisions.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setDivisonOptions(optionValues)
        }


    },[ users, divisions, checkedState, isDisabled ]);

    const changeDivision = (event) => {

        setValue('division', event.target.value)
        getUsers(event.target.value)
    }

    const getUsers = (division_id = '') => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/students?&division=${division_id}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setIsNoRecords(false)

            if(data.length > 0){

                let checkMap = {}
                for(let x of data) {
                    if(x.assigned_user_id && x['assignment_ids'].includes(Number(assignmentId))) {
                        checkMap[x.id] = true
                    }else {
                        checkMap[x.id] = false
                    }
                }
                setCheckedState(prevState => {
                    return {
                        ...prevState,
                        ...checkMap
                    }
                })
            }else {
                setIsNoRecords(true)
            }
            setIsLoading(false)
            setUsers(data)
            initializeUsers.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            initializeUsers.current = true
            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const handleAllCheckBox = (event) => {

        let inverseValue =  !allCheckedState
    
        let tempCheckstate = {}
        for(let y in checkedState) {
          if(inverseValue == true) {
            tempCheckstate[y] = true
          }else{
            tempCheckstate[y] = false
          }
          
        }

        let checkedData = Object.keys(tempCheckstate).filter(key => tempCheckstate[key] === true)

        if(checkedData.length > 0) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setAllCheckedState(inverseValue)
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });
        
    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        let tempCheckstateArr = []
        for(let y in checkedState) {
          if(y == checkId) {
            tempCheckstate[y] = !checkedState[y]
          }
        }
        let dummyCheck = {
          ...checkedState,
          ...tempCheckstate
        }

        for(let z in dummyCheck) {
          tempCheckstateArr.push(dummyCheck[z])
        }
        if(tempCheckstateArr.every(element => element === true)) {
          setAllCheckedState(true)
        }else {
          setAllCheckedState(false)
        }

        if(tempCheckstateArr.some(element => element === true)) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }

        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });
    }

    const onSubmit = () => {

        var selectedFilter = []

        if(Object.keys(checkedState).length > 0 ) {
            selectedFilter = Object.keys(checkedState).filter(key => checkedState[key] === true)
        }

        if(selectedFilter.length > 0 ) {

            let studentAssignments = []
            for(let x in checkedState) {
                studentAssignments.push({
                    id: x,
                    value: checkedState[x]
                })
            }

            let payload = {
                url : `${BACKEND_DOMAIN}/coordinator/students/assign`,
                data: { 
                    assignment_id: assignmentId,
                    student_ids: JSON.stringify(studentAssignments)
                }
            }

            POST(payload).then(res => {

                toast.success(res.data.message)

                let navigateUrl = `/coordinator/assignments?tab=completed`

                if(urlPage) {
                    navigateUrl = `/coordinator/assignments?tab=completed&page=${urlPage}`
                }

                navigate(navigateUrl)

            }).catch(err => {

                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }

                toast.error(err.response.data.message)
            })

        }

    }

    return(
        <>
        <h2 className="page_hdng">Assign Students</h2>
        <div className='my_card chat_contact mt-4'>
            <Row>
                <Col>
                    <div className="my_card_brand_dark_color">
                        <div className='d-flex align-items-center justify-content-end'>
                            <Button variant='trans_success' onClick={() => navigate(-1)} >Back</Button>
                        </div>
                        <hr/>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='col-auto'>
                                        <div className='d-flex justify-content-end'>
                                            <Form.Select className='me-2' name="division" {...register('division')} onChange={(event) => changeDivision(event)} style={{height: '45px'}}>
                                                <option value="">-- Class --</option>
                                                {divisonOptions}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>  
                            </Col>
                            <Col className="col-auto">
                                <Button className="px-2" variant="secondary" onClick={handleAllCheckBox} >Select All</Button>
                            </Col>
                        </Row>
                        { isLoading && 
                            <div className='text-center subscriptions_packages_name no_records_loading'>
                                <h5>Loading...</h5>
                            </div>
                        }
                        { !isLoading && isNoRecords &&
                            <div className='text-center' >
                                <h5>No Records Found!</h5>
                            </div>
                        }
                        { users.length > 0 && 
                            <ListGroup variant="flush" className='mt-2'>
                                { users.map((elem, index) => {

                                        return (
                                            <ListGroup.Item className="contact_list my-2">
                                                <div>
                                                    <Row className='align-items-center justify-content-between'>
                                                        <Col>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <img width="50" className="rounded-circle" src={elem.picture_path} />
                                                                </div>
                                                                <div className="ms-2">
                                                                    <h4 className='fs-18 fw-600 m-0'>{elem.full_name}</h4>
                                                                    <p className='text_light_color2 fs-14 fw-400 m-0'>{elem.division_name}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-auto'>
                                                            <ToggleButton className="mb-2" type="checkbox" variant="success" value={elem.id} onClick={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} >{checkedState[elem.id] == true ? 'Selected' : 'Select'}</ToggleButton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    }) 
                                }
                            </ListGroup>
                        }
                    </div>
                </Col>
                {/* <Col sm="12" lg="8">
                    <div className="my_card_brand_dark_color">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4>Create Assignments</h4>
                        </div>
                    </div>
                </Col> */}
            </Row>
            { users.length > 0 && 
                <div className='text-end py-2'>
                    <Button variant='success' size='lg' disabled={isDisabled} onClick={onSubmit} >ASSIGN</Button>
                </div>
            }
        </div>
        </>
    )
}

export default AssignToStudentComponent;