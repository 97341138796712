import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Button,
  Form,
  Row,
  Tab,
  Nav,
  Modal,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { audio } from "../../../redux/actions";

import ntpd_arrow from "../../../assets/images/icons/ntpd_arrow.png";
import mic_arrow from "../../../assets/images/icons/mic_arrow.png";
import video_arrow from "../../../assets/images/icons/video_arrow.png";
import video from "../../../assets/images/icons/video.png";
import PAGINATION from "../../../components/common/pagination";

import useAssignmentsQueue from "../../../hooks/useAssignmentsQueue";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BACKEND_DOMAIN, MERGER_DOMAIN } from "../../../config/variables";
import { GET, PATCH } from "../../../utils/axios.util";

function InterpreterAssignmentsCtrl(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  // const assignments = useAssignmentsQueue()
  const [assignments, setAssignments] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const [requestedOffset, setRequestedOffset] = useState(0);
  const [progressOffset, setProgressOffset] = useState(0);
  const [reviewOffset, setReviewOffset] = useState(0);
  const [completedOffset, setCompletedOffset] = useState(0);
  const [currentRequestedItems, setCurrentRequestedItems] = useState([]);
  const [currentProgressItems, setCurrentProgressItems] = useState([]);
  const [currentReviewItems, setCurrentReviewItems] = useState([]);
  const [currentCompletedItems, setCurrentCompletedItems] = useState([]);
  const [requestedPageCount, setRequestedPageCount] = useState(0);
  const [progressPageCount, setProgressPageCount] = useState(0);
  const [reviewPageCount, setReviewPageCount] = useState(0);
  const [completedPageCount, setCompletedPageCount] = useState(0);
  const [activePageNumber, setActivePageNumber] = useState("");
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleMessageModalClose = () => setMessageModalShow(false);

  var isAssignmentsListingInitialized = useRef(false);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  var urlTab = urlParams.get("tab");
  var urlPage = urlParams.get("page");
  let defaultTabValue = "requested";
  let currentPageNumValue = 0;
  let initialPageValue = 0;

  if (urlTab == "progress") {
    defaultTabValue = "progress";
  }
  if (urlTab == "review") {
    defaultTabValue = "review";
  }
  if (urlTab == "completed") {
    defaultTabValue = "completed";
  }

  if (urlPage) {
    currentPageNumValue = Number(urlPage) - 1;
    initialPageValue = Number(urlPage) - 1;
  }

  const [currentPageNum, setCurrentPageNum] = useState(currentPageNumValue);
  const initialPageNum = initialPageValue;

  const [defaultTab, setDefaultTab] = useState(defaultTabValue);

  useEffect(() => {
    if (!isAssignmentsListingInitialized.current) {
      getAssignments();
    }

    /* if(Object.keys(assignments).length > 0 && isAssignmentsListingInitialized.current) {

            const requestedEndOffset = requestedOffset + itemsPerPage;
            const progressEndOffset = progressOffset + itemsPerPage;
            const reviewEndOffset = reviewOffset + itemsPerPage;
            const completedEndOffset = completedOffset + itemsPerPage;
            let requestedChunk = []
            let progressChunk = []
            let reviewChunk = []
            let completedChunk = []
            let requestedPageCount = Math.ceil(assignments.list.requested.length / itemsPerPage);
            let progressPageCount = Math.ceil(assignments.list.progress.length / itemsPerPage);
            let reviewPageCount = Math.ceil(assignments.list.review.length / itemsPerPage);
            let completedPageCount = Math.ceil(assignments.list.completed.length / itemsPerPage);

            if(assignments.list.requested.length > 0) {
                requestedChunk = assignments.list.requested.slice(requestedOffset, requestedEndOffset)
            }
            if(assignments.list.progress.length > 0) {
                progressChunk = assignments.list.progress.slice(progressOffset, progressEndOffset)
            }
            if(assignments.list.review.length > 0) {
                reviewChunk = assignments.list.review.slice(reviewOffset, reviewEndOffset)
            }
            if(assignments.list.completed.length > 0) {
                completedChunk = assignments.list.completed.slice(completedOffset, completedEndOffset)
            }

            setRequestedPageCount(requestedPageCount)
            setProgressPageCount(progressPageCount)
            setReviewPageCount(reviewPageCount)
            setCompletedPageCount(completedPageCount)
            setCurrentRequestedItems(requestedChunk)
            setCurrentProgressItems(progressChunk)
            setCurrentReviewItems(reviewChunk)
            setCurrentCompletedItems(completedChunk)

        } */
  }, [
    assignments,
    currentRequestedItems,
    currentProgressItems,
    currentReviewItems,
    currentCompletedItems,
    requestedOffset,
    progressOffset,
    reviewOffset,
    completedOffset,
    itemsPerPage,
  ]);

  const getAssignments = () => {
    let payload = {
      url: `${BACKEND_DOMAIN}/assignments/queue`,
    };

    if (token) {
      payload["headers"] = {
        "x-access-token": token,
      };
    }

    GET(payload)
      .then((res) => {
        const { data } = res.data;
        isAssignmentsListingInitialized.current = true;

        const requestedEndOffset = requestedOffset + itemsPerPage;
        const progressEndOffset = progressOffset + itemsPerPage;
        const reviewEndOffset = reviewOffset + itemsPerPage;
        const completedEndOffset = completedOffset + itemsPerPage;
        let requestedChunk = [];
        let progressChunk = [];
        let reviewChunk = [];
        let completedChunk = [];
        let requestedPageCount = Math.ceil(
          data.list.requested.length / itemsPerPage
        );
        let progressPageCount = Math.ceil(
          data.list.progress.length / itemsPerPage
        );
        let reviewPageCount = Math.ceil(data.list.review.length / itemsPerPage);
        let completedPageCount = Math.ceil(
          data.list.completed.length / itemsPerPage
        );

        if (data.list.requested.length > 0) {
          requestedChunk = data.list.requested.slice(
            requestedOffset,
            requestedEndOffset
          );
        }
        if (data.list.progress.length > 0) {
          progressChunk = data.list.progress.slice(
            progressOffset,
            progressEndOffset
          );
        }
        if (data.list.review.length > 0) {
          reviewChunk = data.list.review.slice(reviewOffset, reviewEndOffset);
        }
        if (data.list.completed.length > 0) {
          completedChunk = data.list.completed.slice(
            completedOffset,
            completedEndOffset
          );
        }

        setRequestedPageCount(requestedPageCount);
        setProgressPageCount(progressPageCount);
        setReviewPageCount(reviewPageCount);
        setCompletedPageCount(completedPageCount);
        setCurrentRequestedItems(requestedChunk);
        setCurrentProgressItems(progressChunk);
        setCurrentReviewItems(reviewChunk);
        setCurrentCompletedItems(completedChunk);

        setAssignments(data);
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        setAssignments({});
      });
  };

  const acceptRequest = (elem) => {
    let payload = {
      url: `${BACKEND_DOMAIN}/interpreters/accept-request`,
      data: {
        queue_id: elem.id,
      },
    };

    PATCH(payload)
      .then((res) => {
        toast.success(res.data.message);
        setSearchParams({ tab: "progress" });
        navigate(0);
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        if (err.response.status === 403) {
          toast.error(err.response.data.message);
        }
      });
  };

  const declineRequest = (queue_id) => {
    let payload = {
      url: `${BACKEND_DOMAIN}/interpreters/decline-request`,
      data: {
        queue_id,
      },
    };

    PATCH(payload)
      .then((res) => {
        toast.success(res.data.message);
        navigate(0);
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        if (err.response.status === 403) {
          toast.error(err.response.data.message);
        }
      });
  };

  const onSubmit = async (data) => {
    let payload = {
      url: `${BACKEND_DOMAIN}/interpreters/finish-recording`,
      data,
    };

    PATCH(payload)
      .then((res) => {
        toast.success(res.data.message);
        handleModalClose();
        setSearchParams({ tab: "review" });
        navigate(0);
      })
      .catch((err) => {
        console.log(err);

        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        if (err.response.status === 403) {
          toast.error(err.response.data.message);
        }
      });
  };

  const handlePageClick = (event, currentTab) => {
    let items = [];

    if (currentTab == "requested") {
      items = assignments.list.requested;
    }
    if (currentTab == "progress") {
      items = assignments.list.progress;
    }
    if (currentTab == "review") {
      items = assignments.list.review;
    }
    if (currentTab == "completed") {
      items = assignments.list.completed;
    }

    const newOffset = (event.selected * itemsPerPage) % items.length;

    const endOffset = newOffset + itemsPerPage;
    let chunk = items.slice(newOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    if (currentTab == "requested") {
      setRequestedPageCount(pageCount);
      setCurrentRequestedItems(chunk);
      setRequestedOffset(newOffset);
    }
    if (currentTab == "progress") {
      setProgressPageCount(pageCount);
      setCurrentProgressItems(chunk);
      setProgressOffset(newOffset);
    }
    if (currentTab == "review") {
      setReviewPageCount(pageCount);
      setCurrentReviewItems(chunk);
      setReviewOffset(newOffset);
    }
    if (currentTab == "completed") {
      setCompletedPageCount(pageCount);
      setCurrentCompletedItems(chunk);
      setCompletedOffset(newOffset);
    }
  };

  const getVideoDuration = (url) => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // const video = document.createElement('video');
        // video.src = URL.createObjectURL(blob);

        // return new Promise((resolve) => {
        //     video.addEventListener('loadedmetadata', () => {
        //         const durationInSeconds = video.duration;
        //         const hours = Math.floor(durationInSeconds / 3600);
        //         const minutes = Math.floor((durationInSeconds % 3600) / 60);
        //         // Ensure there's at least 1 minute if the duration is less than 60 seconds
        //         const adjustedMinutes = durationInSeconds < 60 ? 1 : minutes;

        //         resolve({ hours, minutes: adjustedMinutes });
        //     });
        // });

        const video = document.createElement("video");
        video.src = URL.createObjectURL(blob);

        // Explicitly set preload to metadata
        video.preload = "metadata";

        return new Promise((resolve, reject) => {
          video.addEventListener("loadedmetadata", () => {
            // Ensure the metadata is loaded before accessing the duration
            if (video.readyState >= 1) {
              const durationInSeconds = video.duration;
              const hours = Math.floor(durationInSeconds / 3600);
              const minutes = Math.floor((durationInSeconds % 3600) / 60);
              // Ensure there's at least 1 minute if the duration is less than 60 seconds
              const adjustedMinutes = durationInSeconds < 60 ? 1 : minutes;

              resolve({ hours, minutes: adjustedMinutes });
            } else {
              reject(new Error("Failed to load video metadata"));
            }
          });

          video.addEventListener("error", (error) => {
            reject(error);
          });

          // Autoplay to ensure the metadata loads
          video.autoplay = true;
          video.muted = true; // Mute to avoid autoplay restrictions
          video.play().catch((error) => {
            // Handle play error, possibly due to autoplay restrictions
            console.error("Failed to play video:", error);
            reject(error);
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModalShow = async (id, videoURL) => {
    // const data = await getVideoDuration(videoURL)

    const formData = {
      // hours: data?.hours,
      // minutes: data?.minutes,
      queue_id: id,
    };
    // console.log(formData);
    await onSubmit(formData);
  };

  const viewAssignment = (elem) => {
    if (
      elem.status == "pending" ||
      elem.status == "progress" ||
      elem.status == "interrupted" ||
      elem.status == "error"
    ) {
      if (elem.type == "audio") {
        dispatch(
          audio(
            elem.original_file_path,
            elem.title,
            elem.category_title,
            elem.sub_category_title
          )
        );
      } else if (elem.type == "document") {
        window.open(elem.original_file_path, "_blank");
      } else {
        navigate("/interpreter/assignments/" + elem.assignment_id);
      }
    } else {
      navigate("/interpreter/assignments/" + elem.assignment_id);
    }
  };

  const openRecordingTool = (item) => {
    let activeItemSelector = `#fdgdfg-tabpane-progress ul li.page-item.active a`;
    let activePageNumber = document.querySelector(activeItemSelector).innerText;

    setActivePageNumber(activePageNumber);

    let recordingPath = `${MERGER_DOMAIN}/interpreter/video?token=${props.user.session_token}&queue=${item.id}&page=${activePageNumber}`;

    if (item.type == "audio") {
      recordingPath = `${MERGER_DOMAIN}/interpreter/audio?token=${props.user.session_token}&queue=${item.id}&page=${activePageNumber}`;
    }

    if (item.type == "document") {
      recordingPath = `${MERGER_DOMAIN}/interpreter/pdf?token=${props.user.session_token}&queue=${item.id}&page=${activePageNumber}`;
    }

    window.location.href = recordingPath;
  };

  const changeTab = (tab) => {
    setSearchParams({ tab });
    setCurrentPageNum(0);
  };

  const openMessage = (elem) => {
    setMessage(elem.description);
    setMessageModalShow(true);
  };

  return (
    <>
      <h2 className="page_hdng">Assignments</h2>
      <Row className="mt-3">
        <Col sm="12" md="6" lg="2">
          <div className="assignments_card">
            <h4>
              {Object.keys(assignments).length > 0
                ? assignments.requested_count
                : 0}
            </h4>
            <p className="text-capatilize">requested</p>
          </div>
        </Col>
        <Col sm="12" md="6" lg="2">
          <div className="assignments_card">
            <h4>
              {Object.keys(assignments).length > 0
                ? assignments.progress_count
                : 0}
            </h4>
            <p className="text-capatilize">in progress</p>
          </div>
        </Col>
        <Col sm="12" md="6" lg="2">
          <div className="assignments_card">
            <h4>
              {Object.keys(assignments).length > 0
                ? assignments.merging_count
                : 0}
            </h4>
            <p className="text-capatilize">in merge</p>
          </div>
        </Col>
        <Col sm="12" md="6" lg="2">
          <div className="assignments_card">
            <h4>
              {Object.keys(assignments).length > 0
                ? assignments.review_count
                : 0}
            </h4>
            <p className="text-capatilize">under Review </p>
          </div>
        </Col>
        <Col sm="12" md="6" lg="2">
          <div className="assignments_card">
            <h4>
              {Object.keys(assignments).length > 0
                ? assignments.completed_count
                : 0}
            </h4>
            <p className="text-capatilize">completed</p>
          </div>
        </Col>
        <Col sm="12" md="6" className="col-lg-auto">
          <div className="assignments_card">
            <div className="my-2 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span>
                  <img height={20} src={ntpd_arrow} />
                </span>
                <span className="fs_14 fw_500 text_brand_color2 m-1">
                  {Object.keys(assignments).length > 0
                    ? assignments.formats.documents_count
                    : 0}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span>
                  <img height={20} src={mic_arrow} />
                </span>
                <span className="fs_14 fw_500 text_brand_color2 m-1">
                  {Object.keys(assignments).length > 0
                    ? assignments.formats.audios_count
                    : 0}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span>
                  <img height={20} src={video_arrow} />
                </span>
                <span className="fs_14 fw_500 text_brand_color2 m-1">
                  {Object.keys(assignments).length > 0
                    ? assignments.formats.videos_count
                    : 0}
                </span>
              </div>
            </div>
            <p className="text-capatilize">Assignments Formats</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="fixed_tab">
            <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
              <Row>
                <Col className="col-auto">
                  <Nav variant="fixed_pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="requested"
                        onClick={() => changeTab("requested")}
                      >
                        Requested
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="progress"
                        onClick={() => changeTab("progress")}
                      >
                        In Progress
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="review"
                        onClick={() => changeTab("review")}
                      >
                        Under Review
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="completed"
                        onClick={() => changeTab("completed")}
                      >
                        Completed
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>

              <Tab.Content>
                <Tab.Pane eventKey="requested">
                  <Table className="">
                    <thead>
                      <tr>
                        <th>Assignments Name</th>
                        <th className="text-center">Date & Time</th>
                        {/* <th className='text-center'>Duration </th> */}
                        <th className="text-center">Format</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.requested.length > 0 &&
                        currentRequestedItems.map((elem, index) => {
                          let durationSplit = elem.duration.split(":");
                          let hours = parseInt(durationSplit[0]);
                          let mins = parseInt(durationSplit[1]);
                          let secs = parseInt(durationSplit[2]);

                          var duration = "";
                          var assignmentIcon = "";

                          if (hours > 0) {
                            duration += `${hours}h`;
                          }
                          if (mins > 0) {
                            duration += ` ${mins}m`;
                          }
                          if (secs > 0) {
                            duration += ` ${secs}s`;
                          }
                          duration = duration.trim();

                          if (elem.type == "video") {
                            assignmentIcon = video_arrow;
                          }
                          if (elem.type == "audio") {
                            assignmentIcon = mic_arrow;
                          }
                          if (elem.type == "document") {
                            assignmentIcon = ntpd_arrow;
                          }

                          return (
                            <tr key={elem.id}>
                              <td>
                                <NavLink
                                  to={"/interpreter/assignments/" + elem.id}
                                >
                                  <h5>{elem.title}</h5>
                                </NavLink>
                                <p>
                                  <span className="text_white_color_half">
                                    {elem.division_name},
                                  </span>
                                  <span className="text_brand_color2">
                                    {elem.category_title}
                                  </span>
                                </p>
                              </td>
                              <td className="text-center">
                                <h5>
                                  <Moment format="MM/DD/YY">
                                    {elem.created_at}
                                  </Moment>
                                </h5>
                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                <span className="text_brand_color3">
                                  <Moment format="hh:mm A">
                                    {elem.created_at}
                                  </Moment>
                                </span>
                              </td>
                              {/*  <td className='text-center'>
                                                            <h5>{duration}</h5>
                                                        </td> */}
                              <td className="text-center">
                                <img src={assignmentIcon} />
                              </td>
                              <td className="text-center text_brand_color6 text-capitalize">
                                {elem.status}
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center">
                                  <p
                                    className="text_brand_color3 text-capitalize"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => acceptRequest(elem)}
                                  >
                                    Accept
                                  </p>
                                  &nbsp;|&nbsp;
                                  <p
                                    className="text_brand_color6 text-capitalize"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => declineRequest(elem.id)}
                                  >
                                    Decline
                                  </p>
                                  &nbsp;&nbsp;&nbsp;
                                  <Button
                                    variant="dark"
                                    size="sm"
                                    onClick={() => viewAssignment(elem)}
                                  >
                                    View
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.requested.length === 0 && (
                          <tr className="text-center">
                            <td colSpan="7" className="fs-4">
                              No Records Found!
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {Object.keys(assignments).length > 0 &&
                    assignments.list.requested.length > 0 && (
                      <PAGINATION
                        allItems={assignments.list.requested}
                        chunkedItems={currentRequestedItems}
                        handlePageClick={(event) =>
                          handlePageClick(event, "requested")
                        }
                        pageCount={requestedPageCount}
                        currentPageNumber={currentPageNum}
                        initialPageNumber={initialPageNum}
                      />
                    )}
                </Tab.Pane>

                <Tab.Pane eventKey="progress">
                  <Table className="">
                    <thead>
                      <tr>
                        <th>Assignments Name</th>
                        <th className="text-center">Date & Time</th>
                        {/* <th className='text-center'>Duration </th> */}
                        <th className="text-center">Format</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.progress.length > 0 &&
                        currentProgressItems.map((elem, index) => {
                          let durationSplit = elem.duration.split(":");
                          let hours = parseInt(durationSplit[0]);
                          let mins = parseInt(durationSplit[1]);
                          let secs = parseInt(durationSplit[2]);

                          var duration = "";
                          var assignmentIcon = "";
                          let fontColor = "text_brand_color3";

                          if (elem.status == "disapproved") {
                            fontColor = "text_brand_color7";
                          }

                          if (hours > 0) {
                            duration += `${hours}h`;
                          }
                          if (mins > 0) {
                            duration += ` ${mins}m`;
                          }
                          if (secs > 0) {
                            duration += ` ${secs}s`;
                          }
                          duration = duration.trim();

                          if (elem.type == "video") {
                            assignmentIcon = video_arrow;
                          }
                          if (elem.type == "audio") {
                            assignmentIcon = mic_arrow;
                          }
                          if (elem.type == "document") {
                            assignmentIcon = ntpd_arrow;
                          }

                          return (
                            <tr key={elem.id}>
                              <td>
                                <NavLink
                                  to={"/interpreter/assignments/" + elem.id}
                                >
                                  <h5>{elem.title}</h5>
                                </NavLink>
                                <p>
                                  <span className="text_white_color_half">
                                    {elem.division_name},
                                  </span>
                                  <span className="text_brand_color2">
                                    {elem.category_title}
                                  </span>
                                </p>
                              </td>
                              <td className="text-center">
                                <h5>
                                  <Moment format="MM/DD/YY">
                                    {elem.created_at}
                                  </Moment>
                                </h5>
                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                <span className="text_brand_color3">
                                  <Moment format="hh:mm A">
                                    {elem.created_at}
                                  </Moment>
                                </span>
                              </td>
                              {/* <td className='text-center'>
                                                            <h5>{duration}</h5>
                                                        </td> */}
                              <td className="text-center">
                                <img src={assignmentIcon} />
                              </td>

                              <td
                                className={`text-center ${fontColor} text-capitalize`}
                                style={{
                                  cursor:
                                    elem.status === "disapproved"
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={(event) => {
                                  if (elem.status !== "disapproved") {
                                    event.preventDefault();
                                    return false;
                                  } else {
                                    openMessage(elem);
                                  }
                                }}
                              >
                                {elem.status}
                                {elem.status == "disapproved" && (
                                  <FontAwesomeIcon
                                    icon={icon({
                                      name: "envelope",
                                      style: "solid",
                                    })}
                                    className="mx-2"
                                  />
                                )}
                              </td>

                              <td className="text-center">
                                {elem.status === "merging" &&
                                  "The video is currently in the merge queue. We will update the status once the process is complete"}
                                {elem.status !== "merging" && (
                                  <>
                                    <Button
                                      variant="dark"
                                      size="sm"
                                      onClick={() => openRecordingTool(elem)}
                                    >
                                      Record Now
                                    </Button>
                                    &nbsp;|&nbsp;
                                    <Button
                                      variant="dark"
                                      size="sm"
                                      onClick={() =>
                                        handleModalShow(
                                          elem?.id,
                                          elem.original_file_signed_URL
                                        )
                                      }
                                    >
                                      Finish Recording
                                    </Button>
                                    &nbsp;|&nbsp;
                                    <Button
                                      variant="dark"
                                      size="sm"
                                      onClick={() => viewAssignment(elem)}
                                    >
                                      View
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.progress.length === 0 && (
                          <tr className="text-center">
                            <td colSpan="7" className="fs-4">
                              No Records Found!
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {Object.keys(assignments).length > 0 &&
                    assignments.list.progress.length > 0 && (
                      <PAGINATION
                        allItems={assignments.list.progress}
                        chunkedItems={currentProgressItems}
                        handlePageClick={(event) =>
                          handlePageClick(event, "progress")
                        }
                        pageCount={progressPageCount}
                        currentPageNumber={currentPageNum}
                        initialPageNumber={initialPageNum}
                      />
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey="review">
                  <Table className="">
                    <thead>
                      <tr>
                        <th>Assignments Name</th>
                        <th className="text-center">Date & Time</th>
                        <th className="text-center">Duration </th>
                        <th className="text-center">Format</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.review.length > 0 &&
                        currentReviewItems.map((elem, index) => {
                          let durationSplit = elem.duration.split(":");
                          let hours = parseInt(durationSplit[0]);
                          let mins = parseInt(durationSplit[1]);
                          let secs = parseInt(durationSplit[2]);

                          var duration = "";
                          var assignmentIcon = "";

                          if (hours > 0) {
                            duration += `${hours}h`;
                          }
                          if (mins > 0) {
                            duration += ` ${mins}m`;
                          }
                          if (secs > 0) {
                            duration += ` ${secs}s`;
                          }
                          duration = duration.trim();

                          if (elem.type == "video") {
                            assignmentIcon = video_arrow;
                          }
                          if (elem.type == "audio") {
                            assignmentIcon = mic_arrow;
                          }
                          if (elem.type == "document") {
                            assignmentIcon = ntpd_arrow;
                          }

                          return (
                            <tr key={elem.id}>
                              <td>
                                <h5>{elem.title}</h5>
                                <p>
                                  <span className="text_white_color_half">
                                    {elem.division_name},
                                  </span>
                                  <span className="text_brand_color2">
                                    {elem.category_title}
                                  </span>
                                </p>
                              </td>
                              <td className="text-center">
                                <h5>
                                  <Moment format="MM/DD/YY">
                                    {elem.created_at}
                                  </Moment>
                                </h5>
                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                <span className="text_brand_color3">
                                  <Moment format="hh:mm A">
                                    {elem.created_at}
                                  </Moment>
                                </span>
                              </td>
                              {/* <td className='text-center'>8h30s</td> */}
                              <td className="text-center">
                                <h5>{duration}</h5>
                              </td>
                              <td className="text-center">
                                <img src={assignmentIcon} />
                              </td>
                              <td className="text-center text_brand_color3 text-capitalize">
                                {elem.status}
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() => viewAssignment(elem)}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.review.length === 0 && (
                          <tr className="text-center">
                            <td colSpan="8" className="fs-4">
                              No Records Found!
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {Object.keys(assignments).length > 0 &&
                    assignments.list.review.length > 0 && (
                      <PAGINATION
                        allItems={assignments.list.review}
                        chunkedItems={currentReviewItems}
                        handlePageClick={(event) =>
                          handlePageClick(event, "review")
                        }
                        pageCount={reviewPageCount}
                        currentPageNumber={currentPageNum}
                        initialPageNumber={initialPageNum}
                      />
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey="completed">
                  <Table className="">
                    <thead>
                      <tr>
                        <th>Assignments Name</th>
                        <th className="text-center">Date & Time</th>
                        <th className="text-center">Duration </th>
                        <th className="text-center">Format</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.completed.length > 0 &&
                        currentCompletedItems.map((elem, index) => {
                          let durationSplit = elem.duration.split(":");
                          let hours = parseInt(durationSplit[0]);
                          let mins = parseInt(durationSplit[1]);
                          let secs = parseInt(durationSplit[2]);

                          var duration = "";
                          var assignmentIcon = "";

                          if (hours > 0) {
                            duration += `${hours}h`;
                          }
                          if (mins > 0) {
                            duration += `${mins}m`;
                          }
                          if (secs > 0) {
                            duration += `${secs}s`;
                          }
                          duration = duration.trim();

                          if (elem.type == "video") {
                            assignmentIcon = video_arrow;
                          }
                          if (elem.type == "audio") {
                            assignmentIcon = mic_arrow;
                          }
                          if (elem.type == "document") {
                            assignmentIcon = ntpd_arrow;
                          }

                          return (
                            <tr key={elem.id}>
                              <td>
                                <NavLink
                                  to={"/interpreter/assignments/" + elem.id}
                                >
                                  <h5>{elem.title}</h5>
                                </NavLink>
                                <p>
                                  <span className="text_white_color_half">
                                    {elem.division_name},
                                  </span>
                                  <span className="text_brand_color2">
                                    {elem.category_title}
                                  </span>
                                </p>
                              </td>
                              <td className="text-center">
                                <h5>
                                  <Moment format="MM/DD/YY">
                                    {elem.created_at}
                                  </Moment>
                                </h5>
                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                <span className="text_brand_color3">
                                  <Moment format="hh:mm A">
                                    {elem.created_at}
                                  </Moment>
                                </span>
                              </td>
                              {/* <td className='text-center'>8h30s</td> */}
                              <td className="text-center">
                                <h5>{duration}</h5>
                              </td>
                              <td className="text-center">
                                <img src={assignmentIcon} />
                              </td>
                              <td className="text-center text_brand_color2 text-capitalize">
                                {elem.status}
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  size="sm"
                                  onClick={() => viewAssignment(elem)}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      {Object.keys(assignments).length > 0 &&
                        assignments.list.completed.length === 0 && (
                          <tr className="text-center">
                            <td colSpan="8" className="fs-4">
                              No Records Found!
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {Object.keys(assignments).length > 0 &&
                    assignments.list.completed.length > 0 && (
                      <PAGINATION
                        allItems={assignments.list.completed}
                        chunkedItems={currentCompletedItems}
                        handlePageClick={(event) =>
                          handlePageClick(event, "completed")
                        }
                        pageCount={completedPageCount}
                        currentPageNumber={currentPageNum}
                        initialPageNumber={initialPageNum}
                      />
                    )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
      </Row>

      <Modal
        className="approve-modal"
        show={messageModalShow}
        onHide={handleMessageModalClose}
        centered
      >
        <Modal.Header closeButton className="justify-content-center">
          <div className="text-center">
            <Modal.Title className="fs-35 fw-700">Description</Modal.Title>
          </div>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows="5"
                name="disapprove_message"
                defaultValue={message}
                readOnly
                autoComplete="off"
              />
            </Form.Group>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticate,
  };
};

export default connect(mapStateToProps, null)(InterpreterAssignmentsCtrl);
