import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Button,  Col, Form, FormControl, InputGroup, Modal, Row, Table} from "react-bootstrap";
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mode_edit from '../../../assets/images/icons/mode_edit.png';
import grn_excla from '../../../assets/images/icons/grn_excla.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import search from '../../../assets/images/icons/search.png';
import member5 from '../../../assets/images/member5.png';

import useDivisions from '../../../hooks/useDivisions';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';

function SchoolDivisionsSubCategoriesCtrl(){

    const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();
    const params = useParams();

    const [ category, setCategory ] = useState({})
    const [ subCategories, setSubCategories ] = useState([])
    const [ catDivision, setCatDivision ] = useState({})
    const [ modalTitle, setModalTitle ] = useState('Add Chapter')
    const divisions = useDivisions()

    const [ keyword, setKeyword ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    var isCategoriesInitialized = useRef(false)
    var isSubCategoriesInitialized = useRef(false)

    useEffect(() => {

        if(Object.keys(category).length === 0 && !isCategoriesInitialized.current) {
            getCategory(keyword)
        }

        if(subCategories.length === 0 && !isSubCategoriesInitialized.current) {
            getSubCategories(keyword)
        }

        if(divisions.length > 0) {
            let catDivFilter = divisions.filter(item => {
                return item.id == params.division_id
            })
            if(catDivFilter.length > 0) {
                setCatDivision(catDivFilter[0])
            }
        }

    },[category, subCategories, divisions]);

    const getCategory = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/category/${params.category_id}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setIsLoading(false)
            setCategory(data)
            isCategoriesInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            console.log(err)
            setIsLoading(false)
        })

    }

    const getSubCategories = (keyword) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/sub-categories/${params.category_id}?search=${keyword}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            setIsNoRecords(false)
            setIsLoading(false)
            setSubCategories(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
            isSubCategoriesInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const handleModalShow = (type, subCatId = '') => {

        clearErrors('sub_category')
        clearErrors('teacher')
        if(type == 'add') {

            setModalTitle('Add Chapter')
            setValue('sub_category', '')
            setValue('sub_cat_id', '')
            setValue('teacher', '')
            setModalShow(true);
        }else {
            
            let subCatFiltr = subCategories.filter(item => {
                return item.id == subCatId
            })
            setModalTitle('Edit Chapter')
            setValue('sub_category', subCatFiltr[0]['title'])
            setValue('sub_cat_id', subCatFiltr[0]['id'])
            setValue('teacher', subCatFiltr[0]['teacher'])
            setModalShow(true);
        }
    }

    const deleteSubCategory = (id) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/sub-category/delete`,
            data: { sub_cat_id: id }
        }

        POST(payload).then(res => {

            setIsLoading(false)
            toast.success(res.data.message)
            getSubCategories()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const onSubmit = (data) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/sub-category/save`,
            data
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            setModalShow(false)
            getSubCategories()

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })
    }

    const handlePageClick = (event) => {

        let items = subCategories

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };

    return(
        <>        

            <h2 className="page_hdng">Chapters</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="3">
                    <div className="assignments_card">
                        <h4>{subCategories.length}</h4>
                        <p>Chapters</p>
                    </div>
                </Col>
            </Row>
               
            <Row className="">
                <Col xl="12" className="mt-3"> 
                    <Row className=" align-items-center">
                        <Col>
                            <Breadcrumb className='mb-0'>
                                <Breadcrumb.Item>
                                    <NavLink to="/school/divisions">Home</NavLink>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item>
                                    <NavLink to={`/school/divisions/${params.division_id}/categories`}>
                                        {Object.keys(catDivision).length > 0 && catDivision.name}
                                    </NavLink>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item>
                                    <NavLink to={`/school/divisions/${params.division_id}/categories/${params.category_id}/sub-categories`}>
                                        {Object.keys(category).length > 0 && category.title}
                                    </NavLink>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        
                        <Col className="col-auto">
                            <div className='d-flex align-items-center'>
                                <InputGroup className="">
                                    <FormControl size='md' placeholder="search" onChange={(event) => getSubCategories(event.target.value)} />
                                        <Button variant='secondary' size='md'>
                                            <img width={20} src={search} />
                                        </Button>
                                </InputGroup>
                                
                                <Button variant='success' size='md' className='px-4 ms-2' onClick={() => handleModalShow('add')}>Add Chapter</Button>
                            </div>
                        </Col>
                    </Row>

                    <div className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th className='text-start'>Sub Categories</th>
                                    <th className='text-center'>Assignments</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                { !isLoading && isNoRecords &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                { !isNoRecords && !isLoading && subCategories.length > 0 &&
                                        currentItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td className="fs-14">{elem.title}</td>
                                                    <td className='fs-14 text-center'>{elem.assignments_count}</td>
                                                    <td className='fs-14 text-center'>
                                                        <span className="badge badge_md" onClick={() => handleModalShow('edit', elem.id)} style={{cursor: 'pointer'}} >
                                                            <img src={mode_edit} />
                                                        </span>
                                                        <span className="badge badge_md" onClick={() => deleteSubCategory(elem.id)} style={{cursor: 'pointer'}} >
                                                            <img src={rd_trash} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </Table>
                        { subCategories.length > 0 &&
                            <PAGINATION allItems={subCategories} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                        }
                    </div>                   
                </Col>

            </Row>

            <Modal backdrop="static" show={modalShow} onHide={handleModalClose} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>{modalTitle}</Modal.Title>
                        <p className='fs-20 fw-600'>For Subject : <span className='text_brand_color2'>{Object.keys(category).length > 0 && category.title}</span></p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Control type="text" name="category_id" {...register('category_id')} defaultValue={params.category_id}  hidden  autoComplete="off"/>
                        <Form.Control type="text" name="sub_cat_id" defaultValue={''} {...register('sub_cat_id')}  hidden  autoComplete="off" />
                        <Form.Group className="mb-3">
                            <Form.Label>Chapter<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="sub_category" onChange={(event) => setValue('sub_category', event.target.value)} {...register('sub_category', { required: "Sub Category is required" })}  autoComplete="off" />

                            {errors.sub_category ?.message && <span className="error">{errors.sub_category ?.message}</span> }

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Teacher/Professor Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="teacher" onChange={(event) => setValue('teacher', event.target.value)} {...register('teacher', { required: "This field is required" })}  autoComplete="off"/>

                            {errors.teacher ?.message && <span className="error">{errors.teacher ?.message}</span> }

                        </Form.Group>
                    <Button className='btn-block' size='lg' variant="success" type="submit" >Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SchoolDivisionsSubCategoriesCtrl;