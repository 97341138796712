import axios from "axios";

const GET = async (payload) => {
    return new Promise(function (resolve, reject) {
        
        const {url, headers} = payload

        let user = localStorage.getItem('user')
        let token = ''

        if(user) {
            user = JSON.parse(user)
            token = user['jwt_token']
        }

        var headerFields = {
            'Content-Type': 'application/json',
            'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
            'x-access-token': token
        }
        if(payload.headers) {
            headerFields = {
                ...headerFields,
                ...payload.headers
            }
        }

        axios.get(url, {
            headers: headerFields,
            withCredentials: true
        })      
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

const POST = (payload)  => {
    return new Promise(function (resolve, reject) {

        const {url, data, headers} = payload

        var dataEncoded = '';
        if(data) {
            dataEncoded = Object.keys(data)
            .map((key) => `${key}=${encodeURIComponent(data[key])}`)
            .join('&');
        }

        let user = localStorage.getItem('user')
        let token = ''

        if(user) {
            user = JSON.parse(user)
            token = user['jwt_token']
        }

        var headerFields = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
            'x-access-token': token
        }

        if(headers) {
            headerFields = {
                ...headerFields,
                ...headers
            }
            if(headers['Content-Type'] === 'multipart/form-data' ) {
                dataEncoded = data
            }
        }

        axios.post(url, dataEncoded, { headers: headerFields, withCredentials: true })   
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

const PATCH = (payload)  => {
    return new Promise(function (resolve, reject) {
        
        const {url, data, headers} = payload

        var dataEncoded = Object.keys(data)
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join('&');

        let user = localStorage.getItem('user')
        let token = ''

        if(user) {
            user = JSON.parse(user)
            token = user['jwt_token']
        }

        var headerFields = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
            'x-access-token': token
        }

        if(headers) {
            headerFields = {
                ...headerFields,
                ...headers
            }
            if(headers['Content-Type'] === 'multipart/form-data' ) {
                dataEncoded = data
            }
        }

        axios.patch(url, dataEncoded, { headers: headerFields, withCredentials: true })   
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })

    });
}

const DELETE = (payload)  => {
    return new Promise(function (resolve, reject) {
        
        const {url, data, headers} = payload

        let user = localStorage.getItem('user')
        let token = ''

        if(user) {
            user = JSON.parse(user)
            token = user['jwt_token']
        }

        var headerFields = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
            'x-access-token': token
        }

        axios.delete(url, { headers: headerFields, data })
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            return reject(err);
        })


    });
}

export { GET, POST, PATCH, DELETE }