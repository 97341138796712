import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Col, Button, Row } from "react-bootstrap";
import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import dummyVideoPoster from '../../assets/images/dummy/dummy_video_img.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../config/variables';
import { GET, POST } from '../../utils/axios.util';

function ViewAssignment(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const assignmentId = params.id

    const [ videoDuration, setVideoDuration ] = useState(null)
    const currentDuration = useRef(0)
    const [ assignment, setAssignment ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ thumbnail, setThumbnail ] = useState(null)

    useEffect(() => {

        if(!assignment) {
            getAssignment()
        }

        return () => {
            if(currentDuration.current > 0) {
                updateRecentlyPlayed()
            }
        }
        
    },[ assignment ]);

    const getAssignment = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/assignments?assignment=${assignmentId}`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data

                console.log(data)
                setAssignment(data)

                if(!data.hasOwnProperty('played_duration_seconds') || !(data['played_duration_seconds'] > 0)) {
                    setThumbnail(data.thumbnail)
                }
                setIsLoading(false)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        })

    }

    const handleLoadedData = (vidObj) => {
        setVideoDuration(vidObj.duration)
        let studentPattern = /student/i;

        if(studentPattern.test(location.pathname)) {
            vidObj.currentTime = assignment.played_duration_seconds
        }
    };

    const timeUpdateHandler = (vidObj) => {
        currentDuration.current = vidObj.currentTime
    }

    const pauseHandler = (vidObj) => {
        updateRecentlyPlayed()
        if(vidObj.currentTime == videoDuration) {
            videoCompleted()
        }
    }

    const updateRecentlyPlayed = () => {

        let studentPattern = /student/i;

        if(studentPattern.test(location.pathname)) {

            let payload = {
                url : `${BACKEND_DOMAIN}/student/recently-played/update`,
                data: {
                    assignment_id: assignmentId,
                    played_duration: currentDuration.current
                }
            }

            POST(payload).then(res => {

            }).catch(err => {

                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }

                console.log(err)
            })
        }
    }

    const videoCompleted = () => {

        let studentPattern = /student/i;

        if(studentPattern.test(location.pathname)) {

            let payload = {
                url : `${BACKEND_DOMAIN}/student/assignment-complete`,
                data: {
                    assignment_id: assignmentId,
                    played_duration: currentDuration.current
                }
            }

            POST(payload).then(res => {

            }).catch(err => {

                console.log(err)
                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
            })
        }

    }

    return(
        <>
            <h2 className="page_hdng">Home</h2>
            <div className='my_card chat_contact mt-4'>
                { isLoading && !assignment &&
                    <div className='text-center subscriptions_packages_name no_records_loading'>
                        <h5>Loading...</h5>
                    </div>
                }
                <Row className="justify-content-between align-items-center">
                    { assignment &&
                        <Col className="col-auto">
                            <h5 className="fs-20 fw-600 m-0">{assignment.title}</h5>
                        </Col>
                    }
                    <Col className="col-auto">
                        <Button variant="light" onClick={() => navigate(-1) } >Back</Button>
                    </Col>
                </Row>
                <Row className='mt-3 justify-content-center'>
                    { assignment &&
                         <Col sm="12" lg="9"> 
                            <Player 
                                playsInline 
                                // poster={thumbnail} 
                                src={assignment.file_path}
                                onLoadedData={(e) => {
                                    handleLoadedData(e.target);
                                }}
                                onTimeUpdate={(e) => {
                                    timeUpdateHandler(e.target);
                                }} 
                                onPause={(e) => {
                                    pauseHandler(e.target);
                                }} 
                            />
                        </Col>
                    }
                    { !isLoading && !assignment && 
                        <div className='text-center subscriptions_packages_name no_records_loading' >
                            <h5>No Records Found!</h5>
                        </div>
                    }

                    {/* <Col sm="12"> 
                        <div className='my_card_brand_dark_color mt-3'>
                            <h5 className=' fs-16 text_white_opacity3 text-capitalize'>transcript</h5>
                            <p className='fs-16 m-0 '><span className='text_brand_color2 text-capitalize'>a written or printed version of material originally presented</span> in another medium. a word-for-word transcript comes with each tap a written or printed version of material originally presented in another medium. a word-for-word transcript comes with each tap a written or printed version of material originally presented in another medium. a word-for-word transcript comes with each tap</p>
                        </div>
                    </Col> */}

                </Row>
            </div>
        </>
    )
}

export default ViewAssignment;