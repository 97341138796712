import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumb, Button, Nav, Col, Form, FormControl, InputGroup, Modal, Row, Tab, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Moment from 'react-moment';
import moment from 'moment-timezone';
import docs_play from '../../../../assets/images/icons/docs_play.png';
import grn_excla from '../../../../assets/images/icons/grn_excla.png';
import mode_edit from '../../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../../assets/images/icons/rd_trash.png';
import eye from '../../../../assets/images/icons/eye.svg';
import eye_slash from '../../../../assets/images/icons/eye_slash.svg';
import calendar from '../../../../assets/images/icons/calendar.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_DOMAIN } from '../../../../config/variables';
import { GET, POST } from '../../../../utils/axios.util';
import PAGINATION from '../../../../components/common/pagination';
import NoAccess from '../../../../components/common/noaccess'
import useMenuAccess from '../../../../hooks/useMenuAccess';

function OrganizationUsers() {

    const [isLoading, setIsLoading] = useState(false)
    const [isNoRecords, setIsNoRecords] = useState(false)
    const [allCheckedState, setAllCheckedState] = useState(false)
    const [checkedState, setCheckedState] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const [users, setUsers] = useState({})
    const navigate = useNavigate()
    const params = useParams();
    const location = useLocation();
    const orgnz_id = params.id;
    const [searchParams, setSearchParams] = useSearchParams();
    const [organization, setOrganization] = useState({})
    const orgnz_name = location.state?.orgnz_name;

    const studentsHeaders = ["Firstname", "Lastname", "Email", "Password", "Division"]
    const generalHeaders = ["Firstname", "Lastname", "Email", "Password"]
    const [csvData, setCsvData] = useState([])

    const itemsPerPage = 10
    const [studentsOffset, setStudentsOffset] = useState(0);
    const [adminsOffset, setAdminsOffset] = useState(0);
    const [subAdminsOffset, setSubAdminsOffset] = useState(0);
    const [coordinatorsOffset, setCoordinatorsOffset] = useState(0);
    const [interpretersOffset, setInterpretersOffset] = useState(0);
    const [studentsItems, setStudentsItems] = useState([])
    const [adminsItems, setAdminsItems] = useState([])
    const [subAdminsItems, setSubAdminsItems] = useState([])
    const [coordinatorsItems, setCoordinatorsItems] = useState([])
    const [interpretersItems, setInterpretersItems] = useState([])
    const [studentsPageCount, setStudentsPageCount] = useState(0);
    const [adminsPageCount, setAdminsPageCount] = useState(0);
    const [subAdminsPageCount, setSubAdminsPageCount] = useState(0);
    const [coordinatorsPageCount, setCoordinatorsPageCount] = useState(0);
    const [interpretersPageCount, setInterpretersPageCount] = useState(0);
    const [deleteUserId, setDeleteUserId] = useState('')
    const [deleteUserRole, setDeleteUserRole] = useState('')
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [activePageNumber, setActivePageNumber] = useState('');

    const handleDeleteModalClose = () => setDeleteModalShow(false)

    var isUsersInitialized = useRef(false)

    /* Maintain tab state code starts here */

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')
    let defaultTabValue = 'sub_admins'
    let initialCreatePath = 'sub-admin/create'
    let initialAddText = 'Add Sub Admin'
    let currentPageNumValue = 0
    let initialPageValue = 0

    if (urlTab == 'interpreters') {
        defaultTabValue = 'interpreters'
    }
    if (urlTab == 'coordinators') {
        defaultTabValue = 'coordinators'
    }
    if (urlTab == 'students') {
        defaultTabValue = 'students'
    }

    if (defaultTabValue == 'interpreters') {
        initialCreatePath = 'interpreter/create'
        initialAddText = 'Add Interpreter'
    }
    if (defaultTabValue == 'coordinators') {
        initialCreatePath = 'coordinator/create'
        initialAddText = 'Add Coordinator'
    }
    if (defaultTabValue == 'students') {
        initialCreatePath = 'student/create'
        initialAddText = 'Add Student'
    }

    if (urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [currentPageNum, setCurrentPageNum] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    const [defaultTab, setDefaultTab] = useState(defaultTabValue);
    const [currentTabState, setCurrentTabState] = useState(defaultTabValue);
    const [createPath, setCreatePath] = useState(initialCreatePath)
    const [addText, setAddText] = useState(initialAddText);
    const menuAccess = useMenuAccess();

    /* Maintain tab state code ends here */

    useEffect(() => {

        if (Object.keys(users).length === 0 && !isUsersInitialized.current) {
            getUsers()
        }

        if (Object.keys(organization).length === 0) {
            getOrganization()
        }

    }, [users, organization]);

    const getOrganization = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/organizations/${params.id}/view`
        }

        GET(payload).then(res => {

            const { data } = res.data
            setOrganization(data)

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })

    }

    const getUsers = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/organizations/${params.id}/users/list`,
            data: orgnz_id
        }

        GET(payload).then(res => {

            const { data } = res.data

            const studentsEndOffset = studentsOffset + itemsPerPage;
            const coordinatorsEndOffset = coordinatorsOffset + itemsPerPage;
            const adminsEndOffset = adminsOffset + itemsPerPage;
            const subAdminsEndOffset = subAdminsOffset + itemsPerPage;
            const interpretersEndOffset = interpretersOffset + itemsPerPage;
            let studentsChunk = []
            let coordinatorsChunk = []
            let interpretersChunk = []
            let adminsChunk = []
            let subAdminsChunk = []
            let studentsPageCount = Math.ceil(data.students.length / itemsPerPage);
            let coordinatorsPageCount = Math.ceil(data.coordinators.length / itemsPerPage);
            let interpretersPageCount = Math.ceil(data.interpreters.length / itemsPerPage);
            let adminsPageCount = Math.ceil(data.admins.length / itemsPerPage);
            let subAdminsPageCount = Math.ceil(data.sub_admins.length / itemsPerPage);

            if (data.students.length > 0) {
                studentsChunk = data.students.slice(studentsOffset, studentsEndOffset)
            }
            if (data.coordinators.length > 0) {
                coordinatorsChunk = data.coordinators.slice(coordinatorsOffset, coordinatorsEndOffset)
            }
            if (data.interpreters.length > 0) {
                interpretersChunk = data.interpreters.slice(interpretersOffset, interpretersEndOffset)
            }
            if (data.sub_admins.length > 0) {
                subAdminsChunk = data.sub_admins.slice(subAdminsOffset, subAdminsEndOffset)
                initializeCheckBox(subAdminsChunk)
            }
            // if(data.admins.length > 0) {
            //     adminsChunk = data.admins.slice(adminsOffset, adminsEndOffset)
            //     initializeCheckBox(adminsChunk)
            // }

            // setIsNoRecords(false)
            // setIsLoading(false)
            // setUsers(data)

            setStudentsPageCount(studentsPageCount)
            setCoordinatorsPageCount(coordinatorsPageCount)
            setAdminsPageCount(adminsPageCount)
            setSubAdminsPageCount(subAdminsPageCount)
            setInterpretersPageCount(interpretersPageCount)
            setStudentsItems(studentsChunk)
            setCoordinatorsItems(coordinatorsChunk)
            setInterpretersItems(interpretersChunk)
            setAdminsItems(adminsChunk)
            setSubAdminsItems(subAdminsChunk)

            setIsNoRecords(false)
            setIsLoading(false)
            setUsers(data)
            isUsersInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if (err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const deleteUser = (event) => {

        let user_id = deleteUserId

        let data = {
            user_id
        }

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/users/delete`,
            data
        }

        POST(payload).then(res => {
            setDeleteModalShow(false)
            toast.success(res.data.message)
            getUsers()
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setDeleteModalShow(false)
            toast.error(err.response.data.message)
        })
    }

    const togglePassword = (eLId) => {
        let element = document.getElementById(`password_${eLId}`)
        let eyecon = document.getElementById(`eyecon_${eLId}`)
        if (element.type == 'text') {
            element.setAttribute('type', 'password')
        } else {
            element.setAttribute('type', 'text')
        }

        if (eyecon.getAttribute('src') == eye) {
            eyecon.setAttribute('src', eye_slash)
        } else {
            eyecon.setAttribute('src', eye)
        }
    }

    const handlePageClick = (event, currentTab) => {

        let items = []

        if (currentTab == 'students') {
            items = users.students
        }
        if (currentTab == 'coordinators') {
            items = users.coordinators
        }
        if (currentTab == 'subAdmins') {
            items = users.sub_admins
        }
        if (currentTab == 'admins') {
            items = users.admins
        }
        if (currentTab == 'interpreters') {
            items = users.interpreters
        }

        const newOffset = (event.selected * itemsPerPage) % items.length;

        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        let checkMap = {}
        for (let x of chunk) {
            checkMap[x.id] = false
        }
        setCheckedState(prevState => {
            return {
                ...checkMap
            }
        })
        setAllCheckedState(false)

        if (currentTab == 'students') {
            setStudentsPageCount(pageCount)
            setStudentsItems(chunk)
            setStudentsOffset(newOffset);
        }
        if (currentTab == 'coordinators') {
            setCoordinatorsPageCount(pageCount)
            setCoordinatorsItems(chunk)
            setCoordinatorsOffset(newOffset);
        }
        if (currentTab == 'subAdmins') {
            setSubAdminsPageCount(pageCount)
            setSubAdminsItems(chunk)
            setSubAdminsOffset(newOffset);
        }
        if (currentTab == 'admins') {
            setAdminsPageCount(pageCount)
            setAdminsItems(chunk)
            setAdminsOffset(newOffset);
        }
        if (currentTab == 'interpreters') {
            setInterpretersPageCount(pageCount)
            setInterpretersItems(chunk)
            setInterpretersOffset(newOffset);
        }

        if (chunk.length > 0) {
            initializeCheckBox(chunk)
        }

    };

    const initializeCheckBox = (tempItems) => {

        if (tempItems.length > 0) {
            let checkMap = {}
            for (let x of tempItems) {
                checkMap[x.id] = false
            }
            setCheckedState(prevState => {
                return {
                    ...checkMap
                }
            })
        }
        setIsDisabled(true)

    }

    const handleAllCheckBox = (event) => {

        let inverseValue = !allCheckedState

        let tempCheckstate = {}
        for (let y in checkedState) {
            if (inverseValue == true) {
                tempCheckstate[y] = true
            } else {
                tempCheckstate[y] = false
            }

        }

        let checkedData = Object.keys(tempCheckstate).filter(key => tempCheckstate[key] === true)

        if (checkedData.length > 0) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }

        setAllCheckedState(inverseValue)
        setCheckedState(prevState => {
            return {
                ...prevState,
                ...tempCheckstate
            }
        });

    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        let tempCheckstateArr = []
        for (let y in checkedState) {
            if (y == checkId) {
                tempCheckstate[y] = !checkedState[y]
            }
        }
        let dummyCheck = {
            ...checkedState,
            ...tempCheckstate
        }

        for (let z in dummyCheck) {
            tempCheckstateArr.push(dummyCheck[z])
        }
        if (tempCheckstateArr.every(element => element === true)) {
            setAllCheckedState(true)
        } else {
            setAllCheckedState(false)
        }

        if (tempCheckstateArr.some(element => element === true)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }

        setCheckedState(prevState => {
            return {
                ...prevState,
                ...tempCheckstate
            }
        });
    }

    const exportSelected = () => {

        let csvHeaders = []
        let tempUsers = []
        if (currentTabState == 'students') {
            csvHeaders = studentsHeaders
            tempUsers = studentsItems
        } else {
            csvHeaders = generalHeaders
        }
        if (currentTabState == 'coordinators') {
            tempUsers = coordinatorsItems
        }
        if (currentTabState == 'interpreters') {
            tempUsers = interpretersItems
        }
        if (currentTabState == 'sub_admins') {
            tempUsers = subAdminsItems
        }
        if (currentTabState == 'admins') {
            tempUsers = adminsItems
        }
        let tempCsvData = [[...csvHeaders]]
        for (let x in checkedState) {
            if (checkedState[x] == true) {
                let filterUsers = tempUsers.filter(elem => elem.id == x)
                let filteredUser = []

                if (filterUsers.length > 0) {

                    if (currentTabState == 'students') {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password'], filterUsers[0]['division_name']]
                    } else {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password']]
                    }
                    tempCsvData = [...tempCsvData, filteredUser]

                }
            }
        }

        setCsvData(tempCsvData)

    }

    const exportAll = () => {

        let csvHeaders = []
        let tempUsers = []
        if (currentTabState == 'students') {
            csvHeaders = studentsHeaders
            tempUsers = users.students
        } else {
            csvHeaders = generalHeaders
        }
        if (currentTabState == 'admins') {
            tempUsers = users.admins
        }
        if (currentTabState == 'coordinators') {
            tempUsers = users.coordinators
        }
        if (currentTabState == 'interpreters') {
            tempUsers = users.interpreters
        }
        if (currentTabState == 'sub_admins') {
            tempUsers = users.sub_admins
        }

        let tempCsvData = [[...csvHeaders]]
        for (let x of tempUsers) {
            let filteredUser = []
            if (currentTabState == 'students') {
                filteredUser = [x['first_name'], x['last_name'], x['email'], x['password'], x['division_name']]
            } else {
                filteredUser = [x['first_name'], x['last_name'], x['email'], x['password']]
            }
            tempCsvData = [...tempCsvData, filteredUser]
        }

        setCsvData(tempCsvData)

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
        let tempItems = []
        if (tab == 'admins') {
            setCreatePath('')
            tempItems = adminsItems
            setAddText('Add Admin')
        }
        if (tab == 'students') {
            setCreatePath('student/create')
            tempItems = studentsItems
            setAddText('Add Student')
        }
        if (tab == 'coordinators') {
            setCreatePath('coordinator/create')
            tempItems = coordinatorsItems
            setAddText('Add Coordinator')
        }
        if (tab == 'interpreters') {
            setCreatePath('interpreter/create')
            tempItems = interpretersItems
            setAddText('Add Interpreter')
        }
        if (tab == 'sub_admins') {
            setCreatePath('sub-admin/create')
            tempItems = subAdminsItems
            setAddText('Add Sub Admin')
        }
        setCurrentTabState(tab)
        setAllCheckedState(false)
        initializeCheckBox(tempItems)
    }

    const handleDeleteModalShow = (usr_id, usr_role) => {

        setDeleteUserId(usr_id)
        setDeleteUserRole(usr_role)
        setDeleteModalShow(true)

    }

    const editPage = (elem, type) => {

        let activeItemSelector = ''

        if (currentTabState == 'sub_admins') {
            activeItemSelector = `#fdgdfg-tabpane-sub_admins ul li.page-item.active a`
        }

        if (currentTabState == 'interpreters') {
            activeItemSelector = `#fdgdfg-tabpane-interpreters ul li.page-item.active a`
        }

        if (currentTabState == 'students') {
            activeItemSelector = `#fdgdfg-tabpane-students ul li.page-item.active a`
        }

        if (currentTabState == 'coordinators') {
            activeItemSelector = `#fdgdfg-tabpane-coordinators ul li.page-item.active a`
        }

        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        if (type == 'sub_admins') {
            navigate(`/sub-admin/organizations/${orgnz_id}/users/sub-admin/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`, { state: { orgnz_id } })
        }

        if (type == 'students') {
            navigate(`/sub-admin/organizations/${orgnz_id}/users/student/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }

        if (type == 'coordinators') {
            navigate(`/sub-admin/organizations/${orgnz_id}/users/coordinator/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }

        if (type == 'interpreters') {
            navigate(`/sub-admin/organizations/${orgnz_id}/users/interpreter/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }
    }

    const previousPage = () => {

        let navigateUrl = `/organizations/${orgnz_id}/users`

        if (urlTab) {
            navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}`
        }

        if (urlTab && urlPage) {
            navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}&page=${urlPage}`
        }

        navigate(navigateUrl)
    }


    if (menuAccess && menuAccess.users_menu && menuAccess.users_menu.view_mode === 1) {
        return (
            <>

                <div className='d-flex justify-content-between'>
                    <h2 className="page_hdng">Users </h2>
                    <button className='back_btn' onClick={() => previousPage()} >Back</button>
                </div>
                <Row className="mt-3">
                    {/*  <Col sm="12" md="6" lg="2">
                        <div className="assignments_card">
                            <h4>{(Object.keys(users).length > 0 && users.admins.length > 0) ? users.admins.length : 0}</h4>
                            <p>Admins</p>
                        </div>
                    </Col> */}
                    <Col sm="12" md="6" lg="2">
                        <div className="assignments_card">
                            <h4>{(Object.keys(users).length > 0 && users.sub_admins.length > 0) ? users.sub_admins.length : 0}</h4>
                            <p>Sub Admins</p>
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="2">
                        <div className="assignments_card">
                            <h4>{(Object.keys(users).length > 0 && users.coordinators.length > 0) ? users.coordinators.length : 0}</h4>
                            <p>Coordinators</p>
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="2">
                        <div className="assignments_card">
                            <h4>{(Object.keys(users).length > 0 && users.interpreters.length > 0) ? users.interpreters.length : 0}</h4>
                            <p>Interpreters</p>
                        </div>
                    </Col>
                    <Col sm="12" md="6" lg="2">
                        <div className="assignments_card">
                            <h4>{(Object.keys(users).length > 0 && users.students.length > 0) ? users.students.length : 0}</h4>
                            <p>Students</p>
                        </div>
                    </Col>
                </Row>

                <Row className="">
                    <Col xl="12" className="mt-3">
                        <Row className=" align-items-center">
                            <Col>
                                <Breadcrumb className='mb-0'>
                                    <Breadcrumb.Item>
                                        <NavLink to="/organizations">Home</NavLink>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item>
                                        <NavLink >{Object.keys(organization).length > 0 && organization.name}</NavLink>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="fixed_tab">
                                    <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                                        <Row className='justify-content-between'>
                                            <Col className='col-auto'>
                                                <Nav variant="fixed_pills">
                                                    {/*  <Nav.Item>
                                                    <Nav.Link eventKey="admins" onClick={() => changeTab('admins')} >Admins</Nav.Link>
                                                </Nav.Item> */}
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="sub_admins" onClick={() => changeTab('sub_admins')} >Sub Admins</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="coordinators" onClick={() => changeTab('coordinators')} >Coordinators</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="interpreters" onClick={() => changeTab('interpreters')} >Interpreters</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="students" onClick={() => changeTab('students')} >Students</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>

                                            <Col className='col-auto'>
                                                <Row>
                                                    <Col className='col-auto'>
                                                        <div className='d-flex justify-content-end'>
                                                            <CSVLink
                                                                data={csvData}
                                                                filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                                onClick={() => !isDisabled}
                                                            >
                                                                <Button size='md' className='px-4 ms-2' disabled={isDisabled} onClick={exportSelected} >Export Selected</Button>
                                                            </CSVLink>
                                                            <CSVLink
                                                                data={csvData}
                                                                filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                                onClick={() => (currentTabState == 'admins' ? (Object.keys(users).length > 0 && users.admins.length > 0) : (currentTabState == 'students' ? (Object.keys(users).length > 0 && users.students.length > 0) : (currentTabState == 'coordinators' ? (Object.keys(users).length > 0 && users.coordinators.length > 0) : (currentTabState == 'interpreters' ? (Object.keys(users).length > 0 && users.interpreters.length > 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length > 0 && users.sub_admins.length > 0) : false)))))}
                                                            >
                                                                <Button size='md' className='px-4 ms-2' onClick={exportAll} disabled={(currentTabState == 'admins' ? (Object.keys(users).length === 0 || users.admins.length === 0) : (currentTabState == 'students' ? (Object.keys(users).length === 0 || users.students.length === 0) : (currentTabState == 'coordinators' ? (Object.keys(users).length === 0 || users.coordinators.length === 0) : (currentTabState == 'interpreters' ? (Object.keys(users).length === 0 || users.interpreters.length === 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length === 0 || users.sub_admins.length === 0) : false)))))} >Export All</Button>
                                                            </CSVLink>
                                                            {createPath && menuAccess && menuAccess.users_menu && menuAccess.users_menu.create_mode === 1 &&
                                                                <Button variant='success' size='md' className='px-4 ms-2' onClick={() => navigate(`${createPath}?tab=${currentTabState}&page=${activePageNumber}`)} >{addText}</Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </Col>
                                        </Row>

                                        <Tab.Content>
                                            <Tab.Pane eventKey="sub_admins">
                                                <Table className="">
                                                    <thead>
                                                        <tr>
                                                            <th width="50">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                                    </div>
                                                                ))}
                                                            </th>
                                                            <th colSpan="2">Sub Admin Name</th>
                                                            <th>Added Since</th>
                                                            <th>User Name</th>
                                                            <th>Password</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!isLoading && (Object.keys(users).length > 0 && users.sub_admins.length === 0) &&
                                                            <tr className="text-center">
                                                                <td colSpan="7" className="fs-4">
                                                                    No Records Found!
                                                                </td>
                                                            </tr>
                                                        }
                                                        {!isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.sub_admins.length > 0) &&
                                                            subAdminsItems.map((elem, index) => {
                                                                return (
                                                                    <tr key={elem.id}>
                                                                        <td width="50">
                                                                            {['checkbox'].map((type) => (
                                                                                <div key={`inline-${type}`}>
                                                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                        <td width="50">
                                                                            <img className='tbl_usr_img' src={elem.picture_path} />
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.full_name}</h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {elem.created_at}
                                                                                </Moment>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.email}</h5>
                                                                        </td>
                                                                        <td className="paddsowd">
                                                                            <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                                            <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {/* <span className="badge badge_md">
                                                                        <img src={docs_play} />
                                                                    </span>
                                                                    <span className="badge badge_md">
                                                                        <img src={grn_excla} />
                                                                    </span> */}
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.update_mode === 1 &&
                                                                                <span className="badge badge_md" onClick={() => editPage(elem, 'sub_admins')} style={{ cursor: 'pointer' }} >
                                                                                    <img src={mode_edit} />
                                                                                </span>
                                                                            }
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.delete_mode === 1 &&
                                                                                <NavLink data-role="sub-admin" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                                                    <span className="badge badge_md">
                                                                                        <img src={rd_trash} />
                                                                                    </span>
                                                                                </NavLink>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                                {Object.keys(users).length > 0 && users.sub_admins.length > 0 &&
                                                    <PAGINATION allItems={users.sub_admins} chunkedItems={subAdminsItems} handlePageClick={event => handlePageClick(event, 'sub_admins')} pageCount={subAdminsPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="coordinators">
                                                <Table className="">
                                                    <thead>
                                                        <tr>
                                                            <th width="50">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                                    </div>
                                                                ))}
                                                            </th>
                                                            <th colSpan="2">Coordinators Name</th>
                                                            <th>Added Since</th>
                                                            <th>User Name</th>
                                                            <th>Password</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!isLoading && (Object.keys(users).length > 0 && users.coordinators.length === 0) &&
                                                            <tr className="text-center">
                                                                <td colSpan="7" className="fs-4">
                                                                    No Records Found!
                                                                </td>
                                                            </tr>
                                                        }
                                                        {!isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.coordinators.length > 0) &&
                                                            coordinatorsItems.map((elem, index) => {
                                                                return (
                                                                    <tr key={elem.id}>
                                                                        <td width="50">
                                                                            {['checkbox'].map((type) => (
                                                                                <div key={`inline-${type}`}>
                                                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                        <td width="50">
                                                                            <img className='tbl_usr_img' src={elem.picture_path} />
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.full_name}</h5>
                                                                            <p className='text_light_color'>{elem.division_name}</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {elem.created_at}
                                                                                </Moment>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.email}</h5>
                                                                        </td>
                                                                        <td className="paddsowd">
                                                                            <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                                            <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {/* <span className="badge badge_md">
                                                                        <img src={docs_play} />
                                                                    </span>
                                                                    <span className="badge badge_md">
                                                                        <img src={grn_excla} />
                                                                    </span> */}

                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.update_mode === 1 &&
                                                                                <span className="badge badge_md" onClick={() => editPage(elem, 'coordinators')} style={{ cursor: 'pointer' }} >
                                                                                    <img src={mode_edit} />
                                                                                </span>
                                                                            }
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.delete_mode === 1 &&
                                                                                <NavLink data-role="coordinator" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                                                    <span className="badge badge_md">
                                                                                        <img src={rd_trash} />
                                                                                    </span>
                                                                                </NavLink>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                                {Object.keys(users).length > 0 && users.coordinators.length > 0 &&
                                                    <PAGINATION allItems={users.coordinators} chunkedItems={coordinatorsItems} handlePageClick={event => handlePageClick(event, 'coordinators')} pageCount={coordinatorsPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="interpreters">
                                                <Table className="">
                                                    <thead>
                                                        <tr>
                                                            <th width="50">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                                    </div>
                                                                ))}
                                                            </th>
                                                            <th colSpan="2">Interpreter Name</th>
                                                            <th>Added Since</th>
                                                            <th>User Name</th>
                                                            <th>Password</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!isLoading && (Object.keys(users).length > 0 && users.interpreters.length === 0) &&
                                                            <tr className="text-center">
                                                                <td colSpan="7" className="fs-4">
                                                                    No Records Found!
                                                                </td>
                                                            </tr>
                                                        }
                                                        {!isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.interpreters.length > 0) &&
                                                            interpretersItems.map((elem, index) => {
                                                                return (
                                                                    <tr key={elem.id}>
                                                                        <td width="50">
                                                                            {['checkbox'].map((type) => (
                                                                                <div key={`inline-${type}`}>
                                                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                        <td width="50">
                                                                            <img className='tbl_usr_img' src={elem.picture_path} />
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.full_name}</h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {elem.created_at}
                                                                                </Moment>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.email}</h5>
                                                                        </td>
                                                                        <td className="paddsowd">
                                                                            <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                                            <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {/* <span className="badge badge_md">
                                                                        <img src={docs_play} />
                                                                    </span>
                                                                    <span className="badge badge_md">
                                                                        <img src={grn_excla} />
                                                                    </span> */}
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.update_mode === 1 &&
                                                                                <span className="badge badge_md" onClick={() => editPage(elem, 'interpreters')} style={{ cursor: 'pointer' }} >
                                                                                    <img src={mode_edit} />
                                                                                </span>
                                                                            }
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.delete_mode === 1 &&
                                                                                <NavLink data-role="interpreter" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                                                    <span className="badge badge_md">
                                                                                        <img src={rd_trash} />
                                                                                    </span>
                                                                                </NavLink>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                                {Object.keys(users).length > 0 && users.interpreters.length > 0 &&
                                                    <PAGINATION allItems={users.interpreters} chunkedItems={interpretersItems} handlePageClick={event => handlePageClick(event, 'interpreters')} pageCount={interpretersPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="students">
                                                <Table className="">
                                                    <thead>
                                                        <tr>
                                                            <th width="50">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                                    </div>
                                                                ))}
                                                            </th>
                                                            <th colSpan="2">Students Name</th>
                                                            <th>Added Since</th>
                                                            <th>Parent Name</th>
                                                            <th>Phone</th>
                                                            <th>User Name</th>
                                                            <th>Password</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {isLoading &&
                                                            <div className='text-center subscriptions_packages_name no_records_loading'>
                                                                <h5>Loading...</h5>
                                                            </div>
                                                        }
                                                        {!isLoading && (Object.keys(users).length > 0 && users.students.length === 0) &&
                                                            <tr className="text-center">
                                                                <td colSpan="9" className="fs-4">
                                                                    No Records Found!
                                                                </td>
                                                            </tr>
                                                        }
                                                        {!isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.students.length > 0) &&
                                                            studentsItems.map((elem, index) => {
                                                                return (
                                                                    <tr key={elem.id}>
                                                                        <td width="50">
                                                                            {['checkbox'].map((type) => (
                                                                                <div key={`inline-${type}`}>
                                                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                        <td width="50">
                                                                            <img className='tbl_usr_img' src={elem.picture_path} />
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.full_name}</h5>
                                                                            <p className='text_light_color'>{elem.division_name}</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {elem.created_at}
                                                                                </Moment>
                                                                            </h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.guardian_name}</h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.phone}</h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{elem.email}</h5>
                                                                        </td>
                                                                        <td className="paddsowd">
                                                                            <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                                            <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {/* <span className="badge badge_md">
                                                                        <img src={grn_excla} />
                                                                    </span> */}
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.update_mode === 1 &&
                                                                                <span className="badge badge_md" onClick={() => editPage(elem, 'students')} style={{ cursor: 'pointer' }} >
                                                                                    <img src={mode_edit} />
                                                                                </span>
                                                                            }
                                                                            {menuAccess && menuAccess.users_menu && menuAccess.users_menu.delete_mode === 1 &&
                                                                                <NavLink data-role="student" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                                                    <span className="badge badge_md">
                                                                                        <img src={rd_trash} />
                                                                                    </span>
                                                                                </NavLink>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </Table>
                                                {Object.keys(users).length > 0 && users.students.length > 0 &&
                                                    <PAGINATION allItems={users.students} chunkedItems={studentsItems} handlePageClick={event => handlePageClick(event, 'students')} pageCount={studentsPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                                }
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>Are you sure you want to delete ?</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                            <Button variant="primary" onClick={() => deleteUser()}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else {

        return <NoAccess />
    }
}

export default OrganizationUsers;