import React, { useEffect, useState } from 'react';
import { Form,Table, Col, Button,Row,Tab,Nav, Badge, Modal } from "react-bootstrap";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';

import rd_trash from '../../../assets/images/icons/rd_trash.png';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import Privileged from '../../../HOC/privileged';
import useMenuAccess from '../../../hooks/useMenuAccess';

import { useSelector } from 'react-redux'

function SubAdminTickets(){

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const user = useSelector(state => state.authenticate)

    const itemsPerPage = 10
    const [ pendingOffset, setPendingOffset ] = useState(0);
    const [ progressOffset, setProgressOffset ] = useState(0);
    const [ resolvedOffset, setResolvedOffset ] = useState(0);
    const [ pendingPageCount, setPendingPageCount ] = useState(0);
    const [ progressPageCount, setProgressPageCount ] = useState(0);
    const [ resolvedPageCount, setResolvedPageCount ] = useState(0);
    const [ pendingItems, setPendingItems ] = useState([])
    const [ progressItems, setProgressItems ] = useState([])
    const [ resolvedItems, setResolvedItems ] = useState([])
    const [ activePageNumber, setActivePageNumber ] = useState('');

    const [ tickets, setTickets ] = useState({})
    const [ ticketId, setTicketId ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ replyMessage, setReplyMessage ] = useState(false)
    const [ moreInfoMessage, setMoreInfoMessage ] = useState(false)
    const [ modalText, setModalText ] = useState('')
    const [ modalType, setModalType ] = useState('')
    const handleTicketModalClose = () => setTicketModalShow(false);
    const handleDeleteModalClose = () => setDeleteModalShow(false);
    const handleMessageModalClose = () => setMessageModalShow(false);
    const [ticketModalShow, setTicketModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [messageModalShow, setMessageModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);

    /* Maintain tab state code starts here */
    
    const urlParams = new URLSearchParams(window.location.search);
    let defaultTabValue = 'pending'
    let currentPageNumValue = 0
    let initialPageValue = 0

    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    if(urlTab == 'progress') {
        defaultTabValue = 'progress'
    }

    if(urlTab == 'resolved') {
        defaultTabValue = 'resolved'
    }

    if(urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [ currentPageNum, setCurrentPageNum ] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    // var initialPageNum = initialPageValueRef.current
    const [ defaultTab, setDefaultTab ] = useState(defaultTabValue);
    const [ currentTabState, setCurrentTabState ] = useState(defaultTabValue);
    const menuAccess = useMenuAccess()

    /* Maintain tab state code ends here */

    useEffect(() => {

        if(Object.keys(tickets).length === 0) {
            getTickets()
        }

    },[tickets]);

    const getTickets = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/sub-admin/support/tickets`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data

                console.log(data)

                const pendingEndOffset = pendingOffset + itemsPerPage;
                const progressEndOffset = progressOffset + itemsPerPage;
                const resolvedEndOffset = resolvedOffset + itemsPerPage;
                let pendingChunk = []
                let progressChunk = []
                let resolvedChunk = []
                let pendingPageCount = Math.ceil(data.pending.length / itemsPerPage);
                let progressPageCount = Math.ceil(data.progress.length / itemsPerPage);
                let resolvedPageCount = Math.ceil(data.resolved.length / itemsPerPage);
    
                if(data.pending.length > 0) {
                    pendingChunk = data.pending.slice(pendingOffset, pendingEndOffset)
                }
                if(data.progress.length > 0) {
                    progressChunk = data.progress.slice(progressOffset, progressEndOffset)
                }
                if(data.resolved.length > 0) {
                    resolvedChunk = data.resolved.slice(resolvedOffset, resolvedEndOffset)
                }
    
                setIsNoRecords(false)
                setIsLoading(false)
                
                setPendingPageCount(pendingPageCount)
                setProgressPageCount(progressPageCount)
                setResolvedPageCount(resolvedPageCount)
                setPendingItems(pendingChunk)
                setProgressItems(progressChunk)
                setResolvedItems(resolvedChunk)

                setTickets(data);
                setIsLoading(false)
                setIsNoRecords(false)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 404) {
                setIsLoading(false)
                setIsNoRecords(true)
            }
        })
    }

    const changeStatus = (data) => {

        let URL = `${BACKEND_DOMAIN}/sub-admin/support/tickets/resolve`

        if(moreInfoMessage) {
            URL = `${BACKEND_DOMAIN}/sub-admin/support/tickets/more-info`
        }

        if(modalType == 'close') {
            URL = `${BACKEND_DOMAIN}/sub-admin/support/tickets/close`
        }

        setIsLoading(true)
        let payload = {
            url : URL,
            data: { 
                ticket_id: ticketId,
                message: data.message
            }
        }

        PATCH(payload).then(res => {

            setMessageModalShow(false)
            toast.success(res.data.message)
            setIsLoading(false)
            getTickets()
        }).catch(err => {

            setMessageModalShow(false)
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            console.log(err)
        })
    }

    const deleteTicket = () => {

        let ticket_id = ticketId

        let data = {
            ticket_id
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/sub-admin/support/tickets/delete`,
            data
        }

        POST(payload).then(res => {
            setDeleteModalShow(false)
            toast.success(res.data.message)
            getTickets()
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setDeleteModalShow(false)
            toast.error(err.response.data.message)
        })

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
        setCurrentTabState(tab)
    }

    const openTicketModal = (ticket_id, type) => {
        setTicketId(ticket_id)
        setTicketModalShow(true)
        if(type == 'resolve') {
            setModalText('Are you sure you want to reply and move this ticket to progress ?')
            setModalType('resolve')
        }else {
            setModalText('Are you sure you want to close this ticket ?')
            setModalType('close')
        }
    }

    const openMessageModal = (ticket_id, type) => {
        reset()
        if(type == 'reply') {
            setReplyMessage(true)
            setMoreInfoMessage(false)
        }else {
            setTicketId(ticket_id)
            setReplyMessage(false)
            setMoreInfoMessage(true)
        }
        setTicketModalShow(false)
        setMessageModalShow(true)
    }

    const openCancelModal = (ticket_id) => {
        setTicketId(ticket_id)
        setCancelModalShow(true)
    }

    const cancelTicket = () => {

        let ticket_id = ticketId

        let data = {
            ticket_id
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/support/tickets/cancel`,
            data
        }

        POST(payload).then(res => {
            setCancelModalShow(false)
            toast.success(res.data.message)
            getTickets()
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setCancelModalShow(false)
            toast.error(err.response.data.message)
        })

    }
    
    const openDeleteModal = (ticket_id) => {
        setTicketId(ticket_id)
        setDeleteModalShow(true)
    }

    const handlePageClick = (event, currentTab) => {

        let items = []

        if(currentTab == 'pending') {
            items = tickets.pending
        }
       
        if(currentTab == 'progress') {
            items = tickets.progress
        }

        if(currentTab == 'resolved') {
            items = tickets.resolved
        }

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        if(currentTab == 'pending') {
            setPendingPageCount(pageCount)
            setPendingItems(chunk)
            setPendingOffset(newOffset);
        }
        if(currentTab == 'progress') {
            setProgressPageCount(pageCount)
            setProgressItems(chunk)
            setProgressOffset(newOffset);
        }
        if(currentTab == 'resolved') {
            setResolvedPageCount(pageCount)
            setResolvedItems(chunk)
            setResolvedOffset(newOffset);
        }

    };

    return(
        <>        
            
            <h2 className="page_hdng">Tickets</h2>

            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.pending.length : 0}</h4>
                        <p className='text-capatilize'>pending</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.progress.length : 0}</h4>
                        <p className='text-capatilize'>in progress</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.resolved.length : 0}</h4>
                        <p className='text-capatilize'>resolved</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="fixed_tab">
                        <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                            <Row className="align-items-center justify-content-between">
                                <Col className='col-auto'>
                                    <Nav variant="fixed_pills">
                                    <Nav.Item>
                                            <Nav.Link eventKey="pending" onClick={() => changeTab('pending')}>Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="progress" onClick={() => changeTab('progress')}>In Progress</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="resolved" onClick={() => changeTab('resolved')}>Resolved</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                { user.role === 'sub_admin' && 
                                    <Col className='col-auto'>
                                        <Col className='col-auto'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='success' size='md' className='px-4 ms-2' onClick={() => navigate(`/sub-admin/support/new-ticket`)} >New Ticket</Button>
                                            </div>
                                        </Col>
                                    </Col>
                                }
                            </Row>

                            <Tab.Content>
                                <Tab.Pane eventKey="pending">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { !isLoading && ( isNoRecords || (Object.keys(tickets).length > 0 && tickets.pending.length === 0) ) &&
                                            <tr className="text-center">
                                                <td colSpan="5" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { !isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.pending.length > 0 && 
                                            pendingItems.map((elem, index) => {
                                                return (
                                                    <tr key={elem.id}>
                                                        <td>
                                                            <h5>{elem.ticket_no}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.organization_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.user_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.title}</h5>
                                                        </td>
                                                        <td className="text-center">
                                                            {/* <span className="badge badge_md">
                                                                <img src={docs_play} />
                                                            </span>
                                                            <span className="badge badge_md">
                                                                <img src={grn_excla} />
                                                            </span> */}
                                                            {user.role == 'sub_admin' &&
                                                                <>
                                                                    { elem.status == 'cancelled' &&
                                                                        <Badge bg="brand_color2" className='text_brand_color1'>Cancelled</Badge>
                                                                    }
                                                                    { elem.status == 'active' &&
                                                                        <Badge bg="brand_color2" className='text_brand_color1' onClick={() => openCancelModal(elem.id)} style={{cursor: 'pointer'}}>Cancel</Badge>
                                                                    }
                                                                </>
                                                            }
                                                            {user.role == 'sub_super_admin' &&
                                                                <>
                                                                    { menuAccess && menuAccess.resolve === 1 &&
                                                                        <Badge bg="brand_color2" className='text_brand_color1' onClick={() => openTicketModal(elem.id, 'resolve')} style={{cursor: 'pointer'}}>Reply and start resolving</Badge>
                                                                    }
                                                                    { menuAccess && menuAccess.resolve === 1 && menuAccess.delete_mode === 1 && 
                                                                        <>
                                                                            &nbsp;|&nbsp;
                                                                        </>
                                                                    }
                                                                    { menuAccess && menuAccess.delete_mode === 1 && 
                                                                        <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.id)} >
                                                                            <span className="badge badge_md">
                                                                                <img src={rd_trash} />
                                                                            </span>
                                                                        </NavLink>
                                                                    }
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    { Object.keys(tickets).length > 0 && tickets.pending.length > 0 &&
                                        <PAGINATION allItems={tickets.pending} chunkedItems={pendingItems} handlePageClick={event => handlePageClick(event, 'pending')} pageCount={pendingPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="progress">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                {user.role == 'sub_super_admin' && 
                                                    <th className="text-center">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { !isLoading && ( isNoRecords || (Object.keys(tickets).length > 0 && tickets.progress.length === 0) ) &&
                                            <tr className="text-center">
                                                <td colSpan="5" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { !isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.progress.length > 0 && 
                                            progressItems.map((elem, index) => {
                                                return (
                                                    <tr key={elem.id}>
                                                        <td>
                                                            <h5>{elem.ticket_no}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.organization_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.user_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.title}</h5>
                                                        </td>
                                                        {user.role == 'sub_super_admin' && 
                                                            <td className="text-center">
                                                                {/* <span className="badge badge_md">
                                                                    <img src={docs_play} />
                                                                </span>
                                                                <span className="badge badge_md">
                                                                    <img src={grn_excla} />
                                                                </span> */}

                                                                { menuAccess && menuAccess.resolve === 1 &&
                                                                    <>
                                                                        <Button variant='dark' size='sm' onClick={() => openMessageModal(elem.id, 'more')} >Request More Information
                                                                        </Button>&nbsp;
                                                                        <Badge bg="brand_color2" className='text_brand_color1' onClick={() => openTicketModal(elem.id, 'close')} style={{cursor: 'pointer'}}>Close</Badge>
                                                                    </>
                                                                }
                                                                { menuAccess && menuAccess.resolve === 1 && menuAccess.delete_mode === 1 &&
                                                                    <>
                                                                        &nbsp;|&nbsp;
                                                                    </>
                                                                }
                                                                { menuAccess && menuAccess.resolve === 1 && menuAccess.delete_mode === 1 &&
                                                                    <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.id)} >
                                                                        <span className="badge badge_md">
                                                                            <img src={rd_trash} />
                                                                        </span>
                                                                    </NavLink>
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                )}
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    { Object.keys(tickets).length > 0 && tickets.progress.length > 0 &&
                                        <PAGINATION allItems={tickets.progress} chunkedItems={progressItems} handlePageClick={event => handlePageClick(event, 'progress')} pageCount={progressPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="resolved">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                { user.role == 'sub_super_admin' && 
                                                    <th className="text-center">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { !isLoading && ( isNoRecords || (Object.keys(tickets).length > 0 && tickets.resolved.length === 0) ) &&
                                            <tr className="text-center">
                                                <td colSpan="5" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { !isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.resolved.length > 0 && 
                                            resolvedItems.map((elem, index) => {
                                                return (
                                                    <tr key={elem.id}>
                                                        <td>
                                                            <h5>{elem.ticket_no}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.organization_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.user_name}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>{elem.title}</h5>
                                                        </td>
                                                        { user.role == 'sub_super_admin' &&
                                                            <td className="text-center">
                                                                {/* <span className="badge badge_md">
                                                                    <img src={docs_play} />
                                                                </span>
                                                                <span className="badge badge_md">
                                                                    <img src={grn_excla} />
                                                                </span> */}
                                                                
                                                                { menuAccess && menuAccess.view_mode === 1 &&
                                                                    <Button variant='dark' size='sm' >View
                                                                    </Button>
                                                                }
                                                                { menuAccess && menuAccess.view_mode === 1 && menuAccess.delete_mode === 1 &&
                                                                    <>
                                                                        &nbsp;
                                                                    </>
                                                                }
                                                                { menuAccess && menuAccess.delete_mode === 1 &&
                                                                    <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.id)} >
                                                                        <span className="badge badge_md">
                                                                            <img src={rd_trash} />
                                                                        </span>
                                                                    </NavLink>
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                )}
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    { Object.keys(tickets).length > 0 && tickets.resolved.length > 0 &&
                                        <PAGINATION allItems={tickets.resolved} chunkedItems={resolvedItems} handlePageClick={event => handlePageClick(event, 'resolved')} pageCount={resolvedPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>

                <Modal className="approve-modal" show={ticketModalShow} onHide={handleTicketModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>{modalText}</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={handleTicketModalClose} >NO</Button>
                            <Button variant="primary" onClick={() => openMessageModal(null, 'reply')}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal className="approve-modal" show={messageModalShow} onHide={handleMessageModalClose} centered>
                    <Modal.Header closeButton className='justify-content-center'>
                        <div className='text-center'>
                            <Modal.Title className='fs-35 fw-700'>Reply</Modal.Title>
                        </div>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(changeStatus)}  autoComplete="off" >
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Message<span className='text-danger'>*</span></Form.Label>
                                <Form.Control as="textarea" name="message" {...register('message', { required: "This field is required" })}  autoComplete="off" />

                                {errors.message ?.message && <span className="error">{errors.message ?.message}</span> }
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center'>
                            <Button variant="primary" type="submit" >Submit</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>Are you sure you want to delete ?</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                            <Button variant="primary" onClick={() => deleteTicket()}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal className="approve-modal" show={cancelModalShow} onHide={() => setCancelModalShow(false)} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>Are you sure you want to cancel this Ticket ?</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={() => setCancelModalShow(false)} >NO</Button>
                            <Button variant="primary" onClick={() => cancelTicket()}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </Row>
        </>
    )
}

export default Privileged(SubAdminTickets);