import React from "react";
import { useSelector } from 'react-redux'
import Chat from '../../../components/common/chat'
import SubAdminChat from './chat'
import SubAdminSupportChat from './supportChat'
import { useParams } from "react-router-dom";

function ChatLayout(props) {

    const user = useSelector(state => state.authenticate)
    const { id } = useParams()

    if (true) {
        if (id) {
            return <SubAdminSupportChat {...props} />
        } else {
            return <SubAdminChat {...props} />
        }
    } else {

        return <Chat {...props} />
    }

}

export default ChatLayout;