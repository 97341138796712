import React, { useEffect, useState } from "react";
import {Col,Nav, Row, Tab } from "react-bootstrap";
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET } from '../../../utils/axios.util';

function StudentLibraryCtrl(props) {

    const user = props.user
    const [ categories, setCategories ] = useState([])
    const [ subCategories, setSubCategories ] = useState([])
    const [ filteredSubCategories, setFilteredSubCategories ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)

    useEffect(() => {

        if(categories.length === 0) {
            getAssignments()
        }
        
    },[categories]);

    const getAssignments = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/student/library`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setCategories(data.categories)
            setSubCategories(data.sub_categories)
            if(data.categories.length > 0) {
                if(data.sub_categories.length > 0 ) {
                    let firstCatId = data.categories[0]['id']

                    filterSubCat(firstCatId, data.sub_categories)
                }
            }
            setIsLoading(false)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const filterSubCat = (id, subCats) => {

        let filterSub = subCats.filter(item => {
            return id == item.category_id
        })

        let minSize = 2;
        let startValue = 0

        function leading_zero(num) {
            let newNum = num.toString();
            
            if(newNum.length < minSize) {
                newNum = '0' + newNum;
            }
            return newNum;
        }

        for(let x of filterSub) {
            x['serial_number'] = leading_zero(startValue + 1)
            startValue++
        }
        
        setFilteredSubCategories(filterSub)
    }

    return(
        <>

            <h2 className="page_hdng">Library</h2>
            { !user.is_content_allowed && 
                <Row>
                    <Col>
                        <div className="my_card mt-3 text-center">
                        You do not have access to this feature. Contact your administration for further information.
                        </div>
                    </Col>
                </Row>
            }
            { user.is_content_allowed && isLoading && 
                <div className='text-center subscriptions_packages_name no_records_loading'>
                    <h5>Loading...</h5>
                </div>
            }
            { user.is_content_allowed && isNoRecords && 
                <Row>
                    <Col>
                        <div className="my_card mt-3 text-center">
                            No Records Found!
                        </div>
                    </Col>
                </Row>
            }
            { user.is_content_allowed && categories.length > 0 &&
                <Row>
                    <Col>
                        <div className="my_card mt-3">
                            <Tab.Container defaultActiveKey={categories[0]['id']}>
                                <Nav variant="brand_outline_pills">
                                { categories.map((elem, index) => {
                                    return (
                                        <Nav.Item key={elem.id} onClick={() => filterSubCat(elem.id, subCategories)}>
                                            <Nav.Link eventKey={elem.id} className="text-capitalize">{elem.title}</Nav.Link>
                                        </Nav.Item>
                                        )
                                    })
                                }
                                </Nav>
                                <Tab.Content>
                                    <h5 className="fs-18 fw-400 text_white_color_half">Select Chapter</h5>
                                    { categories.map((elem, index) => {
                                        return (
                                            <Tab.Pane eventKey={elem.id} key={elem.id} >
                                                <div className="library_list">
                                                    <Row>
                                                    { filteredSubCategories.length === 0 && 
                                                        <Col sm="12" lg="12">
                                                            <div className="library_list_card mt-3 d=flex justify-content-center" >
                                                                No Records Found!
                                                            </div>
                                                        </Col>
                                                    }
                                                    { filteredSubCategories.length > 0 &&

                                                        filteredSubCategories.map((elem, index) => {

                                                            return (
                                                                    <Col sm="12" lg="6" key={elem.id}>
                                                                        <NavLink to={elem.title} className="library_list_card mt-3" state={{ sub_cat_id: elem.id }} >
                                                                            <div className="my_card text-center px-4">
                                                                                <h2 className="fs-24 fw-700 text_brand_color2">{elem.serial_number}</h2>
                                                                                <p className="m-0 fs-12 fw-500">{elem.duration}</p>
                                                                            </div>
                                                                            <div className="ms-3">
                                                                                <h5 className="fs-14 fw-600">{elem.title}</h5>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="me-3 d-flex align-items-center">
                                                                                        <img className="me-2" width="20" src={ntpd_arrow} />
                                                                                        <p className="m-0 fs-12 fw-500">{elem.documents_count}</p>
                                                                                    </div>
                                                                                    <div className="me-3 d-flex align-items-center">
                                                                                        <img className="me-2" width="20" src={mic_arrow} />
                                                                                        <p className="m-0 fs-12 fw-500">{elem.audios_count}</p>
                                                                                    </div>
                                                                                    <div className="me-3 d-flex align-items-center">
                                                                                        <img className="me-2" width="20" src={video_arrow} />
                                                                                        <p className="m-0 fs-12 fw-500">{elem.videos_count}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Col>
                                                                )}
                                                            )
                                                    }
                                                        
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            )
                                        })
                                    }
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            }
        
        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate
    }
}
  
export default connect(mapStateToProps, null)(StudentLibraryCtrl);