import React, { useState } from "react";
import  { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import '../../assets/css/_Auth.scss';

function ChangePassword(props) {

    const { register, handleSubmit, clearErrors, getValues, setError, reset, formState: { errors } } = useForm();

    const [ passwordConditions, setPasswordConditions ] = useState([
        {
            text: 'Password must contain atleast one Capital letter',
            isChecked: false
        },
        {
            text: 'Password must contain atleast one small letter',
            isChecked: false
        },
        {
            text: 'Password must contain atleast one number',
            isChecked: false
        },
        {
            text: 'Password must contain atleast one special character',
            isChecked: false
        },
        {
            text: 'Password must contain atleast 8 characters',
            isChecked: false
        }
    ])

    const validatePassword = (e) => {

        clearErrors('new_password')

        let value = e.target.value

        let valueLength = e.target.value.length

        let newConditions = [ ...passwordConditions ]

        let upperCasePattern = /(?=.*[A-Z])/
        let lowerCasePattern = /(?=.*[a-z])/
        let numberPattern = /(?=.*\d)/
        let specialCharPattern = /(?=.*\W)/

        if(upperCasePattern.test(value)) {
            newConditions[0]['isChecked'] = true
        }else {
            newConditions[0]['isChecked'] = false
        }

        if(lowerCasePattern.test(value)) {
            newConditions[1]['isChecked'] = true
        }else {
            newConditions[1]['isChecked'] = false
        }

        if(numberPattern.test(value)) {
            newConditions[2]['isChecked'] = true
        }else {
            newConditions[2]['isChecked'] = false
        }

        if(specialCharPattern.test(value)) {
            newConditions[3]['isChecked'] = true
        }else {
            newConditions[3]['isChecked'] = false
        }

        if(valueLength >= 8) {
            newConditions[4]['isChecked'] = true
        }else {
            newConditions[4]['isChecked'] = false
        }

        setPasswordConditions(newConditions)
    }

    const closeModal = () => {
        reset()
        props.closeChangePasswordModalShow()
    }

    const onSubmit = (data) => {

        let errorFilter = passwordConditions.filter(item => {
            return item.isChecked === false
        })

        if(errorFilter.length > 0) {
            setError("new_password", {
                type: "manual",
                message: "Password must pass all the conditions!"
            });
        }else {
            props.submitPassword(data)
        }

    }

    return(
    <>
        <Modal className="approve-modal" show={props.changePasswordModalShow} onHide={closeModal} backdrop="static" keyboard={false} centered>
            <Modal.Header className='justify-content-center'>
                <div className='text-center'>
                    <Modal.Title className='fs-35 fw-700'>Change Password</Modal.Title>
                </div>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                <Modal.Body id="change-password">
                    <Form.Group className="mb-3">
                        <Form.Label>New Password<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="text" name="new_password" {...register('new_password', { required: "This field is required" })} onChange={(event) => validatePassword(event)} autoComplete="off"/>

                        {errors.new_password ?.message && <span className="error">{errors.new_password ?.message}</span> }

                        { passwordConditions.map((elem, index) => {

                            return (
                                <p>
                                    <div className="d-flex align-items-center">
                                        {['checkbox'].map((type) => (
                                            <div key={`inline-${type}`}>
                                                <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} checked={elem.isChecked} />
                                            </div>
                                        ))}&nbsp;
                                        {elem.text}
                                    </div>
                                </p>
                                )
                            })
                        }
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirm password<span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="text" name="password" {...register('password', { required: "This field is required",
                        validate: (value) => value === getValues("new_password") || "Passwords do not match"
                        })} autoComplete="new-password"/>

                        {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="danger" onClick={props.closeChangePasswordModalShow} >Cancel</Button>
                    <Button variant="primary" type="submit" >Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
    )
}
export default ChangePassword;