const initialState = {
    unread: 0,
    users: {}
}

const chatReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE':

            if(Object.keys(action.payload.users).length > 0) {

                console.log('redux',action.payload)

                let obj = action.payload.users
                let user = action.payload.currentUser
                var count = 0

                for(let key in obj) {

                    for(let x of obj[key]) {
                        if((user.role != x['role'] || user.id != x['user_id'] ) &&  x['is_seen'] === 0) {
                            count += 1
                        }

                    }
                    
                }
            }
            return {
                unread: count,
                users: action.payload.users
            }
        default: return state
    }
}

export default chatReducer