import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from "react-bootstrap";

import play_icon from '../../assets/images/icons/play.svg';

function ViewAssignmentModal(props) {

    const [ state, setState ] = useState(null)

    useEffect(() => {

        setState(props.state)

    },[ props ]);

    return(
        <>
            { state && 
                <Modal {...props} keyboard={false} centered >
                    <Modal.Header closeButton className='justify-content-center'>
                        <div className='w-100'>
                            <Modal.Title className=''>
                                <p className='fs-20 fw-600 m-0'>Request</p>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='my_card bg-added_mob_color'>
                            <Table className='table-borderless assignments_table'>
                                <tbody>
                                    <tr>
                                        <th>Assignments Name</th>
                                        <th>Category</th>
                                        <th>Class</th>
                                    </tr>
                                    <tr>
                                        <td>{state.title}</td>
                                        <td>{state.category}</td>
                                        <td>{state.division}</td>
                                    </tr>

                                    <tr>
                                        <th>Content Type</th>
                                        <th colSpan="2">URL/File</th>
                                    </tr>
                                    <tr>
                                        <td className="text-capitalize">{state.type}</td>
                                        <td colSpan="2">
                                            { state.filename && 
                                                <Button variant='URL_File'>
                                                    <img className='me-2' src={play_icon} />
                                                    <span>{state.filename}</span>
                                                </Button>
                                            }
                                            { !state.filename && 
                                                <p>{state.url}</p>
                                            }
                                        </td>
                                    </tr>

                                    { state.duration && 
                                        <>
                                            <tr>
                                                <th colSpan="3">Duration</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">{state.duration}</td>
                                            </tr>
                                        </>
                                    }
                                    
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default ViewAssignmentModal;