import React from "react";
import  {Button , Col, Form, Row} from 'react-bootstrap';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import Brand from '../../assets/images/logo.png';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/_Auth.scss';

import { BACKEND_DOMAIN } from '../../config/variables';
import { POST } from '../../utils/axios.util';

function ForgotPassword() {

    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (data) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/reset-password`,
            data
        }

        POST(payload).then(res => {

            toast.success(res.data.message)

            navigate('/')
            
        }).catch(error => {

            // Session Logout Code
            if(error.response && error.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            const { data } = error.response.data
            let serverErrors = data
            serverErrors.forEach(errItem => {
                setError(errItem.field, {
                    type: "server",
                    message: errItem.message,
                });
            });
        })

    }

    return(
    <>
        <div className="auth_container">
            <div className="auth_bg">
                <div className="auth_bg_content">
                    <h3>Forgot Password?</h3>
                    <h5>enter your email address in below to reset your password</h5>
                    <Form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                        <Form.Group className="form-group">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" name="email"   {...register("email", 
                                    {
                                        required: "Email is required", 
                                        pattern: { 
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Invalid email address"
                                        }
                                    }
                                )} autoComplete="off" />
                            {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }
                        </Form.Group>

                        <Form.Group className="form-group d-grid">
                            <Button variant="info" type="submit">Send Link</Button>
                        </Form.Group>
                        
                        <Form.Group className="form-group d-grid">
                            <p>Check your mail</p>
                        </Form.Group>
                    </Form>

                </div>
            </div>



            <div className="auth_brand">
                <NavLink className="AuthBackToPageBtn" to="/student">
                    <span className="icon">
                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0379 19.4129C11.3707 19.0801 11.401 18.5592 11.1287 18.1922L11.0379 18.0871L2.95125 10L11.0379 1.91291C11.3707 1.58008 11.401 1.05925 11.1287 0.692236L11.0379 0.587088C10.7051 0.254255 10.1842 0.223997 9.81724 0.496315L9.71209 0.587088L0.962088 9.33709C0.629254 9.66992 0.598997 10.1908 0.871315 10.5578L0.962088 10.6629L9.71209 19.4129C10.0782 19.779 10.6718 19.779 11.0379 19.4129Z" fill="white"/>
                        </svg>
                    </span>
                    <span className="text">Back</span>
                </NavLink>
                <div className="auth_brand_bg">
                    <NavLink>
                        <img src={Brand} />
                    </NavLink>
                </div>
            </div>
        </div>
    </>
    )
}
export default ForgotPassword;