import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Col,
  Button,
  Row,
  Form,
  FloatingLabel,
  ListGroup,
  ToggleButton,
} from "react-bootstrap";
import calendar from "../../assets/images/icons/calendar.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

import { TIME_ZONE, BACKEND_DOMAIN } from "../../config/variables";
import { GET, POST } from "../../utils/axios.util";
import Privileged from "../../HOC/privileged";

function AssignInterpreter() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [expectedDate, setExpectedDate] = useState(
    new Date(moment().tz(TIME_ZONE))
  );
  const [displayExpectedDate, setDisplayExpectedDate] = useState(
    moment().tz(TIME_ZONE).format("MM-DD-YYYY")
  );
  const [interpreters, setInterpreters] = useState([]);
  const [assignment, setAssignment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedState, setCheckedState] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedInterpreter, setSelectedInterpreter] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const assignmentId = params.id;

  var paramsRef = useRef();
  var expectedDateRef = paramsRef.current;
  expectedDateRef = moment().tz(TIME_ZONE).format("YYYY-MM-DD");

  useEffect(() => {
    if (!assignment) {
      getAssignment();
    }
    if (interpreters.length === 0) {
      getInterpreters();
    }
  }, [interpreters, assignment, checkedState, isDisabled]);

  const getAssignment = () => {
    setIsLoading(true);
    let payload = {
      url: `${BACKEND_DOMAIN}/assignments?assignment=${assignmentId}`,
    };

    GET(payload)
      .then((res) => {
        const { data } = res.data;

        setIsLoading(false);
        setAssignment(data);
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        setIsLoading(false);
        console.log(err);

        if (err.response.status === 404) {
          navigate(-1);
        }
      });
  };

  const getInterpreters = () => {
    setIsLoading(true);
    let payload = {
      url: `${BACKEND_DOMAIN}/interpreters`,
    };

    GET(payload)
      .then((res) => {
        const { data } = res.data;

        setIsLoading(false);

        let checkedInter = {};

        for (let x of data) {
          checkedInter[x["id"]] = false;
        }

        setCheckedState(checkedInter);
        setInterpreters(data);
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        setIsLoading(false);
        console.log(err);
      });
  };

  const changeSelected = (checkId, data) => {
    // console.log(data);

    let tempCheckstate = {};
    setSelectedInterpreter(data);
    for (let y in checkedState) {
      if (y == checkId) {
        tempCheckstate[y] = !checkedState[y];
      } else {
        tempCheckstate[y] = false;
      }
    }

    let checkedData = Object.keys(tempCheckstate).filter(
      (key) => tempCheckstate[key] === true
    );

    if (checkedData.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setCheckedState((prevState) => {
      return {
        ...prevState,
        ...tempCheckstate,
      };
    });
  };

  const ExpectedDate = forwardRef(({ value, onClick }, ref) => (
    <button
      className="select_date_box my-2"
      onClick={onClick}
      ref={ref}
      style={{ background: "#0b0c0d" }}
    >
      <span>
        <img src={calendar} />
      </span>
      <span>{!Date ? "Expected Date" : value}</span>
    </button>
  ));

  const validateDate = (date) => {
    setExpectedDate(date);
    setDisplayExpectedDate(moment(date).format("MM-DD-YYYY"));
    expectedDateRef = moment(date).format("YYYY-MM-DD");
  };

  const onSubmit = (data) => {
    // console.log("function called");
    
    var selectedFilter = [];

    if (Object.keys(checkedState).length > 0) {
      selectedFilter = Object.keys(checkedState).filter(
        (key) => checkedState[key] === true
      );
    }

    // if (selectedFilter.length > 0 && data.salary) {
      let payload = {
        url: `${BACKEND_DOMAIN}/interpreters/assign`,
        data: {
          assignment_id: assignmentId,
          interpreter_id: selectedFilter[0],
          expected_date: expectedDateRef,
          salary:
            selectedInterpreter.salary_type == "monthly" ? 0 : data.salary,
        },
      };

    //   console.log(payload);
    //   return
      
      POST(payload)
        .then((res) => {
          toast.success(res.data.message);

          let schoolPattern = /school/i;
          let subAdminPattern = /sub-admin/i;

          if (schoolPattern.test(location.pathname)) {
            navigate("/school/interpretations");
          } else if (subAdminPattern.test(location.pathname)) {
            navigate("/sub-admin/interpretations");
          } else {
            navigate("/coordinator/assignments");
          }
        })
        .catch((error) => {
          // Session Logout Code
          if (error.response && error.response.status === 401) {
            toast.error("Session Expired! Logging out...", { autoClose: 2000 });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }

          toast.error(error.response.data.message);
        });
    // }
  };

  return (
    <>
      <h2 className="page_hdng">Assign Interpreter</h2>
      <div className="d-flex align-items-center justify-content-end">
        <Button variant="trans_success" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <div className="my_card chat_contact mt-4">
        <Row>
          <Col>
            <div className="my_card_brand_dark_color mb-3">
              <h4 className="fs-18 fw-600 m-0">
                {assignment && assignment.assignment_title}
              </h4>
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="mx-2">
                <Form.Label>Expected Date</Form.Label>
                <div className="d-flex align-items-center">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={expectedDate}
                    minDate={new Date(moment().tz(TIME_ZONE))}
                    onChange={(date) => validateDate(date)}
                    customInput={<ExpectedDate />}
                  />
                </div>
              </div>
              {selectedInterpreter != null &&
                selectedInterpreter?.salary_type != "monthly" && (
                  <div className="mx-2">
                    <Form.Label>Salary per Hour</Form.Label>
                    <Form.Control
                      type="number"
                      name="salary"
                      className="my-2"
                        //   {...register("salary", {
                        //     required: "Salary is required",
                        //   })}
                      style={{
                        backgroundColor: "#0b0c0d",
                        height: "45px",
                        width: "50px",
                      }}
                      autoComplete="off"
                    />

                    {errors.salary?.message && (
                      <span className="error">{errors.salary?.message}</span>
                    )}
                  </div>
                )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="my_card_brand_dark_color">
                {isLoading && (
                  <div className="text-center subscriptions_packages_name no_records_loading">
                    <h5>Loading...</h5>
                  </div>
                )}

                {!isLoading && interpreters.length === 0 && (
                  <div className="text-center">
                    <h5>No Records Found!</h5>
                  </div>
                )}

                {interpreters.length > 0 && (
                  <>
                    <Row>
                      {/* <Col>
                                        <Row className="g-2">
                                            <Col className="col-auto">
                                                <Button>10th Class</Button>
                                            </Col>
                                            <Col className="col">
                                                <Form.Select size="sm" aria-label="Default select example">
                                                    <option>Subject</option>
                                                    <option value="1">English</option>
                                                    <option value="2">Math</option>
                                                    <option value="3">Hindi</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button className="px-2" variant="secondary">Select All</Button>
                                    </Col> */}
                    </Row>

                    <ListGroup variant="flush" className="mt-2">
                      {interpreters.map((elem, index) => {
                        return (
                          <ListGroup.Item
                            className="contact_list my-2"
                            key={elem.id}
                          >
                            <div>
                              <Row className="align-items-center justify-content-between">
                                <Col>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img
                                        width="50"
                                        className="rounded-circle"
                                        src={elem.picture_path}
                                      />
                                    </div>
                                    <div className="ms-2">
                                      <h4 className="fs-18 fw-600 m-0">
                                        {elem.full_name}
                                      </h4>
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-auto">
                                  <ToggleButton
                                    className="mb-2"
                                    type="checkbox"
                                    variant="success"
                                    value={elem.id}
                                    checked={checkedState[elem.id]}
                                    onClick={() =>
                                      changeSelected(elem.id, elem)
                                    }
                                  >
                                    {checkedState[elem.id] == true
                                      ? "Selected"
                                      : "Select"}
                                  </ToggleButton>
                                </Col>
                              </Row>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </>
                )}
              </div>
            </Col>
            {/* <Col sm="12" lg="8">
                        <div className="my_card_brand_dark_color">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h4>Create Assignments</h4>
                            </div>
                        </div>
                    </Col> */}
          </Row>
          <Form.Group className="form-group d-grid">
            <div className="text-end py-2">
              <Button
                variant="success"
                size="lg"
                disabled={isDisabled}
                type="submit"
              >
                ASSIGN
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default Privileged(AssignInterpreter);
