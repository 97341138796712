import React, { useEffect, useState, useRef, forwardRef } from 'react';
import {Col, Row,ProgressBar, Button, Badge  } from "react-bootstrap";
import clock from '../../../assets/images/icons/clock.png';
import dummy_video1 from '../../../assets/images/dummy/dummy_video_img.png';
import play from '../../../assets/images/icons/play.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET } from '../../../utils/axios.util';

function StudentLatestPlayedCtrl(){

    const [ assignments, setAssignments ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    let navigate = useNavigate() 

    useEffect(() => {
        
        if(assignments.length === 0) {
            getAssignments()
        }

    },[assignments]);


    const getAssignments = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/student/dashboard/latest-played`
        }

        GET(payload).then(res => {

            const { data } = res.data

            setIsLoading(false)
            setAssignments(data)
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
        })

    }

    return(
        <>

            <h2 className="page_hdng">Latest played</h2>
            <Row>
                <Col>
                    <div className="my_card mt-3">
                        <div className="">
                            {/* <Row className="justify-content-end align-items-center">
                                <Col className="col-auto">
                                    <Button variant="light">View all</Button>
                                </Col>
                            </Row> */}
                            
                            <div className="video_card_list">
                                
                                <Row>

                                    { isLoading &&

                                        <Col lg="12" sm="12">
                                            <div className="video_card">
                                                <div className='text-center' >
                                                    <h5>Loading...</h5>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    { !isLoading && assignments.length === 0 &&

                                        <Col lg="12" sm="12">
                                            <div className="video_card">
                                                <div className='text-center' >
                                                    <h5>No Records Found!</h5>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    { assignments.length > 0 && 

                                        assignments.map((elem, index) => {
                                            
                                            return(
                                                
                                                <Col lg="3" sm="6" key={elem.id} onClick={() => navigate(`/student/assignments/${elem.id}`)} style={{cursor: 'pointer'}} >
                                                    <div className="video_card mt-3">
                                                        <img src={elem.thumbnail} />
                                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                                            <h5 className="fs-16 fw-500 m-0">{elem.assignment_title}</h5>
                                                            <div className="d-flex align-items-center opacity5">
                                                                <img src={clock} />
                                                                <span className="fs-10 ms-1">{elem.duration_string}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <Badge bg="outline_primary" className="fs-10 me-1">{elem.category_title}</Badge>
                                                            <Badge bg="outline_primary" className="fs-10 me-1">{elem.sub_category_title}</Badge>
                                                        </div>
                                                        <Row className="align-items-center mt-2">
                                                            <Col className="">
                                                                <div className="video_play_progress_status">
                                                                    <ProgressBar now={elem.percentage} />
                                                                    <label className="fs-10 fw-400 text_brand_color2">{elem.percentage}%</label>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <span>
                                                                    <img width="20" src={play} />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>


                        </div>
                    </div>
                </Col>
            </Row>
        
        </>
    )
}

export default StudentLatestPlayedCtrl;