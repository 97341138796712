import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Col, Form, Row, Table} from "react-bootstrap";

import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET } from '../../../utils/axios.util';

import left_arrow from '../../../assets/images/icons/left_arrow.png';
import right_arrow from '../../../assets/images/icons/right_arrow.png';
import PAGINATION from '../../../components/common/pagination';

function InterpreterSalaryCtrl(props) {

    const [ transactions, setTransactions ] = useState([])
    const [ fromDate, setFromDate ] = useState(new Date(moment().startOf('week').tz(TIME_ZONE)))
    const [ toDate, setToDate ] = useState(new Date(moment().endOf('week').tz(TIME_ZONE)))
    const [ displayFromDate, setDisplayFromDate ] = useState('')
    const [ displayToDate, setDisplayToDate ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    var isSalaryInitialized = useRef(false)

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    const transactionRef = useRef({
        fromDate: '',
        toDate: ''
    })

    var transactnRef = transactionRef.current

    useEffect(() => {
        
        if(!isSalaryInitialized.current == true) {
            initializeDates()
        }

    },[ transactions, currentItems, itemOffset, itemsPerPage ]);

    const changeDates = (From, To) => {

        let fromDateMonth = From.format('MMM')
        let toDateMonth = To.format('MMM')
        let fromDateYear = From.format('YYYY')
        let toDateYear = To.format('YYYY')
        transactnRef.fromDate = From.format('YYYY-MM-DD')
        transactnRef.toDate = To.format('YYYY-MM-DD')
        
        let fromDateString = ''
        let toDateString = ''

        fromDateString = From.format('MMM DD')
        toDateString = To.format('DD')

        if(fromDateYear !== toDateYear) {
            fromDateString = From.format('YYYY MMM DD')
            toDateString = To.format('YYYY MMM DD')
        }else {
            if(fromDateMonth !== toDateMonth) {
                toDateString = To.format('DD MMM')
            }
        }

        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)

        getTransactions()

    }

    const initializeDates = () => {
        let initialFrom = moment().startOf('week').tz(TIME_ZONE)
        let initialTo = moment().endOf('week').tz(TIME_ZONE)

        changeDates(initialFrom, initialTo)
        
    }

    const getTransactions = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/interpreter/salary/list?fromDate=${transactnRef.fromDate}&toDate=${transactnRef.toDate}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            setIsLoading(false)
            setTransactions(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
            isSalaryInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setTransactions([])
                isSalaryInitialized.current = true
            }
        })

    }

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={left_arrow} onClick={onClick} />
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={right_arrow} onClick={onClick} />
        </button>
    ));

    const validateFromDate = (date) => {
        
        setFromDate(date);
        changeDates(moment(date), moment(transactnRef.toDate))
    }

    const validateToDate = (date) => {

        setToDate(date);
        changeDates(moment(transactnRef.fromDate), moment(date))
    }

    const handlePageClick = (event) => {

        let items = transactions

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };

    return(
        <>

            <h2 className="page_hdng">Salary</h2>
            
            <Row className="">
                <Col xl="12" className="mt-3"> 
                    <Row className="card_outer_hdng align-items-center">
                        <Col>
                            <h3 className="m-0">Salary transactions</h3>
                        </Col>
                        
                        <Col className="col-auto">
                            <div className="assignments_date">
                                <DatePicker
                                    selected={fromDate}
                                    maxDate={toDate}
                                    onChange={(date) => validateFromDate(date)}
                                    customInput={<FromDate />}
                                />
                                <span className="assign_date">{displayFromDate} to {displayToDate}</span>
                                <DatePicker
                                    selected={toDate}
                                    minDate={fromDate}
                                    onChange={(date) => validateToDate(date)}
                                    customInput={<ToDate />}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th className='text-center'>Hours</th>
                                    <th className='text-center'>Assignment</th>
                                    <th className='text-center'>Amount</th>
                                    <th className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !isLoading && (transactions.length === 0) &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                { (transactions.length > 0) &&
                                    currentItems.map((elem, index) => {
                                        return (
                                            <tr key={elem.id}>
                                                <td className="fs-14">{elem.is_due ? '--' : elem.paid_at}</td>
                                                <td className='fs-14 text-center'>{elem.working_hours}</td>
                                                <td className='fs-14 text-center text_white_color_half'>{elem.title}</td>
                                                <td className='fs-14 text-center'>+${elem.salary}</td>
                                                <td className='fs-14 text-center text_white_color_half'>{elem.payment_status == 'unpaid' ? 'Pending' : 'Paid'}</td>
                                            </tr>
                                        )
                                    })
                                }
                                
                            </tbody>
                        </Table>
                        { transactions.length > 0 &&
                            <PAGINATION allItems={transactions} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                        }
                    </div>                   
                </Col>

            </Row>

        
        </>
    )
}

export default InterpreterSalaryCtrl;