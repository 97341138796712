import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux'
import {Col, Form, Button, Row } from "react-bootstrap";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH } from '../../../utils/axios.util';

import rd_trash from '../../../assets/images/icons/rd_trash.png';
import NoAccess from '../../../components/common/noaccess'

function PackageEdit(){

    const { register, handleSubmit, setError, clearErrors, setValue, getValues, formState: { errors } } = useForm();
    const urlParams = new URLSearchParams(window.location.search);
    const user = useSelector(state => state.authenticate)
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams();
    const [ selectedOption, setSelectedOption ] = useState('')
    const [ isDiscount, setIsDiscount ] = useState(false)
    const [ discountPrice, setDiscountPrice ] = useState(0)

    const isViewMode = location.state ? location.state.isView : false
    var menuAccess = null

    const [inputFields, setInputFields] = useState([
        { id: '', feature: '' }
    ])
    
    const [ isLoading, setIsLoading ] = useState(false)
    const [ selectedPackage, setSelectedPackage ] = useState({})
    var urlPage = urlParams.get('page')

    if(user.role_access && user.role_access.length > 0) {
        let menuFilter = user.role_access.filter(item => {
            let menuRegEx = new RegExp(item['menu_name'], 'i');
            return menuRegEx.test(location.pathname)
        })

        if(menuFilter.length > 0){
            menuFilter = menuFilter[0]
            menuAccess = menuFilter
        }
    }

    useEffect(() => {

        if(Object.keys(selectedPackage).length === 0) {
            getPackage()
        }

    },[ selectedPackage ]);

    const getPackage = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/admin/packages/${params.id}`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data

                console.log(data)

                let featureInitialData = inputFields
                if(data.features.length > 0) {
                    featureInitialData = data.features
                }
                setInputFields(featureInitialData);

                let selOption = ''
                let price = 0
                if(data.monthly_amount > 0) {
                    selOption = 'monthly'
                    price = data.monthly_amount
                }else {
                    selOption = 'yearly'
                    price = data.yearly_amount
                }
                data.price = price
                if(data.discount && data.discount > 0) {
                    setValue('discount_percentage', data.discount)
                    setIsDiscount(true)
                }

                if(price && data.discount && (data.discount >= 1 && data.discount <= 100)) {
                    let afterDiscount = price - ( price * data.discount/100 );
                    setDiscountPrice(afterDiscount)
                }else {
                    setDiscountPrice(0)
                }
                setSelectedOption(selOption)
                setSelectedPackage(data)
                setIsLoading(false)
            }
        }).catch(err => {

            // Session Logout Code
            setIsLoading(false)
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
            
        })

    }
  
    const previousPage = () => {
        navigate(`/sub-admin/packages?page=${urlPage}`)
    }

    const onSubmit = (data) => {

        console.log(inputFields)

        for(let i=0; i<inputFields.length; i++) {
            clearErrors(`feature-${i}`)
        }

        for(let i=0; i<inputFields.length; i++) {
            if(!inputFields[i]['feature']) {
                setError(`feature-${i}`, {
                    type: "manual",
                    message: "Feature is required"
                });
                return;
            }
        }

        data['features'] = JSON.stringify(inputFields)

        let URL = `${BACKEND_DOMAIN}/admin/packages/${params.id}/edit`

        let payload = {
            url : URL,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            navigate(`/sub-admin/packages?page=${urlPage}`)

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber=(e)=>{

        let pattern = /^\d$/

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(( key == 110 || key == 190 || key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        
    }

    const handleFormChange = (index, event) => {
        clearErrors(`feature-${index}`)
        let featureData = [...inputFields];
        featureData[index][event.target.name] = event.target.value;
        setInputFields(featureData);
    }

    const addFields = () => {
        let newfield = { id:'', feature: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {

        let featureData = [...inputFields];
        featureData.splice(index, 1)

        setInputFields(featureData)
    }

    const onDueChange = (event) => {
        setValue('discount_percentage', '')
        setIsDiscount(false)
        setSelectedOption(event.target.value)
    }
    
    const onDiscountChange = (event) => {
        setValue('discount_percentage', '')
        if(event.target.checked) {
            setIsDiscount(true)
        }else {
            setIsDiscount(false)
        }
    }

    const limitNumber = (e) => {

        let pattern = /^\d$/

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {

            let discountVal = e.target.value
            if(pattern.test(charValue) || (key >= 96 && key <= 105)) {

                discountVal = `${discountVal}${charValue}`
                discountVal = Number(discountVal.trim())

                if(discountVal >= 1 && discountVal <= 100) {
                    return true;
                }else {
                    e.preventDefault();
                }
            }
            return true;
        }else {
            e.preventDefault();
        }
    }

    const applyDiscount = (e) => {
        e.preventDefault();
        let discountValue = getValues("discount_percentage")
        let priceValue = getValues("price")

        if(priceValue && discountValue && (discountValue >= 1 && discountValue <= 100)) {
            let afterDiscount = priceValue - ( priceValue * discountValue/100 );
            setDiscountPrice(afterDiscount)
        }else {
            setDiscountPrice(0)
        }
    }

    if((menuAccess && menuAccess.update_mode === 1) || isViewMode ) {
        return(
            <> 
                <h2 className="page_hdng">Packages </h2>
                <Row className="mt-5 justify-content-center">
                    <Col sm="5">
                        <div>
                            <h4 className='text-center'>{ !isViewMode ? 'Edit Package' : 'View Package' }</h4>
                            { Object.keys(selectedPackage).length > 0 &&
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Row className="mb-3">
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="name" defaultValue={selectedPackage.name} onChange={(event) => setValue('name', event.target.value)} {...register('name', { required: "Name is required" })} readOnly={isViewMode}  autoComplete="off" />
    
                                                {errors.name ?.message && <span className="error">{errors.name ?.message}</span> }
                                            </Form.Group>
                                        </Col>
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Tier</Form.Label>
                                                <Form.Control type="text" name="tier" defaultValue={selectedPackage.level} onChange={(event) => setValue('tier', event.target.value)} {...register('tier', { required: "Tier is required" })} readOnly={isViewMode}  autoComplete="off" />
    
                                                {errors.tier ?.message && <span className="error">{errors.tier ?.message}</span> }
                                            </Form.Group>
                                        </Col>
    
                                    </Row>
    
                                    <Row className="mb-3">
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Maximum Number of Users</Form.Label>
                                                <Form.Control type="number" name="users_count" defaultValue={selectedPackage.no_of_accounts} onKeyDown={(event) => allowOnlyNumber(event)} {...register('users_count', { required: "Maximum Number of Users is required" })} readOnly={isViewMode}  autoComplete="off" />
    
                                                {errors.users_count ?.message && <span className="error">{errors.users_count ?.message}</span> }
                                            </Form.Group>
                                        </Col>
    
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control type="number" name="price" defaultValue={selectedPackage.price} onKeyDown={(event) => allowOnlyNumber(event)} {...register('price', { required: "Price is required" })} readOnly={isViewMode}  autoComplete="off"/>
    
                                                {errors.price ?.message && <span className="error">{errors.price ?.message}</span> }
                                            </Form.Group>
                                        </Col>
                                        <Col sm="6">
                                            <Form.Label>Due Type</Form.Label>
                                            <div className="d-flex align-items-center">
                                                <div className="form-check">
                                                    <Form.Check type="radio" name="due_type" id="due_type1" 
                                                    {...register('due_type', { 
                                                        required: "This field is required"
                                                    })} value="monthly" onChange={(event) => onDueChange(event)} checked={selectedOption == 'monthly' && true} disabled={isViewMode} />
                                                    <Form.Label className="form-label" htmlFor="due_type1" style={{ color: '#ffffff', padding: '2px' }} >
                                                        Monthly
                                                    </Form.Label>
                                                </div>
                                                <div className="form-check mx-2">
                                                    <Form.Check type="radio" name="due_type"
                                                    id="due_type2" 
                                                    {...register('due_type', { 
                                                        required: "This field is required"
                                                    })} value="yearly" onChange={(event) => onDueChange(event)} checked={selectedOption == 'yearly' && true} />
                                                    <Form.Label className="form-check-label" htmlFor="due_type2" style={{ color: '#ffffff', padding: '2px' }} >
                                                        Yearly
                                                    </Form.Label>
                                                </div>
                                            </div>
    
                                            {errors.due_type && <span className="error">{errors.due_type.message}</span> }
    
                                        </Col>
                                    </Row>
    
                                    { selectedOption == 'yearly' && 
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                {['checkbox'].map((type) => (
                                                    <div key={`inline-${type}`}>
                                                        <Form.Check label="Apply Discount" name="discount" type={type} id={`inline-${type}-1`} onChange={(event) => onDiscountChange(event)} checked={isDiscount} disabled={isViewMode} />
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    }
    
                                    { isDiscount &&
                                        <Row>
                                            <>
                                                <Col sm="6">
                                                    
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Discount Percentage</Form.Label>
                                                        <div className="d-flex justify-content-between">
                                                            <Form.Control type="text" name="discount_percentage" defaultValue={''} onKeyDown={(event) => limitNumber(event)} {...register('discount_percentage', { 
                                                                required: "Percentage is required"
                                                            })} style={{ width: '50%'}} readOnly={isViewMode}  autoComplete="off" />
                                                            <button className='cancel_btn' size='md' onClick={(e) => applyDiscount(e)} disabled={isViewMode} >Apply</button>
                                                        </div>
                                                        {errors.discount_percentage ?.message && <span className="error">{errors.discount_percentage ?.message}</span> }
                                                    </Form.Group>
                                                        
                                                    <p>Discount price: ${discountPrice}</p>
                                                </Col>
                                            </>
                                        </Row>
                                    }
    
                                    {/* <p className='dfn_label'>Features</p> */}
                                    <Form.Label>Features</Form.Label>
                                    {   inputFields.map((input, index) => {
                                            return (
                                                <Row className="mb-3" key={index}>
                                                    <Col sm="12">
                                                        <Form.Group className='mb-3 d-flex justify-content-between'>
                                                            <Form.Control type="text" name={`feature`} onChange={event => handleFormChange(index, event)} value={input.feature} readOnly={isViewMode}  autoComplete="off" />
    
                                                            {inputFields.length > 1 && !isViewMode &&
                                                                <span className="badge badge_md" onClick={() => removeFields(index)}  style={{cursor: 'pointer'}}>
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            }
    
                                                        </Form.Group>
    
                                                        {errors[`feature-${index}`] ?.message && <span className="error">{errors[`feature-${index}`] ?.message}</span> }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
    
                                    { !isViewMode && 
                                        <Button variant='secondary' size='md' onClick={addFields} >
                                            Add More
                                        </Button>
                                    }
    
                                    { !isViewMode && 
                                        <Row className="my-2 justify-content-center">
                                            <Col className='col-auto'>
                                                <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                            </Col>
                                            <Col className='col-auto'>
                                                <button className='save_btn' type="submit" >Save</button>
                                            </Col>
                                        </Row>
                                    }
                                    { isViewMode && 
                                        <Row className="my-2 justify-content-center">
                                            <Col className='col-auto'>
                                                <button className='save_btn' onClick={() => previousPage()} >Back</button>
                                            </Col>
                                        </Row>
                                    }
                                </Form>
                            }
                            { isLoading && 
                                <div className='text-center subscriptions_packages_name no_records_loading'>
                                    <h5>Loading...</h5>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </>
        )
    }else {

        return <NoAccess />
    }
   
}

export default PackageEdit;