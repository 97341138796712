import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Col, Form, InputGroup, Row } from "react-bootstrap";
import { MultiSelect } from 'primereact/multiselect';
import eye from '../../../../assets/images/icons/eye.svg';
import eye_slash from '../../../../assets/images/icons/eye_slash.svg';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../../config/variables';
import { GET, PATCH } from '../../../../utils/axios.util';
import useDivisions from '../../../../hooks/useDivisions';

function SchoolCoordinatorEdit(){
    
    const { register, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const params = useParams();
    const divisions = useDivisions()

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ coordinator, setCoordinator ] = useState({})
    const [ selectedOption, setSelectedOption ] = useState('')
    const [ selectedDivision, setSelectedDivision ] = useState(null);
    const [ isAssignInterpreter, setIsAssignInterpreter ] = useState(false);
    const [ isAssignInterpreterChecked, setIsAssignInterpreterChecked ] = useState(false);
    const [ divisionMaps, setDivisionMaps ] = useState([]);
    const [ phone, setPhone ] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    useEffect(() => {

        if(Object.keys(coordinator).length === 0) {
            getCoordinator()
        }

        if(divisions.length > 0 && divisionMaps.length === 0) {
            let mapDivisions = []

            for(let x of divisions) {

                let tempMap = {
                    name: x.name,
                    code: x.id
                }

                mapDivisions.push(tempMap)
            }

            setDivisionMaps(mapDivisions)
        }

    },[coordinator, divisions, selectedDivision]);

    const getCoordinator = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/school/coordinator/${params.id}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            if(data.divisions.length > 0) {

                let mapDivisions = []

                for(let x of data.divisions) {

                    let usrDiv = {
                        name: x.name,
                        code: x.id
                    }

                    mapDivisions.push(usrDiv)
                }

                setSelectedDivision(mapDivisions)
            }
            
            setPhone(data.phone)
            setCoordinator(data)
            setSelectedOption(data.request_assignment_to)
            if(data.assign_interpreter) {
                setIsAssignInterpreter(true)
                setIsAssignInterpreterChecked(true)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 404) {
                navigate('/school/users')
            }
        })

    }

    const onSubmit = (data) => {

        clearErrors('phone')

        data['phone'] = phone
        data['assign_interpreter'] = isAssignInterpreterChecked

        if (!data.phone) {
            setError("phone", {
              type: "manual",
              message: "Phone number is required"
            });
            return;
        }

        if(!selectedDivision || selectedDivision.length === 0) {

            setError('division', { type: 'custom', message: 'Class is Required' })
            
        }else {

            data['divisions'] = JSON.stringify(selectedDivision)

            let payload = {
                url : `${BACKEND_DOMAIN}/school/coordinator/${params.id}/edit`,
                data
            }

            PATCH(payload).then(res => {

                toast.success(res.data.message)
                
                let navigateUrl = `/school/users`

                if(urlTab) {
                    navigateUrl = `/school/users?tab=${urlTab}`
                }

                if(urlTab && urlPage) {
                    navigateUrl = `/school/users?tab=${urlTab}&page=${urlPage}`
                }

                navigate(navigateUrl)

            }).catch(err => {

                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }

                if(err.response.status === 403) {
                    toast.error(err.response.data.message)
                }
            })

        }

    }

    const onValueChange = (event) => {
        setSelectedOption(event.target.value)
        setIsAssignInterpreterChecked(false)
        if(event.target.value == 'school_admin') {
            setIsAssignInterpreter(true)
        }else {
            setIsAssignInterpreter(false)
        }
    }

    const changeAssignInterpreter = (event) => {
        if(event.target.checked) {
            setIsAssignInterpreterChecked(true)
        }else {
            setIsAssignInterpreterChecked(false)
        }
    }

    const allowOnlyNumber=(e)=>{

        setPhone(e.target.value)
        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {
           
            if(pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(valLength > maxLength){
            
            if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) ) {
                return true;
            }else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */
        
    }

    const formatUSNumber = (e) => {

        clearErrors('phone')

        let entry = e.target.value

        if(entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if(pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            if(count != 10) {
                setError("phone", {
                    type: "manual",
                    message: "Number must be ten digits"
                  });
                  return;
            }

            const match = entry
            .replace(/\D+/g, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''  
            e.target.value = `${part1}${part2}${part3}`
            setPhone(`${part1}${part2}${part3}`)

            return `${part1}${part2}${part3}`
        }
        
    }

    const previousPage = () => {

        let navigateUrl = `/school/users`

        if(urlTab) {
            navigateUrl = `/school/users?tab=${urlTab}`
        }

        if(urlTab && urlPage) {
            navigateUrl = `/school/users?tab=${urlTab}&page=${urlPage}`
        }

        navigate(navigateUrl)
    }

    return(
        <>
        { (Object.keys(coordinator).length > 0) &&
        <>
            <h2 className="page_hdng">Users</h2>
            <Row className="mt-5 justify-content-center">
                <Col sm="5">
                    <div>
                        <h4 className='text-center'>Edit Coordinator</h4>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <p className='dfn_label'>Basic Info</p>
                            <Row className="mb-3">
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control type="text" name="first_name" defaultValue={coordinator.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })}  autoComplete="off" />

                                        {errors.first_name ?.message && <span className="error">{errors.first_name ?.message}</span> }

                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control type="text" name="last_name" defaultValue={coordinator.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')}  autoComplete="off"/>
                                    </Form.Group>
                                </Col>

                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control type="text" name="designation" defaultValue={coordinator.designation} onChange={(event) => setValue('designation', event.target.value)} {...register('designation', { required: "Designation is required" })}  autoComplete="off" />

                                        {errors.designation ?.message && <span className="error">{errors.designation ?.message}</span> }
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event)} onBlur={formatUSNumber} />

                                        {errors.phone ?.message && <span className="error">{errors.phone ?.message}</span> }
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Assign Class</Form.Label>

                                        <MultiSelect value={selectedDivision} onChange={(e) => { clearErrors('division'); setSelectedDivision(e.value)}} options={divisionMaps} optionLabel="name" placeholder="-- Choose Class --" maxSelectedLabels={3} className="form-select"  autoComplete="off" />

                                        {errors.division ?.message && <span className="error">{errors.division ?.message}</span> }
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Label>Assign Request To</Form.Label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <Form.Check type="radio" name="request_to" id="request_to1" 
                                            {...register('request_to', { 
                                                required: "This field is required"
                                            })} value="super_admin" onChange={(event) => onValueChange(event)} checked={selectedOption == 'super_admin' && true} />
                                            <Form.Label className="form-label" for="request_to1" style={{ color: '#ffffff', padding: '2px' }} >
                                                Super Admin
                                            </Form.Label>
                                        </div>
                                        <div className="form-check">
                                            <Form.Check type="radio" name="request_to" id="request_to2"
                                            {...register('request_to', { 
                                                required: "This field is required"
                                            })} value="school_admin" onChange={(event) => onValueChange(event)} checked={selectedOption == 'school_admin' && true} />
                                            <Form.Label className="form-check-label" for="request_to2" style={{ color: '#ffffff', padding: '2px' }} >
                                                School Admin
                                            </Form.Label>
                                        </div>
                                    </div>

                                    {errors.request_to && <span className="error">{errors.request_to.message}</span> }

                                </Col>
                                { (isAssignInterpreter || selectedOption == 'school_admin') && 
                                    <Col sm="6" className="d-flex align-items-center">
                                        {['checkbox'].map((type) => (
                                            <div key={`inline-${type}`}>
                                                <Form.Check label="Assign Interpreter" name="assign_interpreter" type={type} id={`inline-${type}-1`} {...register("assign_interpreter")} onChange={(event) => changeAssignInterpreter(event)} checked={isAssignInterpreterChecked} />
                                            </div>
                                        ))}
                                    </Col>
                                }
                            </Row>

                            <p className='dfn_label'>Credentials</p>
                            <Row className="mb-3">
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>User Name (Email)</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" name="email" defaultValue={coordinator.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', { 
                                            required: "User Name is required",
                                            pattern: { 
                                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Invalid email address"
                                            }
                                        })}  autoComplete="off" />

                                        {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }
                                    </Form.Group>
                                </Col>
                                <Col sm="6">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Create Password</Form.Label>
                                            <InputGroup className="password_frm_grp">
                                                <Form.Control name="password" placeholder="Enter Password" type={isRevealPwd ? "text" : "password"} defaultValue={coordinator.password} onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" } )}  autoComplete="new-password" />
                                                <InputGroup.Text id="basic-addon2">
                                                    <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                </InputGroup.Text>
                                            </InputGroup>
                                            {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3 justify-content-center">
                                <Col className='col-auto'>
                                    <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                </Col>
                                <Col className='col-auto'>
                                    <button className='save_btn' type="submit" >Save</button>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Col>
            </Row>
        </>
        }
        </>
    )
}

export default SchoolCoordinatorEdit;