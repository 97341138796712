import React from 'react';
import {Col, Button,Row,Form, } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function NoAccess() {

    const navigate = useNavigate()

    return(
        <>        
            <div className='d-flex align-items-center justify-content-end'>
                <Button variant='trans_success' onClick={() => navigate(-1)} >Back</Button>
            </div>
            <div className='text-center subscriptions_packages_name no_records_loading'>
                <h5>You do not have access to this feature. Contact your administration for further information.</h5>
            </div>
        </>
    )
}

export default NoAccess;