import React, { useEffect, useState } from 'react';
import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';

import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import clock from '../../../assets/images/icons/clock.png';
import play from '../../../assets/images/icons/play.svg';

function StudentCompletedChapterListCtrl(props){

    const navigate = useNavigate();
    const [ assignments, setAssignments ] = useState(props.assignments)

    useEffect(() => {

        setAssignments(props.assignments)

    },[props.assignments]);

    return(
        <>
            <Row>
                { assignments.map((elem, index) => {

                    var assignmentIcon = ''
                    var favouriteClass = ''
                    if(elem.is_favourite) {
                        favouriteClass = 'add_wishList_btn'
                    }else {
                        favouriteClass = 'wishList_btn'
                    }
                    if(elem.type == 'video') {
                        assignmentIcon = video_arrow
                    }
                    if(elem.type == 'audio') {
                        assignmentIcon = mic_arrow
                    }
                    if(elem.type == 'document') {
                        assignmentIcon = ntpd_arrow
                    }

                    return (
                        <Col sm="12" lg="6" key={elem.id} >
                            <div to="chapter" className="chapter_list_card mt-3">
                                <span className="wishList">
                                    <button className={favouriteClass} onClick={() => props.addRemoveFavourite(elem.id)} >
                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.89985 0.0831367C6.42485 0.0991031 6.93318 0.19077 7.42568 0.35827H7.47485C7.50818 0.374103 7.53319 0.391603 7.54985 0.407436C7.73402 0.466603 7.90819 0.53327 8.07485 0.624936L8.39152 0.766603C8.51652 0.83327 8.66652 0.957436 8.74985 1.00827C8.83319 1.05744 8.92485 1.10827 8.99985 1.16577C9.92568 0.45827 11.0499 0.0749364 12.2082 0.0831367C12.734 0.0831367 13.259 0.157436 13.7582 0.324936C16.834 1.32494 17.9424 4.69994 17.0165 7.64994C16.4915 9.15744 15.6332 10.5333 14.509 11.6574C12.8999 13.2158 11.134 14.5991 9.23319 15.7908L9.02485 15.9166L8.80819 15.7824C6.90069 14.5991 5.12485 13.2158 3.50068 11.6491C2.38402 10.5249 1.52485 9.15744 0.991518 7.64994C0.0498509 4.69994 1.15818 1.32494 4.26735 0.307436C4.50902 0.224103 4.75818 0.16577 5.00818 0.13327H5.10818C5.34235 0.0991031 5.57485 0.0831367 5.80819 0.0831367H5.89985ZM13.3249 2.7166C12.9832 2.5991 15.6875 3.25409 15.5625 3.60409C15.4458 3.95409 15.025 5.9591 15.375 6.08327C15.9092 6.28327 13.6042 6.29242 13.6042 6.87492L13.7915 5.32494C13.7757 5.51577 13.8332 5.69994 13.9499 5.8416C14.0665 5.98327 14.2415 6.06577 14.4249 6.08327C14.7665 6.0741 15.0582 5.79994 15.0832 5.4491V5.34994C15.1082 4.18244 14.4007 3.12494 13.3249 2.7166Z" fill="white"/>
                                    </svg>
                                    </button>
                                </span>
                                <div>
                                    <div className="d-flex align-items-center w-100">
                                        <div className="sqr_video_thumb">
                                            <img src={elem.thumbnail} onClick={() => navigate('/student/assignments/' + elem.id)} />
                                            <span className="play_icon">
                                                <img width="20" src={play} onClick={() => navigate('/student/assignments/' + elem.id)} />
                                            </span>
                                        </div>
                                        <div className="ms-3 w-100">
                                            <NavLink to={'/student/assignments/' + elem.id} >
                                                <h5 className="fs-14 fw-600">{elem.assignment_title}</h5>
                                            </NavLink>
                                            <Badge bg="outline_primary" className="fs-10 me-1">{elem.category_title}</Badge>
                                            <Badge bg="outline_primary" className="fs-10 me-1">{elem.sub_category_title}</Badge>
                                            <div className="mt-2 d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center ">
                                                    <span>
                                                        <img src={assignmentIcon} />
                                                    </span>
                                                    <div className="opacity5">
                                                        <span className="mx-2">|</span>
                                                        <img src={clock} />
                                                        <span className="fs-10 ms-1">{elem.duration_string}</span>
                                                    </div>
                                                </div>
                                                <p className="m-0 fs-12 fw-600 text-capitalize">{elem.status}</p>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        <ProgressBar now={100} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                    })
                }
            </Row>        
        </>
    )
}

export default StudentCompletedChapterListCtrl;