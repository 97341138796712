import React, { useEffect, useRef, useState } from 'react';
import {Modal, Button,  Col,Form,Nav, Pagination, Row,Tab, Table} from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Moment from 'react-moment';

import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import PAGINATION from '../../../components/common/pagination';
import ChangePassword from '../../../components/common/changePassword';

import video from '../../../assets/images/icons/video.png';
import member1 from '../../../assets/images/member1.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH, POST } from '../../../utils/axios.util';
import { useSelector } from 'react-redux';

function InterpreterDashboardCtrl(){

    const navigate = useNavigate();

    const [ assignmentsList, setAssignmentsList ] = useState([])
    const [ transactions, setTransactions ] = useState([])
    const [ payments, setPayments ] = useState({})
    const [ assignments, setAssignments ] = useState({})
    const [ dashboard, setDashboard ] = useState({})
    const [ transactionIsLoading, setTransactionIsLoading ] = useState(false)
    const [ assignmentIsLoading, setAssignmentIsLoading ] = useState(false)
    const [ passwordModalShow, setPasswordModalShow ] = useState(false)
    const [ changePasswordModalShow, setChangePasswordModalShow ] = useState(false)
    const closeChangePasswordModalShow = () => setChangePasswordModalShow(false)
    const handlePasswordModalClose = () => setPasswordModalShow(false)

    var isDashboardSent = useRef(false)
    var isAssignmentSent = useRef(false)
    var isLoginInitialized = useRef(false)
    let user = useSelector((data) => data?.authenticate)

    useEffect(() => {

        if(user.is_loggedin_first === 0) {
            setPasswordModalShow(true)
        }
        
        if(!isDashboardSent.current) {
            getDashboard()
        }
        if(!isAssignmentSent.current) {
            getAssignments()
        }

        if(!isLoginInitialized.current) {
            initializeLogin()
        }

    },[dashboard, assignmentsList]);

    const initializeLogin = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/login/update-login`
        }

        POST(payload).then(res => {

            const { data } = res.data

            isLoginInitialized.current = true
            localStorage.user = JSON.stringify(data)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const getDashboard = () => {

        setTransactionIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/interpreter/dashboard`
        }

        GET(payload).then(res => {

            const { data } = res.data

            setTransactionIsLoading(false)
            setTransactions(data.transactions)
            setPayments(data.payments)
            setAssignments(data.assignments)
            setDashboard(data)
            isDashboardSent.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setTransactionIsLoading(false)
            console.log(err)
        })

    }

    const getAssignments = () => {

        setAssignmentIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/queue?latest=requested`
        }

        GET(payload).then(res => {
            
            const { data } = res.data

            setAssignmentIsLoading(false)
            setAssignmentsList(data.list.requested)
            isAssignmentSent.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setAssignmentIsLoading(false)
            console.log(err)
        })

    }

    const acceptRequest = (queue_id) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/interpreters/accept-request`,
            data: {
                queue_id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            navigate(0)

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const declineRequest = (queue_id) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/interpreters/decline-request`,
            data: {
                queue_id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            navigate(0)

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const openPasswordModal = () => {
        setPasswordModalShow(false)
        setChangePasswordModalShow(true)
    }

    const submitPassword = (data) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/login/update-password`,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setChangePasswordModalShow(false)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })

    }

    return(
        <>
            <div className='d-flex justify-content-between'>
                <h2 className="page_hdng">Dashboard</h2>
                {user?.package_details?.package_name?.toLowerCase() === "enterprise" &&<Button className='login-button'>
                <a target="_blank" href={`${process.env.REACT_APP_CAPTION_TOOL_URL}/app-login?token=${user?.['session_token']}`}>Access Caption Detaction Tool</a>
                </Button>}
            </div>
            <Row className="">
                <Col xl="8">
                    <Row className="mt-3">
                        <Col sm="12">
                            <Row className="card_outer_hdng">
                                <Col>
                                    <h3>Assignments</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6" lg="3">
                            <div className="assignments_card mb-3">
                                <h4>{ Object.keys(assignments).length > 0 ? assignments.requested : 0 }</h4>
                                <p className='text-capatilize'>requested</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="3">
                            <div className="assignments_card mb-3">
                                <h4>{ Object.keys(assignments).length > 0 ? assignments.merging : 0 }</h4>
                                <p className='text-capatilize'>merging</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="3">
                            <div className="assignments_card mb-3">
                                <h4>{ Object.keys(assignments).length > 0 ? assignments.review : 0 }</h4>
                                <p className='text-capatilize'>under Review</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="3">
                            <div className="assignments_card mb-3">
                                <h4>{ Object.keys(assignments).length > 0 ? assignments.completed : 0 }</h4>
                                <p className='text-capatilize'>completed</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm="12">
                            <Row className="card_outer_hdng">
                                <Col>
                                    <h3>Payments</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card active">
                                <h4>${ Object.keys(payments).length > 0 ? payments.week : 0 }</h4>
                                <p className='text-capatilize'>this week</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>${ Object.keys(payments).length > 0 ? payments.month : 0 }</h4>
                                <p className='text-capatilize'>this month</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>${ Object.keys(payments).length > 0 ? payments.total : 0 }</h4>
                                <p className='text-capatilize'>Total till now</p>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col xl="4" className="mt-3"> 
                    <Row className="card_outer_hdng align-items-center">
                        <Col>
                            <h3 className="m-0">Salary transactions</h3>
                        </Col>
                        { transactions.length > 5 &&
                            <Col className="col-auto">
                                <Button size="sm" variant="light">View All</Button>
                            </Col>
                        }
                    </Row>
                    <div className="my_card mt-3">

                        { transactionIsLoading &&
                            <div className="my_card_brand_dark_color py-3">
                                <h4 className="fs-20 fw-600 text_brand_color2 m-0">Loading...</h4>
                            </div>
                        }

                        { transactions.length === 0 && 
                            <div className="my_card_brand_dark_color py-3 text-center">
                                <h4 className="fs-20 fw-600 text_brand_color2 m-0">No Records Found!</h4>
                            </div>
                        }
                        { !transactionIsLoading && transactions.length > 0 && transactions.map((elem, index) => {

                            return (
                            
                                    <div className="my_card_brand_dark_color py-3 mt-3" key={elem.minDate}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <p className="fs-16 fw-500 m-0">
                                                    { (moment(elem.minDate).format('DD MMM') !== moment(elem.maxDate).format('DD MMM')) ? `${moment(elem.minDate).format('DD MMM')} to ${moment(elem.maxDate).format('DD MMM')}` : moment(elem.minDate).format('DD MMM') }</p>
                                                <p className="fs-12 text_light_color m-0">{elem.count} Assignments converted </p>
                                            </div>
                                            <h4 className="fs-20 fw-600 text_brand_color2 m-0">+${elem.amount}</h4>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>                   
                </Col>

            </Row>

            <Row>
                <Col>
                <div className="fixed_tab">
                    <Tab.Container id="fdgdfg" defaultActiveKey="latest_requested">
                        <Row className="justify-content-between">
                            <Col className='col-auto'>
                                <Nav variant="fixed_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="latest_requested" style={{'borderTopLeftRadius' : '14px'}}>Latest Requested</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            { assignmentsList.length > 4 &&
                                <Col className="col-auto mb-2">
                                    <Button size="sm" variant="light">View All</Button>
                                </Col>
                            }
                        </Row>

                        <Tab.Content>
                            <Tab.Pane eventKey="latest_requested">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>Assignments Name</th>
                                            <th>Date & Time</th>
                                            <th>Duration</th>
                                            <th className='text-center'>Format</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { assignmentsList.length === 0 &&
                                            <tr className="text-center">
                                                <td colSpan="8" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { !assignmentIsLoading && assignmentsList.length > 0 &&

                                            assignmentsList.map((elem, index) => {
                                                if(index < 4) {

                                                    let durationSplit = elem.duration.split(':');
                                                    let hours = parseInt(durationSplit[0])
                                                    let mins = parseInt(durationSplit[1])
                                                    let secs = parseInt(durationSplit[2])

                                                    var duration = ''
                                                    var assignmentIcon = ''

                                                    if(hours > 0) {
                                                        duration += `${hours}h`
                                                    }
                                                    if(mins > 0) {
                                                        duration += ` ${mins}m`
                                                    }
                                                    if(secs > 0) {
                                                        duration += ` ${secs}s`
                                                    }
                                                    duration = duration.trim()

                                                    if(elem.type == 'video') {
                                                        assignmentIcon = video_arrow
                                                    }
                                                    if(elem.type == 'audio') {
                                                        assignmentIcon = mic_arrow
                                                    }
                                                    if(elem.type == 'document') {
                                                        assignmentIcon = ntpd_arrow
                                                    }
                                                
                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <NavLink to={'/interpreter/assignments/' + elem.id} >
                                                                    <h5>{elem.title}</h5>
                                                                </NavLink>
                                                                <p>
                                                                    <span className='text_white_color_half'>{elem.division_name},</span> 
                                                                    <span className='text_brand_color2'>{elem.category_title}</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    <Moment format="MM/DD/YY">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </h5>
                                                                <span className='text_brand_color3'>
                                                                    <Moment format="hh:mm A">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <h5>{duration}</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                                <img src={assignmentIcon} />
                                                            </td>
                                                            <td className='text_brand_color6 text-capitalize'>{elem.status}</td>
                                                            <td className='text-center'>
                                                                <div className='d-flex justify-content-center'>
                                                                    <p className="text_brand_color3 text-capitalize" style={{ cursor: 'pointer'}} onClick={() => acceptRequest(elem.id)} >Accept</p>&nbsp;|&nbsp;
                                                                    <p className="text_brand_color6 text-capitalize" style={{ cursor: 'pointer'}} onClick={() => declineRequest(elem.id)} >Decline</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })

                                        }
                                    </tbody>
                                </Table>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={passwordModalShow} onHide={handlePasswordModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>You have logged in for the  first time. Click Yes to change your password.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handlePasswordModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openPasswordModal()} >YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ChangePassword changePasswordModalShow={changePasswordModalShow} closeChangePasswordModalShow={closeChangePasswordModalShow} submitPassword={submitPassword} />
        </>
    )
}

export default InterpreterDashboardCtrl;