import React, { useState, useEffect, useRef } from 'react';
import { Modal, Breadcrumb, Button, Col, Form, FormControl, InputGroup, Nav, Row, Tab, Table } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import search from '../../../assets/images/icons/search.png';
import member5 from '../../../assets/images/member5.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import check from '../../../assets/images/icons/check_lg.png';

import { GET, POST, PATCH } from '../../../utils/axios.util';
import { BACKEND_DOMAIN } from '../../../config/variables';
import PAGINATION from '../../../components/common/pagination';
import Privileged from '../../../HOC/privileged';
import useMenuAccess from '../../../hooks/useMenuAccess';

function Organizations() {

    const { register, handleSubmit, setValue, setError, clearErrors, reset, formState: { errors } } = useForm();

    const [keyword, setKeyword] = useState('')
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [isNoRecords, setIsNoRecords] = useState(false)
    const [organizations, setOrganizations] = useState([]);

    const itemsPerPage = 10
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0);

    var isOrganizationsInitialized = useRef(false)
    var isPackageInitialized = useRef(false)
    var isInstTypeInitialized = useRef(false)
    var phoneRef = useRef('')
    phoneRef.current = ''

    const [orgModalTitle, setOrgModalTitle] = useState('Add Organization')

    const [packages, setPackages] = useState([]);
    const [instituteTypes, setInstituteTypes] = useState([]);
    const [packageOptions, setPackageOptions] = useState(null);
    const [instituteTypeOptions, setInstituteTypeOptions] = useState(null);
    const [orgModalShow, setOrgModalShow] = useState(false);
    const [deleteOrgModalShow, setOrgDeleteModalShow] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [deleteOrgId, setDeleteOrgId] = useState('');
    const [phone, setPhone] = useState('');
    const [orgPhone, setOrgPhone] = useState('');
    const [userId, setUserId] = useState('');
    const handleOrgDeleteModalClose = () => setOrgDeleteModalShow(false);
    const handleOrgModalClose = () => setOrgModalShow(false);
    const handleSuccessModalClose = () => setSuccessModalShow(false);

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [showNewType, setshowNewType] = useState('block');
    const [showSchoolType, setShowSchoolType] = useState('none');
    const [orgTypeName, setOrgTypeName] = useState('Organization');
    const [orgTypeText, setOrgTypeText] = useState('');
    const [isViewMode, setIsViewMode] = useState(false);
    const menuAccess = useMenuAccess();

    useEffect(() => {

        if (instituteTypes.length === 0 && !isInstTypeInitialized.current) {
            getInstituteTypes()
        }

        if (organizations.length === 0 && isInstTypeInitialized.current && !isOrganizationsInitialized.current) {
            getOrganizations(keyword)
        }

        if (packages.length === 0 && !isPackageInitialized.current) {
            getPackages()
        }

        if (packages.length > 0 && !packageOptions) {
            let optionValues = packages.map((e) => {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            })
            setPackageOptions(optionValues)
        }

        if (instituteTypes.length > 0 && !instituteTypeOptions) {
            let instTypeOptionValues = instituteTypes.map((e) => {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            })
            setInstituteTypeOptions(instTypeOptionValues)
        }

    }, [packages, organizations, instituteTypes, currentItems, itemOffset, itemsPerPage]);

    const getOrganizations = (keyword) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/organizations?search=${keyword}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            if (data.length > 0) {
                for (let x of data) {
                    if (x['organization_type']) {

                        let instTypeOrgFilter = instituteTypes.filter(item => {
                            return item.id == x['organization_type']
                        })

                        if (instTypeOrgFilter.length > 0) {
                            x['orgTypeValue'] = instTypeOrgFilter[0].name
                        }

                    } else {
                        x['orgTypeValue'] = x['org_type']
                    }
                }
            }

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            isOrganizationsInitialized.current = true

            setIsNoRecords(false)
            if (data.length === 0) {
                setIsNoRecords(true)
            }
            setIsLoading(false)
            setOrganizations(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if (err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const getInstituteTypes = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/institute-types`
        }

        GET(payload).then(res => {

            const { data } = res.data

            setInstituteTypes(data)
            isInstTypeInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })

    }

    const getPackages = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/packages`
        }

        GET(payload).then(res => {

            const { data } = res.data

            console.log(data)

            setPackages(data)
            isPackageInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })

    }

    const deleteOrganization = () => {

        setIsLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/admin/organizations/delete`,
            data: {
                user_id: userId,
                organization_id: deleteOrgId
            }
        }

        POST(payload).then(res => {

            setIsLoading(false)
            setOrgDeleteModalShow(false)
            toast.success(res.data.message)
            getOrganizations()

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            setOrgDeleteModalShow(false)
            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handlePageClick = (event) => {

        let items = organizations

        const newOffset = (event.selected * itemsPerPage) % items.length;

        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);

    };

    const handleOrgModalShow = (type, org_id = '') => {

        reset()
        setIsViewMode(false)
        setPhone('')
        setOrgPhone('')
        clearErrors('organization')
        if (type == 'add') {

            setOrgModalTitle('Add Organization')
            setValue('organization', '')
            setValue('organization_id', '')
            setShowSchoolType('none')
            setOrgModalShow(true);
        } else {

            let orgFiltr = organizations.filter(item => {
                return item.id == org_id
            })

            setValue('user_id', orgFiltr[0]['user_id'])
            setValue('organization_id', orgFiltr[0]['id'])
            setValue('organization', orgFiltr[0]['name'])
            setValue('org_address', orgFiltr[0]['address'])
            setValue('first_name', orgFiltr[0]['first_name'])
            setValue('last_name', orgFiltr[0]['last_name'])
            if (orgFiltr[0]['organization_type']) {

                let instTypeOrgFilter = instituteTypes.filter(item => {
                    return item.id == orgFiltr[0]['organization_type']
                })

                setValue('org_type', orgFiltr[0]['organization_type'])

                if (instTypeOrgFilter.length > 0) {

                    if (instTypeOrgFilter[0].name.toLowerCase() == 'school') {
                        setValue('school_type', orgFiltr[0]['org_type'])
                        setShowSchoolType('block')
                    }
                    setshowNewType('none')
                } else {
                    setshowNewType('none')
                }

            } else {
                setValue('new_org_type', orgFiltr[0]['org_type'])
                setshowNewType('block')
            }

            if (orgFiltr[0]['subscription_id']) {
                setValue('package', orgFiltr[0]['subscription_id'])
            }
            setValue('email', orgFiltr[0]['email'])
            setValue('password', orgFiltr[0]['password'])
            setPhone(orgFiltr[0]['phone'])
            setOrgPhone(orgFiltr[0]['org_phone'])
            setOrgModalShow(true);
            if (type == 'view') {
                setOrgModalTitle('View Organization')
                setIsViewMode(true)
            } else {
                setOrgModalTitle('Edit Organization')
                setIsViewMode(false)
            }
        }
    }

    const handleOrgDeleteModalShow = (elem) => {

        setDeleteOrgId(elem.id)
        setUserId(elem.user_id)
        setOrgDeleteModalShow(true)

    }

    const togglePassword = (eLId) => {
        let element = document.getElementById(`password_${eLId}`)
        let eyecon = document.getElementById(`eyecon_${eLId}`)
        if (element.type == 'text') {
            element.setAttribute('type', 'password')
        } else {
            element.setAttribute('type', 'text')
        }

        if (eyecon.getAttribute('src') == eye) {
            eyecon.setAttribute('src', eye_slash)
        } else {
            eyecon.setAttribute('src', eye)
        }
    }

    const handleInstTypes = (event) => {

        let optionText = event.target.selectedOptions[0].text.toLowerCase()
        let typeVal = event.target.value
        if (typeVal) {
            setOrgTypeName(typeVal)
            let instFilter = instituteTypes.filter(itm => itm.id == typeVal)
            setOrgTypeName(instFilter[0]['name'])
        } else {
            setOrgTypeName('Organization')
        }
        setValue('new_org_type', '')
        setOrgTypeText(optionText)
        if (typeVal) {
            setshowNewType('none')
        } else {
            setshowNewType('block')
        }
        if (optionText == 'school') {
            setShowSchoolType(('block'))
        } else {
            setShowSchoolType(('none'))
        }
    }

    const onSubmit = (data) => {

        clearErrors('phone')
        clearErrors('org_phone')

        data['phone'] = phone
        data['org_phone'] = orgPhone
        data['orgType_text'] = orgTypeText

        if (!data.phone) {
            setError("phone", {
                type: "manual",
                message: "Phone number is required"
            });
            return;
        }

        if (!data.org_phone) {
            setError("org_phone", {
                type: "manual",
                message: "Organization Phone number is required"
            });
            return;
        }

        if (!data.org_type && !data.new_org_type) {
            setError("new_org_type", {
                type: "manual",
                message: "Organization Type is required"
            });
            return;
        }

        if (orgTypeText == 'school' && !data.school_type) {
            setError("school_type", {
                type: "manual",
                message: "School Type is required"
            });
            return;
        }

        let URL = `${BACKEND_DOMAIN}/admin/organizations/create`
        let REQUEST = POST

        if (data['organization_id']) {
            URL = `${BACKEND_DOMAIN}/admin/organizations/edit`
            REQUEST = PATCH
        }

        let payload = {
            url: URL,
            data
        }

        REQUEST(payload).then(res => {

            // toast.success(res.data.message)
            setOrgModalShow(false)
            setSuccessModalShow(true)
            setTimeout(() => {
                setSuccessModalShow(false)
            }, 1000)
            getOrganizations()

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber = (e, type) => {

        if (type == 'admin') {
            setPhone(e.target.value)
        } else {
            setOrgPhone(e.target.value)
        }

        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {

            if (pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue = String.fromCharCode(e.keyCode);
        let key = e.which

        if (valLength > maxLength) {

            if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46)) {
                return true;
            } else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue)) {
            return true;
        } else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */

    }

    const formatUSNumber = (e, type) => {

        if (type == 'admin') {
            clearErrors('phone')
        } else {
            clearErrors('org_phone')
        }

        let entry = e.target.value

        if (entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if (pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            let fieldName = ''
            if (type == 'admin') {
                fieldName = 'phone'
            } else {
                fieldName = 'org_phone'
            }

            if (count != 10) {
                setError(fieldName, {
                    type: "manual",
                    message: "Number must be ten digits"
                });
                return;
            }

            const match = entry
                .replace(/\D+/g, '')
                .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
            e.target.value = `${part1}${part2}${part3}`

            if (type == 'admin') {
                setPhone(`${part1}${part2}${part3}`)
            } else {
                setOrgPhone(`${part1}${part2}${part3}`)
            }

            return `${part1}${part2}${part3}`
        }

    }

    return (
        <>

            <h2 className="page_hdng">Organizations</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="3">
                    <div className="assignments_card">
                        <h4>{organizations.length}</h4>
                        <p>Organizations</p>
                    </div>
                </Col>
            </Row>


            <Row className="">
                <Col xl="12" className="mt-3">
                    <Row className=" align-items-center">
                        <Col>
                            <Breadcrumb className='mb-0'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>

                        <Col className="col-auto">
                            <div className='d-flex align-items-center'>
                         <InputGroup className="">
                                    <FormControl size='md' placeholder="search" onChange={(event) => getOrganizations(event.target.value)}/>
                                    <Button variant='secondary' size='md'>
                                        <img width={20} src={search} />
                                    </Button>
                                </InputGroup>

                                { menuAccess && menuAccess.create_mode === 1 &&
                                    <NavLink className="btn btn-success btn-md px-4 ms-2" onClick={() => handleOrgModalShow('add')} >Add Organization</NavLink>
                                }

                            </div>
                        </Col>
                    </Row>

                    <div className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th className='text-start'>ID</th>
                                    <th className='text-start'>Organization Name</th>
                                    <th className='text-start'>Organization Type</th>
                                    <th className='text-start'>User Name</th>
                                    <th className='text-start'>Password</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading &&
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                {!isLoading && isNoRecords &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                {!isNoRecords && !isLoading && organizations.length > 0 &&
                                    currentItems.map((elem, index) => {
                                        return (
                                            <tr key={elem.id}>
                                                <td className="fs-14">
                                                    <p>{elem.unique_id}</p>
                                                </td>
                                                <td className="fs-14">
                                                    <p>{elem.name}</p>
                                                </td>
                                                <td className="fs-14">
                                                    <p>{elem.orgTypeValue}</p>
                                                </td>
                                                <td className="fs-14">
                                                    <p>{elem.email}</p>
                                                </td>

                                                <td className="paddsowd">
                                                    <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                    <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                </td>
                                                {/* <td className='fs-14 text-center'>
                                                    <div className="total_students">
                                                        { elem.coordinators.length === 0 &&
                                                            <span className="total_students_img">
                                                                --
                                                            </span>
                                                        }
                                                        { elem.coordinators.length > 0 &&
                                                            elem.coordinators.map((coord, index) => {
                                                                if(index < 2) {
                                                                    return (
                                                                        <span key={coord.id}>
                                                                            <span className="total_students_img">
                                                                                <img src={coord.picture_path} />
                                                                            </span>
                                                                        </span>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        { elem.coordinators.length > 3 &&
                                                            <span className="total_students_img">
                                                                <img src={elem.coordinators[2].picture_path} />
                                                                <span className="more_students">{elem.coordinators.length - 3}+</span>
                                                            </span>
                                                        }
                                                    </div>
                                                </td> */}
                                                <td className='fs-14 text-center'>
                                                    {menuAccess.users_menu && menuAccess.users_menu.view_mode === 1 &&
                                                        <Button variant='dark' size='sm' onClick={() => navigate(`/sub-admin/organizations/${elem.id}/users`)} >Users
                                                        </Button>
                                                    }
                                                    {menuAccess && menuAccess.users_menu && menuAccess.users_menu.view_mode === 1 && (menuAccess.view_mode === 1 || menuAccess.update_mode === 1 || menuAccess.delete_mode === 1) &&
                                                        <>
                                                            &nbsp;|&nbsp;
                                                        </>
                                                    }
                                                    {menuAccess && menuAccess.view_mode === 1 &&
                                                        <Button variant='dark' size='sm' onClick={() => handleOrgModalShow('view', elem.id)} >View
                                                        </Button>
                                                    }
                                                    {menuAccess && (menuAccess.update_mode === 1 || menuAccess.delete_mode === 1) &&
                                                        <>
                                                            &nbsp;
                                                        </>
                                                    }

                                                    {menuAccess && menuAccess.update_mode === 1 &&
                                                        <>
                                                            <span className="badge badge_md" onClick={() => handleOrgModalShow('edit', elem.id)} style={{ cursor: 'pointer' }} >
                                                                <img src={mode_edit} />
                                                            </span>
                                                        </>
                                                    }
                                                    {menuAccess && menuAccess.delete_mode === 1 &&
                                                        <>
                                                            <span className="badge badge_md" onClick={() => handleOrgDeleteModalShow(elem)} style={{ cursor: 'pointer' }}>
                                                                <img src={rd_trash} />
                                                            </span>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {organizations.length > 0 &&
                            <PAGINATION allItems={organizations} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                        }
                    </div>
                </Col>

            </Row>

            <Modal backdrop="static" show={orgModalShow} onHide={handleOrgModalClose} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>{orgModalTitle}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Control type="text" name="organization_id" defaultValue={''} {...register('organization_id')} hidden  autoComplete="off" />
                        <Form.Control type="text" name="user_id" defaultValue={''} {...register('user_id')} hidden  autoComplete="off" />
                        <Form.Group className="mb-3">
                            <Form.Label>{orgTypeName} Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="organization" onChange={(event) => setValue('organization', event.target.value)} {...register('organization', { required: "Organization is required" })} readOnly={isViewMode}  autoComplete="off" />

                            {errors.organization?.message && <span className="error">{errors.organization?.message}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Organization Address<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="org_address" onChange={(event) => setValue('org_address', event.target.value)} {...register('org_address', { required: "Address is required" })} readOnly={isViewMode}  autoComplete="off" />

                            {errors.org_address?.message && <span className="error">{errors.org_address?.message}</span>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Organization Phone<span className='text-danger'>*</span></Form.Label>
                            <Form.Control name="org_phone" type="text" defaultValue={orgPhone} onKeyDown={(event) => allowOnlyNumber(event, 'org_phone')} onBlur={(event) => formatUSNumber(event, 'org_phone')} readOnly={isViewMode}  autoComplete="off" />

                            {errors.org_phone && <span className="error">{errors.org_phone.message}</span>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="org_type">
                            <Form.Label>Organization Type<span className='text-danger'>*</span></Form.Label>
                            <Form.Select className="org-dropdown" name="org_type" {...register("org_type")} onChange={(e) => handleInstTypes(e)} defaultValue={''} disabled={isViewMode} >
                                <option value="" >-- Other --</option>
                                {instituteTypeOptions}
                            </Form.Select>

                            {errors.org_type?.message && <span className="error">{errors.org_type?.message}</span>}

                        </Form.Group>

                        <Form.Group className="mb-3" style={{ display: showSchoolType }}>
                            <Form.Label>School Type<span className='text-danger'>*</span></Form.Label>
                            <Form.Select className="org-dropdown" name="school_type" {...register("school_type")} defaultValue={''} disabled={isViewMode}>
                                <option value="K-12 Mainstream School" >K-12 Mainstream School</option>
                                <option value="K-12 Residential Deaf School" >K-12 Residential Deaf School</option>
                                <option value="College/University" >College/University</option>
                            </Form.Select>

                            {errors.school_type?.message && <span className="error">{errors.school_type?.message}</span>}
                        </Form.Group>

                        <Form.Group className="mb-3" style={{ display: showNewType }}>
                            <Form.Label>New Organization Type<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="new_org_type" onChange={(event) => setValue('new_org_type', event.target.value)} {...register('new_org_type')} readOnly={isViewMode}  autoComplete="off" />

                            {errors.new_org_type?.message && <span className="error">{errors.new_org_type?.message}</span>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Admin First Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="first_name" onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} readOnly={isViewMode}  autoComplete="off" />

                            {errors.first_name?.message && <span className="error">{errors.first_name?.message}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Admin Last Name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="last_name" onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name', { required: "Last name is required" })} readOnly={isViewMode}  autoComplete="off" />

                            {errors.last_name?.message && <span className="error">{errors.last_name?.message}</span>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Admin Phone<span className='text-danger'>*</span></Form.Label>
                            <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event, 'admin')} onBlur={(event) => formatUSNumber(event, 'admin')} readOnly={isViewMode}  autoComplete="off" />

                            {errors.phone && <span className="error">{errors.phone.message}</span>}

                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Admin User Name (Email)<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" defaultValue={''} onChange={(event) => setValue('email', event.target.value)} {...register('email',
                                {
                                    required: "User Name is required",
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address"
                                    }
                                }
                            )} readOnly={isViewMode}  autoComplete="off" />

                            {errors.email && <span className="error">{errors.email.message}</span>}

                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Admin Password<span className='text-danger'>*</span></Form.Label>
                            <InputGroup className="password_frm_grp">
                                <Form.Control name="password" placeholder="Enter Password" type={isRevealPwd ? "text" : "password"} defaultValue={''} onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" })} readOnly={isViewMode}  autoComplete="new-password" />
                                <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#2f3034', border: '0px' }}>
                                    <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                </InputGroup.Text>
                            </InputGroup>

                            {errors.password?.message && <span className="error">{errors.password?.message}</span>}

                        </Form.Group>
                        <Form.Label>Packages<span className='text-danger'>*</span></Form.Label>
                        <Form.Group className="mb-3" controlId="package">
                            <Form.Select className="org-dropdown" name="package" {...register("package", { required: 'Package is required' })} onChange={(e) => getPackages(e.target.value)} defaultValue={''} disabled={isViewMode} >
                                <option value="" disabled={!packageOptions ? true : false} >-- N/A --</option>
                                {packageOptions}
                            </Form.Select>

                            {errors.package?.message && <span className="error">{errors.package?.message}</span>}

                        </Form.Group>
                        {!isViewMode &&
                            <Button className='btn-block' size='lg' variant="success" type="submit" >Submit</Button>
                        }
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal className="approve-modal" show={deleteOrgModalShow} onHide={handleOrgDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Deleting this organization will delete all the users and assignments associated with it. Are you sure you want to continue ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleOrgDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deleteOrganization()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size="sm" show={successModalShow} onHide={handleSuccessModalClose} centered>
                <Modal.Header className='justify-content-center'>
                    <div className='text-center w-100'>
                        <Modal.Title className='fs-35 fw-700'>
                            <img src={check} />
                        </Modal.Title>
                        <p className='fs-20 fw-600 mt-3'>Organization Saved Successfully</p>
                    </div>
                </Modal.Header>
            </Modal>

        </>
    )
}

export default Privileged(Organizations);