import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Modal, Badge, Button, Col, Row, Tab, Nav, ProgressBar, } from "react-bootstrap";
import left_arrow from '../../../assets/images/icons/left_arrow.png';
import right_arrow from '../../../assets/images/icons/right_arrow.png';
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import clock from '../../../assets/images/icons/clock.png';
import dummy_video1 from '../../../assets/images/dummy/dummy_video_img.png';
import dummy_video2 from '../../../assets/images/dummy/dummy_video_img2.png';
import dummy_video3 from '../../../assets/images/dummy/dummy_video_img3.png';
import play from '../../../assets/images/icons/play.svg';
import ChangePassword from '../../../components/common/changePassword';

import { NavLink, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST, PATCH } from '../../../utils/axios.util';
import { useSelector } from 'react-redux';

function StudentDashboardCtrl(props) {

    const [fromDate, setFromDate] = useState(new Date(moment().startOf('week').tz(TIME_ZONE)))
    const [toDate, setToDate] = useState(new Date(moment().endOf('week').tz(TIME_ZONE)))
    const [displayFromDate, setDisplayFromDate] = useState('')
    const [displayToDate, setDisplayToDate] = useState('')
    const [latestPlayed, setLatestPlayed] = useState([])
    const [recentlyPlayed, setRecentlyPlayed] = useState([])
    const [categories, setCategories] = useState([])
    const [isCurrentWeek, setIsCurrentWeek] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [passwordModalShow, setPasswordModalShow] = useState(false)
    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)
    const handlePasswordModalClose = () => setPasswordModalShow(false)
    const closeChangePasswordModalShow = () => setChangePasswordModalShow(false)
    const paramRef = useRef({
        category: '',
        fromDate: '',
        toDate: ''
    })

    var params = paramRef.current
    var isDashboardInitialized = useRef(false)
    let navigate = useNavigate()
    var isLoginInitialized = useRef(false)
    let user = useSelector((data) => data?.authenticate)

    useEffect(() => {

        console.log(user)

        if (user.is_loggedin_first === 0) {
            setPasswordModalShow(true)
        }

        if (!isDashboardInitialized.current) {
            initializeDashboard()
        }

        if (!isLoginInitialized.current) {
            initializeLogin()
        }

    }, [latestPlayed, recentlyPlayed, categories]);

    const initializeLogin = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-login`
        }

        POST(payload).then(res => {

            const { data } = res.data

            isLoginInitialized.current = true
            localStorage.user = JSON.stringify(data)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const getDashboard = () => {

        setIsLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/student/dashboard?fromDate=${params.fromDate}&toDate=${params.toDate}&category=${params.category}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            let latests = data.latest
            let recents = data.recently_played

            if (recents.length > 0) {
                recents = recents.slice(0, 4)
            }

            if (latests.length > 0) {
                latests = latests.slice(0, 4)
            }

            setIsLoading(false)
            setCategories(data.categories)
            setLatestPlayed(latests)
            setRecentlyPlayed(recents)
            isDashboardInitialized.current = true
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
        })

    }

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={left_arrow} onClick={onClick} />
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={right_arrow} onClick={onClick} />
        </button>
    ));

    const initializeDates = () => {

        let initialFrom = ''
        let initialTo = ''
        let fromDateMonth = ''
        let toDateMonth = ''
        let fromDateYear = ''
        let toDateYear = ''

        initialFrom = moment().startOf('week').tz(TIME_ZONE)
        initialTo = moment().endOf('week').tz(TIME_ZONE)
        fromDateMonth = initialFrom.format('MMM')
        toDateMonth = initialTo.format('MMM')
        fromDateYear = initialFrom.format('YYYY')
        toDateYear = initialTo.format('YYYY')
        setFromDate(new Date(initialFrom))
        setToDate(new Date(initialTo))

        let fromDateString = initialFrom.format('MMM DD')
        let toDateString = initialTo.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = initialFrom.format('YYYY MMM DD')
            toDateString = initialTo.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = initialTo.format('DD MMM')
            }
        }

        return {
            initialFrom,
            initialTo,
            fromDateString,
            toDateString
        }

    }

    const initializeDashboard = () => {

        const { initialFrom, initialTo, fromDateString, toDateString } = initializeDates('dashboard')
        params.fromDate = initialFrom.format('YYYY-MM-DD')
        params.toDate = initialTo.format('YYYY-MM-DD')
        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)
        setIsCurrentWeek(true)
        getDashboard()

    }

    const changeDates = (tempFrom, tempTo) => {

        let From = tempFrom
        let To = tempTo

        let fromDateMonth = From.format('MMM')
        let toDateMonth = To.format('MMM')
        let fromDateYear = From.format('YYYY')
        let toDateYear = To.format('YYYY')
        let fromDateString = From.format('MMM DD')
        let toDateString = To.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = From.format('YYYY MMM DD')
            toDateString = To.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = To.format('DD MMM')
            }
        }

        params.fromDate = From.format('YYYY-MM-DD')
        params.toDate = To.format('YYYY-MM-DD')
        let isSameWeek = moment(params.fromDate).isSame(params.toDate, 'week')
        let isSameYear = moment(params.fromDate).isSame(params.toDate, 'year')
        if (!isSameWeek || !isSameYear) {
            setIsCurrentWeek(false)
        } else {
            setIsCurrentWeek(true)
        }
        setFromDate(new Date(tempFrom))
        setToDate(new Date(tempTo))
        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)
        getDashboard()

    }

    const validateFromDate = (date) => {

        changeDates(moment(date), moment(params.toDate))
    }

    const validateToDate = (date) => {

        changeDates(moment(params.fromDate), moment(date))
    }

    const changeCategory = (cat) => {

        params.category = cat
        getDashboard()
    }

    const openPasswordModal = () => {
        setPasswordModalShow(false)
        setChangePasswordModalShow(true)
    }

    const submitPassword = (data) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-password`,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setChangePasswordModalShow(false)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })

    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                <h2 className="page_hdng">Dashboard</h2>
                {user?.package_details?.package_name?.toLowerCase() === "enterprise" && <Button className='login-button'>
                    <a target="_blank" href={`${process.env.REACT_APP_CAPTION_TOOL_URL}/app-login?token=${user?.['session_token']}`}>Access Caption Detaction Tool</a>
                </Button>}
            </div>
            <Row>
                {categories.length > 0 &&
                    <Col xl="12" className="mt-3">
                        <Row className="card_outer_hdng">
                            <Col>
                                <h3>Assignments</h3>
                            </Col>
                        </Row>
                        <div className="my_card mt-3">
                            <Row className="justify-content-between align-items-center">
                                <Col className="col-auto">
                                    <div className="assignments_date">
                                        <DatePicker
                                            selected={fromDate}
                                            maxDate={toDate}
                                            onChange={(date) => validateFromDate(date, 'dashboard')}
                                            customInput={<FromDate />}
                                        />
                                        <span className="assign_date">{displayFromDate} to {displayToDate}</span>
                                        <DatePicker
                                            selected={toDate}
                                            minDate={fromDate}
                                            onChange={(date) => validateToDate(date, 'dashboard')}
                                            customInput={<ToDate />}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-auto">
                                    <Button variant="light" onClick={initializeDashboard} disabled={isCurrentWeek ? true : false}>This Week</Button>
                                </Col>
                            </Row>
                            <hr />
                            <Tab.Container id="assignments" defaultActiveKey={categories[0]['id']}>
                                <Nav variant="brand_pills">
                                    {categories.map((elem, index) => {
                                        return (
                                            <Nav.Item key={elem.id}>
                                                <Nav.Link eventKey={elem.id} className="text-capitalize" onClick={() => changeCategory(elem.id)} >{elem.title}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                    }
                                </Nav>

                                <Tab.Content className="p-2">
                                    {categories.map((elem, index) => {

                                        let documentPercentage = Math.floor((elem['formats']['document']['progress'] / elem['formats']['document']['total']) * 100)
                                        let audioPercentage = Math.floor((elem['formats']['audio']['progress'] / elem['formats']['audio']['total']) * 100)
                                        let videoPercentage = Math.floor((elem['formats']['video']['progress'] / elem['formats']['video']['total']) * 100)

                                        documentPercentage = documentPercentage ? documentPercentage : 0
                                        audioPercentage = audioPercentage ? audioPercentage : 0
                                        videoPercentage = videoPercentage ? videoPercentage : 0

                                        return (
                                            <Tab.Pane eventKey={elem.id} key={elem.id}>
                                                <div className="mt-3">
                                                    <div className="progress_row my-2">
                                                        <div className="progress_icon">
                                                            <img height={20} src={ntpd_arrow} />
                                                        </div>
                                                        <div className="progressBar_bg">
                                                            <ProgressBar now={documentPercentage} variant="brand_color2" />
                                                            <label>{elem['formats']['document']['progress']}/{elem['formats']['document']['total']}</label>
                                                        </div>
                                                    </div>

                                                    <div className="progress_row my-2">
                                                        <div className="progress_icon">
                                                            <img height={20} src={mic_arrow} />
                                                        </div>
                                                        <div className="progressBar_bg">
                                                            <ProgressBar now={audioPercentage} variant="brand_color2" />
                                                            <label>{elem['formats']['audio']['progress']}/{elem['formats']['audio']['total']}</label>
                                                        </div>
                                                    </div>

                                                    <div className="progress_row my-2">
                                                        <div className="progress_icon">
                                                            <img height={20} src={video_arrow} />
                                                        </div>
                                                        <div className="progressBar_bg">
                                                            <ProgressBar now={videoPercentage} variant="brand_color2" />
                                                            <label>{elem['formats']['video']['progress']}/{elem['formats']['video']['total']}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        )
                                    })
                                    }
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                }

                <Col>
                    <div className="my_card mt-3">
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-auto">
                                <h5 className="fs-18 fw-600 m-0">Latest Video</h5>
                            </Col>
                            {user.is_content_allowed && latestPlayed.length > 4 &&
                                <Col className="col-auto">
                                    <NavLink to="latest-played">
                                        <Button variant="light">View all</Button>
                                    </NavLink>
                                </Col>
                            }
                        </Row>
                        <div className="video_card_list">
                            <Row>
                                {!user.is_content_allowed &&
                                    <Col lg="12" sm="12">
                                        <div className="video_card">
                                            <div className='text-center' >
                                                <h5>You do not have access to this feature. Contact your administration for further information.</h5>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {user.is_content_allowed && !isLoading && latestPlayed.length === 0 &&

                                    <Col lg="12" sm="12">
                                        <div className="video_card">
                                            <div className='text-center' >
                                                <h5>No Records Found!</h5>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {user.is_content_allowed && latestPlayed.length > 0 &&

                                    latestPlayed.map((elem, index) => {

                                        return (

                                            <Col lg="3" sm="6" key={elem.id} onClick={() => navigate(`/student/assignments/${elem.id}`)} style={{ cursor: 'pointer' }} >
                                                <div className="video_card">
                                                    <img src={elem.thumbnail} />
                                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <h5 className="fs-16 fw-500 m-0">{elem.assignment_title}</h5>
                                                        <div className="d-flex align-items-center opacity5">
                                                            <img src={clock} />
                                                            <span className="fs-10 ms-1">{elem.duration_string}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Badge bg="outline_primary" className="fs-10 me-1">{elem.category_title}</Badge>
                                                        <Badge bg="outline_primary" className="fs-10 me-1">{elem.sub_category_title}</Badge>
                                                    </div>
                                                    <Row className="align-items-center mt-2">
                                                        <Col className="">
                                                            <div className="video_play_progress_status">
                                                                <ProgressBar now={elem.percentage} />
                                                                <label className="fs-10 fw-400 text_brand_color2">{elem.percentage}%</label>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-auto">
                                                            <NavLink to="video">
                                                                <img width="20" src={play} />
                                                            </NavLink>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>

                    <div className="my_card mt-3">
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-auto">
                                <h5 className="fs-18 fw-600 m-0">Recently played</h5>
                            </Col>
                            {user.is_content_allowed && recentlyPlayed.length > 4 &&
                                <Col className="col-auto">
                                    <NavLink to="recently-played">
                                        <Button variant="light">View all</Button>
                                    </NavLink>
                                </Col>
                            }
                        </Row>
                        <div className="video_card_list">
                            <Row>
                                {!user.is_content_allowed &&
                                    <Col lg="12" sm="12">
                                        <div className="video_card">
                                            <div className='text-center' >
                                                <h5>You do not have access to this feature. Contact your administration for further information.</h5>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {user.is_content_allowed && !isLoading && recentlyPlayed.length === 0 &&

                                    <Col lg="12" sm="12">
                                        <div className="video_card">
                                            <div className='text-center' >
                                                <h5>No Records Found!</h5>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {user.is_content_allowed && recentlyPlayed.length > 0 &&

                                    recentlyPlayed.map((elem, index) => {

                                        return (
                                            <Col lg="3" sm="6" key={elem.id} onClick={() => navigate(`/student/assignments/${elem.id}`)} style={{ cursor: 'pointer' }} >
                                                <div className="video_card">
                                                    <img src={elem.thumbnail} />
                                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <h5 className="fs-16 fw-500 m-0">{elem.assignment_title}</h5>
                                                        <div className="d-flex align-items-center opacity5">
                                                            <img src={clock} />
                                                            <span className="fs-10 ms-1">{elem.duration_string}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Badge bg="outline_primary" className="fs-10 me-1">{elem.category_title}</Badge>
                                                        <Badge bg="outline_primary" className="fs-10 me-1">{elem.sub_category_title}</Badge>
                                                    </div>
                                                    <Row className="align-items-center mt-2">
                                                        <Col className="">
                                                            <div className="video_play_progress_status">
                                                                <ProgressBar now={elem.percentage} />
                                                                <label className="fs-10 fw-400 text_brand_color2">{elem.percentage}%</label>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-auto">
                                                            <NavLink to="video">
                                                                <img width="20" src={play} />
                                                            </NavLink>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )
                                    })

                                }
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={passwordModalShow} onHide={handlePasswordModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>You have logged in for the  first time. Click Yes to change your password.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handlePasswordModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openPasswordModal()} >YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ChangePassword changePasswordModalShow={changePasswordModalShow} closeChangePasswordModalShow={closeChangePasswordModalShow} submitPassword={submitPassword} />

        </>
    )
}

export default StudentDashboardCtrl;