import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Modal, Col, Row, Tab, Nav, ProgressBar, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import left_arrow from '../../../assets/images/icons/left_arrow.png';
import right_arrow from '../../../assets/images/icons/right_arrow.png';
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import calendar from '../../../assets/images/icons/calendar.png';
import ChangePassword from '../../../components/common/changePassword';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST, PATCH } from '../../../utils/axios.util';
import { useSelector } from 'react-redux';

function SchoolDashboardCtrl(props) {

    const [fromDate, setFromDate] = useState(new Date(moment().startOf('week').tz(TIME_ZONE)))
    const [toDate, setToDate] = useState(new Date(moment().endOf('week').tz(TIME_ZONE)))
    const [transactionFromDate, setTransactionFromDate] = useState(new Date(moment().startOf('month').tz(TIME_ZONE)))
    const [transactionToDate, setTransactionToDate] = useState(new Date(moment().endOf('month').tz(TIME_ZONE)))
    const [displayFromDate, setDisplayFromDate] = useState('')
    const [displayToDate, setDisplayToDate] = useState('')
    const [displayTransactionFromDate, setDisplayTransactionFromDate] = useState('')
    const [displayTransactionToDate, setDisplayTransactionToDate] = useState('')
    const [transactionIsLoading, setTransactionIsLoading] = useState(false)
    const [categoriesLoading, setCategoriesLoading] = useState(false)
    const [transactionIsNoRecords, setTransactionIsNoRecords] = useState(false)
    const [dashboard, setDashboard] = useState({})
    const [transactions, setTransactions] = useState([])
    const [categories, setCategories] = useState([])
    const [members, setMembers] = useState([])
    const [subscription, setSubscription] = useState({})
    const [payments, setPayments] = useState({})
    const [assignments, setAssignments] = useState({})
    const [users, setUsers] = useState({})
    const [isCurrentWeek, setIsCurrentWeek] = useState(true)
    const [isCurrentMonth, setIsCurrentMonth] = useState(true)
    const [passwordModalShow, setPasswordModalShow] = useState(false)
    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)
    const handlePasswordModalClose = () => setPasswordModalShow(false)
    const closeChangePasswordModalShow = () => setChangePasswordModalShow(false)
    const paramRef = useRef({
        category: '',
        fromDate: '',
        toDate: ''
    })
    const navigate = useNavigate()

    const transactionRef = useRef({
        fromDate: '',
        toDate: ''
    })

    var params = paramRef.current
    var transactnRef = transactionRef.current
    var isDashboardInitialized = useRef(false)
    var isTransactionInitialized = useRef(false)
    var isLoginInitialized = useRef(false)
    let user = useSelector((data) => data?.authenticate)

    useEffect(() => {

        if (user.is_loggedin_first === 0) {
            setPasswordModalShow(true)
        }

        if (!isDashboardInitialized.current) {
            initializeDashboard()
        }
        if (!isTransactionInitialized.current) {
            initializeTransactions()
        }

        if (!isLoginInitialized.current) {
            initializeLogin()
        }

    }, [dashboard, categories, transactions]);

    const getDashboard = () => {

        setCategoriesLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/school/dashboard?fromDate=${params.fromDate}&toDate=${params.toDate}&category=${params.category}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            setCategoriesLoading(false)
            setCategories(data.categories)
            setMembers(data.members)
            if (data.subscription) {
                setSubscription(data.subscription)
            }
            setPayments(data.payments)
            setAssignments(data.assignments)
            setUsers(data.users)

            isDashboardInitialized.current = true
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            setCategoriesLoading(false)
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

        })

    }

    const getTransactions = () => {

        setTransactionIsLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/school/payments/transactions?fromDate=${transactnRef.fromDate}&toDate=${transactnRef.toDate}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setTransactionIsNoRecords(false)
            setTransactionIsLoading(false)
            setTransactions(data)
            isTransactionInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setTransactionIsLoading(false)
            if (err.response.status === 404) {
                setTransactionIsNoRecords(true)
                setTransactions([])
                isTransactionInitialized.current = true
            }
        })

    }

    const initializeLogin = () => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-login`
        }

        POST(payload).then(res => {

            const { data } = res.data

            isLoginInitialized.current = true
            localStorage.user = JSON.stringify(data)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    /* const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={left_arrow} onClick={onClick} />
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={right_arrow} onClick={onClick} />
        </button>
    )); */

    const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            {/* <span>{!fromDate ? 'Start Date' : value}</span> */}
            <span>{!displayFromDate ? 'Start Date' : value}</span>
        </button>
    ));

    const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button className='select_date_box' onClick={onClick} ref={ref}>
            <span>
                <img src={calendar} />
            </span>
            {/* <span>{!toDate ? 'End Date' : value}</span> */}
            <span>{!displayToDate ? 'End Date' : value}</span>
        </button>
    ));

    const initializeDates = (type) => {

        let initialFrom = ''
        let initialTo = ''
        let fromDateMonth = ''
        let toDateMonth = ''
        let fromDateYear = ''
        let toDateYear = ''

        if (type == 'dashboard') {

            initialFrom = moment().startOf('week').tz(TIME_ZONE)
            initialTo = moment().endOf('week').tz(TIME_ZONE)
            fromDateMonth = initialFrom.format('MMM')
            toDateMonth = initialTo.format('MMM')
            fromDateYear = initialFrom.format('YYYY')
            toDateYear = initialTo.format('YYYY')
            setFromDate(new Date(initialFrom))
            setToDate(new Date(initialTo))
        } else {

            initialFrom = moment().startOf('month').tz(TIME_ZONE)
            initialTo = moment().endOf('month').tz(TIME_ZONE)
            fromDateMonth = initialFrom.format('MMM')
            toDateMonth = initialTo.format('MMM')
            fromDateYear = initialFrom.format('YYYY')
            toDateYear = initialTo.format('YYYY')
            setTransactionFromDate(new Date(initialFrom))
            setTransactionToDate(new Date(initialTo))
        }

        let fromDateString = initialFrom.format('MMM DD')
        let toDateString = initialTo.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = initialFrom.format('YYYY MMM DD')
            toDateString = initialTo.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = initialTo.format('DD MMM')
            }
        }

        return {
            initialFrom,
            initialTo,
            fromDateString,
            toDateString
        }

    }

    const initializeDashboard = () => {

        const { initialFrom, initialTo, fromDateString, toDateString } = initializeDates('dashboard')
        params.fromDate = initialFrom.format('YYYY-MM-DD')
        params.toDate = initialTo.format('YYYY-MM-DD')
        setDisplayFromDate(fromDateString)
        setDisplayToDate(toDateString)
        setIsCurrentWeek(true)
        getDashboard()

    }

    const initializeTransactions = () => {

        const { initialFrom, initialTo, fromDateString, toDateString } = initializeDates('transactions')
        transactnRef.fromDate = initialFrom.format('YYYY-MM-DD')
        transactnRef.toDate = initialTo.format('YYYY-MM-DD')
        setDisplayTransactionFromDate(fromDateString)
        setDisplayTransactionToDate(toDateString)
        setIsCurrentMonth(true)
        getTransactions()

    }

    const changeDates = (tempFrom, tempTo, type) => {

        let From = tempFrom
        let To = tempTo

        let fromDateMonth = From.format('MMM')
        let toDateMonth = To.format('MMM')
        let fromDateYear = From.format('YYYY')
        let toDateYear = To.format('YYYY')
        let fromDateString = From.format('MMM DD')
        let toDateString = To.format('DD')

        if (fromDateYear !== toDateYear) {
            fromDateString = From.format('YYYY MMM DD')
            toDateString = To.format('YYYY MMM DD')
        } else {
            if (fromDateMonth !== toDateMonth) {
                toDateString = To.format('DD MMM')
            }
        }

        if (type == 'dashboard') {
            params.fromDate = From.format('YYYY-MM-DD')
            params.toDate = To.format('YYYY-MM-DD')
            let isSameWeek = moment(params.fromDate).isSame(params.toDate, 'week')
            let isSameYear = moment(params.fromDate).isSame(params.toDate, 'year')
            if (!isSameWeek || !isSameYear) {
                setIsCurrentWeek(false)
            } else {
                setIsCurrentWeek(true)
            }
            setFromDate(new Date(tempFrom))
            setToDate(new Date(tempTo))
            setDisplayFromDate(fromDateString)
            setDisplayToDate(toDateString)
            // getDashboard()
        } else {
            transactnRef.fromDate = From.format('YYYY-MM-DD')
            transactnRef.toDate = To.format('YYYY-MM-DD')
            let isSameMonth = moment(transactnRef.fromDate).isSame(transactnRef.toDate, 'month')
            let isSameYear = moment(transactnRef.fromDate).isSame(transactnRef.toDate, 'year')
            if (!isSameMonth || !isSameYear) {
                setIsCurrentMonth(false)
            } else {
                setIsCurrentMonth(true)
            }
            setTransactionFromDate(new Date(tempFrom))
            // setTransactionToDate(new Date(toDate))
            setTransactionToDate(new Date(tempTo))
            setDisplayTransactionFromDate(fromDateString)
            setDisplayTransactionToDate(toDateString)
            // getTransactions()
        }
    }

    const validateFromDate = (date, type) => {

        if (type == 'dashboard') {
            changeDates(moment(date), moment(params.toDate), type)
        } else {
            changeDates(moment(date), moment(transactnRef.toDate), type)
        }
    }

    const validateToDate = (date, type) => {

        if (type == 'dashboard') {
            changeDates(moment(params.fromDate), moment(date), type)
        } else {
            changeDates(moment(transactnRef.fromDate), moment(date), type)
        }
    }

    const changeCategory = (cat) => {

        params.category = cat
        getDashboard()
    }

    const openPasswordModal = () => {
        setPasswordModalShow(false)
        setChangePasswordModalShow(true)
    }

    const submitPassword = (data) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/login/update-password`,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setChangePasswordModalShow(false)

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })

    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                <h2 className="page_hdng">Dashboard</h2>
                    {user?.package_details?.package_name?.toLowerCase() === "enterprise" &&<Button className='login-button'>
                    <a target="_blank" href={`${process.env.REACT_APP_CAPTION_TOOL_URL}/app-login?token=${user?.['session_token']}`}>Access Caption Detection Tool</a>
                    </Button>}
            </div>
            <Row>
                <Col xl="7" className="mt-3">
                    {categories.length > 0 &&
                        <>
                            <Row className="card_outer_hdng">
                                <Col>
                                    <h3>Assignments</h3>
                                </Col>
                            </Row>
                            <div className="my_card mt-3">
                                <Row className="justify-content-between align-items-center">
                                    <Col className="col-auto">
                                        <div className="assignments_date school-admin">
                                            <DatePicker
                                                selected={fromDate}
                                                maxDate={toDate}
                                                onChange={(date) => validateFromDate(date, 'dashboard')}
                                                customInput={<FromDate />}
                                            />
                                            {/* <span className="assign_date">{displayFromDate} to {displayToDate}</span> */}
                                            <span className="assign_date mx-1" style={{ width: 20 }}>to</span>
                                            <DatePicker
                                                selected={toDate}
                                                minDate={fromDate}
                                                onChange={(date) => validateToDate(date, 'dashboard')}
                                                customInput={<ToDate />}
                                            />
                                            <Button className="align-items-center mx-2" variant="dark" onClick={() => getDashboard()} style={{ opacity: 1 }} >Search</Button>
                                        </div>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button variant="light" onClick={initializeDashboard} disabled={isCurrentWeek ? true : false} >This week</Button>
                                    </Col>
                                </Row>
                                <hr />
                                <Tab.Container id="assignments" defaultActiveKey={categories[0]['id']}>
                                    <Nav variant="brand_pills">
                                        {categories.map((elem, index) => {
                                            return (
                                                <Nav.Item key={elem.id}>
                                                    <Nav.Link eventKey={elem.id} className="text-capitalize" onClick={() => changeCategory(elem.id)} >{elem.title}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                        }
                                    </Nav>

                                    <Tab.Content className="p-2">
                                        {categories.map((elem, index) => {

                                            let documentPercentage = Math.floor((elem['formats']['document']['progress'] / elem['formats']['document']['total']) * 100)
                                            let audioPercentage = Math.floor((elem['formats']['audio']['progress'] / elem['formats']['audio']['total']) * 100)
                                            let videoPercentage = Math.floor((elem['formats']['video']['progress'] / elem['formats']['video']['total']) * 100)

                                            documentPercentage = documentPercentage ? documentPercentage : 0
                                            audioPercentage = audioPercentage ? audioPercentage : 0
                                            videoPercentage = videoPercentage ? videoPercentage : 0

                                            return (
                                                <Tab.Pane eventKey={elem.id} key={elem.id}>
                                                    <div className="mt-3">

                                                        {categoriesLoading &&
                                                            <div className="text-center" >
                                                                Loading...
                                                            </div>
                                                        }
                                                        {!categoriesLoading &&
                                                            <>
                                                                <div className="progress_row my-2">
                                                                    <div className="progress_icon">
                                                                        <img height={20} src={ntpd_arrow} />
                                                                    </div>
                                                                    <div className="progressBar_bg">
                                                                        <ProgressBar now={documentPercentage} variant="brand_color2" />
                                                                        <label>{elem['formats']['document']['progress']}/{elem['formats']['document']['total']}</label>
                                                                    </div>
                                                                </div>

                                                                <div className="progress_row my-2">
                                                                    <div className="progress_icon">
                                                                        <img height={20} src={mic_arrow} />
                                                                    </div>
                                                                    <div className="progressBar_bg">
                                                                        <ProgressBar now={audioPercentage} variant="brand_color2" />
                                                                        <label>{elem['formats']['audio']['progress']}/{elem['formats']['audio']['total']}</label>
                                                                    </div>
                                                                </div>

                                                                <div className="progress_row my-2">
                                                                    <div className="progress_icon">
                                                                        <img height={20} src={video_arrow} />
                                                                    </div>
                                                                    <div className="progressBar_bg">
                                                                        <ProgressBar now={videoPercentage} variant="brand_color2" />
                                                                        <label>{elem['formats']['video']['progress']}/{elem['formats']['video']['total']}</label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                </Tab.Pane>
                                            )
                                        })
                                        }
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </>
                    }

                </Col>

                <Col xl="5" className="">
                    <Row>
                        <Col sm="12">
                            <Row className="mt-3 card_outer_hdng justify-content-between align-items-center">
                                <Col>
                                    <h3>Users</h3>
                                </Col>
                                {members.length > 5 &&
                                    <Col className="col-auto">
                                        <Button className="" variant="secondary" onClick={() => navigate('/school/users')} >View All</Button>
                                    </Col>
                                }

                            </Row>
                            <div className="my_card mt-3">
                                <Row className="gx-1 justify-content-center align-items-center">
                                    {members.length === 0 &&
                                        <h5 className="text-center">No Records Found!</h5>
                                    }
                                    {members.length > 0 && members.map((elem, index) => {

                                        return (
                                            <Col key={elem.id}>
                                                <div className="members">
                                                    <img className='tbl_usr_img' src={elem.picture_path} />
                                                    <h5>{elem.full_name}</h5>
                                                    <p className="text-capitalize">{elem.role == 'sub_admin' ? 'Sub Admin' : elem.role}</p>
                                                </div>
                                            </Col>
                                        )

                                    })

                                    }
                                </Row>
                            </div>
                        </Col>
                        {Object.keys(subscription).length > 0 &&
                            <Col sm="12">
                                <Row className="card_outer_hdng mt-4">
                                    <Col>
                                        <h3>My Subscription</h3>
                                    </Col>
                                </Row>
                                <div className="my_card_brand_color3 mt-3 p-3">
                                    <Row className="basic_subscription justify-content-center align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <span>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="4" cy="4" r="4" fill="#F2F1FF" />
                                                        <circle opacity="0.8" cx="4" cy="12" r="4" fill="#F2F1FF" />
                                                        <circle opacity="0.6" cx="4" cy="20" r="4" fill="#F2F1FF" />
                                                        <circle opacity="0.4" cx="12" cy="20" r="4" fill="#F2F1FF" />
                                                        <circle cx="20" cy="20" r="4" fill="#F2F1FF" />
                                                        <circle cx="12" cy="12" r="4" fill="#F2F1FF" />
                                                    </svg>
                                                </span>
                                                <h5 className="mb-0 ms-3">{subscription.name} Subscription</h5>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <button className="change_plan_btn" onClick={() => navigate('/school/subscriptions')} >Change plan</button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        }

                    </Row>

                </Col>
                <Col xl="7" className="">
                    <Row className="card_outer_hdng mt-3">
                        <Col>
                            <h3>Assignments</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.requested : 0}</h4>
                                <p>requested</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.progress : 0}</h4>
                                <p>in progress</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.review : 0}</h4>
                                <p>under Review</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(assignments).length > 0 ? assignments.completed : 0}</h4>
                                <p>completed</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="card_outer_hdng mt-3">
                        <Col>
                            <h3>Users</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(users).length > 0 ? users.sub_admin : 0}</h4>
                                <p>sub admins</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(users).length > 0 ? users.coordinator : 0}</h4>
                                <p>coordinators</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(users).length > 0 ? users.interpreter : 0}</h4>
                                <p>interpreters</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>{Object.keys(users).length > 0 ? users.student : 0}</h4>
                                <p>students</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="card_outer_hdng mt-3">
                        <Col>
                            <h3>Payments</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card active">
                                <h4>${Object.keys(payments).length > 0 ? payments.week : 0}</h4>
                                <p>this week</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>${Object.keys(payments).length > 0 ? payments.month : 0}</h4>
                                <p>this month</p>
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <div className="assignments_card">
                                <h4>${Object.keys(payments).length > 0 ? payments.total : 0}</h4>
                                <p>Total So Far</p>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col xl="5" className="mt-3 transactions">
                    <Row className="card_outer_hdng">
                        <Col>
                            <h3>transactions</h3>
                        </Col>
                        <Col className="col-auto">
                            <div className="d-flex justify-content-between">
                                <div className="assignments_date school-admin-transactions">

                                    <DatePicker
                                        selected={transactionFromDate}
                                        maxDate={transactionToDate}
                                        onChange={(date) => validateFromDate(date, 'transactions')}
                                        customInput={<FromDate />}
                                    />
                                    {/*  <span className="assign_date">{displayTransactionFromDate} to {displayTransactionToDate}</span> */}
                                    <span className="assign_date mx-1" style={{ width: 20 }}>to</span>
                                    <DatePicker
                                        selected={transactionToDate}
                                        minDate={transactionFromDate}
                                        onChange={(date) => validateToDate(date, 'transactions')}
                                        customInput={<ToDate />}
                                    />
                                </div>
                                {/* <Button variant="secondary" onClick={initializeTransactions} disabled={isCurrentMonth ? true : false} >{moment().tz(TIME_ZONE).format('MMMM')}</Button> */}
                                <Button className="align-items-center mx-2" variant="secondary" onClick={() => getTransactions()} >Search</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div className="my_card mt-3 transactions">
                                <table>
                                    <tbody>
                                        {transactionIsLoading &&
                                            <tr>
                                                <td className="user_table text-center" width="100">
                                                    Loading...
                                                </td>
                                            </tr>
                                        }
                                        {!transactionIsLoading && (transactionIsNoRecords || transactions.length === 0) &&
                                            <tr>
                                                <td className="user_table text-center" width="100">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        {!transactionIsLoading && transactions.length > 0 && transactions.map((elem, index) => {

                                            return (
                                                <tr key={elem.id}>
                                                    <td className="user_table" width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td className="text-left">
                                                        <h6 className="m-0">{elem.full_name}</h6>
                                                        <p className="m-0">{elem.assignments_count} Assignments converted </p>
                                                    </td>
                                                    <td className="transact_amnt">+${elem.amount}</td>
                                                </tr>
                                            )

                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal className="approve-modal" show={passwordModalShow} onHide={handlePasswordModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>You have logged in for the  first time. Click 'Yes' to change your password.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handlePasswordModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openPasswordModal()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ChangePassword changePasswordModalShow={changePasswordModalShow} closeChangePasswordModalShow={closeChangePasswordModalShow} submitPassword={submitPassword} />
        </>
    )
}

export default SchoolDashboardCtrl;