import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import left_arrow from "../../../assets/images/icons/left_arrow.png";
import right_arrow from "../../../assets/images/icons/right_arrow.png";
import ntpd_arrow from "../../../assets/images/icons/ntpd_arrow.png";
import mic_arrow from "../../../assets/images/icons/mic_arrow.png";
import video_arrow from "../../../assets/images/icons/video_arrow.png";
import calendar from "../../../assets/images/icons/calendar.png";

import { TIME_ZONE, BACKEND_DOMAIN } from "../../../config/variables";
import { GET } from "../../../utils/axios.util";
import { useSelector } from "react-redux";

function SubAdminDashboard(props) {
  const [transactionFromDate, setTransactionFromDate] = useState(
    new Date(moment().startOf("month").tz(TIME_ZONE))
  );
  const [transactionToDate, setTransactionToDate] = useState(
    new Date(moment().endOf("month").tz(TIME_ZONE))
  );
  const [displayTransactionFromDate, setDisplayTransactionFromDate] =
    useState("");
  const [displayTransactionToDate, setDisplayTransactionToDate] = useState("");
  const [transactionIsLoading, setTransactionIsLoading] = useState(false);
  const [transactionIsNoRecords, setTransactionIsNoRecords] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [payments, setPayments] = useState({});
  const [interpretations, setInterpretations] = useState({});
  const [users, setUsers] = useState({});
  const [isCurrentMonth, setIsCurrentMonth] = useState(true);
  let user = useSelector((data) => data?.authenticate);

  // const [ fromDate, setFromDate ] = useState('');
  // const [ toDate, setToDate ] = useState('');

  const transactionRef = useRef({
    fromDate: "",
    toDate: "",
  });

  var transactnRef = transactionRef.current;
  var isDashboardInitialized = useRef(false);
  var isTransactionInitialized = useRef(false);

  useEffect(() => {
    if (!isDashboardInitialized.current) {
      getDashboard();
    }

    if (!isTransactionInitialized.current) {
      initializeTransactions();
    }
  }, [dashboard, transactions]);

  const getDashboard = () => {
    let payload = {
      url: `${BACKEND_DOMAIN}/sub-admin/dashboard`,
    };

    GET(payload)
      .then((res) => {
        const { data } = res.data;
        console.log(data, "------------- DATA");

        setPayments(data.payments);
        setInterpretations(data.interpretations);
        setUsers(data.users);
        isDashboardInitialized.current = true;
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        console.log(err);
        isDashboardInitialized.current = true;
      });
  };

  const getTransactions = () => {
    setTransactionIsLoading(true);
    let payload = {
      url: `${BACKEND_DOMAIN}/sub-admin/payments/transactions?fromDate=${transactnRef.fromDate}&toDate=${transactnRef.toDate}`,
    };

    GET(payload)
      .then((res) => {
        const { data } = res.data;

        setTransactionIsNoRecords(false);
        setTransactionIsLoading(false);
        setTransactions(data);
        isTransactionInitialized.current = true;
      })
      .catch((err) => {
        // Session Logout Code
        if (err.response && err.response.status === 401) {
          toast.error("Session Expired! Logging out...", { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        setTransactionIsLoading(false);
        if (err.response.status === 404) {
          setTransactionIsNoRecords(true);
          setTransactions([]);
          isTransactionInitialized.current = true;
        }
      });
  };

  /* const FromDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={left_arrow} onClick={onClick} />
        </button>
    )); */

  const FromDate = forwardRef(({ value, onClick }, ref) => (
    <button className="select_date_box" onClick={onClick} ref={ref}>
      <span>
        <img src={calendar} />
      </span>
      {/* <span>{!fromDate ? 'Start Date' : value}</span> */}
      <span>{!displayTransactionFromDate ? "Start Date" : value}</span>
    </button>
  ));

  /* const ToDate = forwardRef(({ value, onClick }, ref) => (
        <button>
            <img src={right_arrow} onClick={onClick} />
        </button>
    )); */

  const ToDate = forwardRef(({ value, onClick }, ref) => (
    <button className="select_date_box" onClick={onClick} ref={ref}>
      <span>
        <img src={calendar} />
      </span>
      {/* <span>{!toDate ? 'End Date' : value}</span> */}
      <span>{!displayTransactionToDate ? "End Date" : value}</span>
    </button>
  ));

  const initializeDates = () => {
    let initialFrom = "";
    let initialTo = "";
    let fromDateMonth = "";
    let toDateMonth = "";
    let fromDateYear = "";
    let toDateYear = "";

    initialFrom = moment().startOf("month").tz(TIME_ZONE);
    initialTo = moment().endOf("month").tz(TIME_ZONE);
    fromDateMonth = initialFrom.format("MMM");
    toDateMonth = initialTo.format("MMM");
    fromDateYear = initialFrom.format("YYYY");
    toDateYear = initialTo.format("YYYY");
    setTransactionFromDate(new Date(initialFrom));
    setTransactionToDate(new Date(initialTo));

    let fromDateString = initialFrom.format("MMM DD");
    let toDateString = initialTo.format("DD");

    if (fromDateYear !== toDateYear) {
      fromDateString = initialFrom.format("YYYY MMM DD");
      toDateString = initialTo.format("YYYY MMM DD");
    } else {
      if (fromDateMonth !== toDateMonth) {
        toDateString = initialTo.format("DD MMM");
      }
    }

    return {
      initialFrom,
      initialTo,
      fromDateString,
      toDateString,
    };
  };

  const initializeTransactions = () => {
    const { initialFrom, initialTo, fromDateString, toDateString } =
      initializeDates("transactions");
    transactnRef.fromDate = initialFrom.format("YYYY-MM-DD");
    transactnRef.toDate = initialTo.format("YYYY-MM-DD");
    setDisplayTransactionFromDate(fromDateString);
    setDisplayTransactionToDate(toDateString);
    setIsCurrentMonth(true);
    getTransactions();
  };

  const changeDates = (tempFrom, tempTo, type) => {
    let From = tempFrom;
    let To = tempTo;

    let fromDateMonth = From.format("MMM");
    let toDateMonth = To.format("MMM");
    let fromDateYear = From.format("YYYY");
    let toDateYear = To.format("YYYY");
    let fromDateString = From.format("MMM DD");
    let toDateString = To.format("DD");

    if (fromDateYear !== toDateYear) {
      fromDateString = From.format("YYYY MMM DD");
      toDateString = To.format("YYYY MMM DD");
    } else {
      if (fromDateMonth !== toDateMonth) {
        toDateString = To.format("DD MMM");
      }
    }

    transactnRef.fromDate = From.format("YYYY-MM-DD");
    transactnRef.toDate = To.format("YYYY-MM-DD");
    let isSameMonth = moment(transactnRef.fromDate).isSame(
      transactnRef.toDate,
      "month"
    );
    let isSameYear = moment(transactnRef.fromDate).isSame(
      transactnRef.toDate,
      "year"
    );
    if (!isSameMonth || !isSameYear) {
      setIsCurrentMonth(false);
    } else {
      setIsCurrentMonth(true);
    }
    setTransactionFromDate(new Date(tempFrom));
    setTransactionToDate(new Date(To));
    setDisplayTransactionFromDate(fromDateString);
    setDisplayTransactionToDate(toDateString);
    // getTransactions()
  };

  const validateFromDate = (date, type) => {
    changeDates(moment(date), moment(transactnRef.toDate), type);
  };

  const validateToDate = (date, type) => {
    changeDates(moment(transactnRef.fromDate), moment(date), type);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page_hdng">Dashboard</h2>
        {user?.package_details?.package_name?.toLowerCase() ===
          "enterprise" && (
          <Button className="login-button">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_CAPTION_TOOL_URL}/app-login?token=${user?.["session_token"]}`}
            >
              Access Caption Detection Tool
            </a>
          </Button>
        )}
      </div>
      <Row>
        <Col xl="7" className="">
          <Row className="card_outer_hdng mt-3">
            <Col>
              <h3>Interpretations</h3>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  {Object.keys(interpretations).length > 0
                    ? interpretations.requested
                    : 0}
                </h4>
                <p>requested</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  {Object.keys(interpretations).length > 0
                    ? interpretations.progress
                    : 0}
                </h4>
                <p>in progress</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  {Object.keys(interpretations).length > 0
                    ? interpretations.review
                    : 0}
                </h4>
                <p>under Review</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  {Object.keys(interpretations).length > 0
                    ? interpretations.completed
                    : 0}
                </h4>
                <p>completed</p>
              </div>
            </Col>
          </Row>

          <Row className="card_outer_hdng mt-3">
            <Col>
              <h3>Users</h3>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>{Object.keys(users).length > 0 ? users.admin : 0}</h4>
                <p>admin</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>{Object.keys(users).length > 0 ? users.sub_admin : 0}</h4>
                <p>sub admin</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>{Object.keys(users).length > 0 ? users.coordinator : 0}</h4>
                <p>coordinator</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>{Object.keys(users).length > 0 ? users.student : 0}</h4>
                <p>student</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>{Object.keys(users).length > 0 ? users.interpreter : 0}</h4>
                <p>interpreter</p>
              </div>
            </Col>
          </Row>

          <Row className="card_outer_hdng mt-3">
            <Col>
              <h3>Payments</h3>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card active">
                <h4>${Object.keys(payments).length > 0 ? payments.week : 0}</h4>
                <p>this week</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  ${Object.keys(payments).length > 0 ? payments.month : 0}
                </h4>
                <p>this month</p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="assignments_card">
                <h4>
                  ${Object.keys(payments).length > 0 ? payments.total : 0}
                </h4>
                <p>Current Total</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl="5" className="mt-3 transactions">
          <Row className="card_outer_hdng">
            <Col>
              <h3>transactions</h3>
            </Col>
            <Col className="col-auto">
              <div className="d-flex">
                {/* <div className="assignments_date primary"> */}

                <DatePicker
                  selected={transactionFromDate}
                  maxDate={transactionToDate}
                  onChange={(date) => validateFromDate(date, "transactions")}
                  customInput={<FromDate />}
                />
                {/* <span className="assign_date">{displayTransactionFromDate} to {displayTransactionToDate}</span> */}
                <span className="assign_date mx-1" style={{ width: 20 }}>
                  to
                </span>
                <DatePicker
                  selected={transactionToDate}
                  minDate={transactionFromDate}
                  onChange={(date) => validateToDate(date, "transactions")}
                  customInput={<ToDate />}
                />
                {/* </div> */}
                {/* <Button variant="secondary" onClick={initializeTransactions} disabled={isCurrentMonth ? true : false} >{moment().tz(TIME_ZONE).format('MMMM')}</Button> */}

                <Button
                  className="align-items-center"
                  variant="secondary"
                  onClick={() => getTransactions()}
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div className="my_card mt-3 transactions">
                <table>
                  <tbody>
                    {transactionIsLoading && (
                      <tr>
                        <td className="user_table text-center" width="100">
                          Loading...
                        </td>
                      </tr>
                    )}
                    {(transactionIsNoRecords || transactions.length === 0) && (
                      <tr>
                        <td className="user_table text-center" width="100">
                          No Records Found!
                        </td>
                      </tr>
                    )}
                    {!transactionIsLoading &&
                      transactions.length > 0 &&
                      transactions.map((elem, index) => {
                        return (
                          <tr key={elem.id}>
                            <td className="user_table" width="50">
                              <img
                                className="tbl_usr_img"
                                src={elem.picture_path}
                              />
                            </td>
                            <td className="text-left">
                              <h6 className="m-0">{elem.full_name}</h6>
                              <p className="m-0">
                                {elem.assignments_count} Assignments converted{" "}
                              </p>
                            </td>
                            <td className="transact_amnt">+${elem.amount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SubAdminDashboard;
