import React, { useState, useEffect } from 'react';
import {Col, Button,Row,InputGroup,Form, FloatingLabel, Tab, Nav, Badge } from "react-bootstrap";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../../assets/images/dummy.png';
import paper_clip from '../../../assets/images/icons/paper_clip.png';
import search from '../../../assets/images/icons/search.png';
import member2 from '../../../assets/images/member2.png';

import firebaseDb from '../../../utils/firebase.util';
import { set, ref, push, update, onChildAdded } from "firebase/database";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';

function StudentChatCtrl(props) {

    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const user = props.user

    const [ chatsList, setChatsList ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)

    useEffect(() => {

        if(chatsList.length === 0 ) {
            setIsLoading(true)
            getChats()
        }
        
    },[ chatsList ]);

    const getChats = () => {

        const chatListRef = ref(firebaseDb, `support/user_${user.id}`);

        let chats = [...chatsList]

        setIsLoading(false)

        onChildAdded(chatListRef, (data) => {

            let value= data.val()
            value['key'] = data.key
            chats = [...chats, value]

            if(data.val().role != user.role) {

                const updates = {};
                updates[`support/user_${user.id}/${data.key}`] = {
                    ...value,
                    is_seen: 1,
                    timestamp: moment().tz(TIME_ZONE).format('XX')
                };

                update(ref(firebaseDb), updates);

            }
            
            setChatsList(chats)
        });

    }

    const onSubmit = (data) => {

        setValue('chat', '')

        let payload= {
            user_id: user.id,
            full_name: `${user.first_name} ${user.last_name}`,
            picture_path: `${user.picture_path}`,
            is_seen: 0,
            role: user.role,
            message: data.chat,
            status: 'online',
            timestamp: moment().tz(TIME_ZONE).format('XX')
        }

        const chatListRef = ref(firebaseDb, `support/user_${user.id}`);
        const newChatRef = push(chatListRef);
        const key = newChatRef.key;

        payload['key'] = key

        set(newChatRef, payload)
        .catch(error => {
            console.log(error)
        });

    }

    return(
        <>
            <h2 className="page_hdng">Chats</h2>
            <div className='my_card chat_contact mt-4'>
                <Row>
                    <Col sm="12" lg="12">
                        <div className="my_card_brand_dark_color">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='chat_active_uesr'>
                                    <img src={dummy_img} />
                                    <div className=''>
                                        <h5 className='fs-20 fw-600 m-0'>Olive</h5>
                                        <span className='online_mark'>online</span>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='chat_msg_container'>
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                <ul className='chat_msg_list'>
                                    { chatsList.length > 0 &&

                                        chatsList.map((elem, index) => {

                                            let classValue = 'chat_msg_list_item msg_me'

                                            if(elem.role != user.role) {
                                                classValue = 'chat_msg_list_item msg_other'
                                            } 

                                            return (
                                                <li className={classValue} key={elem.key}>
                                                    <div className='chat_msg'>{elem.message}</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <FloatingLabel controlId="floatingTextarea" className="chat_textarea" >
                                    <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Form.Control as="textarea" placeholder="Type something" name="chat" {...register('chat')} defaultValue={''} onChange={(e) => setValue('chat', e.target.value)}  autoComplete="off" />
                                        <div className='chat_textarea_buttons'>
                                            <div className="upload_btn_wrapper">
                                                <button className="attach_btn">
                                                    <img height="15" src={paper_clip} />
                                                    <span className='ms-2'>Attach</span>
                                                </button>
                                                <Form.Control type="file" name="myfile"  autoComplete="off"/>
                                            </div>
                                            <Button variant='success' type="submit">Send</Button>
                                        </div>
                                    </Form>
                                </FloatingLabel>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate
    }
}
  
export default connect(mapStateToProps, null)(StudentChatCtrl);