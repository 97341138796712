import React from "react";
import { Badge, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { audio } from "../../redux/actions";
// import sqr_dummy_video_img from '../../assets/images/dummy/sqr_dummy_video_img.png';
import sqr_dummy_video_img from '../../assets/images/logo.png';
import play_icon from '../../assets/images/icons/play_icon.png';
import dummy_audio from '../../assets/soniye.mp3';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'

function AudioPlayerComponent(props){

    const dispatch = useDispatch()
    const filePath =  props.audio.file_path
    const title =  props.audio.title
    const category =  props.audio.category
    const subCategory =  props.audio.sub_category

    const closePlayer = () => {
        dispatch(audio(''))
    }

    return(
        <div className="text-center text_white_color">
            <button className="audio-btn-close" onClick={closePlayer}>x</button>
            <img src={sqr_dummy_video_img} />
            <p className="my-2">{title}</p>
            <div className="d-flex w-100 justify-content-center">
                <Badge bg="outline-primary" className="mx-2">{category}</Badge>
                <Badge bg="outline-primary" className="mx-2">{subCategory}</Badge>
            </div>
            <div>
            <AudioPlayer 
                autoPlay
                src={filePath} 
                showSkipControls={false}
                showJumpControls={false} 
                layout="horizontal-reverse"
                customAdditionalControls={[]}
            />
            </div>
        </div>
    )
}

export default AudioPlayerComponent;