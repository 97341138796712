import React, { useEffect, useState } from 'react';
import {Col, Button, Row, Dropdown, Form, FloatingLabel } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import dummy_img from '../../assets/images/dummy.png';
import rd_trash from '../../assets/images/icons/rd_trash.png';
import paper_clip from '../../assets/images/icons/paper_clip.png';

import { BACKEND_DOMAIN } from '../../config/variables';
import { GET, POST } from '../../utils/axios.util';

function SupportForm(props){

    const { register, handleSubmit, setError, clearErrors, reset, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const [ reasons, setReasons ] = useState([])
    const [ options, setOptions ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ fileList, setFileList ] = useState([]);

    useEffect(() => {

        if(reasons.length === 0) {
            getSupportReasons()
        }

        if(reasons.length > 0 && !options) {
            let optionValues =  reasons.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.title}</option>
                )
            }) 
            setOptions(optionValues)
        }
        
    },[reasons]);

    const getSupportReasons = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/support/reasons`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data

                setReasons(data);
                setIsLoading(false)
            }
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const onSubmit = (data) => {

        clearErrors('attachment')
        const formData = new FormData();
        formData.append("reason", data.reason);
        formData.append("description", data.description);
        if(fileList.length > 0) {
            fileList.forEach((file, i) => {
                formData.append(`attachment`, file);
            });
        }
       
        let payload = {
            url : `${BACKEND_DOMAIN}/support/submit`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            setFileList([])
            reset()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const isValidFileUploaded=(file)=>{
        const validExtensions = ['png','jpeg','jpg']
        const fileExtension = file.type.split('/')[1]
        const typePattern = /image/
        return validExtensions.includes(fileExtension) || typePattern.test(file.type)
    }

    const handleUploadChange = (event) => {

        clearErrors('attachment')
        let files = event.target.files

        if(files && files !== undefined && files !== null){ 
    
            let tempFiles = []
            for(let x in files) {

                if(typeof files[x] === 'object') {
                    if(isValidFileUploaded(files[x])){
                        tempFiles.push(files[x])
                    }else {
                        setFileList([])
                        setError('attachment', { type: 'custom', message: 'Invaid Image type' });
                        return
                    }
                }

            }
            setFileList(tempFiles)
           
        }
    }

    return(
        <>
            { isLoading && 
                <div className='text-center subscriptions_packages_name no_records_loading'>
                    <h5>Loading...</h5>
                </div>
            }
            { !isLoading && (reasons.length > 0) &&
                <>
                    <h2 className="page_hdng">Support</h2>
                    <div className='my_card mt-4'>
                        <div className="my_card_brand_dark_color py-5">
                            <div className='d-flex align-items-center justify-content-end'>
                                <Button variant='trans_success' onClick={() => navigate(-1)}>Back</Button>
                            </div>
                            <hr/>
                            <Row className='justify-content-center py-5'>
                                <Col lg="6">
                                    <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}  autoComplete="off">

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Reason</Form.Label>
                                            <Form.Select name="reason" {...register("reason", { required: 'Reason is required' })} >
                                                <option value="" >-- Select a Reason --</option>
                                                {options}
                                            </Form.Select>

                                            {errors.reason ?.message && <span className="error">{errors.reason ?.message}</span> }

                                        </Form.Group>
        
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control rows={6}  as="textarea" placeholder='Enter description here' name="description" {...register("description", { required: 'Description is required' })} />

                                            {errors.description ?.message && <span className="error">{errors.description ?.message}</span> }

                                        </Form.Group>
                                        <Form.Group className="form-group mb-3" controlId="">
                                            <div className="upload_btn_wrapper">
                                                <button className="attach_btn">
                                                    <img height="15" src={paper_clip} />
                                                    <span className='ms-2'>Attachment</span>
                                                </button>
                                                <Form.Control  type="file" name="attachment" onChange={handleUploadChange} accept="image/*" multiple autoComplete="off" />

                                                { fileList.length > 0 && 
                                                    <>
                                                        <p>
                                                            {fileList.length} Files
                                                            <span className="badge badge_md" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => setFileList([])} >
                                                                <img src={rd_trash} />
                                                            </span>
                                                        </p>
                                                    </>
                                                }

                                                {errors.attachment ?.message && <span className="error">{errors.attachment ?.message}</span> }
                                            </div>

                                        </Form.Group>
                                        
                                        <Form.Group className="d-grid">
                                            <Button size='lg' variant="success" type="submit" > Generate Ticket</Button>
                                        </Form.Group>
        
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default SupportForm;