import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import NoAccess from '../components/common/noaccess'

const Privileged = (OriginalComponent) => {

    return function NewComponent(props) {

        const user = useSelector(state => state.authenticate)
        const location = useLocation();

        if((user.role == 'sub_super_admin' || user.role == 'sub_admin')) {
            if(user.role_access && user.role_access.length > 0) {

                var locationPaths = location.pathname
                if(/organizations/i.test(locationPaths)) {

                    let createRegEx = new RegExp('create', 'i');
                    let editRegEx = new RegExp('edit', 'i');

                    if(/users/i.test(locationPaths)) {

                        let userMenuFilter = user.role_access.filter(item => {
                            return /users/i.test(item['menu_name'])
                        })

                        if(userMenuFilter.length > 0){

                            if(( (createRegEx.test(locationPaths) && userMenuFilter[0].create_mode === 1) || (editRegEx.test(locationPaths) && userMenuFilter[0].update_mode === 1) ) ) {
    
                                return <OriginalComponent />
                            }else {
                                
                                return <NoAccess />
                            }
                        }else {
                                
                            return <NoAccess />
                        }
                    }else {

                        let orgMenuFilter = user.role_access.filter(item => {
                            return /organizations/i.test(item['menu_name'])
                        })

                        if(orgMenuFilter.length > 0) {
                            
                            return <OriginalComponent />
                        }else {
                            return <NoAccess />
                        }
                        
                    }
                    
                }else {

                    let menuFilter = user.role_access.filter(item => {
                        let menuRegEx = new RegExp(item['menu_name'], 'i');
                        return menuRegEx.test(location.pathname)
                    })

                    if(menuFilter.length > 0){

                        menuFilter = menuFilter[0]

                        let menuRegEx = new RegExp(menuFilter['menu_name'], 'i');
                        let createRegEx = new RegExp('create', 'i');
                        let assignRegEx = new RegExp('assign', 'i');
                        let editRegEx = new RegExp('edit', 'i');

                        /* if(
                        (menuRegEx.test(locationPaths) && createRegEx.test(locationPaths) && (menuFilter['create_mode'] === 1)) || (menuRegEx.test(locationPaths) && editRegEx.test(locationPaths) && (menuFilter['update_mode'] === 1)) || (menuRegEx.test(locationPaths) && !createRegEx.test(locationPaths) && !editRegEx.test(locationPaths) && (menuFilter['view_mode'] === 1)) ) { */
                        if((menuRegEx.test(locationPaths) && createRegEx.test(locationPaths) && (menuFilter['create_mode'] === 1)) || (menuRegEx.test(locationPaths) && !createRegEx.test(locationPaths) && !assignRegEx.test(locationPaths)) || (/interpretations/i.test(locationPaths) && ((assignRegEx.test(locationPaths) && menuFilter['assign_interpreter'] === 1) || (!assignRegEx.test(locationPaths)))) ) {

                            return <OriginalComponent />
                        }else {
                            return <NoAccess />
                        }
                    }else {
                        return <NoAccess />
                    }
                }
                
            }
            
        }else {

            return <OriginalComponent {...props} />
        }

    }

}

export default Privileged;