import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Col, Button, Row, Form } from "react-bootstrap";
import dummy_img from '../../../assets/images/default-avatar.png';
import upload_img from '../../../assets/images/icons/upload.png';

import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { POST } from '../../../utils/axios.util';
import useDivisions from '../../../hooks/useDivisions';

function CoordinatorAddStudentCtrl() {

    const { register, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const divisions = useDivisions()

    const [displayDate, setDisplayDate] = useState('')
    const [date, setDate] = useState('')
    const [options, setOptions] = useState(null)
    const [file, setFile] = useState('')
    const [phone, setPhone] = useState('');
    const [previewImage, setPreviewImage] = useState(dummy_img);

    useEffect(() => {

        if (divisions.length > 0 && !options) {
            let optionValues = divisions.map((e) => {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            })
            setOptions(optionValues)
        }

    }, [divisions]);

    const onSubmit = (data) => {

        clearErrors('phone')

        data['phone'] = phone

        if (!data.phone) {
            setError("phone", {
                type: "manual",
                message: "Phone number is required"
            });
            return;
        }

        data['dob'] = moment(data.dob).format('YYYY-MM-DD')
        
        const formData = new FormData();
        formData.append("avatar", file);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("parents_name", data.parents_name);
        formData.append("division_id", data.division_id);
        formData.append("email", data.email);
        formData.append("contact_email", data.contact_email);
        formData.append("dob", data.dob);
        formData.append("password", data.password);
        formData.append("phone", phone);

        let payload = {
            url: `${BACKEND_DOMAIN}/coordinator/students/create`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            navigate('/coordinator/students')

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handleUploadChange = (event) => {

        if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0] !== null) {

            setFile(event.target.files[0])
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    const allowOnlyNumber = (e) => {

        setPhone(e.target.value)
        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {

            if (pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue = String.fromCharCode(e.keyCode);
        let key = e.which

        if (valLength > maxLength) {

            if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46)) {
                return true;
            } else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if ((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue)) {
            return true;
        } else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */

    }

    const formatUSNumber = (e) => {

        clearErrors('phone')

        let entry = e.target.value

        if (entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if (pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            if (count != 10) {
                setError("phone", {
                    type: "manual",
                    message: "Number must be ten digits"
                });
                return;
            }

            const match = entry
                .replace(/\D+/g, '')
                .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
            e.target.value = `${part1}${part2}${part3}`
            setPhone(`${part1}${part2}${part3}`)

            return `${part1}${part2}${part3}`
        }

    }

    const DATE = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" name="dob" {...register("dob", { required: "Date Of Birth is required" })} defaultValue={displayDate} onClick={onClick} ref={ref} onChange={(e) => e.target.value = displayDate} autoComplete="off" />
    ));

    return (
        <>
            <h2 className="page_hdng">Add Student</h2>
            <div className='my_card mt-4'>
                <Row>
                    <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
                        <input id="upload" name="upload" type="file" onChange={handleUploadChange} hidden />

                        <Col>
                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Basic Info</h5>
                                    <Button>
                                        <div className='d-flex align-items-center'>
                                            <span className='me-2 mt-1'>Bulk Upload</span>
                                            <span>
                                                <img alt='' src={upload_img} />
                                            </span>
                                        </div>
                                    </Button>
                                </div>
                                <hr />
                                <Row>
                                    <Col className='col-auto'>
                                        <Form.Group controlId="">
                                            <div className='profile_dtls_user_img'>
                                                <img height={100} src={previewImage} alt="profile" onClick={() => document.getElementById("upload").click()} style={{ cursor: 'pointer' }} autoComplete="off" />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type='text' placeholder='First Name' name="first_name" defaultValue={''} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} autoComplete="off" />

                                                    {errors.first_name?.message && <span className="error">{errors.first_name?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type='text' placeholder='Last Name' name="last_name" defaultValue={''} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')} autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="form-group mb-3">
                                                    <Form.Label>DOB</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name="dob"
                                                        placeholder="MM/DD/YYYY"
                                                        {...register("dob", {
                                                            required: "Date Of Birth is required",
                                                            pattern: {
                                                                value: /^\d{2}\/\d{2}\/\d{4}$/,
                                                                message: "Invalid date format (MM/DD/YYYY)"
                                                            }
                                                        })}
                                                        autoComplete="off"
                                                    />

                                                    {errors.dob?.message && <span className="error">{errors.dob?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="6">
                                                <Form.Group controlId="">
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Select aria-label="Default select example" name="division_id" {...register("division_id", { required: 'Class is required' })} >
                                                        <option value="">-- Choose Class --</option>
                                                        {options}
                                                    </Form.Select>

                                                    {errors.division_id?.message && <span className="error">{errors.division_id?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Parent/Guardian Info</h5>
                                </div>
                                <hr />
                                <Row>
                                    <Col lg="11">
                                        <Row>
                                            <Col lg="4" sm="7">
                                                <Form.Group controlId="">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type='text' placeholder='Email' name="contact_email" defaultValue={''} onChange={(event) => setValue('contact_email', event.target.value)} {...register('contact_email', {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Invalid email address"
                                                        }
                                                    })} autoComplete="off" />

                                                    {errors.contact_email?.message && <span className="error">{errors.contact_email?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="7">
                                                <Form.Group controlId="">
                                                    <Form.Label>Parents Name</Form.Label>
                                                    <Form.Control type='text' placeholder='Parents' name="parents_name" defaultValue={''} onChange={(event) => setValue('parents_name', event.target.value)} {...register('parents_name', { required: "First name is required" })} autoComplete="off" />

                                                    {errors.parents_name?.message && <span className="error">{errors.parents_name?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                            <Col lg="4" sm="5">
                                                <Form.Group controlId="">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control placeholder='Phone' name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event, 'student')} onBlur={(event) => formatUSNumber(event, 'student')} autoComplete="off" />

                                                    {errors.phone?.message && <span className="error">{errors.phone?.message}</span>}

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            {/* <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>School Info</h5>
                                </div>
                                <hr/>
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>School Name</Form.Label>
                                            <Form.Control type='text' placeholder='School' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6" sm="7">
                                        <Form.Group controlId="">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" placeholder='Address' />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div> */}

                            <div className="my_card_brand_dark_color mt-3">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='fs-20 fw-500'>Credentials</h5>
                                </div>
                                <hr />
                                <Row>
                                    <Col lg="4" sm="4">
                                        <Form.Group controlId="">
                                            <Form.Label>User Name (Email)</Form.Label>
                                            <Form.Control type='email' placeholder='Enter email' name="email" defaultValue={''} onChange={(event) => setValue('email', event.target.value)} {...register('email', {
                                                required: "User Name is required",
                                                pattern: {
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address"
                                                }
                                            })} autoComplete="off" />

                                            {errors.email?.message && <span className="error">{errors.email?.message}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6" sm="7">
                                        <Form.Group controlId="">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type='password' placeholder='Enter Password' name="password" onChange={e => setValue('password', e.target.value)} {...register("password", { required: "Password is required" })} autoComplete="new-password" />

                                            {errors.password?.message && <span className="error">{errors.password?.message}</span>}

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <Row className='justify-content-lg-end mt-3'>
                                <Col className='col-auto'>
                                    <Button variant='trans_success' size='lg' onClick={() => navigate('/coordinator/students')} >Cancel</Button>
                                </Col>
                                <Col className='col-auto'>
                                    <Button variant='success' size='lg' type="submit" >Save Changes</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Row>
            </div>
        </>
    )
}

export default CoordinatorAddStudentCtrl;