import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Button, Col, Form, Modal, Row} from 'react-bootstrap';
import moment from 'moment-timezone';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';

function BankAccountForm(props) {

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [ displayDate, setDisplayDate ] = useState('')
    const [ date, setDate ] = useState(new Date(moment().tz(TIME_ZONE)))
    const [ banks, setBanks ] = useState([])
    const [ bankOptions, setBankOptions ] = useState(null);
    var isBankInitialized = useRef(false)

    useEffect(() => {

        if(!isBankInitialized.current) {
            getBanks()
        }

        if(banks.length > 0 && !bankOptions) {
            let optionValues =  banks.map((e)=> {
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setBankOptions(optionValues)
        }

        if(Object.keys(props.bankDetails).length > 0) {
            setValue('bank_id', props.bankDetails.bank_id)
            setValue('name', props.bankDetails.accnt_name)
            setValue('held_by', props.bankDetails.held_by)
            setValue('routing_number', props.bankDetails.routing_number)
            setValue('account_number', props.bankDetails.accnt_number)
            setValue('account_type', props.bankDetails.accnt_type)
            setValue('ifsc_code', props.bankDetails.ifsc_code)
        }else {
            reset()
        }

    }, [ banks, props.bankDetails ])

    const getBanks = () => {

        let URL = `${BACKEND_DOMAIN}/banks/list`

        let payload = {
            url: URL
        }

        GET(payload).then(res => {
            
            const { data } = res.data

            isBankInitialized.current = true
            setBanks(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const onSubmit = (data) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/banks/save`,
            data
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            reset()
            props.getTransactions()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const DATE = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" name="expiry_date" placeholder="12/25" {...register("expiry_date", { required: "Exp Date is required" })} defaultValue={displayDate} onClick={onClick} ref={ref} onChange={(e) => e.target.value = displayDate} autoComplete="off" />
    ));

    const handleNumber = (event) => {
        if (event.target.value.length > 16) {
            event.target.value = event.target.value.slice(0, 16)
        }
        setValue('number', event.target.value)
    }

    const handleRoutingNumber = (event) => {
        if (event.target.value.length > 9) {
            event.target.value = event.target.value.slice(0, 9)
        }
        setValue('routing_number', event.target.value)
    }

    return (
      <Modal className="" {...props} aria-labelledby="" centered>
        <Modal.Header closeButton>
          <Modal.Title id="">Add New Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="account-form" onSubmit={handleSubmit(onSubmit)} >
            
                <Form.Group className="mb-3" controlId="bank">
                <Form.Label>Banks</Form.Label>
                    <Form.Select name="bank_id" {...register("bank_id", { 
                        required: "Bank Name is required",
                        })} defaultValue={''} >
                        <option value="" >-- Choose --</option>
                        {bankOptions}
                    </Form.Select>

                    {errors.bank_id ?.message && <span className="error">{errors.bank_id ?.message}</span> }

                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Account Holder's Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder="Noman Manzoor" 
                    {...register("name", { 
                        required: "Name is required",
                    })} autoComplete="off" />

                    {errors.name ?.message && <span className="error">{errors.name ?.message}</span> }

                </Form.Group>

                <Form.Group className="mb-3" controlId="bank">
                    <Form.Label>Account Held By</Form.Label>
                    <Form.Select name="held_by" {...register("held_by", { 
                        required: "This field is required",
                    })} >
                    <option value="" >-- Choose --</option>
                    <option value="individual">Individual</option>
                    <option value="organization">Organization</option>
                    </Form.Select>

                    {errors.held_by ?.message && <span className="error">{errors.held_by ?.message}</span> }

                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Routing Number</Form.Label>
                    <Form.Control type="number" name="routing_number" onInput={handleRoutingNumber} {...register("routing_number", { 
                        required: "Routing Number is required",
                        minLength: {
                            value: 9,
                            message: "Routing Number must be 9 digits"
                        }  
                    })} autoComplete="off" />

                    {errors.routing_number ?.message && <span className="error">{errors.routing_number ?.message}</span> }

                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Account number</Form.Label>
                    <Form.Control type="number" name="account_number" placeholder="9865  1451  2654  2156" onInput={handleNumber} {...register("account_number", { 
                        required: "Account Number is required",
                        minLength: {
                            value: 16,
                            message: "Number must be 16 digits"
                        }  
                    })} autoComplete="off" />

                    {errors.account_number ?.message && <span className="error">{errors.account_number ?.message}</span> }

                </Form.Group>

                <Form.Group className="mb-3" controlId="bank">
                    <Form.Label>Account Type</Form.Label>
                    <Form.Select name="account_type" {...register("account_type", { 
                        required: "Account Type is required",
                    })} >
                    <option value="" >-- Choose --</option>
                    <option value="checking">Checking</option>
                    <option value="savings">Savings</option>
                    </Form.Select>

                    {errors.account_type ?.message && <span className="error">{errors.account_type ?.message}</span> }

                </Form.Group>

                <Row className="justify-content-center my-3">
                    <Col className="col-7">
                        <Form.Group className="form-group d-grid">
                            <div className='d-grid'>
                                <Button variant='success' size='lg' className='mt-3' type="submit" >Submit</Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Modal.Body>
      </Modal>
    );
}


export default BankAccountForm;