import React, { useState, useEffect, useRef } from 'react';
import {Breadcrumb, Button,  Col,Form,FormControl,InputGroup,Modal, Row,Tab, Table} from "react-bootstrap";
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import search from '../../../assets/images/icons/search.png';

import useDivisions from '../../../hooks/useDivisions';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST, PATCH } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';

function SchoolDivisionsCategoriesCtrl(){

    const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();
    const params = useParams();
    const navigate = useNavigate();

    const [ categories, setCategories ] = useState([])
    const [ catDivision, setCatDivision ] = useState({})
    const [ modalTitle, setModalTitle ] = useState('Add Subject')
    const divisions = useDivisions()
    
    const [ keyword, setKeyword ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    var isCategoriesInitialized = useRef(false)

    useEffect(() => {

        if(categories.length === 0 && !isCategoriesInitialized.current) {
            getCategories(keyword)
        }

        if(divisions.length > 0) {
            let catDivFilter = divisions.filter(item => {
                return item.id == params.id
            })
            if(catDivFilter.length > 0) {
                setCatDivision(catDivFilter[0])
            }
        }

    },[ categories, divisions, currentItems, itemOffset, itemsPerPage ]);

    const getCategories = (keyword) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/categories/${params.id}?search=${keyword}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            setIsNoRecords(false)
            setIsLoading(false)
            setCategories(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
            isCategoriesInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const handleModalShow = (type, catId = '') => {

        clearErrors('category')
        if(type == 'add') {

            setModalTitle('Add Subject')
            setValue('category', '')
            setValue('category_id', '')
            setModalShow(true);
        }else {
            
            let catFiltr = categories.filter(item => {
                return item.id == catId
            })
            setModalTitle('Edit Subject')
            setValue('category', catFiltr[0]['title'])
            setValue('category_id', catFiltr[0]['id'])
            setModalShow(true);
        }
    }

    const changeStatus = (id) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/category/status`,
            data: { category_id: id }
        }

        PATCH(payload).then(res => {

            setIsLoading(false)
            toast.success(res.data.message)
            getCategories()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            console.log(err)
        })
    }

    const deleteCategory = (id) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/category/delete`,
            data: { category_id: id }
        }

        POST(payload).then(res => {

            setIsLoading(false)
            toast.success(res.data.message)
            getCategories()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const onSubmit = (data) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/school/divisions/category/save`,
            data
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            setModalShow(false)
            getCategories()

        }).catch(err => {

            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
        })
    }

    const handlePageClick = (event) => {

        let items = categories

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };
    
    return(
        <>        

            <h2 className="page_hdng">Subjects</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="3">
                    <div className="assignments_card">
                        <h4>{categories.length}</h4>
                        <p>Subjects</p>
                    </div>
                </Col>
            </Row>
                        
            <Row className="">
                <Col xl="12" className="mt-3"> 
                    <Row className=" align-items-center">
                        <Col>
                            <Breadcrumb className='mb-0'>
                                <Breadcrumb.Item>
                                    <NavLink to="/school/divisions">Home</NavLink>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item>
                                    <NavLink to={`/school/divisions/${params.id}/categories`}>{Object.keys(catDivision).length > 0 && catDivision.name}</NavLink>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        
                        <Col className="col-auto">
                            <div className='d-flex align-items-center'>
                                <InputGroup className="">
                                    <FormControl size='md' placeholder="search" onChange={(event) => getCategories(event.target.value)} />
                                        <Button variant='secondary' size='md'>
                                            <img width={20} src={search} />
                                        </Button>
                                </InputGroup>
                                
                                <Button variant='success' size='md' className='px-4 ms-2' onClick={() => handleModalShow('add')}>Add Subject</Button>
                            </div>
                        </Col>
                    </Row>

                    <div className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th className='text-start'>Subjects</th>
                                    <th className='text-center'>Chapters</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                { !isLoading && isNoRecords &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                { !isNoRecords && !isLoading && categories.length > 0 &&
                                    currentItems.map((elem, index) => {
                                        return (
                                            <tr key={elem.id}>
                                                <td className="fs-14">
                                                    <NavLink to={`/school/divisions/${params.id}/categories/${elem.id}/sub-categories`} className="text-capitalize">{elem.title}</NavLink>
                                                </td>
                                                <td className='fs-14 text-center'>{elem.sub_categories_count}</td>
                                                <td className='fs-14 text-center'>
                                                    <Form.Check type="switch" id="" checked={elem.status == 'active'} onClick={() => changeStatus(elem.id)} />
                                                </td>
                                                <td className='fs-14 text-center'>
                                                    
                                                    <Button variant='dark'  size='sm' onClick={() => navigate(`/school/divisions/${params.id}/categories/${elem.id}/sub-categories`)} >View Chapters</Button>&nbsp;|&nbsp;
                                                    <span className="badge badge_md" onClick={() => handleModalShow('edit', elem.id)} style={{cursor: 'pointer'}} >
                                                        <img src={mode_edit} />
                                                    </span>
                                                    <span className="badge badge_md" onClick={() => deleteCategory(elem.id)} style={{cursor: 'pointer'}} >
                                                        <img src={rd_trash} />
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        { categories.length > 0 &&
                            <PAGINATION allItems={categories} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} />
                        }
                    </div>                   
                </Col>

            </Row>

            <Modal backdrop="static" show={modalShow} onHide={handleModalClose} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>{modalTitle}</Modal.Title>
                        <p className='fs-20 fw-600'>For Class : <span className='text_brand_color2'>{Object.keys(catDivision).length > 0 && catDivision.name}</span></p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Control type="text" name="division_id" {...register('division_id')} defaultValue={Object.keys(catDivision).length > 0 && catDivision.id}  hidden  autoComplete="off" />
                        <Form.Control type="text" name="category_id" defaultValue={''} {...register('category_id')}  hidden  autoComplete="off" />
                        <Form.Group className="mb-3">
                            <Form.Label>Subject<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type='text' defaultValue={''} name="category" onChange={(event) => setValue('category', event.target.value)} {...register('category', { required: "Category is required" })}  autoComplete="off"  />

                            {errors.category ?.message && <span className="error">{errors.category ?.message}</span> }
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <div className='d-flex align-items-center justify-content-between'>
                                <Form.Label>Sub Category (if any)</Form.Label>
                                <Button variant="link" className='p-0 fs-16 fw-400 text_brand_color3'>Add more</Button>
                            </div>
                            <Form.Control type='text' value="Chapter 1"  />
                        </Form.Group> */}
                        <Button className='btn-block' size='lg' variant="success" type="submit" >Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default SchoolDivisionsCategoriesCtrl;