import React, { useState, useEffect } from 'react';
import {InputGroup, Col, Button,Form,Modal, Row, ProgressBar } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import check from '../../../assets/images/icons/check_lg.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';

import { BACKEND_DOMAIN, MERGER_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import useDivisions from '../../../hooks/useDivisions';
import ViewAssignmentModal from '../../../components/common/assignmentModal';

function CoordinatorRequestAssignments(props){

    const { control, register, handleSubmit, setValue, setError, clearErrors, reset, formState: { errors } } = useForm();
    const navigate = useNavigate()

    const [ progressModalShow, setProgressModalShow ] = useState(false);
    const [ progressModalText, setProgressModalText ] = useState('Upload is in progress');
    const [ modalShow, setShow ] = useState(false);
    const [ viewAssignmentModal, setViewAssignmentModal ] = useState(false);
    const handleModalClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const divisions = useDivisions()
    const [ categories, setCategories ] = useState([])
    const [ subCategories, setSubCategories ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ divisionOptions, setDivisionOptions ] = useState(null)
    const [ categoryOptions, setCategoryOptions ] = useState(null)
    const [ subCatOptions, setSubCatOptions ] = useState(null)
    const [ file, setFile ] = useState(null)
    const [ fileName, setFileName ] = useState('')
    const [ thumbnail, setThumbnail ] = useState(null)
    const [ thumbnailName, setThumbnailName ] = useState('')
    const [ assignmentDetails, setAssignmentDetails ] = useState(null)
    const [ type, setType ] = useState('video')
    const [ categoryName, setCategoryName ] = useState('')
    const [ divisionName, setDivisionName ] = useState('')
    const [ newCatShow, setNewCatShow ] = useState('block')
    const [ newSubCatShow, setNewSubCatShow ] = useState('block')
    const [ newDivShow, setNewDivShow ] = useState('block')
    const [ progressBar, setProgressBar ] = useState(0)

    const user = props.user

    useEffect(() => {

        if(divisions.length > 0 && !divisionOptions) {
            let divisionValues =  divisions.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setDivisionOptions(divisionValues)
        }

        if(categories.length > 0) {
            let categoryValues =  categories.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.title}</option>
                )
            }) 
            setCategoryOptions(categoryValues)
        }

        if(subCategories.length > 0) {
            let subCatValues =  subCategories.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.title}</option>
                )
            }) 
            setSubCatOptions(subCatValues)
        }

    },[ divisions, categories, subCategories]);

    const getCategories = (divId) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/divisions/categories?division=${divId}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setIsLoading(false)
            setCategories(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
            setIsLoading(false)
            // console.log(err)
            
            if(err.response.status === 404) {
                setCategories([])
                setCategoryOptions(null)
                setSubCategories([])
                setSubCatOptions(null)
            }
        })

    }

    const getSubCategories = (catId) => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/coordinator/divisions/sub-categories?category=${catId}`
        }

        GET(payload).then(res => {
            const { data } = res.data

            setIsLoading(false)
            setSubCategories(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            if(err.response &&  err.response.status === 404) {
                setSubCategories([])
                setSubCatOptions(null)
            }
            
            setIsLoading(false)
            console.log(err)
        })

    }

    // const isValidFileUploaded=(file, uploadType)=>{
    //     let validExtensions = []
    //     if(type == 'audio') {
    //         validExtensions = ['mp3', 'mpeg']
    //     }
    //     if(type == 'video') {
    //         validExtensions = ['mp4', ]
    //     }
    //     if(type == 'document') {
    //         validExtensions = ['pdf']
    //     }
    //     let fileExtension = file.type.split('/')[1]

    //     if(uploadType == 'thumbnail') {
    //         if(/image/.test(file.type)) {
    //             return true
    //         }
    //     }

    //     return validExtensions.includes(fileExtension)
    // }

    const isValidFileUploaded = (file, uploadType) => {
        var validMimeTypes = [];
    
        // Check for audio MIME types
        if (type === 'audio') {
            validMimeTypes = ['audio/mp3', 'audio/mpeg'];
        }
    
        // Check for video MIME types, including .mov (which has the MIME type video/quicktime)
        if (type === 'video') {
            validMimeTypes = ['video/mp4', 'video/quicktime']; // Added video/quicktime for .mov files
        }
    
        // Check for document MIME types
        if (type === 'document') {
            validMimeTypes = ['application/pdf'];
        }
    
        // Check for image MIME types if the upload is for thumbnails
        if (type === 'thumbnail') {
            if (/image/.test(file.type)) {
                return true;
            }
        }
    
        // Return true if the MIME type matches the valid types
        // console.log(validMimeTypes);
        
        return validMimeTypes.includes(file.type);
    };
    
    
    

    const handleUpload = event => {

        clearErrors('content')
        let files = event.target.files

        if(files && files !== undefined && files !== null){ 

            // if(files[0].size > 104857600.04) {
            //     setFile(null)
            //     setError('content', { type: 'custom', message: 'File size is too large.' });
            //     return
            // }
    // console.log(files[0]);
    
            if(isValidFileUploaded(files[0], 'content')){
                setFileName(files[0].name)
                setFile(files[0])
            }else {
                setFile(null)
                setError('content', { type: 'custom', message: 'Invalid file type' });
                return
            }

        }

    }

    const handleThumbnailUpload = event => {

        clearErrors('thumbnail')
        let files = event.target.files

        if(files && files !== undefined && files !== null){ 
    
            if(isValidFileUploaded(files[0], 'thumbnail')){
                setThumbnailName(files[0].name)
                setThumbnail(files[0])
            }else {
                setThumbnail(null)
                setError('thumbnail', { type: 'custom', message: 'File type must be an image' });
                return
            }

        }

    }

    const onSubmit = (data) => {

        if (!file && !data.url ) {
            setError("neitherFields", {
              type: "manual",
              message: "File or URL field is required"
            });
            return;
        }

        if (!data.category && !data.new_cat ) {
            setError("new_cat", {
              type: "manual",
              message: "Subject is required"
            });
            return;
        }

        if (!data.division && !data.new_div ) {
            setError("new_div", {
              type: "manual",
              message: "Class is required"
            });
            return;
        }

        if (!data.sub_category && !data.new_sub_cat ) {
            setError("new_sub_cat", {
              type: "manual",
              message: "Chapter is required"
            });
            return;
        }

        const formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("assignment_name", data.assignment_name);
        formData.append("category", data.category);
        formData.append("division", data.division);
        formData.append("sub_category", data.sub_category);
        formData.append("type", data.type);
        formData.append("new_cat", data.new_cat);
        formData.append("new_sub_cat", data.new_sub_cat);
        formData.append("new_div", data.new_div);
        if(thumbnail) {
            formData.append("thumbnail", thumbnail);
        }
        if(file) {
            formData.append("contentFile", file);
        }else {
            formData.append("url", data.url);
        }

        let payload = {
            url : `${MERGER_DOMAIN}/api/v1/original-content`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }

        let authUser = localStorage.getItem('user')
        let token = ''

        if(authUser) {
            authUser = JSON.parse(authUser)
            token = authUser['jwt_token']
        }

        let headerFields = {
            'Content-Type': 'multipart/form-data',
            'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
            'x-access-token': token
        }

        let validationPayload = {
            url : `${BACKEND_DOMAIN}/assignments/validate`,
            data
        }

        POST(validationPayload).then(() => {

            setProgressModalText('Upload is in progress')
            setProgressModalShow(true)

            axios.post(payload.url, payload.data, { headers: headerFields, withCredentials: true,
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    let progress = Math.round((100 * data.loaded) / data.total)

                    if(progress === 100) {
                        setProgressModalText('Upload Success!')
                    }
                    setProgressBar(progress)
                }
            })   
            .then((res) => {
                const { data } = res.data
    
                setProgressModalShow(false)
                reset()
                setFile(null)
                setThumbnail(null)
                setType('video')
                setCategoryOptions(null)
                setSubCatOptions(null)
                setAssignmentDetails({
                    ...data,
                    category: categoryName, 
                    division: divisionName
                })
                handleShow()
                navigate(`/coordinator/assignments`)
            })
            .catch((err) => {
                console.log(err)
    
                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
                
                setProgressModalText('Upload Failed :(')
                setProgressModalShow(false)
                if(err.response && (err.response.status === 424 || err.response.status === 409 || err.response.status === 403)) {
                    toast.error(err.response.data.message)
                }else if(err.message){
                    toast.error(err.message)
                }
    
            })

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response && err.response.status === 403) {
                toast.error(err.response.data.message)
            }

            if(err.response && err.response.data) {
                const { data } = err.response.data
                let serverErrors = data
                serverErrors.forEach(errItem => {
                    setError(errItem.field, {
                        type: "server",
                        message: errItem.message,
                    });
                });
            }
           
        })

    }

    const onChangeCategory = event => {
        let catVal = event.target.value
        let catFilter = categories.filter(item => {
            return event.target.value == item.id
        })
        setValue('new_cat', '')
        
        if(!catVal) {
            setNewCatShow('block')
        }else {
            setCategoryName(catFilter[0]['title'])
            setNewCatShow('none')
        }
        getSubCategories(event.target.value)
    }

    const onChangeSubCategory = event => {
        let subCatVal = event.target.value

        setValue('new_sub_cat', '')
        if(!subCatVal) {
            setNewSubCatShow('block')
        }else {
            setNewSubCatShow('none')
        }
    }

    const onChangeDivision = event => {
        let divVal = event.target.value
        let divFilter = divisions.filter(item => {
            return event.target.value == item.id
        })
        setValue('new_div', '')

        if(!divVal) {
            setNewDivShow('block')
        }else {
            // getCategories(event.target.value)
            setDivisionName(divFilter[0]['name'])
            setNewDivShow('none')
        }
        getCategories(event.target.value)
    }

    return(
        <>        

            { isLoading && 
                <div className='text-center subscriptions_packages_name no_records_loading'>
                    <h5>Loading...</h5>
                </div>
            }
                <>
                    <h2 className="page_hdng">Create Assignment</h2>
                    <div className='my_card mt-4'>
                        <div className="my_card_brand_dark_color py-5">
                        <div className='d-flex align-items-center justify-content-end'>
                            <NavLink to="/coordinator/assignments">
                                <Button variant='trans_success'>Back</Button>
                            </NavLink>
                        </div>
                        <hr/>
                            <Row className='justify-content-center'>
                                <Col md="8">
                                    <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
                                        <Row className='g-5'>
                                            <Col sm={6}>
                                                <Form.Label>Assignment Name</Form.Label>
                                                <Form.Group className="mb-3" controlId="assignments">
                                                    <Form.Control type="text" name="assignment_name" placeholder="Name" {...register('assignment_name', { required: "Assignment name is required" })} autoComplete="off" />

                                                    {errors.assignment_name ?.message && <span className="error">{errors.assignment_name ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label>Class</Form.Label>
                                                <Form.Group className="mb-3" controlId="class">
                                                    <Form.Select name="division" {...register("division")} onChange={(event) => onChangeDivision(event)} disabled={!divisionOptions} >
                                                            <option value="" >-- Other --</option>
                                                            {divisionOptions}
                                                    </Form.Select>

                                                    {errors.division ?.message && <span className="error">{errors.division ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            {/* <Col sm={6} style={{ display: newDivShow}} >
                                                <Form.Label>New Class</Form.Label>

                                                <Form.Group className="mb-3" controlId="new_div">

                                                    <Form.Select name="new_div" {...register("new_div")} >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                        <option value={11}>11</option>
                                                        <option value={12}>12</option>
                                                    </Form.Select>

                                                    {errors.new_div ?.message && <span className="error">{errors.new_div ?.message}</span> }

                                                </Form.Group>
                                            </Col> */}
                                            <Col sm="6" style={{display: newDivShow}} >
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Control type="text" name="new_div" onChange={(event) => setValue('new_div', event.target.value)} {...register('new_div')} autoComplete="off" />

                                                    {errors.new_div && <span className="error">{errors.new_div.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='g-5'>
                                            <Col sm={6}>
                                                <Form.Label>Subject</Form.Label>
                                                <Form.Group className="mb-3" controlId="category">
                                                    <Form.Select name="category" {...register("category")} disabled={!categoryOptions ? true : false} onChange={(event) => onChangeCategory(event)} >
                                                        <option value="" >-- Other --</option>
                                                        {categoryOptions}
                                                    </Form.Select>

                                                    {errors.category ?.message && <span className="error">{errors.category ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} style={{ display: newCatShow}}>
                                                <Form.Label>New Subject</Form.Label>
                                                <Form.Group className="mb-3" controlId="new_cat">
                                                    <Form.Control type="text" name="new_cat" placeholder="Name" {...register('new_cat')} autoComplete="off" />

                                                    {errors.new_cat ?.message && <span className="error">{errors.new_cat ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label>Chapter</Form.Label>
                                                <Form.Group className="mb-3" controlId="sub_category">
                                                    <Form.Select name="sub_category" {...register("sub_category")} disabled={!subCatOptions ? true : false} onChange={(event) => onChangeSubCategory(event)} >
                                                        <option value="">-- Other --</option>
                                                        {subCatOptions}
                                                    </Form.Select>

                                                    {errors.sub_category ?.message && <span className="error">{errors.sub_category ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} style={{ display: newSubCatShow}}>
                                                <Form.Label>New Chapter</Form.Label>
                                                <Form.Group className="mb-3" controlId="new_category">
                                                    <Form.Control type="text" name="new_sub_category" placeholder="Name" {...register('new_sub_cat')} autoComplete="off" />

                                                    {errors.new_sub_cat ?.message && <span className="error">{errors.new_sub_cat ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label>Content Type</Form.Label>
                                                <Form.Group className="mb-3" controlId="contentType">
                                                    <Form.Select name="type" {...register("type", { required: 'Type is required' })} onChange={e => {setFile(null); setType(e.target.value)} } >
                                                        <option value="video" >Video</option>
                                                        <option value="audio" >Audio</option>
                                                        <option value="document" >Document</option>
                                                    </Form.Select>

                                                    {errors.type ?.message && <span className="error">{errors.type ?.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        
                                        <Row className='align-items-center g-3'>
                                            
                                        {/* { type == 'video' && 
                                            <Col>
                                             <Form.Label>Choose Thumbnail</Form.Label>
                                                { thumbnail &&
                                                    <span className="badge badge_md float-end" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => setThumbnail(null)} >
                                                        <img src={rd_trash} />
                                                    </span>
                                                }
                                                
                                                 <Form.Group className="form-group mb-3" controlId="">
                                                    <div className='choosen_file_control d-flex align-items-center justify-content-between' style={{opacity: type !== 'video' && 0.5}}>
                                                        <label>{thumbnail ? thumbnailName : 'No File Chosen' }</label>
                                                        <div className="upload_btn_wrapper asignment">
                                                            <button className="attach_btn bg-brand_color2 text_brand_color1 fw-500 m-0" style={{background: type !== 'video' && '#5de09466'}}>
                                                                <span className='ms-2'>Choose</span>
                                                            </button>
                                                            <Controller
                                                                name="thumbnail"
                                                                control={control}
                                                                render={({ field: { ref, ...field } }) => (
                                                                    <Form.Control 
                                                                    {...field} 
                                                                    innerRef={ref} 
                                                                    type="file"
                                                                    accept="image/*" 
                                                                    onChange={(e) => {
                                                                        handleThumbnailUpload(e)
                                                                        clearErrors("thumbnail");
                                                                    }}
                                                                    disabled={(type !== 'video') ? true : false} />
                                                                )}
                                                            />
                                                            
                                                        </div>
                                                        
                                                    </div>

                                                    {errors.thumbnail && <span className="error">{errors.thumbnail.message}</span> }

                                                </Form.Group>   

                                            </Col>
                                        } */}
                                            

                                            <Col sm={6}>
                                                <Form.Label>Choose file <span style={{color: '#fff'}}>(File size should be less than 100 mb)</span></Form.Label>
                                                { file &&
                                                    <span className="badge badge_md float-end" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => setFile(null)} >
                                                        <img src={rd_trash} />
                                                    </span>
                                                }
                                                
                                                <Form.Group className="form-group mb-3" controlId="">
                                                    <div className='choosen_file_control d-flex align-items-center justify-content-between'>

                                                        <label>{file ? (fileName.length > 19 ? fileName.substring(0, 19) + "..." : fileName ) : 'No File Chosen' }</label>
                                                        <div className="upload_btn_wrapper">
                                                            <button className="d-flex attach_btn bg-brand_color2 text_brand_color1 fw-500 m-0" style={{cursor: 'pointer'}}>
                                                                <span className='ms-2'>Choose</span>
                                                            </button>
                                                            <Controller
                                                                name="content"
                                                                control={control}
                                                                render={({ field: { ref, ...field } }) => (
                                                                    <Form.Control 
                                                                    {...field} 
                                                                    innerRef={ref} 
                                                                    type="file"
                                                                    accept={type == 'audio' ? "audio/mp3" : type == 'video' ? "video/*, video/quicktime" : "application/pdf" } 
                                                                    onChange={(e) => {
                                                                        handleUpload(e)
                                                                        clearErrors("neitherFields");
                                                                    }} />
                                                                )}
                                                            />
                                                            
                                                        </div>
                                                        
                                                    </div>

                                                    {errors.content && <span className="error">{errors.content.message}</span> }
                                                    {errors.neitherFields && <span className="error">{errors.neitherFields.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            {/* <Col className='col-auto'>
                                                <span className='d-block pt-3 text_white_color_half'>Or</span>
                                            </Col>
                                            <Col>
                                                <Form.Label>URL</Form.Label>
                                                <Form.Group className="mb-3" controlId="url">
                                                    <InputGroup className="assignment">
                                                        <Controller
                                                            name="url"
                                                            control={control}
                                                            render={({ field: { ref, ...field } }) => (
                                                                <Form.Control 
                                                                {...field} 
                                                                innerRef={ref} 
                                                                type="text" 
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    setValue('url', e.target.value)
                                                                    setFile(null);
                                                                    clearErrors("neitherFields");
                                                                }} disabled={file ? true : false} />
                                                            )}
                                                        />
                                                    </InputGroup>
                                                    
                                                    {errors.neitherFields && <span className="error">{errors.neitherFields.message}</span> }

                                                </Form.Group>
                                            </Col> */}
                                        </Row>
                                        
                                        <Row className='justify-content-center mt-4'>
                                            <Col sm="5">
                                                <Form.Group className="d-grid">
                                                    <Button size='lg' variant="success" type="submit" disabled={isLoading && true} >Submit</Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>


                    <Modal size="sm" show={modalShow} onHide={handleModalClose} centered>
                        <Modal.Header className='justify-content-center'>
                            <div className='text-center w-100'>
                                <Modal.Title className='fs-35 fw-700'>
                                    <img src={check} />
                                </Modal.Title>
                                <p className='fs-20 fw-600 mt-3'>Request sent Successfully!</p>
                                <Button onClick={() => {handleModalClose(); setViewAssignmentModal(true)}} size='lg' variant="success" className='btn-block'>View Assignment</Button>
                            </div>
                        </Modal.Header>
                    </Modal>
                </>

            <ViewAssignmentModal state={assignmentDetails} show={viewAssignmentModal} onHide={() => setViewAssignmentModal(false)} />


            <Modal size="sm" show={progressModalShow} id="progress-modal" backdrop="static" centered>
                <Modal.Header className='justify-content-center'>
                    <div className='text-center w-100'>
                        <p className='progress-modal' id="progress-text">{progressModalText}</p>
                        <ProgressBar now={progressBar} /><span>{progressBar}%</span>
                    </div>
                </Modal.Header>
            </Modal>

            {/* <div class="modal fade" id="progress-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="progressModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <p class="text-center" id="progress-text">Upload is in Progress!</p>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> */}

        </>
    )
}

const mapStateToProps = state => {
    return {
       user: state.authenticate
    }
}

export default connect(mapStateToProps, null)(CoordinatorRequestAssignments);