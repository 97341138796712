import React, { useState } from 'react';
import {Col, Button,Row,Dropdown,Form, FloatingLabel } from "react-bootstrap";
import dummy_img from '../../../assets/images/dummy.png';
import paper_clip from '../../../assets/images/icons/paper_clip.png';

function InterpreterSupportChatCtrl(){
    
    return(
        <>
        <h2 className="page_hdng">support Chat</h2>
            <div className='my_card mt-4'>
                <Row className='align-items-center justify-content-between'>
                    <Col className='col-auto'>
                        <Button variant='success'>Your Ticket No 9845651266</Button>
                    </Col>
                    <Col className='col-auto'>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" size='md'>Urgent</Dropdown.Toggle>
                            <Dropdown.Menu variant="dark">
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="my_card_brand_dark_color mt-3">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='chat_active_uesr'>
                                    <img src={dummy_img} />
                                    <div className=''>
                                        <h5 className='fs-20 fw-600 m-0'>Olive</h5>
                                        <span className='online_mark'>online</span>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='chat_msg_container'>
                                <ul className='chat_msg_list'>
                                    <li className='chat_msg_list_item msg_me'>
                                        <div className='chat_msg'>Hello There</div>
                                    </li>
                                    <li className='chat_msg_list_item msg_other'>
                                        <div className='chat_msg'>How can we Help you</div>
                                    </li>
                                    <li className='chat_msg_list_item msg_me'>
                                        <div className='chat_msg'>27-Jul-2022 — to put an instruction or piece of information into your computer by pressing letters, numbers or other keys on your computer keyboard: Type ...</div>
                                    </li>
                                </ul>
                                <FloatingLabel controlId="floatingTextarea" label="Type something" className="chat_textarea" >
                                    <Form.Control as="textarea" placeholder="Type something"  autoComplete="off" />
                                    <div className='chat_textarea_buttons'>
                                        <div className="upload_btn_wrapper">
                                            <button className="attach_btn">
                                                <img height="15" src={paper_clip} />
                                                <span className='ms-2'>Attach</span>
                                            </button>
                                            <Form.Control  type="file" name="myfile"  autoComplete="off" />
                                        </div>
                                        <Button variant='success'>Send</Button>
                                    </div>
                                </FloatingLabel>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InterpreterSupportChatCtrl;